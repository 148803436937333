import React from "react";

import "./HomePageFooter.css";
// import { openModal, showSignUpForm } from "../../redux/slices/formDetails";
// import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function HomePageFooter() {

    const dispatch = useDispatch();
    // const handleClick = () => dispatch(openModal());
    // const { isLoggedIn } = useSelector((state) => state.user);
    // const [selected, setSelected] = useState(0);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // const onClickSignUp = () => {
    //     // if (!isLoggedIn) {
    //     handleClick();
    //     dispatch(showSignUpForm());
    //     // }
    // };

    // const onClickTab = (tab) => {
    //     if (selected !== tab) {
    //         setSelected(tab);
    //     } else {
    //         setSelected(0);
    //     }
    // };

    return (
        <div className="HMP1Footer">
            <div className="HMP1FooterContainer">
                <div className="HMP1FooterContainer_inner">
                    {/* <img src="./assets/logo.png" /> */}
                    <h3>Features</h3>
                    <a href='/templates/all' target="_blank"> Templates</a>

                    <h3 style={{ paddingTop: "1rem" }}>Compare</h3>
                    <a href='/help/duonut-a-free-typeform-alternative' target="_blank">Typeform Alternative</a>
                    <a href='/help/best-free-jotform-alternative' target="_blank">Jotform Alternative</a>

                </div>
                <div className="HMP1FooterContainer_inner">
                    <h3>Integrations</h3>
                    <a href='/integrations/slack' target="_blank"> Slack</a>
                    <a href='/integrations/hubspot' target="_blank"> HubSpot</a>

                    <h3 style={{ paddingTop: "1rem" }}>Resources</h3>
                    <a href='/help/how-to-integrate-duonut-with-slack' target="_blank">Integration for Slack</a>
                    <a href='/help/how-to-integrate-duonut-with-hubspot' target="_blank">HubSpot Integration</a>
                    {/* <a href='/blog' target="_blank">Blog</a> */}
                    <a href='https://duonut.com/blog' target="_blank">Blog</a>

                </div>

                {/* <div className="HMP1FooterContainer_inner">
                    <h3>Templates</h3>
                    <div onClick={() => onClickTab(1)} >Popular templates
                        <svg style={{ transform: selected === 1 ? "rotate(180deg)" : "none", padding: "2px 4px" }} width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.26342 2.97603L2.24581 0.958417C1.6984 0.410996 0.81085 0.410997 0.263428 0.958418L4.26342 4.95841L8.26343 0.958418C7.71597 0.410997 6.82845 0.410997 6.28103 0.958418L4.26342 2.97603Z" fill="currentColor"></path>
                        </svg>
                    </div>
                    {selected === 1 && <div style={{ paddingLeft: "10px" }}>
                        <a href='/templates/education_&_learning/customer-service-template' target="_blank">Customer Service Template</a>
                        <a href='/templates/marketing_templates/feedback-template-1' target="_blank">Feedback Template</a>
                        <a href='/templates/education_&_learning/quiz-for-parents-special-kid' target="_blank">Quiz for Parents</a>
                    </div>}
                    <div onClick={() => onClickTab(2)} >Recent templates
                        <svg style={{ transform: selected === 2 ? "rotate(180deg)" : "none", padding: "2px 4px" }} width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.26342 2.97603L2.24581 0.958417C1.6984 0.410996 0.81085 0.410997 0.263428 0.958418L4.26342 4.95841L8.26343 0.958418C7.71597 0.410997 6.82845 0.410997 6.28103 0.958418L4.26342 2.97603Z" fill="currentColor"></path>
                        </svg>
                    </div>
                    {selected === 2 && <div style={{ paddingLeft: "10px" }}>
                        <a href='/templates/education_&_learning/customer-service-template' target="_blank">Customer Service Template</a>
                        <a href='/templates/marketing_templates/feedback-template-1' target="_blank">Feedback Template</a>
                        <a href='/templates/education_&_learning/quiz-for-parents-special-kid' target="_blank">Quiz for Parents</a>
                    </div>}
                    <div onClick={() => onClickTab(3)} >Popular categories
                        <svg style={{ transform: selected === 3 ? "rotate(180deg)" : "none", padding: "2px 4px" }} width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.26342 2.97603L2.24581 0.958417C1.6984 0.410996 0.81085 0.410997 0.263428 0.958418L4.26342 4.95841L8.26343 0.958418C7.71597 0.410997 6.82845 0.410997 6.28103 0.958418L4.26342 2.97603Z" fill="currentColor"></path>
                        </svg>
                    </div>
                    {selected === 3 && <div style={{ paddingLeft: "10px" }}>
                        <a href='/templates/marketing_templates' target="_blank">Marketing</a>
                        <a href='/templates/education_&_learning' target="_blank">Education & Learning</a>
                        <a href='/templates/general' target="_blank">General</a>
                    </div>}
                    <div onClick={() => onClickTab(4)} >Recent categories
                        <svg style={{ transform: selected === 4 ? "rotate(180deg)" : "none", padding: "2px 4px" }} width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.26342 2.97603L2.24581 0.958417C1.6984 0.410996 0.81085 0.410997 0.263428 0.958418L4.26342 4.95841L8.26343 0.958418C7.71597 0.410997 6.82845 0.410997 6.28103 0.958418L4.26342 2.97603Z" fill="currentColor"></path>
                        </svg>
                    </div>
                    {selected === 4 && <div style={{ paddingLeft: "10px" }}>
                        <a href='/templates/marketing_templates' target="_blank">Marketing</a>
                        <a href='/templates/education_&_learning' target="_blank">Education & Learning</a>
                        <a href='/templates/general' target="_blank">General</a>
                    </div>}
                </div> */}

                <div className="HMP1FooterContainer_inner">
                    <h3>Company</h3>
                    <a href='/pricing' target="_blank">Pricing</a>
                    <a href='/privacypolicy' target="_blank">Privacy Policy</a>
                    <a href='/termsandconditions' target="_blank">Terms and Conditions</a>
                    <a href='/cookiepolicy' target="_blank">Cookie Policy</a>
                    <a href='/contactus' target="_blank">Contact Us</a>
                </div>
            </div>

            <div className="HMP1copyright">Copyright {currentYear}  | Duonut Inc | hello@duonut.com</div>

            <div style={{
                position: "absolute", bottom: "0", width: "100%", display: "flex",
                justifyContent: "center", alignItems: "center", padding: "8px", background: "rgb(244, 249, 252)"
            }}>
                &#128274;  Data securely stored with AWS in the USA <img style={{ width: "2rem", paddingLeft: "8px" }} src={require("../../assets/Images/USA.png")} loading="lazy" alt="duonut" />
            </div>
        </div >
    );
}
