import React, { useEffect, useState, useRef } from "react";
import "./GmailIntegration.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateGmailIntegration } from "../../redux/slices/duonutSettings";
import { domain } from "../../services/constants";
import { useGoogleLogin } from '@react-oauth/google';
import { getUserDetails } from "../../services/auth";
import { updateGmailKey } from "../../redux/slices/user";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";

const GmailIntegration = ({ setSelected }) => {
    const dispatch = useDispatch();
    const { title, gmail_integration } = useSelector(state => state.duonutSettings);
    const { gmail_key } = useSelector((state) => state.user);
    const { selectedUserActivity, userActivities, textFormData } = useSelector((state) => state.userActivity);
    const [clickAuthButton, setclickAuthButton] = useState(0);
    const [duonutConnected, setduonutConnected] = useState(false);
    const [accessKey, setaccessKey] = useState("");
    const [refreshKey, setrefreshKey] = useState("");
    const [isSave, setisSave] = useState(false);
    const [selectedTrigger, setselectedTrigger] = useState("");
    const [selectedStep, setselectedStep] = useState(-1);
    const [toEmail, settoEmail] = useState("");
    const [subject, setsubject] = useState("");
    const [message, setmessage] = useState("");
    const [suggestedVar, setSuggestedVar] = useState([]);
    const [suggestedVar2, setSuggestedVar2] = useState([]);
    const [suggestedVar3, setSuggestedVar3] = useState([]);
    const [popUpPositionX, setPopUpPositionX] = useState(null)
    const [popUpPositionY, setPopUpPositionY] = useState(null)
    const suggestionRef = useRef(null)
    const suggestionRef2 = useRef(null)
    const suggestionRef3 = useRef(null)

    // console.log("textFormData", textFormData);

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post(
                'https://oauth2.googleapis.com/token',
                new URLSearchParams({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET, // Replace with your client secret
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                })
            );
            // console.log(response.data)
            setaccessKey(response.data.access_token);
            // return response.data.access_token;
        } catch (error) {
            console.error('Error refreshing access token', error);
            throw error;
        }
    };

    useEffect(() => {
        if (gmail_integration?.access_key) {
            // console.log("access")
            setaccessKey(gmail_integration.access_key);
            setrefreshKey(gmail_integration.refresh_key);
            setselectedTrigger(gmail_integration.trigger);
            setselectedStep(gmail_integration.step);
            settoEmail(gmail_integration.to);
            setsubject(gmail_integration.subject);
            setmessage(gmail_integration.message);
            setclickAuthButton(2);
            setduonutConnected(true);

        } else if (gmail_key) {
            refreshAccessToken(gmail_key);

            setrefreshKey(gmail_key);
            setselectedTrigger("");
            setselectedStep("");
            settoEmail("");
            setsubject("");
            setmessage("");
            setclickAuthButton(2);
        } else {
            setaccessKey("");
            setrefreshKey("");
            setselectedTrigger("");
            setselectedStep("");
            settoEmail("");
            setsubject("");
            setmessage("");
            setclickAuthButton(0);
            setduonutConnected(false);
        }
    }, []);

    const exchangeCodeForTokens = async (authorizationCode) => {

        const response = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
                grant_type: 'authorization_code',
                redirect_uri: 'https://app.duonut.com', // Redirect URI configured in Google Cloud Platform
                code: authorizationCode,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to exchange code for tokens');
        }

        const data = await response.json();
        // console.log("qqq", data)
        return data;
    };


    const handleAuthentication = () => {
        if (clickAuthButton === 2) {
            setaccessKey("");
            setrefreshKey("");
            setselectedTrigger("");
            setselectedStep("");
            settoEmail("");
            setsubject("");
            setmessage("");
            setclickAuthButton(0);
            const gmailData = {}
            dispatch(updateGmailIntegration(gmailData));

            const data = {
                userId: localStorage.getItem("userId"),
            };
            fetch(`${domain}/duonut/gmail_api`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then((res) => {
                // console.log(res);
                getUserDetails((err, userDetails) => {
                    if (err) return;
                    dispatch(updateGmailKey(userDetails.data.gmail_key));

                });

            }).catch((e) => {
                console.log(e.message, e);
            });

            return null;
        }

        setclickAuthButton(1);
        googleLogin();

    }

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            // console.log(tokenResponse);
            try {
                const tokenData = await exchangeCodeForTokens(tokenResponse.code);
                // console.log(tokenData);
                // Access token in tokenData.access_token
                // Refresh token (if available) in tokenData.refresh_token
                const accessToken = tokenData.access_token;
                const refreshToken = tokenData.refresh_token;
                setaccessKey(accessToken);
                setrefreshKey(refreshToken);

                // sendEmail(accessToken);

                setclickAuthButton(2);

                const data = {
                    key: refreshToken,
                    userId: localStorage.getItem("userId"),
                };
                fetch(`${domain}/duonut/gmail_api`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                }).then((res) => {
                    // console.log(res);
                    getUserDetails((err, userDetails) => {
                        if (err) return;
                        dispatch(updateGmailKey(userDetails.data.gmail_key));

                    });

                }).catch((e) => {
                    console.log(e.message, e);
                });
            } catch (error) {
                console.error('Failed to exchange code for tokens:', error);
            }

        },
        onFailure: error => {
            console.error('Login failed', error);
        },
        scope: 'https://www.googleapis.com/auth/gmail.send',
        flow: 'authorization_code',
        codeExchange: exchangeCodeForTokens,
    });


    const sendEmail = async (accessToken) => {
        var email_id = "saket555raj@gmail.com";
        var subject = "Test Email";
        var message = "This is a test email sent from Gmail API.";

        const email = [
            `To: ${email_id}`,
            `Subject: ${subject}`,
            '',
            `${message}`,
        ].join('\n');

        const base64EncodedEmail = btoa(unescape(encodeURIComponent(email)));

        try {
            await axios.post(
                'https://www.googleapis.com/gmail/v1/users/me/messages/send',
                {
                    raw: base64EncodedEmail,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Email sent successfully');
        } catch (error) {
            console.error('Error sending email', error);
        }
    };

    const handleSaveGmail = (e) => {
        e.preventDefault();

        setisSave(true);
        setTimeout(() => {
            setisSave(false);
            setduonutConnected(true);
        }, 3000);

        const gmailData = {
            access_key: accessKey,
            refresh_key: refreshKey,
            trigger: selectedTrigger,
            step: selectedStep,
            to: toEmail,
            subject: subject,
            message: message,
        }
        // console.log(gmailData);
        dispatch(updateGmailIntegration(gmailData));

    };

    const handleDisconnect = () => {
        const gmailData = {}
        dispatch(updateGmailIntegration(gmailData));
        setselectedTrigger("");
        setselectedStep("");
        settoEmail("");
        setsubject("");
        setmessage("");
        setduonutConnected(false);
    }

    const handleClosesug = () => setSuggestedVar([])
    const handleClosesug2 = () => setSuggestedVar2([])
    const handleClosesug3 = () => setSuggestedVar3([])
    useOutsideAlerter(suggestionRef, handleClosesug);
    useOutsideAlerter(suggestionRef2, handleClosesug2);
    useOutsideAlerter(suggestionRef3, handleClosesug3);

    const handleChangeToEmail = (e) => {

        const inputValue = e.target.value;

        // Check if "@" is entered
        const atIndex = inputValue.indexOf("@");
        if (atIndex !== -1 && Object.keys(textFormData).length > 0) {
            // Extract the text after "@" until the cursor position
            const textAfterAt = inputValue.substring(atIndex + 1, e.target.selectionStart);

            // Filter formData keys that match the entered text
            const matchingKeys = Object.keys(textFormData).filter((key) =>
                key.toLowerCase().includes(textAfterAt.toLowerCase())
            );

            const cursorRect = e.target.getBoundingClientRect();
            // console.log(e, cursorRect)

            setPopUpPositionX(cursorRect.width);
            setPopUpPositionY(cursorRect.height);

            // Display suggestions
            setSuggestedVar(matchingKeys);
        } else {
            // Clear suggestions if "@" is not present
            setSuggestedVar([]);
        }

        settoEmail(e.target.value);
    };

    const handleChooseSuggestion = (selectedSuggestion) => {
        // console.log("selectedSuggestion", selectedSuggestion)
        // Get the current input value
        const currentValue = toEmail;
        // console.log("currentValue", currentValue)

        // Find the position of "@" in the current value
        const atIndex = currentValue.indexOf("@");

        // Replace the text after "@" until the cursor position with the selected suggestion
        const updatedValue = currentValue.substring(0, atIndex) + `{{${selectedSuggestion}}}` + currentValue.substring(atIndex + 1);

        // Clear suggestions
        setSuggestedVar([]);

        settoEmail(updatedValue);
    };

    const handleChangeSubject = (e) => {

        const inputValue = e.target.value;

        // Check if "@" is entered
        const atIndex = inputValue.indexOf("@");
        if (atIndex !== -1 && Object.keys(textFormData).length > 0) {
            // Extract the text after "@" until the cursor position
            const textAfterAt = inputValue.substring(atIndex + 1, e.target.selectionStart);

            // Filter formData keys that match the entered text
            const matchingKeys = Object.keys(textFormData).filter((key) =>
                key.toLowerCase().includes(textAfterAt.toLowerCase())
            );

            const cursorRect = e.target.getBoundingClientRect();
            // console.log(e, cursorRect)

            setPopUpPositionX(cursorRect.width);
            setPopUpPositionY(cursorRect.height);

            // Display suggestions
            setSuggestedVar2(matchingKeys);
        } else {
            // Clear suggestions if "@" is not present
            setSuggestedVar2([]);
        }

        setsubject(e.target.value);
    };

    const handleChooseSuggestion2 = (selectedSuggestion) => {
        // console.log("selectedSuggestion", selectedSuggestion)
        // Get the current input value
        const currentValue = subject;
        // console.log("currentValue", currentValue)

        // Find the position of "@" in the current value
        const atIndex = currentValue.indexOf("@");

        // Replace the text after "@" until the cursor position with the selected suggestion
        const updatedValue = currentValue.substring(0, atIndex) + `{{${selectedSuggestion}}}` + currentValue.substring(atIndex + 1);

        // Clear suggestions
        setSuggestedVar2([]);

        setsubject(updatedValue);
    };

    const handleChangeMessage = (e) => {

        const inputValue = e.target.value;

        // Check if "@" is entered
        const atIndex = inputValue.indexOf("@");
        if (atIndex !== -1 && Object.keys(textFormData).length > 0) {
            // Extract the text after "@" until the cursor position
            const textAfterAt = inputValue.substring(atIndex + 1, e.target.selectionStart);

            // Filter formData keys that match the entered text
            const matchingKeys = Object.keys(textFormData).filter((key) =>
                key.toLowerCase().includes(textAfterAt.toLowerCase())
            );

            const cursorRect = e.target.getBoundingClientRect();
            // console.log(e, cursorRect)

            setPopUpPositionX(cursorRect.width);
            setPopUpPositionY(cursorRect.height);

            // Display suggestions
            setSuggestedVar3(matchingKeys);
        } else {
            // Clear suggestions if "@" is not present
            setSuggestedVar3([]);
        }

        setmessage(e.target.value);
    };

    const handleChooseSuggestion3 = (selectedSuggestion) => {
        // console.log("selectedSuggestion", selectedSuggestion)
        // Get the current input value
        const currentValue = message;
        // console.log("currentValue", currentValue)

        // Find the position of "@" in the current value
        const atIndex = currentValue.indexOf("@");

        // Replace the text after "@" until the cursor position with the selected suggestion
        const updatedValue = currentValue.substring(0, atIndex) + `{{${selectedSuggestion}}}` + currentValue.substring(atIndex + 1);

        // Clear suggestions
        setSuggestedVar3([]);

        setmessage(updatedValue);
    };

    return (
        <div>
            <div >
                <svg onClick={() => setSelected(0)} style={{ display: "flex", cursor: "pointer" }} width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="black" fill="none"><path d="M12.7715 24.8389L4.00001 16.0517L12.7715 7.2644" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path><path d="M28 16.0517L4.0001 16.0517" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "2rem" }}>
                <label style={{ display: "flex", alignItems: "center" }}>
                    <svg style={{ paddingRight: "2rem" }} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="52 42 88 66">
                        <path fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6"></path><path fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15"></path><path fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2"></path><path fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92"></path><path fill="#c5221f" d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2"></path>
                    </svg>
                    Integrate with Gmail </label>
                <button type="submit" key={clickAuthButton} className="SlackIntegrationButton" disabled={clickAuthButton === 1}
                    onClick={() => { handleAuthentication() }}>{clickAuthButton === 1 ? "Authenticating" : clickAuthButton === 2 ? "Revoke" : "Authenticate"}</button>
            </div>

            {clickAuthButton === 2 && <form onSubmit={handleSaveGmail}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                    <label>Select Trigger </label>
                    <select id="mySelect" required style={{ padding: "10px", width: "21.4rem", cursor: "pointer" }}
                        value={selectedTrigger} onChange={(e) => setselectedTrigger(e.target.value)}>
                        <option selected disabled style={{ display: "none", color: "#44474d" }} value="">Select Trigger</option>
                        <option style={{ padding: "5px" }} value="Form Completed">Form Completed</option>
                        <option style={{ padding: "5px" }} value="Step Loaded">Step Loaded</option>
                        <option style={{ padding: "5px" }} value="File Submitted">File Submitted</option>
                    </select>
                </div>

                {selectedTrigger === "Step Loaded" && <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                    <label>Select Step </label>
                    <select id="mySelect" required style={{ padding: "10px", width: "21.4rem", cursor: "pointer" }}
                        value={selectedStep} onChange={(e) => setselectedStep(e.target.value)}>
                        <option selected disabled style={{ display: "none", color: "#44474d" }} value="">Select Step</option>
                        {userActivities.map((activity, idx) => {
                            return <option key={activity.name} value={idx} > {activity.name} </option>
                        }
                        )}
                    </select>
                </div>}

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                    <label>To </label>
                    <div style={{ position: "relative", display: "flex" }}>
                        <textarea
                            rows="2"
                            className="gmail_textbox"
                            value={toEmail === null ? "" : toEmail}
                            onChange={(e) => (handleChangeToEmail(e))}
                            required
                            placeholder="use @ for getting Field"
                        ></textarea>
                        {suggestedVar.length > 0 && (
                            <div ref={suggestionRef} style={{ position: "absolute", backgroundColor: "white", transform: `translate(${popUpPositionX / 2 - 100}px, ${popUpPositionY}px)`, padding: "10px", boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px", zIndex: "999" }}>
                                <div>Recall information from...</div>
                                {suggestedVar.map((item, i) => {
                                    return <div key={i}
                                        style={{ padding: "4px", paddingLeft: "10px", backgroundColor: "#e7e4e4", borderRadius: "4px", margin: "4px", cursor: "pointer" }}
                                        onClick={() => handleChooseSuggestion(item)}>{item}</div>
                                })}
                            </div>
                        )}
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                    <label>Subject </label>
                    <div style={{ position: "relative", display: "flex" }}>
                        <textarea
                            rows="2"
                            className="gmail_textbox"
                            value={subject === null ? "" : subject}
                            onChange={(e) => (handleChangeSubject(e))}
                            required
                            placeholder="use @ for getting Field"
                        ></textarea>
                        {suggestedVar2.length > 0 && (
                            <div ref={suggestionRef2} style={{ position: "absolute", backgroundColor: "white", transform: `translate(${popUpPositionX / 2 - 100}px, ${popUpPositionY}px)`, padding: "10px", boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px", zIndex: "999" }}>
                                <div>Recall information from...</div>
                                {suggestedVar2.map((item, i) => {
                                    return <div key={i}
                                        style={{ padding: "4px", paddingLeft: "10px", backgroundColor: "#e7e4e4", borderRadius: "4px", margin: "4px", cursor: "pointer" }}
                                        onClick={() => handleChooseSuggestion2(item)}>{item}</div>
                                })}
                            </div>
                        )}
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                    <label>Message </label>
                    <div style={{ position: "relative", display: "flex" }}>
                        <textarea
                            rows="3"
                            className="gmail_textbox"
                            value={message === null ? "" : message}
                            onChange={(e) => (handleChangeMessage(e))}
                            required
                            placeholder="use @ for getting Field"
                        ></textarea>
                        {suggestedVar3.length > 0 && (
                            <div ref={suggestionRef3} style={{ position: "absolute", backgroundColor: "white", transform: `translate(${popUpPositionX / 2 - 100}px, ${popUpPositionY}px)`, padding: "10px", boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px", zIndex: "999" }}>
                                <div>Recall information from...</div>
                                {suggestedVar3.map((item, i) => {
                                    return <div key={i}
                                        style={{ padding: "4px", paddingLeft: "10px", backgroundColor: "#e7e4e4", borderRadius: "4px", margin: "4px", cursor: "pointer" }}
                                        onClick={() => handleChooseSuggestion3(item)}>{item}</div>
                                })}
                            </div>
                        )}
                    </div>
                </div>

                <button className="SlackIntegrationButton"
                    style={{ marginTop: "2rem", marginBottom: "2rem", float: "right", backgroundColor: "#DB615C", border: "1px solid #DB615C" }}
                    type="submit">{duonutConnected ? isSave ? "Updating" : "Update" : isSave ? "Saved" : "Save"}</button>
            </form>
            }

            {clickAuthButton === 2 && duonutConnected && <button type="submit" className="SlackIntegrationButton"
                style={{ margin: "2rem", float: "right", color: "#e5251e", background: "transparent", border: "1px solid #DB615C" }}
                onClick={() => handleDisconnect()}>
                <i className="fa fa-trash-o" aria-hidden="true" style={{ marginRight: "10px" }}></i>
                Disconnect</button>}

        </div>
    );
}

export default GmailIntegration;