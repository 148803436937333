import React, { useState, useEffect } from 'react'
import './CreditPayment.css';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { domain } from "../../../services/constants";
import { saveProlificPaymentDetails } from "../../../services/duonut";
import PaymentSuccess from './PaymentSucces';

export default function PaymentForm({ credit_payment }) {

    const userName = localStorage.getItem("userName");
    const userId = localStorage.getItem("userId");
    const userEmail = localStorage.getItem("userEmail");

    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [country, setcountry] = useState("");
    const [postal_code, setpostal_code] = useState("");
    const [allcountry, setallcountry] = useState([]);
    const [pSuccess, setpSuccess] = useState(false);
    const [payStart, setpayStart] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // console.log("monthly", monthly)

    useEffect(() => {
        // console.log("userEmail", userEmail)
        if (userName !== "null") {
            setname(userName);
        }
        if (userEmail) {
            setemail(userEmail);
        }
    }, [userName, userEmail]);

    useEffect(() => {
        fetch('https://restcountries.com/v3.1/all')
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                var allcount = [];
                data.forEach(country => {
                    allcount.push(country.name.common);
                });
                allcount.sort();
                setallcountry(allcount);
            })
            .catch(error => console.error('Error fetching country data:', error));
    }, []);

    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            setErrorMessage(submitError.message);
            setTimeout(() => {
                setErrorMessage(null);
            }, 15000); //miliseconds
            return;
        }

        setpayStart(true);

        const data = {
            id: userId,
            name: name,
            address: address,
            city: city,
            state: state,
            country: country,
            postal_code: postal_code,
            credit_payment: credit_payment,
        };
        // Create the PaymentIntent and obtain clientSecret from your server endpoint
        const res = await fetch(`${domain}/account/create-prolific-payment-intent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Specify the content type as JSON
            },
            body: JSON.stringify(data),
        });

        const { clientSecret: clientSecret } = await res.json();

        // Get a reference to the CardElement
        const cardElement = elements.getElement(CardElement);

        // Create a PaymentMethod object using the card information
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });

        if (error) {
            // Handle errors (e.g., card validation errors)
            console.error(error.message);
            setErrorMessage(error.message);
            setpayStart(false);
            setTimeout(() => {
                setErrorMessage(null);
            }, 15000); //miliseconds
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            // console.log("paymentMethod", paymentIntent);

            var paymentData = {
                payment_id: paymentIntent.id,
                name: name,
                address: address,
                city: city,
                state: state,
                postal_code: postal_code,
                country: country,
                credit_payment: credit_payment,
            }
            saveProlificPaymentDetails(paymentData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
                setpayStart(false);
                setpSuccess(true);
            });
        }


    };

    return (
        <>
            {pSuccess ? <PaymentSuccess /> :
                <div className=''>
                    {/* <div className='ADP1backBtn' onClick={() => { navigate(-1) }}>
                        <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back
                    </div> */}
                    <div className='credit_wrapper'>
                        <h1 className='credit_wrapper_heading'>Confirm your credit_payment purchase</h1>

                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className="col-xs-12 col-md-12 col-lg-6">
                                    <div className='demo_form_modal__input_container'>
                                        <div>Full Name*</div>
                                        <input placeholder="Full Name"
                                            value={name}
                                            onChange={e => setname(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className='demo_form_modal__input_container'>
                                        <div>Address*</div>
                                        <input placeholder="Address"
                                            value={address}
                                            onChange={e => setaddress(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className='demo_form_modal__input_container'>
                                        <div>Country*</div>
                                        <select id="mySelect" required value={country} onChange={e => setcountry(e.target.value)}>
                                            <option selected disabled style={{ display: "none", color: "#44474d" }} value="">Select country</option>
                                            {allcountry.map((item, i) => {
                                                return <option key={i} value={item}>{item}</option>
                                            })}
                                        </select>
                                    </div>

                                    {country === "India" && <div className='demo_form_modal__input_container'>
                                        <div>State*</div>
                                        <input placeholder="State"
                                            value={state}
                                            onChange={e => setstate(e.target.value)}
                                            required
                                        />
                                    </div>}

                                    <div style={{ display: "flex", gap: "15px" }}>
                                        <div className='demo_form_modal__input_container'>
                                            <div>City*</div>
                                            <input placeholder="City"
                                                value={city}
                                                onChange={e => setcity(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className='demo_form_modal__input_container'>
                                            <div>Postal Code*</div>
                                            <input placeholder="Postal Code"
                                                value={postal_code}
                                                onChange={e => setpostal_code(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className='demo_form_modal__input_container'>
                                        <div>Payment Details*</div>
                                        <div className='cardDetails'>
                                            <CardElement />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-12 col-lg-6">
                                    <div className='payment_right_container'>
                                        <h2>Purchase details</h2>
                                        <br></br>
                                        <h3>Add Panel Credits</h3>
                                        <br></br>
                                        {/* {lifetime ? <div>Lifetime {userPlan} access</div> : monthly !== "true" ? <div>Annual plan ({userPlan === "pro" ? "$44" : "$89"} USD x 12 months)</div> : <div></div>}
                                        <br></br> */}
                                        <h4>Total = ${credit_payment}</h4>
                                        <br></br>

                                        <button type="submit" className='btn-primary' disabled={!stripe || !elements || payStart} >
                                            Pay
                                        </button>
                                        {/* Show error message to your customers */}
                                        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>}
        </>
    )
}
