import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements } from '../../../redux/slices/userActivity';

export default function ButtonAction() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [conditions, setConditions] = useState({});
    const [action, setAction] = useState('Go to next step')
    const [url, setUrl] = useState('');
    const [goToStep, setGoToStep] = useState(-1);
    const dispatch = useDispatch()

    useEffect(() => {
        conditions?.action ?
            setAction(conditions.action) : setAction("Go to next step")

        conditions?.url ?
            setUrl(conditions.url) : setUrl("")

        conditions?.goToStep ?
            setGoToStep(conditions.goToStep) : setGoToStep(-1)

    }, [selectedComponent.name, selectedUserActivity, conditions, userActivities[selectedUserActivity]])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const conditions = tempElement?.conditions;
            setConditions(conditions);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleActionChange = e => {
        // const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const conditions = tempElement.conditions;
            tempElement = {
                ...tempElement,
                conditions: {
                    ...conditions,
                    action: e.target.value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleUrlChange = e => {
        setUrl(e.target.value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const conditions = tempElement.conditions;
            tempElement = {
                ...tempElement,
                conditions: {
                    ...conditions,
                    action: action,
                    url: e.target.value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }
    const handleGoToStepChange = e => {
        let value = parseInt(e.target.value);

        setGoToStep(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const conditions = tempElement.conditions;
            tempElement = {
                ...tempElement,
                conditions: {
                    ...conditions,
                    action: action,
                    goToStep: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    return (<>
        <div className="EditorRowContainer">
            <p style={{ marginRight: "1rem" }}>Action</p>
            <select value={action} className='text_editor_dropdown' onChange={e => handleActionChange(e)} >
                <option value='Go to next step' > Go to next step </option>
                <option value='Go to previous step' > Go to previous step </option>
                <option value='Open url' > Open url </option>
                <option value='Go to step' > Go to step </option>
                <option value='Form submitted' > Form submitted </option>
            </select>
        </div>
        {action === "Open url" &&
            <div className="EditorRowContainer">
                <p style={{ marginRight: "1rem" }}>Url</p>
                <input type="text" value={url} id='url'
                    style={{ fontSize: "14px", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", alignItems: "center", width: "13rem", height: "1.6rem", paddingLeft: "5px" }}
                    onChange={e => handleUrlChange(e)} />
            </div>
        }
        {action === "Go to step" &&
            <div className="EditorRowContainer">
                <p style={{ marginRight: "1rem" }}>Step</p>
                <select value={goToStep} className='text_editor_dropdown' onChange={e => handleGoToStepChange(e)} >
                    <option key={"select option"} value={-1} style={{ display: "none", color: "#44474d" }} selected disabled> Select...</option>
                    {userActivities.map((activity, idx) => {
                        if (selectedUserActivity === idx) return;
                        return <option key={activity.name} value={idx} > {activity.name} </option>
                    }
                    )}
                    <option key={"Thank you"} value={userActivities.length} > Thank you </option>
                </select>
            </div>
        }
    </>
    )
}

