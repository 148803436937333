import React, { useState, useEffect } from 'react';
import './Element.css';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { saveQuizAnsAsync, saveQuizResponse, showDuonutresponse } from '../../redux/slices/DuonutReducer';
import imgPreview from '../../assets/Images/image-preview.png';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { updateGoToSlide } from '../../redux/slices/userActivity';
import { showduonutData } from '../../redux/slices/DuonutReducer';
import axios from "axios";
import { domain } from "../../services/constants";

const GenericConjoint = ({
    slideId,
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, gap,
        labelFontSize, labelFontWeight, labelColor, imageWidth, imagePadding, showAttribute,
    },
    elementHoverAttr,
    conditions,
    language,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { selectedSlide, } = useSelector((state) => state.userActivity);
    const { viewer_display_id, viewer_nick_name, } = useSelector((state) => state.duonut);
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const { width } = useWindowDimensions();
    const [hover, setHover] = useState(-1);

    const duonutDataMain = useSelector(showduonutData);
    // const duonutData = duonutDataMain[0];

    const [inputText, setInputText] = useState("");

    const [currentSlide, setCurrentSlide] = useState(0);
    const [choiceSets, setChoiceSets] = useState([]);
    const [currentOptions, setCurrentOptions] = useState([]);

    // Helper function to generate combinations
    const generateCombinations = (attributes) => {
        const levels = attributes.map((attr) => attr.levels);
        return levels.reduce(
            (acc, curr) => acc.flatMap((x) => curr.map((y) => [...x, y])),
            [[]]
        );
    };

    const randomSample = (array, size) => {
        const shuffled = [...array].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, size);
    };

    useEffect(() => {
        if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
            fetchText(text);
            // fetchOptionsText(shuffledOptions);
        } else {
            setInputText(text);
            // setoptionsText(shuffledOptions);
        }

        const combinations = generateCombinations(options);

        const numProfilesPerSet = 3; // Number of profiles per choice set
        const numChoiceSets = 10; // Number of choice sets to generate

        // Generate choice sets
        const sets = Array.from({ length: numChoiceSets }, () =>
            randomSample(combinations, numProfilesPerSet)
        );

        // console.log("sets", sets)
        setChoiceSets(sets);
        setCurrentOptions(sets[currentSlide]);

    }, [text, options]);

    const fetchText = async (texts) => {
        const t_text = await translateText(texts, language);
        setInputText(t_text);
    };
    const translateText = async (texts, targetLanguage) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${domain}/duonut/language_translator`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    source: "en",
                    target: targetLanguage,
                    query: texts,
                },
            });

            if (response.status === 200) {
                // console.log("Translation:", response);
                return response?.data.translation; // return the translated text
            } else {
                // Handle unsuccessful status codes
                return "Error: Translation failed. Please try again.";
            }
        } catch (error) {
            console.error('Error in translation API:', error);
            return "Error translating text. Please try again."; // Handle API errors gracefully
        }
    };


    const submitResponse = (item, index) => {
        if (location.pathname.includes("/duonut/")) {

            const save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0]?.watch_id || "",
                result: 0,
                question: text || "",
                answered_text: item.join(", "),
                nickname: viewer_nick_name,
                step: currentSlide === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${currentSlide}`,
                type: "GenericConjoint",
            };

            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));
            dispatch(saveQuizAnsAsync(formData));

            const save_response = {
                id,
                type: "GenericConjoint",
                step: currentSlide === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${currentSlide}`,
                question: text || "",
                answered_text: item.join(", "),
            };
            dispatch(saveQuizResponse(save_response));

            if (currentSlide < choiceSets.length - 1) {
                const divContainer = document.getElementById("myDivContainer");

                if (divContainer) {
                    // Decrease opacity to 0
                    divContainer.style.opacity = "0.2";

                    // Restore opacity to 1 after 500ms
                    setTimeout(() => {
                        divContainer.style.opacity = "1";
                    }, 500);
                }
                setCurrentOptions(choiceSets[currentSlide + 1]);
                setCurrentSlide(currentSlide + 1);

            } else {
                // console.log("Survey completed!");
                var nextPage = selectedSlide + 1;

                setTimeout(() => {
                    dispatch(updateGoToSlide(nextPage));
                }, 1000);
            }

        }
    }

    return (<>
        <div style={{ display: "flex", flexDirection: "column", gap }}>
            <div style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing,
            }}>
                {inputText}
            </div>

            <div id="myDivContainer" style={{
                display: "flex",
                justifyContent: textAlign,
                alignItems: 'center',
                flexDirection: (viewType === 3 || width < 800) ? "column" : 'row',
                flexWrap: "wrap"
            }}>
                {showAttribute && <div style={{
                    display: "flex", alignItems: "center", flexDirection: "column", fontSize: fontSize + 2,
                    backgroundColor: backgroundColor,
                    border: border || "0",
                    color: color,
                    borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                    textTransform, textAlign, letterSpacing, padding, margin, minWidth: "10rem"
                }}>
                    {options.map((item, i) => (
                        <div
                            key={i}
                            style={{
                                display: "flex", alignItems: "center", justifyContent: "center",
                                padding,
                                margin,
                                width: item.levels?.some((level) =>
                                    level.includes("https://duonutfiles.s3.amazonaws.com")
                                )
                                    ? imageWidth
                                    : "100%",
                                height: item.levels?.some((level) =>
                                    level.includes("https://duonutfiles.s3.amazonaws.com")
                                )
                                    ? imageWidth
                                    : "100%",
                            }}
                        >
                            {item.attribute}
                        </div>
                    ))}

                </div>}
                {currentOptions.map((option, indx) => (
                    <div key={indx} style={{ display: "flex", alignItems: "center", lineHeight }}>
                        <input
                            type="radio"
                            id={`radio_option${indx}`}
                            name="radio_option"
                            style={{
                                width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px", accentColor: color
                            }}
                            checked={selectedAnswer.includes(option)}
                            onChange={() => { submitResponse(option, indx); }}
                        />
                        <div style={{
                            display: "flex", flexDirection: "column", alignItems: "center",
                            backgroundColor: hover === indx ? elementHoverAttr?.backgroundColor : backgroundColor,
                            border: hover === indx ? elementHoverAttr?.border : border || "0",
                            color: hover === indx ? elementHoverAttr?.color : color,
                            borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                            textTransform, textAlign, letterSpacing, padding, margin, cursor: location.pathname.includes("/duonut/") ? "pointer" : "default",
                            width: "100%", height: "100%", boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)", minWidth: "10rem"
                        }}
                            onMouseEnter={() => setHover(indx)}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => { submitResponse(option, indx); }}>
                            {option.map((item, i) => {
                                return (
                                    <div style={{ padding, margin, width: "100%", height: "100%" }}
                                    >
                                        {item.includes("https://duonutfiles.s3.amazonaws.com") ? (
                                            <img
                                                src={item || imgPreview}
                                                style={{ width: imageWidth, height: imageWidth, objectFit: "contain", padding: imagePadding, boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)" }}
                                                alt=""
                                            />
                                        ) : (
                                            <div className='two-line-truncate' style={{
                                                width: item.includes("https://duonutfiles.s3.amazonaws.com")
                                                    ? imageWidth
                                                    : "100%",
                                                height: item.includes("https://duonutfiles.s3.amazonaws.com")
                                                    ? imageWidth
                                                    : "100%",
                                            }}>{item}</div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>

            <div style={{ width: "100%" }}>
                <button style={{
                    fontSize,
                    backgroundColor: backgroundColor,
                    border: border || "0",
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
                    color: color,
                    borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                    textTransform, textAlign, letterSpacing, padding, margin, width: "14rem",
                    float: "right", cursor: "pointer"
                }}
                    onClick={() => { submitResponse(["None of the above"], 0); }}>NONE OF THE ABOVE</button>
            </div>
        </div>
    </>
    );
}

export default GenericConjoint;
