import axios from "axios";
import { domain } from "./constants";

const headers = {
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
  "Content-Type": "application/json",
  "Access-Control-Allow-origin": "*",
};
const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};

export const getAllDuonuts = (id, cb) => {
  axios
    .get(
      `${domain}/duonut/`,
      {
        params: {
          // activity_category_id: 1,
          visibility: true,
          ordering: "priority",
        },
      },
      headers
    )
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const getMyDuonuts = (cb) => {
  axios
    .get(
      `${domain}/duonut/my_duonuts`,
      {
        params: {
          created_by: localStorage.getItem("userId"),
          // ordering: "priority",
        },
      },
      headers
    )
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const getAdminDuonuts = (cb) => {
  axios
    .get(
      `${domain}/duonut/my_duonuts`,
      {
        params: {
          created_by: 2,
        },
      },
      headers
    )
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const getDuonut = (id, cb) => {
  const header = getAuthHeaders();
  axios
    .get(
      `${domain}/duonut/get_duonut/${id}`,
      // {
      //   params: {
      //     ordering: "priority",
      //   },
      // },
      header
    )
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const deleteDuonut = (id, cb) => {
  const header = getAuthHeaders();
  axios
    .delete(
      `${domain}/duonut/delete_duonut/${id}`,
      header
    )
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const savePaymentDetails = (paymentData, cb) => {
  const header = getAuthHeaders();
  // console.log(paymentData);
  axios
    .post(`${domain}/duonut/save_payment_details`, paymentData, header)
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const saveProlificPaymentDetails = (paymentData, cb) => {
  const header = getAuthHeaders();
  // console.log(paymentData);
  axios
    .post(`${domain}/duonut/prolific_payment`, paymentData, header)
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const creditDeduction = (creditData, cb) => {
  const header = getAuthHeaders();
  // console.log(paymentData);
  axios
    .post(`${domain}/duonut/credit_deducted`, creditData, header)
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};