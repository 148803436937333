import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements } from '../../../redux/slices/userActivity';

export default function FieldPosition() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [fieldPosition, setfieldPosition] = useState('column')
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const fieldPosition = tempElement?.elementAttributes?.fieldPosition;
            if (fieldPosition) {
                setfieldPosition(fieldPosition);
            } else {
                setfieldPosition("column");
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleFieldPositionChange = e => {
        const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    fieldPosition: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    return (<>
        <div className="EditorRowContainer">
            <div style={{ marginRight: "1rem" }}>Field Position</div>
            <select value={fieldPosition} className='text_editor_dropdown' onChange={e => handleFieldPositionChange(e)} >
                <option value='column' > Column </option>
                <option value='row' > Row </option>
            </select>
        </div>
    </>
    )
}

