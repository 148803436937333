import React, { useEffect, useState, useRef } from "react";
import "./Slides.css";
import { useSelector, useDispatch } from "react-redux";
import { addUserActivity, addUserActivity1, selectUserActivity, removeUserActivity, duplicateUserActivity, updateUserActivity } from '../../redux/slices/userActivity';
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import VerticalBars from '../../assets/icons/myduonut/vertical-bars.svg'
import { setUserActivity } from "../../redux/slices/userActivity";
// import { Steps, Hints } from 'intro.js-react';
// import introJs from "intro.js";
import "intro.js/introjs.css";

const Slides = (props) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);
    const { display_id } = useSelector(state => state.duonutSettings);
    const [showOptions, setShowOptions] = useState(false);
    const [renameOption, setRenameOption] = useState(-1);
    const [isHovered, setIsHovered] = useState(false);

    const optionsRef = useRef(null)
    const handleClose = () => {
        setShowOptions(false);
    }
    useOutsideAlerter(optionsRef, handleClose);


    const optionsRefs = useRef([]);
    // Function to handle click outside input elements
    const handleClickOutside = (event) => {
        if (optionsRefs?.current?.every(ref => ref && !ref.current?.contains(event.target))) {
            setRenameOption(-1);
        }
    };

    // Add click event listener on component mount
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        // Remove event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const defaultElement = [
        {
            id: 1,
            elementType: 'Text',
            transparency: 1,
            zIndex: 1,
            order: 1,
            posX: 0,
            posY: 100,
            height: "auto",
            width: "100%",
            elementAttributes: {
                color: '#000000', text: "Type Your Heading", formattedText: [], fontFamily: "Poppins",
                fontWeight: 800, fontSize: 24, textAlign: "center"
            },
        },
        {
            id: 2,
            elementType: 'TextField',
            transparency: 1,
            zIndex: 2,
            order: 2,
            posX: 260,
            posY: 240,
            height: "auto",
            width: 500,
            elementAttributes: {
                color: '#000000', text: "Field Name", fontFamily: "Poppins",
                fontWeight: 400, fontSize: 16, backgroundColor: "#ffffff", border: "1px solid #000000",
                textAlign: "left", borderRadius: "0px", fieldPosition: "column",
                placeholder: "Type your text here."
            }
        },
        {
            id: 3,
            elementType: 'Button',
            transparency: 1,
            zIndex: 3,
            order: 3,
            posX: 410,
            posY: 433,
            height: "auto",
            width: 200,
            elementAttributes: {
                color: '#ffffff', text: "Next", fontFamily: "Poppins",
                fontWeight: 600, fontSize: 16, backgroundColor: "#000000", border: "1px solid #000000",
                textAlign: "center", borderRadius: "5px"
            },
            elementHoverAttributes: {
                color: '#ffffff', backgroundColor: "blue", border: "1px solid #000000"
            },
        }
    ]


    const addActivity = () => {
        dispatch(addUserActivity({
            elements: [],
            backgroundColor: "#ffffff",
            backgroundImage: "",
            name: `Step ${userActivities.length + 1}`,
        }));
    }

    let tapedId = null;
    const selectActivity = (idx) => {
        dispatch(selectUserActivity(idx));

        if (tapedId !== idx) {
            tapedId = idx;
            setTimeout(function () {
                tapedId = null;
            }, 400);
            return false;
        }

        // setRenameOption(idx + 1);
        // document.getElementById(`nameInput${idx + 1}`).focus();

        setRenameOption(idx + 1);
        const inputElement = document.getElementById(`nameInput${idx + 1}`);
        if (inputElement) {
            inputElement.focus();
        }

    }

    const popUpHandler = (e) => {
        setShowOptions(!showOptions);

    }

    const addActivity1 = (e) => {
        setShowOptions(false);
        dispatch(addUserActivity1({
            elements: [],
            backgroundColor: "#ffffff",
            backgroundImage: "",
            name: `Step ${userActivities.length + 1}`,
        }));
        e.stopPropagation();
    }

    const removeActivity = (e, idx) => {
        setShowOptions(false);
        dispatch(removeUserActivity({ idx }));
        e.stopPropagation();
    }
    const handleDuplicate = (e) => {
        setShowOptions(false);
        let duplicateData = {
            ...userActivities[selectedUserActivity],
            name: `Step ${userActivities.length + 1}`
        }
        dispatch(duplicateUserActivity(duplicateData));
        e.stopPropagation();
    }

    const handleRename = (e, indx) => {
        setShowOptions(false);
        setRenameOption(indx + 1);
        document.getElementById(`nameInput${indx + 1}`).focus();
        e.stopPropagation();
    }

    const handleRenameInputChange = (e) => {
        dispatch(
            updateUserActivity({
                name: e.target.value,
            })
        );
    }

    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const dragStart = (e, position) => {
        // console.log('start');
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = (e) => {
        let copyListItems = [...userActivities];
        //dragItem.current is index
        if (dragItem.current === null) return;
        if (dragOverItem.current === null) return;
        selectActivity(dragOverItem.current);
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);

        dragItem.current = null;
        dragOverItem.current = null;

        if (copyListItems !== null)
            return dispatch(
                setUserActivity({
                    activities: copyListItems,
                })
            );
    };

    return (
        <div className="slideApp">
            <div className="Slide-AddPage-Button" onClick={() => addActivity()}>
                <i className="fa fa-plus" aria-hidden="true"></i> Add Step
            </div>
            <div className="slideAppContainer" style={{ overflow: isHovered ? "hidden auto" : "hidden" }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                {userActivities.map((activity, idx) => {
                    optionsRefs.current[idx] = optionsRefs.current[idx] || React.createRef();
                    return (<div key={idx} className="SlideContainer" ref={optionsRefs.current[idx]}
                        style={selectedUserActivity === idx ? { border: "1px solid rgb(49, 132, 201)" } : {}}
                        onClick={(e) => { selectActivity(idx); handleRename(e, idx) }}
                        draggable
                        onDragStart={(e) => dragStart(e, idx)}
                        onTouchStart={(e) => dragStart(e, idx)}
                        onDragOver={(e) => dragEnter(e, idx)}
                        onTouchMove={(e) => dragEnter(e, idx)}
                        onDragEnd={drop}
                        onTouchEnd={drop}
                    >
                        <input type='text'
                            id={`nameInput${idx + 1}`}
                            value={activity?.name}
                            style={{
                                width: "65%", minHeight: "30px",
                                backgroundColor: "transparent",
                                border: renameOption === idx + 1 ? "1px solid black" : "0",
                                borderRadius: "5px",
                                paddingLeft: "5px",
                                marginLeft: "5px",
                                fontSize: "1rem"
                            }}
                            onChange={(e) => handleRenameInputChange(e)}
                            readOnly={renameOption === idx + 1 ? false : true}
                        />
                        <div className="PMenuLineBar" style={{ cursor: "pointer" }}
                            onClick={e => { e.stopPropagation(); popUpHandler(e) }}>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "block" }} width="16" height="16" viewBox="0 0 16 16">
                                <path fill="#ffffff" d="M3.25 9.25a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5zm4.75 0a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5zm4.75 0a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5z" fill-rule="evenodd"></path>
                            </svg> */}
                            <img src={VerticalBars} loading="lazy"
                                style={{ cursor: 'pointer', height: "0.9rem" }}
                            />
                        </div>
                        {(showOptions && selectedUserActivity === idx) && <div ref={optionsRef}
                            className="PMenuOptions-1"
                            style={
                                // {}
                                // popUpPositionX < 1350 ?
                                { top: "2rem", right: "0" }
                                //: {top: `${popUpPositionY + 260}px`, left: `${popUpPositionX - 280}px` }
                            }>
                            <div className="mainpopup">
                                <ul>
                                    <li className='item' onClick={() => window.open(`/duonut/${display_id}?step=${idx + 1}`)}>
                                        <span>View Step</span>
                                    </li>
                                    <li className='item' onClick={addActivity1}>
                                        <span>Add Step</span>
                                    </li>
                                    <li className='item' onClick={handleDuplicate}>
                                        <span>Duplicate Step</span>
                                    </li>
                                    <li className='item' onClick={(e) => handleRename(e, idx)}>
                                        <span>Rename</span>
                                    </li>
                                    <li className='item' style={userActivities.length > 1 ? {} : { pointerEvents: "none", opacity: "0.5" }} onClick={(e) => removeActivity(e, selectedUserActivity)}>
                                        <span>Delete Step</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        }
                    </div>);
                })}
            </div>
        </div>
    );
}

export default Slides;