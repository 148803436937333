import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import "./duonut.css"
import { useDispatch, useSelector } from "react-redux";
// import ImageSlider from './imageSlider';
import useOutsideAlerter from "../../hooks/useOutsideAlerter"
import { getNumberSuffix } from '../../utils';
import useWindowDimensions from '../../hooks/useWindowDimensions';
// import VerticalBars from '../../assets/icons/myduonut/vertical-bars.svg'
import { selectUserActivity } from '../../redux/slices/userActivity';
import ShareModal from '../Modals/ModalBox/ShareModal';
import EmbedModal from '../Modals/ModalBox/EmbedModal';
import QrCodeModal from '../Modals/ModalBox/QrCodeModal';
import { updateDeleteDuonutConfirmModal } from "../../redux/slices/formDetails";
import { frontendUrl } from "../../services/constants";
// import { convertLinkToDataUrl } from "../../utils";
import { getDuonut } from "../../services/duonut";
import { setUserActivity } from "../../redux/slices/userActivity"
import { toast } from "react-toastify";
import { createDuonut } from "../../services/activity";
import {
    updateDescription, updateIsPublic, updateIsPrivate, updateSettings, updateTitle, updatePassword,
    updatePublished, updateSlackIntegration, updateHubspotIntegration, updateGmailIntegration,
    updateScheduledWorkflow, updateGoogleSheetIntegration, updateLock
} from "../../redux/slices/duonutSettings";
import { domain } from "../../services/constants";
import NuggetsModal from '../../Components/Modals/ModalBox/NuggetsModal';

var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

var shareData = {};

const DuonutNewGrid = ({ type, name, active, display_id, total_views, user_activity, qrcode, created_at, updated_at, isFiltered, id: duonutId, fetchDuonut, nuggets, onClickCreateButton, published }) => {
    const [showOptions, setShowOptions] = useState(false)
    const [popUpPositionX, setPopUpPositionX] = useState(null)
    const [popUpPositionY, setPopUpPositionY] = useState(null)
    const { selectedUserActivity } = useSelector((state) => state.userActivity);
    const { settings } = useSelector(state => state.duonutSettings);

    const date = new Date(created_at)
    const lmdate = new Date(updated_at)
    const { width, height } = useWindowDimensions()
    const duonutthumbnail_set = user_activity.length > 0 ? user_activity.map(item => item?.thumbnail) : [];
    const [currentIndex, setCurrentIndex] = useState(0)
    const [previousIndex, setPreviousIndex] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [showShareModal, setshowShareModal] = useState(false)
    const [showEmbedModal, setshowEmbedModal] = useState(false)
    const [showQrCode, setshowQrCode] = useState(false)
    const { isLoggedIn } = useSelector((state) => state.user);
    const [showNuggetsModal, setshowNuggetsModal] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }


    const popUpHandler = e => {
        setshowShareModal(false);
        setshowEmbedModal(false);
        setshowQrCode(false);
        setShowOptions(!showOptions)
        setshowNuggetsModal(false);
        let pos = menuIconRef.current.getBoundingClientRect()
        let offsetLeft = menuIconRef.current.offsetLeft

        if (isFiltered) {
            setPopUpPositionX(offsetLeft + 13)
            setPopUpPositionY(menuIconRef.current.offsetTop)
            //if exceeds window width
            if (offsetLeft + 13 + 250 > width) {
                setPopUpPositionX(offsetLeft - 225)
            }
            // console.log(pos.top, height)
            if (pos.top + 350 > height) {
                setPopUpPositionY(menuIconRef.current.offsetTop - 220)
            }
        } else {
            setPopUpPositionX(pos.left - 16)
            //if exceeds window width
            if (pos.left + 13 + 250 > width) {
                setPopUpPositionX(pos.left - 205)
            }
            setPopUpPositionY(menuIconRef.current.offsetTop - 60)
        }

        e.stopPropagation();
    }

    // to close duonut options menu
    const optionsRef = useRef(null)
    const menuIconRef = useRef(null)
    const handleClose = () => setShowOptions(false)
    const handleShareModal = () => { setshowShareModal(false); setshowEmbedModal(false); setshowQrCode(false); setshowNuggetsModal(false); }
    useOutsideAlerter(optionsRef, handleClose, handleShareModal);

    let timeInterval = useRef(null)

    //change thumbnails
    let intervalId;

    const changeThumbnails = (playing) => setIsPlaying(true)

    useEffect(() => {
        if (isPlaying === false) return
        intervalId = setInterval(() => {
            // console.log(currentIndex)
            if (currentIndex === duonutthumbnail_set.length - 1) {
                setCurrentIndex(0)
            }
            else {
                setCurrentIndex(prevIndex => prevIndex + 1)
            }
        }, 2000)
        return () => clearInterval(intervalId)
    }, [isPlaying, currentIndex]);

    const resetThumbnails = () => {
        clearInterval(intervalId)
        setIsPlaying(false)
        setCurrentIndex(0)
    }

    const handleEdit = (e) => {

        dispatch(selectUserActivity(0))
        window.open(`/design/${duonutId}`)
        // window.location.reload()
        e.stopPropagation();
    }

    const handleView = (e) => {
        window.open(`${frontend_url}/duonut/${display_id}`)
        e.stopPropagation();
    }

    const handleShare = (e) => {
        shareData = {
            display_id: display_id,
            duonut_name: name,
            password: "",
            message: ""
        }
        setshowShareModal(true);
        e.stopPropagation();
    }

    const handleEmbedCode = (e) => {
        shareData = {
            display_id: display_id,
            duonut_name: name,
            password: "",
            message: ""
        }
        setshowEmbedModal(true);
        e.stopPropagation();
    }

    const handleQrCode = (e) => {
        shareData = {
            display_id: display_id,
            duonut_name: name,
            password: "",
            message: "",
            qrcode: qrcode
        }
        setshowQrCode(true);
        e.stopPropagation();
    }

    useEffect(() => {
        if (duonutthumbnail_set.length === 1) return
        if (isPlaying === false) return clearInterval(timeInterval.current)

        if (currentIndex === 0) {
            setPreviousIndex(duonutthumbnail_set.length - 1)
        } else {
            setPreviousIndex(currentIndex - 1);
        }
        return () => clearInterval(timeInterval.current);
    }, [currentIndex]);

    /* DELETE method */

    const handelDelete = (e) => {
        dispatch(
            updateDeleteDuonutConfirmModal({
                active: true,
                duonutId,
            })
        );
        e.stopPropagation();
    };

    const handleReport = (e) => {
        window.open(`/duonutreports/${duonutId}`)
        e.stopPropagation();
    }

    const handleAnalytics = (e) => {
        window.open(`/duonutanalytics/${duonutId}`)
        e.stopPropagation();
    }

    let interval = null;
    const handleCopy = (e) => {
        const controller = new AbortController();
        interval = setInterval(() => {
            if (isLoggedIn) {
                clearInterval(interval);
                createDuonut({ name: "Untitled", duonut_category: 1, duonut_setting: settings }, controller, (err, res) => {
                    // setLoading(false);
                    clearInterval(interval);
                    if (err) {
                        console.log(err);
                        return;
                    }
                    // console.log('DUONUT CREATED --- ');
                    fetchActivityDuonut(duonutId, res?.data?.id);
                });
            }
        }, 10);
        e.stopPropagation();

        return () => {
            controller.abort();
            clearInterval(interval)
        };
    }

    const fetchActivityDuonut = (duonutId, newDuonutId) => {
        getDuonut(duonutId, (err, res) => {
            // console.log("fetch duonut resp", res);
            if (err) {
                console.log(err);
                window.location = "/";
                dispatch(setUserActivity({
                    activities: [{
                        elements: [],
                        backgroundColor: "white",
                        backgroundImage: "",
                    }]
                }));
                return;
            }
            if (parseInt(localStorage.getItem("userId")) !== parseInt(res.data.created_by.id)) {
                window.location = "/";
                return;
            }

            toast.success(`Copying this Duonut`);

            let mastersData = res.data.user_activity;
            let settings = { ...res.data.setting }

            dispatch(updateTitle(res.data.name));
            dispatch(updateDescription(res.data.description));
            dispatch(updatePassword(res.data.password));
            dispatch(updateIsPublic(res.data.visibility));
            dispatch(updateIsPrivate(!res.data.visibility));
            dispatch(updatePublished(res.data.published));
            dispatch(updateSlackIntegration(res.data.slack_integration));
            dispatch(updateHubspotIntegration(res.data.hubspot_integration));
            dispatch(updateGmailIntegration(res.data.gmail_integration));
            dispatch(updateScheduledWorkflow(res.data.scheduled_workflow));
            dispatch(updateGoogleSheetIntegration(res.data.google_sheet_integration));
            dispatch(updateLock(res.data.lock));

            dispatch(updateSettings({ settings }));
            mastersData.length >= 1 && dispatch(setUserActivity({ activities: mastersData }));

            setTimeout(() => {
                navigate(`/design/${newDuonutId}`);
            }, 500);

        });
    };

    const handelDuonutStatus = () => {
        const data = {
            duonutId: duonutId,
        };
        fetch(`${domain}/duonut/duonut_acive_status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((res) => {
            // console.log(res);
            if (active) {
                toast.success(`${name} Duonut status is off`);
            } else {
                toast.success(`${name} Duonut status is on`);
            }
            fetchDuonut();
        }).catch((e) => {
            console.log(e.message, e);
        });

    }

    const handleAddNuggets = (e) => {
        // console.log(nuggets)
        if (nuggets.length <= 0) {
            onClickCreateButton();
            return;
        }
        shareData = {
            duonut_id: duonutId,
            display_id: display_id,
            duonut_name: name,
            nuggets: nuggets,
        }
        // console.log(shareData)
        setshowNuggetsModal(true);
        e.stopPropagation();
    }
    function getRelativeTime(createdAt) {
        const now = new Date();
        const date = new Date(createdAt);
        const diffInSeconds = Math.floor((now - date) / 1000);

        const intervals = [
            { label: "year", seconds: 31536000 },
            { label: "month", seconds: 2592000 },
            { label: "day", seconds: 86400 },
            { label: "hour", seconds: 3600 },
            { label: "minute", seconds: 60 },
            { label: "second", seconds: 1 },
        ];

        for (const interval of intervals) {
            const count = Math.floor(diffInSeconds / interval.seconds);
            if (count > 0) {
                return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
            }
        }

        return "just now";
    }

    return (

        <div className='duonutNew' style={{ cursor: "auto", margin: "25px 0px 30px 75px", width: type === "grid" ? "100%" : "30%", }} onMouseEnter={() => changeThumbnails(true)} onMouseLeave={resetThumbnails} >
            {showShareModal && <ShareModal shareData={shareData} bgcolor={"#3184C9"} />}
            {showEmbedModal && <EmbedModal shareData={shareData} bgcolor={"#3184C9"} />}
            {showQrCode && <QrCodeModal shareData={shareData} bgcolor={"#3184C9"} />}
            {showNuggetsModal && <NuggetsModal shareData={shareData} bgcolor={"#3184C9"} />}

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #ccc",
                background: " white",
                padding: "20px",
                borderRadius: " 5px",
                cursor: "pointer",
                borderLeft: "5px solid #3184C9",
                // width: "25%"
            }}
                onClick={handleEdit} >
                <div>
                    <div style={{ marginRight: "2vw", fontWeight: "bold", fontSize: "17px", marginBottom: "5px" }}>{name}</div>
                    <div
                        style={{ marginRight: "2vw", fontSize: "17px", color: "#5e6c72", borderRadius: "5px", marginBottom: "2px" }}
                        title={`${new Date(created_at).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                        })}, ${new Date(created_at).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                        })}`} // Full Date and Time Tooltip
                    >
                        Created {getRelativeTime(created_at)} {/* Relative Date */}
                    </div>
                    <div style={{ marginRight: "2vw", fontSize: "17px", marginBottom: "2px" }}>{total_views} Views</div>
                    <div style={{ fontSize: "17px", display: "flex", alignItems: "center" }}>
                        <label>Status</label> &nbsp;
                        <label className="checkbox-slider">
                            <input type="checkbox" id="active" name="active" checked={active ? true : false} onChange={() => { handelDuonutStatus() }} />
                            <span className="slider-span"></span>
                        </label>
                    </div>
                    <div style={{ marginRight: "2vw", marginTop: "5px", fontSize: "15px" }}>{published ? "published" : "Draft"}</div>
                </div>

                <div className="right" style={{ height: "20px", position: "relative" }} onClick={e => popUpHandler(e)} ref={menuIconRef}>

                    {/* { {isPlaying &&  */}
                    <i class="fa fa-ellipsis-v" aria-hidden="true" style={{ cursor: 'pointer', fontSize: "20px" }}></i>
                    {/* <img src={VerticalBars} loading="lazy"
                            style={{ cursor: 'pointer', padding: '2px 6px', paddingLeft: '8px' }}
                        /> */}
                    {/* { */}
                    {
                        showOptions && <div ref={optionsRef}
                            className="options"
                        // style={
                        //     // popUpPositionX < 1350 ?
                        //     { top: `${popUpPositionY}px`, left: `${popUpPositionX}px` }
                        //     //: { top: `${popUpPositionY + 260}px`, left: `${popUpPositionX - 280}px` }
                        // }
                        >
                            <div className="top">
                                <div className="popuptitle">
                                    {name}
                                </div>
                                <span>
                                    {`created on ${getNumberSuffix(date.getDate())} ${mS[date.getMonth()]} ${date.getFullYear()}`}
                                </span>
                                <span>
                                    {`updated on ${getNumberSuffix(lmdate.getDate())} ${mS[lmdate.getMonth()]} ${lmdate.getFullYear()}`}
                                </span>
                            </div>
                            <div className="mainpopup">
                                <ul>
                                    <li className='item' onClick={handleEmbedCode}>
                                        <span>Get Embed Code</span>
                                    </li>
                                    {qrcode && <li className='item' onClick={handleQrCode}>
                                        <span>QR Code</span>
                                    </li>}
                                    <li className='item' onClick={handleView}>
                                        <span>View as a user</span>
                                    </li>
                                    <li className='item' onClick={handleShare}>
                                        <span>Share</span>
                                    </li>
                                    <li className='item' onClick={handleReport}>
                                        <span>Analytics</span>
                                    </li>
                                    <li className='item' onClick={handleAnalytics}>
                                        <span>Reports</span>
                                    </li>
                                    <li className='item' onClick={handleCopy}>
                                        <span>Duplicate</span>
                                    </li>
                                    <li className='item' onClick={handelDelete}>
                                        <span>Delete</span>
                                    </li>
                                    {nuggets && <li className='item' onClick={handleAddNuggets}>
                                        <span>Add to Workflows</span>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    );
};

export default DuonutNewGrid;
