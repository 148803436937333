import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Payment from "./CreditPayment/Payment";
// import { updateLogoutModalActive } from "../../redux/slices/formDetails";
import { useNavigate } from "react-router-dom";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
// import { useDispatch } from "react-redux";
import axios from "axios";
import { domain } from "../../services/constants";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import MenuLeftBar from "../../Components/MenuLeftBar/MenuLeftBar";

const Credit = () => {
    const {    credit, total_credit } = useSelector((state) => state.user);
    const [credit_payment, setCredit] = useState(10);
    const [showPayment, setShowPayment] = useState(false);  // or set the correct logic based on your requirements
    // const dispatch = useDispatch();
    // const [selectedPanelTab, setselectedPanelTab] = useState(1);
    // const [isCollapsed, setIsCollapsed] = useState(false); // State to control sidebar collapse
    const navigate = useNavigate();
    const [openLogout, setopenLogout] = useState(false);
    const [transaction, setTransaction] = useState([]);
    const [ispublished, setispublished] = useState(false);
    // const [isSmallScreen, setIsSmallScreen] = useState(false);
    // const [isHovered, setIsHovered] = useState(false);

    const handelClose = () => {
        setopenLogout(false);
    }
    const logoutRef = useRef(null);
    useOutsideAlerter(logoutRef, handelClose);

    const handleIncreaseCredit = () => {
        if (credit_payment < 2000) {
            setCredit(credit_payment + 5);
        }
    };

    const handleDecreaseCredit = () => {
        if (credit_payment > 5) {
            setCredit(credit_payment - 5);
        }
    };

    const handleAddCredit = () => {
        setShowPayment(true);
    };
    const handleClosePayment = () => {
        setShowPayment(false);
    };
    useEffect(() => {
        const token = localStorage.getItem("access");
        axios({
            method: "GET",
            url: `${domain}/duonut/payment_transaction_history`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },

        }).then(data => {
            // console.log('Full Transaction Data:', data);
            setTransaction(data?.data);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });


    }, [ispublished]);
    return (
        <div className='duonutContainer' style={{ display: "flex" }}>
            <MenuLeftBar />
            <div style={{
                display: "flex", flexDirection: "column", minWidth: "95%", paddingTop: "10vh", overflowY: "scroll"
            }}>
                <div className='AIDPN1container2'>
                    < div style={{ background: "#cccccc1f", borderRadius: "5px", padding: "15px 25px" }}>
                        <h2 style={{ margin: "auto", paddingBottom: "1rem", textAlign: "center" }}>Credits</h2>
                        <div>
                            <p style={{ fontSize: "14px" }}>Your Acoount has</p>
                            <h3>{total_credit - credit} / 100 + {total_credit - 100}</h3>
                            <p style={{ fontSize: "14px", color: "#6e777c" }}>Free 100 credit is added to your account to use any Pro feature of your choice</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                <div
                                    style={{
                                        border: "1px solid #ccc", borderRadius: "5px", padding: "5px",
                                        display: "flex", justifyContent: "space-between", width: "80px",
                                        height: "30px", width: "40%", margin: "20px 0px"
                                    }}>
                                    <input
                                        type="number"
                                        id="credit"
                                        name="credit"
                                        min="5"
                                        max="2000"
                                        value={credit_payment}
                                        onChange={(e) => setCredit(Math.min(Math.max(Number(e.target.value), 5), 2000))}
                                        style={{ width: '40px', textAlign: 'center' }}
                                    />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <button
                                            type="button"
                                            onClick={handleIncreaseCredit}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25B2;
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleDecreaseCredit}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25BC;
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {/* {userAccountType === "free" ? (
                                                      <a href='https://duonut.com/pricing#pricing_container_list' target='_blank'>
                                                          <button style={{ border: "1px solid #ccc", borderRadius: "5px", background: "#ccc", padding: "12px", cursor: "pointer" }}>Add Credit <i class="fa fa-lock" aria-hidden="true"></i></button>
                                                      </a>
                                                  ) : ( */}
                                    {/* (userAccountType === "pro" || userAccountType === "plus" || userAccountType === "enterprise") && ( */}
                                    <button style={{ border: "1px solid #ccc", borderRadius: "5px", background: "#ccc", padding: "12px", cursor: "pointer" }} onClick={handleAddCredit}>Add Credit</button>
                                    {/* ) */}
                                    {/* )} */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#cccccc1f", borderRadius: "5px", marginTop: "25px" }}>
                        <h2 style={{ margin: "auto", padding: "2rem 0rem", textAlign: "center" }}>Previous Transactions</h2>
                        <div style={{ padding: "0px 10px" }}>
                            <table style={{ width: "100%", borderCollapse: "collapse", margin: "1rem 0" }}>
                                <thead>
                                    <tr style={{ background: "#f4f4f4", textAlign: "left" }}>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>No.</th>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>Name</th>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>Message</th>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>Amount</th>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>Status</th>
                                        <th style={{ padding: "1rem", borderBottom: "1px solid #ddd" }}>Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transaction.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((transaction, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: "1rem", border: "1px solid #ddd" }}>{index + 1}</td>
                                            {/* <td style={{ padding: "1rem", border: "1px solid #ddd" }}>Sneha Pandit</td> */}
                                            <td style={{ padding: "1rem", border: "1px solid #ddd" }}>{transaction.name}</td>
                                            <td style={{ padding: "1rem", border: "1px solid #ddd" }}>{transaction.message}</td>
                                            <td style={{ padding: "1rem", border: "1px solid #ddd" }}>{transaction.amount}</td>
                                            <td style={{ padding: "1rem", border: "1px solid #ddd" }}>{transaction.status}</td>
                                            <td style={{ padding: "1rem", border: "1px solid #ddd" }}>
                                                {new Date(transaction.created_at).toLocaleDateString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                })}{" "}
                                                {new Date(transaction.created_at).toLocaleTimeString("en-US", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: true,
                                                })}
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {showPayment && (
                        <div className="credit-overlay">
                            <div className="credit-container">
                                <button
                                    className="credit-close-button"
                                    onClick={handleClosePayment}
                                >
                                    ×
                                </button>
                                <Payment credit_payment={credit_payment} />
                            </div>
                        </div>
                    )}
                </div>
            </div >
        </div >

    );
}

export default Credit;