import React, {  useState } from "react";
import "./LeftSidebar.css";
import Slides from "./Slides";
import ElementsList from "./ElementsList";
import "intro.js/introjs.css";
import {  useNavigate } from 'react-router-dom';

const LeftSidebar = () => {
    const [selectedTab, setSelectedTab] = useState(1); // Initially showing Steps tab
    const navigate = useNavigate()

    return (
        <div className="LeftSidebarMainContainer" data-intro="Here you can add and check the number of slides/steps and add as many elements as per your needs.">
            <div className="LeftSidebarContainer">
                <div
                    className={`LeftSidebarTab ${selectedTab === 1 ? "active" : ""}`}
                    onClick={() => setSelectedTab(1)}
                >
                    Step
                    {selectedTab === 1 && <div className="selected-line"></div>}
                </div>
                <div
                    className={`LeftSidebarTab ${selectedTab === 2 ? "active" : ""}`}
                    onClick={() => setSelectedTab(2)}
                >
                    Elements
                    {selectedTab === 2 && <div className="selected-line"></div>}
                </div>
            </div>
            {selectedTab === 1 && (
                <div className="TabWrapper-Step">
                    <Slides className="Slides" />
                </div>
            )}
            {selectedTab === 2 && (
                <div className="TabWrapper-Elements">
                    <ElementsList className="ElementsList" />
                </div>
            )}
        </div>
    );
};

export default LeftSidebar;