import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements,    updateElementInputFocus } from '../../redux/slices/userActivity';
import {  useLocation, } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import Select from 'react-select';

const DropdownMultiselect = ({
    id,
    elementAttr: {
        color, fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius,
        options, required, fieldPosition, gap,
    },
    conditions,
}) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity,  selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const [dropdownMulti, setdropdownMulti] = useState([]);
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name, viewer_url_params } = useSelector((state) => state.duonut);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [inputText, setInputText] = useState(text);

    useEffect(() => {
        setInputText(text);
    }, [text]);

    const handleDropdownMultiTextChange = (e) => {
        // console.log(e.target.value);
        setInputText(e.target.value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    text: e.target.value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleDropdownMultiSelectChange = (selectedOptions) => {
        if (location.pathname.includes("/duonut/")) {
            // console.log("dropdownMulti", dropdownMulti)
            const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
            setdropdownMulti(selectedValues);
            submitResponse(selectedValues);
        }
    };

    const submitResponse = (selectedValues) => {
        if (location.pathname.includes("/duonut/")) {
            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: selectedValues.length > 0 ? 1 : 0,
                question: text,
                answered_text: selectedValues.map((value) => ({ value })),
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "DropdownMultiselect",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                url_params: viewer_url_params,
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => {
                const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                formData.append(key, value);
              });

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "DropdownMultiselect",
                step: selectedSlide + 1,
                question: text,
                answered_text: selectedValues.map((value) => ({ value })),
            }
            dispatch(saveQuizResponse(save_response));
        }
    }

    const handleInputFocus = () => {
        dispatch(updateElementInputFocus(true));
    }
    const handleInputBlur = () => {
        dispatch(updateElementInputFocus(false));
    }

    // console.log("ddd", options);

    return (
        <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: fieldPosition ? fieldPosition : "column", gap: gap ? gap : fieldPosition === 'row' ? '20px' : '0' }}>
            <EditText
                id={`dropdownmulti-input-${id}`}
                value={required && location.pathname.includes("/duonut/") ? inputText + " *" : inputText}
                className='edit-input-text'
                style={{
                    backgroundColor: "transparent",
                    border: "0",
                    outline: "none",
                    // display: "flex",
                    // alignItems: "center",
                    // width: "max-content",
                    color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                    textAlign, lineHeight, letterSpacing, padding
                }}
                onEditMode={() => {
                    handleInputFocus();
                    // setTimeout(() => document.querySelector(`#dropdownmulti-input-${id}`).select(), 100); 
                }}
                onChange={(e) => handleDropdownMultiTextChange(e)}
                onBlur={handleInputBlur}
                readonly={(location.pathname).includes("/duonut/") || viewType === 3 ? true : false}
            />
            <Select
                styles={{
                    container: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "100%",
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                        textAlign, lineHeight, letterSpacing, padding,
                        backgroundColor, border: border ? border : "0", margin, borderRadius,
                    }),
                }}
                isMulti
                options={options.map((item, i) => ({
                    value: item,
                    label: item,
                }))}
                value={dropdownMulti.map((value) => ({ value, label: value }))}
                onChange={handleDropdownMultiSelectChange}
            // isDisabled={location.pathname.includes("/duonut/") ? false : true}
            />
        </div>
    );
}

export default DropdownMultiselect;
