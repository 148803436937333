import React, { useState, useEffect } from 'react';
import './Element.css';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { saveQuizAnsAsync, saveQuizResponse, showDuonutresponse } from '../../redux/slices/DuonutReducer';
import imgPreview from '../../assets/Images/image-preview.png';
import useWindowDimensions from "../../hooks/useWindowDimensions";
// import { EditText, EditTextarea } from "react-edit-text";
import { getOpenEndedQuestionAsync } from "../../redux/slices/AiDuonutNewReducer";
import { updateGoToSlide, updateElementInputFocus, } from '../../redux/slices/userActivity';
import { showduonutData } from '../../redux/slices/DuonutReducer';
import axios from "axios";
import { domain } from "../../services/constants";

var aiCount = 0;
var aiConversation = [];
const RadioMultiImage = ({
    slideId,
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, maxLength, gap,
        labelFontSize, labelFontWeight, labelColor, optionsImage, imageWidth, imagePadding, fieldPosition, required,
        openEnded, openEndedObjective, openEndedFeedbackDesired, description, description2,
        descriptionFontSize, descriptionFontWeight, descriptionColor, description2FontSize, description2FontWeight, description2Color,
    },
    elementHoverAttr,
    conditions,
    language,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {     selectedSlide, } = useSelector((state) => state.userActivity);
    const { viewer_display_id, viewer_nick_name, viewer_url_params } = useSelector((state) => state.duonut);
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const { width } = useWindowDimensions();
    const [hover, setHover] = useState(-1);
    const [isOther, setisOther] = useState(false);
    const [loading, setloading] = useState(false);
    const [textField, setTextField] = useState('');

    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];

    const [shuffledOptions, setShuffledOptions] = useState([]);
    const [inputText, setInputText] = useState("");
    const [optionsText, setoptionsText] = useState([]);

    useEffect(() => {
        if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
            fetchText(text);
            fetchOptionsText(shuffledOptions);
        } else {
            setInputText(text);
            setoptionsText(shuffledOptions);
        }
    }, [text, shuffledOptions]);

    // const fetchOptionsText = async (texts) => {
    //     const translatedTexts = []; // Initialize an array to hold translated texts

    //     // Create an array to hold the texts to be translated (combining item, description, and description2)
    //     const textsToTranslate = texts.map(item => {
    //         const delimiter = "|;|";
    //         return `${item.item}${delimiter}${item.description}${delimiter}${item.description2}`;
    //     });

    //     // Translate all texts in one go by joining them with a unique delimiter
    //     const combinedText = textsToTranslate.join("|||"); // Using a different delimiter to join all texts
    //     const translatedCombinedText = await translateText(combinedText, language);

    //     // Split the translated text into individual translated parts
    //     const translatedParts = translatedCombinedText.split("|||");

    //     // Now split the individual parts and push to the result array
    //     let index = 0;
    //     for (const item of texts || []) {
    //         const delimiter = "|;|";
    //         // Ensure we are not accessing an undefined or out-of-bounds element in translatedParts
    //         if (translatedParts[index]) {
    //             const translatedText = translatedParts[index].split(delimiter);
    //             translatedTexts.push({
    //                 item: translatedText[0] || "",
    //                 image: item.image,
    //                 description: translatedText[1] || "",
    //                 description2: translatedText[2] || "",
    //             });
    //         } else {
    //             console.error(`No translation found for item at index ${index}`);
    //         }
    //         index++;
    //     }

    //     setoptionsText(translatedTexts); // Update the state with the translated texts
    // };
    const fetchOptionsText = async (texts) => {
        // Use unique placeholders for tags to avoid them being translated
        const combinedText = texts.map(item => {
            return `<TAG_section>
                    <TAG_item>${item.item}</TAG_item>
                    <TAG_description>${item.description}</TAG_description>
                    <TAG_description2>${item.description2}</TAG_description2>
                </TAG_section>`;
        }).join("<separator>");

        // Translate all sections at once
        const translatedCombinedText = await translateText(combinedText, language);

        // Replace the placeholders back to actual tags
        const decodedText = translatedCombinedText
            .replace(/<TAG_section>/g, "<section>")
            .replace(/<\/TAG_section>/g, "</section>")
            .replace(/<TAG_item>/g, "<item>")
            .replace(/<\/TAG_item>/g, "</item>")
            .replace(/<TAG_description>/g, "<description>")
            .replace(/<\/TAG_description>/g, "</description>")
            .replace(/<TAG_description2>/g, "<description2>")
            .replace(/<\/TAG_description2>/g, "</description2>");

        // Split the translated text back into sections
        const translatedSections = decodedText.split("<separator>");

        // Map the translated sections back to their original structure
        const translatedTexts = translatedSections.map((section, index) => {
            const itemMatch = section.match(/<item>(.*?)<\/item>/);
            const descriptionMatch = section.match(/<description>(.*?)<\/description>/);
            const description2Match = section.match(/<description2>(.*?)<\/description2>/);

            return {
                item: itemMatch ? itemMatch[1] : "",
                description: descriptionMatch ? descriptionMatch[1] : "",
                description2: description2Match ? description2Match[1] : "",
                // image: texts[index].image, // Retain the original image
            };
        });

        setoptionsText(translatedTexts); // Update the state with the translated texts
    };

    const fetchText = async (texts) => {
        const t_text = await translateText(texts, language);
        setInputText(t_text);
    };
    const translateText = async (texts, targetLanguage) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${domain}/duonut/language_translator`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    source: "en",
                    target: targetLanguage,
                    query: texts,
                },
            });

            if (response.status === 200) {
                // console.log("Translation:", response);
                return response?.data.translation; // return the translated text
            } else {
                // Handle unsuccessful status codes
                return "Error: Translation failed. Please try again.";
            }
        } catch (error) {
            console.error('Error in translation API:', error);
            return "Error translating text. Please try again."; // Handle API errors gracefully
        }
    };

    useEffect(() => {
        // Shuffle the options and related arrays
        const combined = options.map((item, index) => ({
            item,
            description: description[index],
            description2: description2[index],
            image: optionsImage[index],
        }));

        if (location.pathname.includes("/duonut/")) {
            // Fisher-Yates Shuffle Algorithm
            for (let i = combined.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [combined[i], combined[j]] = [combined[j], combined[i]];
            }
        }

        setShuffledOptions(combined);
    }, [options, description, description2, optionsImage]);


    useEffect(() => {
        setisOther(false);
        aiCount = 0;
        aiConversation = [];
    }, []);


    const handleQuestionAnswerInputChange = (e) => {
        // console.log(e)
        if (location.pathname.includes("/duonut/")) {
            setTextField(e.target.value);
            if (e.keyCode === 13) {
                document.getElementById("AiSubmitBtn").click();
            }
        }
    }
    const submitResponse = (item, answer) => {
        if (location.pathname.includes("/duonut/") && !openEnded) {
            // console.log("ans", item)

            setSelectedAnswer(item); // Update selectedAnswer state

            // if (location.pathname.includes("/duonut/")) {
            const save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0]?.watch_id || "",
                result: 0,
                question: text || "",
                answered_text: answer,
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "RadioMultiImage",
            };

            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));
            dispatch(saveQuizAnsAsync(formData));

            const save_response = {
                id,
                type: "RadioMultiImage",
                step: selectedSlide + 1,
                question: text || "",
                answered_text: answer,
            };
            dispatch(saveQuizResponse(save_response));

            var nextPage = selectedSlide + 1;

            setTimeout(() => {
                dispatch(updateGoToSlide(nextPage));
            }, 1000);
        } else if (location.pathname.includes("/duonut/") && openEnded) {
            // console.log("question", inputText, textField);
            setSelectedAnswer(item); // Update selectedAnswer state

            var userIsPro = false;
            if (duonutData?.created_by.pro_user) {
                userIsPro = true;
            } else if (duonutData?.created_by.expire_date) {
                const currentDate = new Date();
                const dateToCompare = new Date(duonutData?.created_by.expire_date);
                const comparisonResult = currentDate < dateToCompare;
                if (comparisonResult) {
                    userIsPro = true;
                } else {
                    userIsPro = false;
                }
            }
            if ((!userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit)) || (userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit))) {

                setloading(true);

                aiConversation = [...aiConversation,
                { question: inputText, answer: textField },
                ];

                var get_ans = {
                    question: inputText,
                    answer: answer,
                    objective: openEndedObjective,
                    feedbackDesired: openEndedFeedbackDesired,
                    aiConversation: aiConversation,
                    option_type: 1,
                }

                dispatch(getOpenEndedQuestionAsync(get_ans)).then((res) => {
                    // console.log(res);
                    if (res?.question) {
                        // console.log(res?.question);
                        setInputText(res?.question);
                        if (document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`)) {
                            document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`).value = "";
                        }
                        setloading(false);

                        var save_ans = {
                            display_id: viewer_display_id,
                            watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                            result: 1,
                            question: inputText ? inputText : "",
                            answered_text: answer,
                            // end: lastQue ? "1" : "0",
                            nickname: viewer_nick_name,
                            step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                            type: aiCount === 0 ? "RadioMultiImage" : "QuestionAnswer",
                            // country: location[0] ? location[0].address?.country : "",
                            // city: location[0] ? location[0].address?.state_district : "",
                            // location: location[0] ? location[0].display_name : "",
                            url_params: viewer_url_params,
                        }
                        // console.log("save_ans", save_ans);
                        const formData = new FormData();
                        Object.keys(save_ans).forEach(key => {
                            const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                            formData.append(key, value);
                        });

                        dispatch(saveQuizAnsAsync(formData));

                        var save_response = {
                            type: aiCount === 0 ? "RadioMultiImage" : "QuestionAnswer",
                            step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                            question: inputText ? inputText : "",
                            answered_text: answer,
                        }
                        dispatch(saveQuizResponse(save_response));
                        // console.log("save_response");

                        aiCount++;

                    }
                }).catch((e) => {
                    console.log(e.message, e);
                });

            } else {
                var save_ans = {
                    display_id: viewer_display_id,
                    watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                    result: 1,
                    question: inputText ? inputText : "",
                    answered_text: answer,
                    // end: lastQue ? "1" : "0",
                    nickname: viewer_nick_name,
                    step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                    type: aiCount === 0 ? "RadioMultiImage" : "QuestionAnswer",
                    // country: location[0] ? location[0].address?.country : "",
                    // city: location[0] ? location[0].address?.state_district : "",
                    // location: location[0] ? location[0].display_name : "",
                    url_params: viewer_url_params,
                }
                // console.log(save_ans);
                const formData = new FormData();
                Object.keys(save_ans).forEach(key => {
                    const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                    formData.append(key, value);
                });

                dispatch(saveQuizAnsAsync(formData));

                var save_response = {
                    type: aiCount === 0 ? "RadioMultiImage" : "QuestionAnswer",
                    step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                    question: inputText ? inputText : "",
                    answered_text: answer,
                }
                dispatch(saveQuizResponse(save_response));

                var nextPage = selectedSlide + 1;

                setTimeout(() => {
                    dispatch(updateGoToSlide(nextPage));
                }, 1000);
            }

        }
    }
    const handleInputFocus = () => dispatch(updateElementInputFocus(true));
    const handleInputBlur = () => dispatch(updateElementInputFocus(false));

    return (<>
        {aiCount === 0 && <div style={{ display: "flex", flexDirection: "column", gap }}>
            <div style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing,
            }}>
                {inputText}
            </div>

            <div style={{
                display: "flex",
                justifyContent: textAlign,
                alignItems: 'center',
                flexDirection: (viewType === 3 || width < 800) ? "column" : fieldPosition,
                flexWrap: "wrap"
            }}>
                {shuffledOptions.map((option, i) => (
                    <div key={i} style={{ display: "flex", alignItems: "center", lineHeight }}>
                        <input
                            type="radio"
                            id={`radio_option${i}`}
                            name="radio_option"
                            style={{
                                width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px", accentColor: color
                            }}
                            checked={selectedAnswer.includes(option.item)}
                            disabled={maxLength && maxLength === selectedAnswer.length && !selectedAnswer.includes(option.item)}
                            onChange={() => {
                                if (!(maxLength && maxLength === selectedAnswer.length && !selectedAnswer.includes(option.item))) {
                                    submitResponse(option.item, `${option.item}-${option.description}-${option.description2}`);
                                }
                            }}
                        />
                        <div
                            style={{
                                display: "flex", flexDirection: "column", alignItems: "center",
                                backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                                border: hover === i ? elementHoverAttr?.border : border || "0",
                                color: hover === i ? elementHoverAttr?.color : color,
                                borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                                textTransform, textAlign, letterSpacing, padding, margin, cursor: location.pathname.includes("/duonut/") ? "pointer" : "default",
                                width: "100%", height: "100%",
                            }}
                            onMouseEnter={() => setHover(i)}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => {
                                if (!(maxLength && maxLength === selectedAnswer.length && !selectedAnswer.includes(option.item))) {
                                    submitResponse(option.item, `${option.item}-${option.description}-${option.description2}`);
                                }
                            }}
                        >
                            <div>{optionsText[i]?.item}</div>
                            <img
                                src={option.image || imgPreview}
                                style={{ width: imageWidth, height: imageWidth, objectFit: "contain", padding: imagePadding, boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)" }}
                                alt=""
                            />
                            <div className='two-line-truncate' style={{
                                color: descriptionColor, fontSize: descriptionFontSize, fontWeight: descriptionFontWeight, width: imageWidth
                            }}>{optionsText[i]?.description}</div>
                            <div style={{
                                color: description2Color, fontSize: description2FontSize, fontWeight: description2FontWeight,
                            }}>{optionsText[i]?.description2}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>}
        {openEnded && aiCount > 0 && <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: "column", height: "350px", width: "600px", margin: "auto" }}>
            <div
                style={{
                    backgroundColor: "transparent",
                    border: "0",
                    outline: "none",
                    // display: "flex",
                    // alignItems: "center",
                    width: "100%",
                    color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                    textAlign, lineHeight, letterSpacing, padding
                }}
            >{inputText}</div>
            <div style={{ display: "flex" }}>
                <input type='text' key={`qainput-${selectedSlide + 1}-ai${aiCount}`}
                    id={`qainput-${selectedSlide + 1}-ai${aiCount}`}
                    style={{
                        width: "100%", minHeight: "40px",
                        color, fontFamily, fontSize: fontSize - 4, fontStyle, textDecoration, textTransform,
                        textAlign: "left", lineHeight, letterSpacing, padding: "4px 10px", boxSizing: "border-box",
                        backgroundColor, border: '1px solid gray', margin: "0px 5px", borderRadius
                    }}
                    onKeyUp={(e) => handleQuestionAnswerInputChange(e)}
                    readOnly={location.pathname.includes("/duonut/") ? false : true}
                    required={required}
                    placeholder={"Type your answer here.."}
                />
                <button id="AiSubmitBtn" onClick={() => submitResponse(textField, textField)}
                    style={{ backgroundColor: "#4299e1", color: "white", borderRadius: "6px", padding: '1rem', cursor: "pointer" }}>
                    {loading ? <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "1rem" }} />
                        : <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>}
                </button>
            </div>
        </div>}
    </>
    );
}

export default RadioMultiImage;
