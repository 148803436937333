import React from 'react';
import './ManufacturingChecklists.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
import useWindowDimensions from "../../hooks/useWindowDimensions";
// import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";

const ManufacturingChecklists = () => {
    const { width} = useWindowDimensions();
    // const [selected, setSelected] = useState(0);
    const navigate = useNavigate();

    return (
        <div className='BTD1App'>
            <div className='BTD1template'>
                <h1 style={{ fontSize: "2.5rem", paddingTop: "2rem" }}>
                    The Ultimate Beginner's Guide to Manufacturing Checklists
                </h1>
                <div style={{ fontSize: "14px" }}>By, Ramya Bhaskar (Founder - Donut Inc)</div>

                <div style={{ display: "flex", marginTop: "2rem" }}>
                    <div style={{ width: width < 800 ? "100%" : "50%" }}>
                        <h2 id='BlogTD1'>The Importance of having Manufacturing Checklists</h2>
                        <br></br>
                        <div>
                            Precision and efficiency are very important in the ever-changing manufacturing world. One powerful tool to ensure both is the manufacturing checklist. This guide will walk you through the essentials of creating and using checklists in your manufacturing processes, helping you streamline workflows and improve productivity.
                        </div>
                    </div>
                    <div className='BTD1section-1' style={{ width: width < 800 ? "100%" : "50%", padding: "0" }}>
                        <img className='BTD1section-1-image' style={{ width: "80%", border: "none", borderRadius: "15px", objectFit: 'cover' }} src="/assets/blogs/blog7-1.jpg" alt="" />
                    </div>
                </div>

                <br></br>

                <br></br>
                <br></br>

                <h1>What is a Manufacturing Checklist?</h1>
                <br></br>
                <br></br>
                <h2 id='BlogTD1'>Understanding the Basics</h2>
                <br></br>
                <div>
                    A manufacturing checklist is nothing but a systematic list of tasks, procedures, or steps that need to be completed to ensure a product is made correctly and fault free. It serves as a critical reference point for workers, ensuring consistency and quality across production lines. From the initial stages of planning to the final quality checks, a well-crafted checklist keeps everyone on the same page.
                </div>
                <br></br>

                <h2 id='BlogTD1'>Why Use Manufacturing Checklists?</h2>
                <br></br>
                <div>
                    Manufacturing Checklists ensure that every step is followed precisely, which is particularly important in industries where precision is key. They help in maintaining standards, minimizing errors, and reducing waste. Additionally, checklists facilitate training for new employees, providing a clear framework of what needs to be done and in what order.
                </div>
                <br></br>
                <div className='BTD1section-1'>
                    <img className='BTD1section-1-image' src="/assets/blogs/blog7-2.jpg" alt="" />
                </div>
                <br></br>


                <h1>Building Your First Checklist</h1>
                <br></br>
                <br></br>
                <h2 id='BlogTD1'>Getting Started</h2>
                <br></br>
                <div>
                    To create an effective manufacturing checklist, start by mapping out your workflow. Identify each step in the process and outline the necessary actions. This can be done manually or with the help of a <a href='https://duonut.com' target="_blank" >workflow builder</a>, a tool that allows you to visualize and automate your processes.
                </div>
                <br></br>
                <h2 id='BlogTD1'>Key Elements to Include</h2>
                <br></br>
                <div> When building your checklist, include the following elements:</div>
                <br></br>
                <div style={{ paddingLeft: "2rem" }}>
                    <div>1.  Task Description: A clear and concise explanation of each task.</div>
                    <div>2.  Sequence: The order in which tasks should be performed.</div>
                    <div>3.  Responsible Person: Who is responsible for each task.</div>
                    <div>4.  Timeframe: The expected time to complete each task.</div>
                    <div>5.  Checkpoints: Specific points where checks should be made to ensure quality.</div>
                </div>


                <br></br>
                <br></br>
                <h1>Automating Your Checklists</h1>
                <br></br>
                <br></br>
                <h2 id='BlogTD2'>Leveraging Technology</h2>
                <br></br>
                <div>
                    In today’s digital age, automation can significantly enhance the efficiency of your manufacturing checklists. By using a workflow builder like <a href='https://duonut.com' target="_blank" >Duonut</a>, you can automate routine tasks, reminders, and notifications. This not only saves time but also reduces the risk of human error.</div>
                <br></br>

                <h2 id='BlogTD3'>Tools for Automation</h2>
                <br></br>
                <div>
                    Several no-code tools are available to help you automate your checklists. Anyone with basic knowledge of computers will be able to create <a href='https://duonut.com' target="_blank" >workflows</a> in minutes using Duonut. These tools can integrate with your existing systems and provide real-time updates on task completion, helping you keep track of your workflow effortlessly. This also helps the management to monitor the progress of their employees in real-time.
                </div>
                <br></br>
                <br></br>

                <div style={{ display: "flex", justifyContent: "center", position: "relative", height: "90vh" }}>
                    <iframe id="iframeid" style={{ width: "1024px", height: "575px", transform: "scale(0.9)", position: "absolute" }} src="https://duonut.com/duonut/7191696268" title="Duonut Iframe"></iframe>
                </div>
                <br></br>


                <br></br>
                <br></br>
                <h1>Implementing Checklists in Your Workflow</h1>
                <br></br>
                <br></br>
                <h2 id='BlogTD2'>Training Your Team</h2>
                <br></br>
                <div>
                    Once your checklists are ready, it's crucial to train your team on how to use them effectively. Conduct training sessions to familiarize your employees with the new system. Highlight the benefits of using checklists and demonstrate how they can make their work easier and more efficient.
                </div>
                <br></br>
                <br></br>

                <h2 id='BlogTD2'>Monitoring and Adjusting </h2>
                <br></br>
                <div>
                    After implementation, monitor the effectiveness of your checklists. Gather feedback from your team and make adjustments as necessary. This iterative process ensures that your checklists remain relevant and useful as your manufacturing processes evolve.
                </div>
                <br></br>
                <br></br>

                <div style={{ display: "flex", justifyContent: "center", position: "relative", height: "90vh" }}>
                    <iframe id="iframeid" style={{ width: "1024px", height: "575px", transform: "scale(0.9)", position: "absolute" }} src="https://duonut.com/duonut/5129399213" title="Duonut Iframe"></iframe>
                </div>
                <br></br>

                <br></br>
                <br></br>
                <h1>Benefits of Using Manufacturing Checklists</h1>
                <br></br>
                <br></br>
                <h2 id='BlogTD2'>Improved Efficiency</h2>
                <br></br>
                <div>
                    Manufacturing checklists streamline your workflow, ensuring that tasks are completed in the correct order and on time. This improves overall efficiency and reduces downtime.
                </div>
                <br></br>
                <h2 id='BlogTD2'>Enhanced Quality Control </h2>
                <br></br>
                <div>
                    Checklists help maintain high standards of quality by ensuring that every step is performed correctly. They act as a safeguard against mistakes and ensure that every product meets your quality criteria.
                </div>
                <br></br>
                <h2 id='BlogTD2'>Better Compliance</h2>
                <br></br>
                <div>
                    In many industries, compliance with regulations is crucial. Manufacturing checklists help ensure that all regulatory requirements are met, reducing the risk of non-compliance and potential penalties.
                </div>
                <br></br>
                <h2 id='BlogTD2'>Take Control of Your Manufacturing Process </h2>
                <br></br>
                <div>
                    By incorporating manufacturing checklists into your workflow, you can significantly improve your production processes. From building your checklist to automating tasks and training your team, each step is a move towards a more efficient and reliable manufacturing operation. Start today, and see the difference checklists can make in your manufacturing process.
                </div>
                <br></br>

                <div>View Duonut’s various workflow templates here - <a href='https://duonut.com/templates/education_&_learning' target="_blank" >https://duonut.com/templates/education_&_learning</a></div>



                <br></br>
                <br></br>
                <br></br>

                <h2>Related Blogs</h2>
                <br></br>
                <div className='BLG1blogs'>
                    <div className='BLG1blog' onClick={() => navigate('/blog/onboarding-documents')}>
                        <div className='BLG1blogImgContainer'>
                            <img className='BLG1blogimage' src="/assets/blogs/eop1.jpg" alt="" />
                        </div>
                        <h2 style={{ padding: "10px 0px" }}>How to Create an Effective Onboarding Program[SOP]</h2>
                        <div>
                            Effective onboarding can have a serious impact on employee productivity. It definitely takes more than going through the motions...
                        </div>
                    </div>

                    <div className='BLG1blog' onClick={() => navigate('/blog/cut-patient-onboarding')}>
                        <div className='BLG1blogImgContainer'>
                            <img className='BLG1blogimage' src="/assets/blogs/bcpo2.png" alt="" />
                        </div>
                        <h2 style={{ padding: "10px 0px" }}>Cut Patient onboarding time by 25 minutes</h2>
                        <div>
                            To make patient paperwork more convenient.
                        </div>
                    </div>

                    <div className='BLG1blog' onClick={() => navigate('/blog/improve-healthcare-workflows')}>
                        <div className='BLG1blogImgContainer'>
                            <img className='BLG1blogimage' src="/assets/blogs/bihw1.png" alt="" />
                        </div>
                        <h2 style={{ padding: "10px 0px" }}>Improve Healthcare Workflows and Save more than $35,000</h2>
                        <div>
                            Healthcare services requires the employers to collect a lot of patient data, which focuses on building better systems...
                        </div>
                    </div>


                </div>
                <br></br>
                <br></br>
            </div>
            <HomePageFooter />
        </div>
    );

}

export default ManufacturingChecklists;