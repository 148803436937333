import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";

// Register components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function BWChart({ percentages, attribute, levels }) {
    const chartData = {
        labels: levels,
        datasets: [
            {
                label: "Most Preferred (%)",
                data: levels.map(level => percentages[level]?.best || 0),
                backgroundColor: "rgba(24, 143, 30, 0.6)", // Positive values
                borderColor: "rgb(52, 180, 121)",
                borderWidth: 1,
            },
            {
                label: "Least Preferred (%)",
                data: levels.map(level => -(percentages[level]?.worst || 0)),
                backgroundColor: "rgba(255, 99, 132, 0.6)", // Negative values
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
            },
        ],
    };

    const options = {
        indexAxis: "y", // Horizontal bars
        scales: {
            x: {
                ticks: {
                    callback: value => `${value}%`,
                },
                title: {
                    display: true,
                    text: "Percentage",
                },
            },
            y: {
                title: {
                    display: true,
                    text: attribute,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${Math.abs(context.raw).toFixed(2)}%`;
                    },
                },
            },
        },
    };

    return (
        <div style={{ margin: "1rem 0rem" }}>
            <h3>{attribute}</h3>
            <Bar data={chartData} options={options} />
        </div>
    );
}

export default BWChart;
