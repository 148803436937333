import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import GoogleButton from "../../../googleButton";
import MicrosoftButton from "../../../microsoftButton";
import Input from "../../../Input/input";
import PasswordInput from "../../../Input/passwordInput";

import { closeModal, showSignUpForm, toggleforgotPassword } from "../../../../redux/slices/formDetails";
import { updateIsLoggedIn, updateUserAccountType, updateUserProAccess, updateUserTrialDays, updateTotalCredit, updateCredit } from "../../../../redux/slices/user";
import { getUserDetails, loginUser } from "../../../../services/auth";
import { validateEmail } from "../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAiNewAsync1 } from "../../../../redux/slices/AiNewReducer";
import { getMyDuonuts } from "../../../../services/duonut";

export default function Signin({ handleClose, signInError, setSignInError }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const location = useLocation();

  const [withEmail, setwithEmail] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLogIn, setisLogIn] = useState(false);

  const changeForm = () => {
    dispatch(showSignUpForm(true));
  };

  const handleError = (err) => {
    if (err.data.detail === "Email is not registered!") {
      setEmailError("Email not registered");
    } else {
      setPasswordError("Invalid credentials.");
    }
  };

  const handleLogin = (e) => {
    const state = sessionStorage.getItem('state');
    const canvaId = sessionStorage.getItem('canvaId');
    e.preventDefault();
    setEmailError("");
    setPasswordError("");

    const result = validateEmail(email.trim());

    if (email === "" || password === "") {
      email === "" && setEmailError("This field cant be empty");
      password === "" && setPasswordError("This field cant be empty");
      return;
    }
    if (result === null) return setEmailError("Please enter your email address in format : xyz@gmail.com");

    const credentials = {
      email: email.trim(),
      password: password.trim(),
      canvaId: canvaId,
    };

    loginUser(credentials, (err, res) => {
      if (err) {
        setisLogIn(false);
        return handleError(err.response);
      };
      if (res !== null) {
        setisLogIn(true);
        // alert("logged in");
        handleClose();
        if (rememberMe) {
          localStorage.setItem("access", res.data.access);
          localStorage.setItem("refresh", res.data.refresh);
          localStorage.setItem("withGoogle", false);
          localStorage.setItem("withMicrosoft", false);
        } else {
          localStorage.setItem("access", res.data.access);
          localStorage.setItem("refresh", res.data.refresh);
          localStorage.setItem("withGoogle", false);
          localStorage.setItem("withMicrosoft", false);
        }
        getUserDetails((err, userDetails) => {
          if (err) return handleError(err.response);
          localStorage.setItem("userName", userDetails.data.name);
          localStorage.setItem("userEmail", userDetails.data.email);
          localStorage.setItem("userId", userDetails.data.id);
          localStorage.setItem("appId", userDetails.data.app_id);
          localStorage.setItem("subDomain", userDetails.data.sub_domain);
          localStorage.setItem("proUser", userDetails.data.pro_user);
          localStorage.setItem("userProfilePicture", userDetails.data.profile_picture);
          localStorage.setItem("userType", userDetails.data.user_type);
          localStorage.setItem("expireDate", userDetails.data.expire_date);

          dispatch(updateTotalCredit(userDetails.data.total_credit));
          dispatch(updateCredit(userDetails.data.credit));
          dispatch(updateIsLoggedIn(true));
          dispatch(updateUserAccountType(userDetails.data.user_type ? userDetails.data.user_type : 'free'));
          if (String(userDetails.data.pro_user) === "true") {
            dispatch(updateUserProAccess(true));
            dispatch(updateUserAccountType('pro'));
          } else if (userDetails.data.expire_date) {
            const currentDate = new Date();
            const dateToCompare = new Date(userDetails.data.expire_date);
            const comparisonResult = currentDate < dateToCompare;
            if (comparisonResult) {
              dispatch(updateUserProAccess(true));
            } else {
              dispatch(updateUserProAccess(false));
              dispatch(updateUserAccountType('free'));
            }
          }

          if (userDetails.data.date_joined) {
            // console.log(userDetails.data.date_joined)
            // Get the current date and time
            const currentDate = new Date();

            // Subtract 7 days (7 days * 24 hours/day * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
            const currentDateMinus7Days = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));

            // Define the date to compare with
            const dateToCompare = new Date(userDetails.data.date_joined);

            // Calculate the difference in milliseconds
            const diffInMilliseconds = Math.abs(dateToCompare - currentDateMinus7Days);

            // Convert milliseconds to days
            const diffInDays = Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));

            // console.log(`Difference in days: ${diffInDays}`);

            // Compare the dates
            const comparisonResult = currentDateMinus7Days < dateToCompare;
            // console.log(currentDate, currentDateMinus7Days, dateToCompare, comparisonResult)

            dispatch(updateUserTrialDays(diffInDays));
            //   if (comparisonResult) {
            //   dispatch(updateUserProAccess(true));
            // }
          }

          if (canvaId && state) {
            window.location.replace(`https://www.canva.com/apps/configured?success=true&state=${state}`)
          }
          else if (location.pathname.substring(0, 7) !== "/design" && location.pathname.substring(0, 8) !== "/pricing" && location.pathname.substring(0, 8) !== "/payment") {
            // navigate("/myduonuts");
            // window.location.reload();
            // window.location = "/myduonuts";
            const saveAiNewPromise = dispatch(saveAiNewAsync1())
              .then((res) => {
                console.log("Save Response:", res);
                return res?.empty_message ? 0 : res?.length > 0 ? 1 : 0;  // Return 1 if status is 201, else 0
              })
              .catch((e) => {
                console.log("Save Error:", e.message, e);
                return 0; // Treat errors as 0
              });

            const getMyDuonutsPromise = new Promise((resolve) => {
              getMyDuonuts((err, res) => {
                if (err) {
                  console.log("Duonuts Error:", err.response);
                  resolve(0); // Treat errors as 0
                } else {
                  console.log("Duonuts Response:", res);
                  resolve(res?.data?.length > 0 ? 1 : 0); // Return 1 if length > 0, else 0
                }
              });
            });

            // Call both APIs simultaneously
            Promise.all([saveAiNewPromise, getMyDuonutsPromise]).then(([saveResStatus, getDuonutsLength]) => {
              console.log("Save Res Status:", saveResStatus, "Duonuts Length:", getDuonutsLength);
              // setisLogIn(false);
              if (saveResStatus === 0 && getDuonutsLength === 0) {
                window.location = "/home";
              } else {
                window.location = "/myduonuts";
              }
            })
              .catch((e) => {
                setisLogIn(false);
                console.error("Error during API execution:", e);
              });

            // window.location = "/home";
          }
        });

      }
    });
  };
  console.log(isLogIn);
  const handleForgotPassword = () => {
    dispatch(closeModal());
    dispatch(toggleforgotPassword());
  };

  const pageVariants = {
    initial: { y: "-100vh" },
    in: { y: 0 },
    out: { y: "-100vw" },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.6,
  };

  useEffect(() => {
    return () => {
      setSignInError("");
    };
  }, []);

  // console.log(rememberMe);

  return (
    <>
      {isLogIn ? (<div style={{ position: "absolute", display: "flex", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
        <img alt="loader" src={require(`../../../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "5rem" }} />
      </div>) : (
        <div className="form_modal__form sign-in-form">
          <div className="logo" >
            <img style={{ width: "auto", height: "2rem", cursor: "pointer" }}
              src="/assets/icons/duonut_icon.png" />
            {/* <img style={{ width: "auto", height: "1.5rem", cursor: "pointer", paddingRight: "1.5rem" }}
                src="../../assets/icons/duonut_logo.png" /> */}
          </div>
          <h2 style={{ color: '#3184C9', display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>WELCOME BACK!</h2>
          <div className="modal_back_btn icon-button" style={location.pathname === "/login" ? { display: "none" } : {}} onClick={handleClose}>
            {`< Back`}
          </div>
          {!withEmail && <div
            className={location.pathname === "/login" ?
              "form_modal__header1 form_modal__signin-header" :
              "form_modal__header form_modal__signin-header"}>
            <label>Log in or Sign up in seconds</label>
          </div>}

          {/* Added the inputs  */}
          {withEmail && <form onSubmit={handleLogin}>
            {/* <h2 style={{ paddingBottom: "1rem", fontWeight: "600" }}>
            <label style={{ verticalAlign: "middle", cursor: "pointer" }} onClick={() => setwithEmail(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="currentColor" d="M15.45 17.97 9.5 12.01a.25.25 0 0 1 0-.36l5.87-5.87a.75.75 0 0 0-1.06-1.06l-5.87 5.87c-.69.68-.69 1.8 0 2.48l5.96 5.96a.75.75 0 0 0 1.06-1.06z"></path>
              </svg>
            </label>
            Continue with Email
          </h2> */}
            <br></br>
            <div className="form_modal__form_fields" style={{ width: "100%" }}>
              <Input placeholder="Email" value={email} setValue={setEmail} errorMsg={emailError} style={{ width: "100%" }} />
              <PasswordInput placeholder="Password" value={password} setValue={setPassword} errorMsg={passwordError} style={{ width: "100%" }} />
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center remember-me">
                <input defaultChecked={rememberMe} onChange={() => setRememberMe(!rememberMe)} id="remember_me" type="checkbox" />
                <label htmlFor="remember_me" className="text">
                  Remember Me
                </label>
              </div>
              <div className="forgot-password" onClick={handleForgotPassword}>
                Forgot Password?
                {/* <div className="text_underline"></div> */}
              </div>
            </div>
            <button className="form_modal__submit_btn">SIGN IN</button>

            {/* OR */}
            <div style={{ display: "flex", alignItems: "center", color: "grey", marginTop: "10px" }}>
              <hr style={{ flex: 1 }} />
              <span style={{ padding: "0 10px" }}>OR</span>
              <hr style={{ flex: 1 }} />
            </div>
          </form>}

          {signInError !== "" && (
            <div
              className="error"
              style={{ textAlign: "center", color: "rgba(227, 0, 0, 1)", marginBottom: "1rem" }}>
              {signInError}
            </div>
          )}
          {withEmail && <>
            <br></br>
            <GoogleButton text={"Continue with Google"} setisLogIn={setisLogIn} />
            {/* <GoogleButton style={{ borderRadius: "50px" }} /> */}
            <MicrosoftButton text={"Continue with Microsoft"} setisLogIn={setisLogIn}/>
            {/* <MicrosoftButton /> */}
            {/* <div onClick={() => setwithEmail(true)} className="form_modal__btn-secondary">
            <svg xmlns="http://www.w3.org/2000/svg"
              width="24" height="24"
              viewBox="0 0 24 24"
              className="google-icon">
              <path fill="currentColor" d="M20.37 5.03A2 2 0 0 1 22 7v10a2 2 0 0 1-1.96 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h16.1H4zm.13 2.07-4.53 5.31 4.53 4.63a.5.5 0 0 0 0-.04V7.1zm-17-.14a.5.5 0 0 0 0 .04v10a.5.5 0 0 0 0 .04l4.59-4.7L3.5 6.97zm5.57 6.53-3.92 4 13.7.01L15 13.56a4 4 0 0 1-5.93-.07zm9.88-6.99H5l5.07 5.96a2.5 2.5 0 0 0 3.81 0l5.07-5.96z"></path>
            </svg>
            {/* <img src="/assets/icons/btn_google_light.png" className="google-icon" alt="" /> 
            <p>Continue with Email</p>
          </div> */}
            <br></br>
          </>}
          {/* <FormDivider /> */}
          {/* <div style={{ height: "3vh" }}></div> */}
          {withEmail && <form onSubmit={handleLogin}>
            {/* <h2 style={{ paddingBottom: "1rem", fontWeight: "600" }}>
            <label style={{ verticalAlign: "middle", cursor: "pointer" }} onClick={() => setwithEmail(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="currentColor" d="M15.45 17.97 9.5 12.01a.25.25 0 0 1 0-.36l5.87-5.87a.75.75 0 0 0-1.06-1.06l-5.87 5.87c-.69.68-.69 1.8 0 2.48l5.96 5.96a.75.75 0 0 0 1.06-1.06z"></path>
              </svg>
            </label> Continue with Email</h2>
          <br></br> */}
            {/* <div className="form_modal__form_fields">
            <Input placeholder="email" value={email} setValue={setEmail} errorMsg={emailError} />
            <PasswordInput placeholder="password" value={password} setValue={setPassword} errorMsg={passwordError} />
          </div> */}
            {/* <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center remember-me">
              <input defaultChecked={rememberMe} onChange={() => setRememberMe(!rememberMe)} id="remember_me" type="checkbox" />
              <label htmlFor="remember_me" className="text">
                Remember Me
              </label>
            </div>
            <div className="forgot-password" onClick={handleForgotPassword}>
              Forgot Password? <div className="text_underline"></div>
            </div>
          </div>
          <button className="form_modal__submit_btn">SIGN IN</button> */}
          </form>}
          {!withEmail && <><div className="form_modal__form_footer_container">
            <div className="form-footer">
              By proceeding, you acknowledge and accept Duonut's &nbsp;
              <span className="form-link" style={{ display: "inline-block" }} onClick={() => window.open("/termsandconditions")}>
                Terms of use <span className="text_underline"></span>
              </span> &nbsp; & &nbsp;
              <span className="form-link" onClick={() => window.open("/privacypolicy")} >
                Privacy Policy
                <span className="text_underline"></span>
              </span>
            </div>
          </div>
            <br></br>
            <br></br>
          </>}
          <div className="form-footer" style={{ display: "flex", justifyContent: "space-between", gap: "70px" }}>
            <div onClick={() => window.open("https://duonut.com")}
              style={{ cursor: "pointer", position: "relative", color: "#3498db", textAlign: "left", fontSize: "14px", fontWeight: "500" }}>
              {/* <span className="text_underline"> </span> */}
              <i className="fa fa-long-arrow-left" aria-hidden="true"></i> &nbsp;duonut.com
            </div>
            <div style={{ cursor: "pointer", position: "relative", color: "black", fontSize: "14px", textAlign: "right" }}>
              No account? &nbsp;
              <span className="form-link" onClick={changeForm} style={{ fontSize: "14px", fontWeight: "500", color: "#3498db" }}>
                {/* <span className="text_underline"> </span> */}
                Sign Up &nbsp;<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
