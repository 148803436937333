import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements } from '../../../redux/slices/userActivity';

export default function RatingType() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [ratingType, setRatingType] = useState('star')
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const ratingType = tempElement?.elementAttributes?.ratingType;
            setRatingType(ratingType);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleRatingTypeChange = e => {
        const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    ratingType: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }



    return (<>
        <div className="EditorRowContainer">
            <div style={{ marginRight: "1rem" }}>Icon</div>
            <select value={ratingType} className='text_editor_dropdown' onChange={e => handleRatingTypeChange(e)} >
                <option value='star' > Star </option>
                <option value='heart' > Heart </option>
                <option value='thumb' > Thumb </option>
                <option value='correct' > Correct </option>
            </select>
        </div>
    </>
    )
}

