import React, {  useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './AiPage.css';
import { domain } from "../../services/constants";
import axios from "axios";
import background from '../../assets/Images/Aigradient.svg';

import '../../Components/HomePage/HomePage.css';
import '../../Components/HomePage//css/namari-color.css';
import '../../Components/HomePage//css/style123.css';
import '../../Components/HomePage//css/animate.css';
import '../../Components/HomePage//css/font-awesome.min.css';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { openModal, showSignUpForm, showSignInForm } from "../../redux/slices/formDetails";
import { Link, useNavigate } from "react-router-dom";
// import TextTransition from "react-text-transition";
import { toggleCreateFromScratchModalDuonut } from "../../redux/slices/CreateFromScratchReducer";
import { toggleDemoModalDuonut } from "../../redux/slices/DemoReducer";
import 'swiper/css';
import 'swiper/css/effect-fade';
import Slider from "react-slick";
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
import { addUserActivity, selectUserActivity, removeUserActivity, updateUserActivityElements, setUserActivity } from '../../redux/slices/userActivity';
import { getDuonut } from "../../services/duonut";
import { createDuonut } from "../../services/activity";
import { toggleProMessageModalDuonut, updateProMessageModalMessage } from "../../redux/slices/ProMessageReducer";
import { addElementInUserActivity } from '../../redux/slices/userActivity';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

pdfjsLib.GlobalWorkerOptions.workerSrc = '/assets/file/pdf.worker.min.mjs';

var gptData = [];
var gptImageData = [];
var duonutId;
var isUpdateSlides = false;
const AiPage = () => {
    const { userActivities, } = useSelector((state) => state.userActivity);
    const { settings } = useSelector(state => state.duonutSettings);
    const { isLoggedIn,  userProAccess } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const msgref = useRef(null);
    const msgref1 = useRef(null);
    const [selectedBtn, setselectedBtn] = useState(0);
    const [settingTabNum, setsettingTabNum] = useState(1);

    const [gptQuestionInput, setgptQuestionInput] = useState('');
    const [gptType, setgptType] = useState(1);
    const [selectedCategory, setselectedCategory] = useState(1);
    const [selectedSlideNo, setselectedSlideNo] = useState(1);
    const [selectedTab, setselectedTab] = useState(1);
    const [errMsg, seterrMsg] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);

    var sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handleClick = () => dispatch(openModal());

    // const handleClickSignUp = () => {
    //     if (!isLoggedIn) {
    //         handleClick();
    //         dispatch(showSignUpForm());
    //     } else {
    //         navigate("/design");
    //     }

    // };

    // const buyBtn = (item) => {
    //     if (!isLoggedIn) {
    //         handleClick();
    //         dispatch(showSignInForm());
    //         setselectedBtn(item);
    //     } else {
    //         if (item === 1) {
    //             navigate('/payment/pro');
    //             // window.open("https://rzp.io/l/9uSUqpj");
    //         } else if (item === 2) {
    //             navigate('/payment/plus');
    //             // window.open("https://rzp.io/l/qYlzpZGWFE");
    //         } else if (item === 3) {
    //             window.open("https://rzp.io/l/OjUDGY06");
    //         }
    //         // else if (item === 4) {
    //         //     window.open("tel:" + "9353709306");
    //         // }
    //     }
    // }

    useEffect(() => {
        // console.log("after login")
        if (selectedBtn === 1) {
            navigate('/payment/pro');
            // window.open("https://rzp.io/l/9uSUqpj");
        } else if (selectedBtn === 2) {
            navigate('/payment/plus');
            // window.open("https://rzp.io/l/qYlzpZGWFE");
        } else if (selectedBtn === 3) {
            window.open("https://rzp.io/l/OjUDGY06");
        }
        // else if (selectedBtn === 4) {
        //     window.open("tel:" + "9353709306");
        // }
    }, [isLoggedIn]);

    const handleClickCreateFromScratch = () => {
        dispatch(toggleCreateFromScratchModalDuonut(true));
    };

    // const handleClickDemo = () => {
    //     dispatch(toggleDemoModalDuonut(true));
    // };


    const handleGPTQuestionChange = (event) => {
        setgptQuestionInput(event.target.value);
        // console.log(event);
        // console.log(event.target.value);
        if (event.keyCode === 13) {
            document.getElementById("gptBtn").click();
        }
    };

    const handleUploadFile = (event) => {
        const file = event.target.files[0];
        // console.log(event);
        // console.log(event.target.value);

        if (file) {
            const fileSize = file.size; // size in bytes
            const fileSizeInKB = fileSize / 1024; // size in KB

            if (fileSizeInKB > 2048) {
                seterrMsg("Please upload less than 2 MB.");
                setTimeout(() => {
                    seterrMsg("");
                }, 3000); //miliseconds
                return null;
            }
        }

        setUploadedFile(file);
    };

    const continueBtn = () => {
        msgref1.current.click();
    }

    const handleCategoryChange = event => {
        // console.log(event);
        setselectedCategory(parseInt(event.target.value));

    };
    const handleSlideNoChange = event => {
        // console.log(event);
        setselectedSlideNo(event.target.value);

    };

    async function extractTextFromPDF(file) {
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;
        const numPages = pdf.numPages;
        const textPromises = [];

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            textPromises.push(pdf.getPage(pageNum).then(page => page.getTextContent()));
        }

        const texts = await Promise.all(textPromises);
        return texts.map(textContent => textContent.items.map(item => item.str).join(' ')).join('\n');
    }

    async function extractTextFromDOCX(file) {
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.extractRawText({ arrayBuffer });
        return result.value;
    }

    const handleGPTSubmit = async () => {

        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return;
        }

        if (!gptQuestionInput && gptType === 1) {
            msgref.current.click();
            return;
        }

        if (!uploadedFile && gptType === 2) {
            seterrMsg("Please upload audio file.");
            setTimeout(() => {
                seterrMsg("");
            }, 3000); //miliseconds
            return;
        }

        if (!uploadedFile && gptType === 3) {
            seterrMsg("Please upload pdf / doc file.");
            setTimeout(() => {
                seterrMsg("");
            }, 3000); //miliseconds
            return;
        }

        const aiPageFirstTime = localStorage.getItem("aft");

        if (!userProAccess && aiPageFirstTime) {
            dispatch(toggleProMessageModalDuonut(true));
            dispatch(updateProMessageModalMessage('Duonut Ai access'));
            return null;
        }

        localStorage.setItem("aft", true);

        setselectedTab(2);
        gptData = [];
        var newQuestion;

        if (gptType === 1) {
            if (selectedCategory === 1) {
                newQuestion = gptQuestionInput + ` ${selectedSlideNo} Survey questions with options`;
            } else if (selectedCategory === 2) {
                newQuestion = gptQuestionInput + ` ${selectedSlideNo} MCQ questions with answer options`;
            } else if (selectedCategory === 3) {
                newQuestion = `write ${selectedSlideNo} small paragraph on ` + gptQuestionInput;
            } else if (selectedCategory === 4) {
                const stopWords = ["create"];
                // Split the sentence into an array of words
                const words = gptQuestionInput.split(" ");
                // Filter out the stop words
                const filteredWords = words.filter(word => !stopWords.includes(word.toLowerCase()));
                // Join the remaining words back into a sentence
                const finalSentence = filteredWords.join(" ");
                newQuestion = `create a form with FieldName and type in json ` + finalSentence;
            }

            GPTApi(newQuestion);
        } else if (gptType === 2) {

            var payloadWhisper = {
                file: uploadedFile,
                model: "whisper-1",
            }
            axios({
                method: "POST",
                url: "https://api.openai.com/v1/audio/transcriptions",
                data: payloadWhisper,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization:
                        "Bearer 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"
                }
            }).then((res) => {
                // console.log(res.data.text);

                if (selectedCategory === 1) {
                    newQuestion = res?.data?.text + ` create ${selectedSlideNo} Survey questions with options from this summary`;
                } else if (selectedCategory === 2) {
                    newQuestion = res?.data?.text + ` create ${selectedSlideNo} MCQ questions with answer options from this summary`;
                } else if (selectedCategory === 3) {
                    newQuestion = res?.data?.text + ` write ${selectedSlideNo} small paragraph from this summary`;
                } else if (selectedCategory === 4) {
                    newQuestion = `create a form with FieldName and type in json from this summary` + res?.data?.text;
                }

                GPTApi(newQuestion);

            }).catch((e) => {
                console.log(e.message, e);
            });

        } else if (gptType === 3) {

            if (uploadedFile.type === "application/pdf") {
                const extractedText = await extractTextFromPDF(uploadedFile);
                // console.log(extractedText);

                if (selectedCategory === 1) {
                    newQuestion = extractedText + ` create ${selectedSlideNo} Survey questions with options from this summary`;
                } else if (selectedCategory === 2) {
                    newQuestion = extractedText + ` create ${selectedSlideNo} MCQ questions with answer options from this summary`;
                } else if (selectedCategory === 3) {
                    newQuestion = extractedText + ` write ${selectedSlideNo} small paragraph from this summary`;
                } else if (selectedCategory === 4) {
                    newQuestion = `create a form with FieldName and type in json from this summary` + extractedText;
                }

                GPTApi(newQuestion);
            } else if (uploadedFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                const extractedText = await extractTextFromDOCX(uploadedFile);
                // console.log(extractedText);

                if (selectedCategory === 1) {
                    newQuestion = extractedText + ` create ${selectedSlideNo} Survey questions with options from this summary`;
                } else if (selectedCategory === 2) {
                    newQuestion = extractedText + ` create ${selectedSlideNo} MCQ questions with answer options from this summary`;
                } else if (selectedCategory === 3) {
                    newQuestion = extractedText + ` write ${selectedSlideNo} small paragraph from this summary`;
                } else if (selectedCategory === 4) {
                    newQuestion = `create a form with FieldName and type in json from this summary` + extractedText;
                }

                GPTApi(newQuestion);
            }
        }

    }

    const GPTApi = (newQuestion) => {

        var payload = {};
        if (selectedCategory === 4) {
            payload = {
                messages: [{ "role": "user", "content": newQuestion }],
                max_tokens: 2000,
                temperature: 0.7,
                n: 1,
                model: "gpt-3.5-turbo",
                response_format: { "type": "json_object" }
            }
        } else {
            payload = {
                messages: [{ "role": "user", "content": newQuestion }],
                max_tokens: 2000,
                temperature: 0.7,
                n: 1,
                model: "gpt-3.5-turbo",
            }
        }

        axios({
            method: "POST",
            url: "https://api.openai.com/v1/chat/completions",
            data: payload,
            headers: {
                "Content-Type": "application/json",
                Authorization:
                    "Bearer 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"
            }
        }).then((res) => {
            // console.log(res);
            if (res?.status === 200) {
                // console.log(res.data);
                if (parseInt(selectedSlideNo) > 1 && selectedCategory !== 4) {
                    // List of stop words to be removed
                    const stopWords = ["is", "a", "that", "in", "the", "on", "and", "an", "to", "for", "of", "with", "by", "as",
                        "question", "questions", "create", "about", "mcq", "mcqs", "option", "options", "answer", "answers",
                        "survey", "story", "form", "write", "paragraph", "summary"
                    ];
                    // Split the sentence into an array of words
                    const words = gptQuestionInput ? gptQuestionInput.split(" ") : newQuestion.split(" ");

                    // Filter out the stop words
                    const filteredWords = words.filter(word => !stopWords.includes(word.toLowerCase()));

                    // Join the remaining words back into a sentence
                    const finalSentence = filteredWords.join(" ");

                    // console.log(words.length);
                    if (words.length > 25) {
                        var summ = gptQuestionInput ? gptQuestionInput : newQuestion;
                        var newQues = `give 1 line proper title for this summary ` + summ;
                        // console.log(newQues);
                        var newpayload = {
                            messages: [{ "role": "user", "content": newQues }],
                            max_tokens: 2000,
                            temperature: 0.7,
                            n: 1,
                            model: "gpt-3.5-turbo",
                        }

                        axios({
                            method: "POST",
                            url: "https://api.openai.com/v1/chat/completions",
                            data: newpayload,
                            headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                    "Bearer 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"
                            }
                        }).then((res) => {
                            // console.log(res);
                            if (res?.status === 200) {
                                const payload1 = {
                                    model: "dall-e-2",
                                    prompt: res?.data?.choices[0]?.message?.content,
                                    n: parseInt(selectedSlideNo) >= 4 ? 3 : parseInt(selectedSlideNo) >= 3 ? 2 : 1,
                                    size: "256x256",
                                    response_format: "b64_json",
                                }
                                axios({
                                    method: "POST",
                                    url: "https://api.openai.com/v1/images/generations",
                                    data: payload1,
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization:
                                            "Bearer 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"
                                    }
                                }).then((res1) => {
                                    // console.log(res1);
                                    var imageData = [];
                                    if (res1?.data?.data?.length > 0) {
                                        res1?.data?.data?.map((item, i) => {
                                            imageData.push(item.b64_json);
                                        })
                                        // console.log(imageData);
                                        gptImageData = imageData;
                                        convertInJson(res.data.choices[0]?.message.content);
                                    }
                                }).catch((e) => {
                                    console.log(e.message, e);
                                });
                            }
                        }).catch((e) => {
                            console.log(e.message, e);
                        });

                    } else {

                        const payload1 = {
                            model: "dall-e-2",
                            prompt: finalSentence,
                            n: parseInt(selectedSlideNo) >= 4 ? 3 : parseInt(selectedSlideNo) >= 3 ? 2 : 1,
                            size: "256x256",
                            response_format: "b64_json",
                        }
                        axios({
                            method: "POST",
                            url: "https://api.openai.com/v1/images/generations",
                            data: payload1,
                            headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                    "Bearer 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"
                            }
                        }).then((res1) => {
                            // console.log(res1);
                            var imageData = [];
                            if (res1?.data?.data?.length > 0) {
                                res1?.data?.data?.map((item, i) => {
                                    imageData.push(item.b64_json);
                                })
                                // console.log(imageData);
                                gptImageData = imageData;
                                convertInJson(res.data.choices[0]?.message.content);
                            }
                        }).catch((e) => {
                            console.log(e.message, e);
                        });

                    }

                } else {
                    convertInJson(res.data.choices[0]?.message.content);
                }
            }
        }).catch((e) => {
            console.log(e.message, e);
        });

    }

    function extractFields(jsonData) {
        // Check if 'fields' key exists directly within jsonData
        if (jsonData?.fields) {
            return jsonData.fields;
        }
        // Check if 'form' key exists and then 'fields' within 'form'
        if (jsonData?.form && jsonData?.form?.fields) {
            return jsonData.form.fields;
        }

        if (jsonData.Fields) {
            return jsonData.Fields;
        }
        if (jsonData.Form && jsonData.Form.Fields) {
            return jsonData.Form.Fields;
        }
        // Return an empty array or null if 'fields' not found in any format
        return [];
    }

    const convertInJson = (res) => {
        // console.log(res, gptQuestionInput, selectedCategory);
        let jsonData = [];

        if (selectedCategory === 1) {
            // console.log("survey");
            let splitData = res.split(/\n\n\d+\.\s|\n\d+\.\s/).filter(element => element);
            // console.log(splitData);
            splitData.map((item, i) => {
                let splitData1 = item.split("\n").filter(element => element);
                jsonData.push(splitData1);
            })

        } else if (selectedCategory === 2) {
            // console.log("mcq");
            let splitData = res.split(/\n\nQ\d+\.\s|\n\n\d+\.\s/).filter(element => element);
            // console.log(splitData);
            splitData.map((item, i) => {
                let splitData1 = item.split("\n").filter(element => element);
                jsonData.push(splitData1);
            })

        } else if (selectedCategory === 3) {
            // console.log("story");
            let splitData = res.split("\n\n").filter(element => element);
            // console.log(splitData);
            splitData.map((item, i) => {
                jsonData.push(item);
            })

        } else if (selectedCategory === 4) {
            // console.log("Form", JSON.parse(res));
            var formData = extractFields(JSON.parse(res))
            jsonData.push(formData);

        }

        // console.log(jsonData);
        gptData = jsonData;
        handleCreateDuonut();
    };


    let interval = null;
    const handleCreateDuonut = () => {
        const controller = new AbortController();
        interval = setInterval(() => {
            if (isLoggedIn) {
                clearInterval(interval);
                createDuonut({ name: "Untitled", duonut_category: 1, duonut_setting: settings }, controller, (err, res) => {
                    // setLoading(false);
                    clearInterval(interval);
                    if (err) {
                        console.log(err);
                        return;
                    }
                    // console.log('DUONUT CREATED --- ');
                    duonutId = res?.data?.id;
                    handleSelectTheme();

                });
            }
        }, 10);

        return () => {
            controller.abort();
            clearInterval(interval)
        };
    }

    const handleSelectTheme = () => {
        // console.log(masterData, gptData);

        dispatch(setUserActivity({
            activities: [{
                elements: [],
                backgroundColor: "white",
                backgroundImage: "",
            }]
        }));

        // var duonutId = 107;
        var duonutId = 205;

        if (selectedCategory === 1) {
            // duonutId = 107;
            duonutId = 205;
        } else if (selectedCategory === 2) {
            // duonutId = 108;
            duonutId = 206;
        }
        else if (selectedCategory === 3) {
            // duonutId = 109;
            duonutId = 207;
        }

        if (selectedCategory !== 4) {
            getDuonut(duonutId, (err, res) => {
                // console.log("fetch duonut resp", res);
                if (err) {
                    console.log(err);
                    return;
                }
                // console.log("fetch duonut res.data", res.data)
                gptData.map((item, i) => {
                    // console.log(i);
                    if (selectedSlideNo < i + 1) return;

                    var masterData = res.data.user_activity[i];
                    dispatch(addUserActivity({ ...masterData, name: `Step ${i + 1}` }));
                })
                dispatch(removeUserActivity({ idx: 0 }));
                dispatch(selectUserActivity(0));
                isUpdateSlides = true;

            });
        } else {
            [1, 2, 3, 4, 5].map((item, i) => {
                // console.log(i);
                if (selectedSlideNo < i + 1) return;

                const stopWords = ["create"];
                // Split the sentence into an array of words
                const words = gptQuestionInput?.split(" ");
                // Filter out the stop words
                const filteredWords = words?.filter(word => !stopWords.includes(word.toLowerCase()));
                // Join the remaining words back into a sentence
                const finalSentence = filteredWords?.join(" ");

                var masterData = {
                    elements: [
                        {
                            id: 1,
                            elementType: 'Text',
                            transparency: 1,
                            zIndex: 1,
                            order: 1,
                            posX: 0,
                            posY: 30,
                            height: "auto",
                            width: "100%",
                            elementAttributes: {
                                color: '#000000', text: finalSentence ? finalSentence : "Set Title", formattedText: [], fontFamily: "Poppins",
                                fontWeight: 800, fontSize: 24, textAlign: "center"
                            },
                        },
                        {
                            id: 2,
                            elementType: 'Button',
                            transparency: 1,
                            zIndex: 2,
                            order: 2,
                            posX: 410,
                            posY: 500,
                            height: "auto",
                            width: 200,
                            elementAttributes: {
                                color: '#ffffff', text: "Next", fontFamily: "Poppins",
                                fontWeight: 600, fontSize: 16, backgroundColor: "#000000", border: "1px solid #000000",
                                textAlign: "center", borderRadius: "5px"
                            },
                            elementHoverAttributes: {
                                color: '#ffffff', backgroundColor: "blue", border: "1px solid #000000"
                            },
                        }
                    ],
                    backgroundColor: "#ffffff",
                    backgroundImage: "",
                    name: "Step 1",
                };
                dispatch(addUserActivity({ ...masterData, name: `Step ${i + 1}` }));
            })
            dispatch(removeUserActivity({ idx: 0 }));
            dispatch(selectUserActivity(0));
            isUpdateSlides = true;
        }
    };

    function selectFields(jsonElement, idNum) {
        var labelText = jsonElement?.fieldName ? jsonElement?.fieldName :
            jsonElement?.FieldName ? jsonElement?.FieldName :
                jsonElement?.label ? jsonElement?.label :
                    jsonElement?.question ? jsonElement?.question : "type your label";

        if (jsonElement?.type === "text") {
            var element = {
                id: idNum + 1,
                elementType: 'TextField',
                transparency: 1,
                zIndex: idNum + 1,
                order: idNum + 1,
                posX: 245,
                posY: 100 + (130 * (idNum - 2)),
                height: "auto",
                width: 500,
                elementAttributes: {
                    color: '#000000', text: labelText, fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 16, backgroundColor: "#ffffff", border: "1px solid #000000",
                    textAlign: "left", borderRadius: "0px", fieldPosition: "column",
                    placeholder: "Type your text here."
                }
            }
            return element;
        }

        if (jsonElement?.type === "textarea") {
            var element = {
                id: idNum + 1,
                elementType: 'TextArea',
                transparency: 1,
                zIndex: idNum + 1,
                order: idNum + 1,
                posX: 245,
                posY: 100 + (130 * (idNum - 2)),
                height: "auto",
                width: 500,
                elementAttributes: {
                    color: '#000000', text: labelText, fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 16, backgroundColor: "#ffffff", border: "1px solid #000000",
                    textAlign: "left", borderRadius: "0px", fieldPosition: "column",
                    placeholder: "Type your text here."
                }
            }
            return element;
        }

        if (jsonElement?.type === "email") {
            var element = {
                id: idNum + 1,
                elementType: 'Email',
                transparency: 1,
                zIndex: idNum + 1,
                order: idNum + 1,
                posX: 245,
                posY: 100 + (130 * (idNum - 2)),
                height: "auto",
                width: 500,
                elementAttributes: {
                    color: '#000000', text: labelText, fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 16, backgroundColor: "#ffffff", border: "1px solid #000000",
                    textAlign: "left", borderRadius: "0px", fieldPosition: "column",
                    placeholder: "Type your Email here."
                }
            }
            return element;
        }

        if (jsonElement?.type === "password") {
            var element = {
                id: idNum + 1,
                elementType: 'Password',
                transparency: 1,
                zIndex: idNum + 1,
                order: idNum + 1,
                posX: 245,
                posY: 100 + (130 * (idNum - 2)),
                height: "auto",
                width: 500,
                elementAttributes: {
                    color: '#000000', text: labelText, fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 16, backgroundColor: "#ffffff", border: "1px solid #000000",
                    textAlign: "left", borderRadius: "0px", fieldPosition: "column",
                    placeholder: "Type your Password here."
                }
            }
            return element;
        }

        if (jsonElement?.type === "tel") {
            var element = {
                id: idNum + 1,
                elementType: 'Phone',
                transparency: 1,
                zIndex: idNum + 1,
                order: idNum + 1,
                posX: 245,
                posY: 100 + (130 * (idNum - 2)),
                height: "auto",
                width: 500,
                elementAttributes: {
                    color: '#000000', text: labelText, fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 16, backgroundColor: "#ffffff", border: "1px solid #000000",
                    textAlign: "left", borderRadius: "0px", fieldPosition: "column",
                    placeholder: "Type your Phone number here."
                }
            }
            return element;
        }

        if (jsonElement?.type === "number") {
            var element = {
                id: idNum + 1,
                elementType: 'NumberField',
                transparency: 1,
                zIndex: idNum + 1,
                order: idNum + 1,
                posX: 245,
                posY: 100 + (130 * (idNum - 2)),
                height: "auto",
                width: 500,
                elementAttributes: {
                    color: '#000000', text: labelText, fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 16, backgroundColor: "#ffffff", border: "1px solid #000000",
                    textAlign: "left", borderRadius: "0px", fieldPosition: "column",
                    placeholder: "Type your number here."
                }
            }
            return element;
        }

        if (jsonElement?.type === "date") {
            var element = {
                id: idNum + 1,
                elementType: 'DateField',
                transparency: 1,
                zIndex: idNum + 1,
                order: idNum + 1,
                posX: 245,
                posY: 100 + (130 * (idNum - 2)),
                height: "auto",
                width: 500,
                elementAttributes: {
                    color: '#000000', text: labelText, fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 16, backgroundColor: "#ffffff", border: "1px solid #000000",
                    textAlign: "left", borderRadius: "0px", fieldPosition: "column",
                }
            }
            return element;
        }

        if (jsonElement?.type === "file") {
            var element = {
                id: idNum + 1,
                elementType: 'FileUpload',
                transparency: 1,
                zIndex: idNum + 1,
                order: idNum + 1,
                posX: 245,
                posY: 100 + (130 * (idNum - 2)),
                height: "auto",
                width: 500,
                elementAttributes: {
                    color: '#000000', text: labelText, fontFamily: "Poppins",
                    fontWeight: 400, fontSize: 16, backgroundColor: "#ffffff", border: "1px solid #000000",
                    textAlign: "left", borderRadius: "0px", fieldPosition: "column",
                }
            }
            return element;
        }

        // Return an empty array or null if 'fields' not found in any format
        return {};
    }

    useEffect(() => {
        console.log(userActivities, gptData, gptImageData, duonutId, isUpdateSlides);

        if (selectedCategory === 1 && userActivities.length > 0 && isUpdateSlides && duonutId) {
            // console.log("survey inside");
            isUpdateSlides = false;
            var imageCount = 0;

            userActivities.map((activity, i) => {
                if (activity.elements.some(element => element.elementType === "CheckboxGroup")) {
                    let tempElement = activity?.elements?.find(element => element?.elementType === "CheckboxGroup");
                    const elementAttrib = tempElement?.elementAttributes;
                    // console.log("tempElement1", tempElement)

                    tempElement = {
                        ...tempElement,
                        elementAttributes: {
                            ...elementAttrib,
                            text: gptData[i][0],
                            options: gptData[i].slice(1)
                        },
                    };
                    // console.log("tempElement2", tempElement)
                    dispatch(
                        updateUserActivityElements({
                            selectedActivityIdx: i,
                            selectedElementIdx: activity?.elements?.findIndex(element => element?.elementType === "CheckboxGroup"),
                            element: tempElement,
                        })
                    );

                }
                if (activity.elements.some(element => element.elementType === "Image")) {
                    let tempElement = activity?.elements?.find(element => element?.elementType === "Image");
                    const elementAttrib = tempElement?.elementAttributes;

                    const dataUrlPrefix = `data:image/png;base64,`;
                    const image_data = {
                        duonut_id: duonutId,
                        name: `${duonutId}-${i}`,
                        uri: dataUrlPrefix + gptImageData[imageCount],
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);
                        tempElement = {
                            ...tempElement,
                            elementAttributes: {
                                ...elementAttrib,
                                url_data: {},
                                url: res.data.file_url,
                            },
                        };
                        dispatch(
                            updateUserActivityElements({
                                selectedActivityIdx: i,
                                selectedElementIdx: activity?.elements?.findIndex(element => element?.elementType === "Image"),
                                element: tempElement,
                            })
                        );

                        imageCount++;

                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                }
            })

            setTimeout(() => {
                navigate(`/design/${duonutId}`);
            }, 1000);

        }

        if (selectedCategory === 2 && userActivities.length > 0 && isUpdateSlides && duonutId) {
            // console.log("mcq inside");
            isUpdateSlides = false;

            var imageCount = 0;
            userActivities.map((activity, i) => {
                if (activity.elements.some(element => element.elementType === "Mcq")) {

                    let tempElement = activity?.elements?.find(element => element?.elementType === "Mcq");
                    const elementAttrib = tempElement?.elementAttributes;
                    // console.log("tempElement1", tempElement)
                    // var options = gptData[i].slice(1, 5);
                    // var ansoption = gptData[i][5];
                    // var ansoption1 = ansoption?.split("Answer:")[1]?.trim();

                    // Get the options and answer
                    let options = [];
                    for (let j = 1; j < gptData[i].length; j++) {
                        if (gptData[i][j].startsWith("Answer:")) {
                            break;
                        }
                        options.push(gptData[i][j]);
                    }

                    // Get the correct answer from the options
                    const ansoption = gptData[i].find(option => option.startsWith("Answer:"));
                    const ansoption1 = ansoption?.split("Answer:")[1]?.trim();

                    // Find the correct answer index (1-based)
                    const correctAnswerIndex = options.indexOf(ansoption1) !== -1 ? options.indexOf(ansoption1) + 1 : 1;

                    tempElement = {
                        ...tempElement,
                        elementAttributes: {
                            ...elementAttrib,
                            text: gptData[i][0],
                            options: options,
                            correctAnswer: correctAnswerIndex,
                        },
                    };
                    // console.log("tempElement2", tempElement)
                    dispatch(
                        updateUserActivityElements({
                            selectedActivityIdx: i,
                            selectedElementIdx: activity?.elements?.findIndex(element => element?.elementType === "Mcq"),
                            element: tempElement,
                        })
                    );

                }
                if (activity.elements.some(element => element.elementType === "Image")) {
                    let tempElement = activity?.elements?.find(element => element?.elementType === "Image");
                    const elementAttrib = tempElement?.elementAttributes;

                    const dataUrlPrefix = `data:image/png;base64,`;
                    const image_data = {
                        duonut_id: duonutId,
                        name: `${duonutId}-${i}`,
                        uri: dataUrlPrefix + gptImageData[imageCount],
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);
                        tempElement = {
                            ...tempElement,
                            elementAttributes: {
                                ...elementAttrib,
                                url_data: {},
                                url: res.data.file_url,
                            },
                        };
                        dispatch(
                            updateUserActivityElements({
                                selectedActivityIdx: i,
                                selectedElementIdx: activity?.elements?.findIndex(element => element?.elementType === "Image"),
                                element: tempElement,
                            })
                        );

                        imageCount++;
                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                }
            })

            setTimeout(() => {
                navigate(`/design/${duonutId}`);
            }, 1000);

        }

        if (selectedCategory === 3 && userActivities.length > 0 && isUpdateSlides && duonutId) {
            // console.log("story inside");
            isUpdateSlides = false;

            var imageCount = 0;
            userActivities.map((activity, i) => {
                var countNub = 0;
                activity?.elements?.forEach((element, elementIndex) => {
                    if (element.elementType === "Text") {
                        let tempElement = element;
                        const elementAttrib = tempElement?.elementAttributes;

                        tempElement = {
                            ...tempElement,
                            elementAttributes: {
                                ...elementAttrib,
                                text: countNub === 0 ? gptQuestionInput ? gptQuestionInput : "Set Title" : gptData[i],
                            },
                        };

                        countNub++;

                        dispatch(
                            updateUserActivityElements({
                                selectedActivityIdx: i,
                                selectedElementIdx: elementIndex,
                                element: tempElement,
                            })
                        );
                    }
                    if (element.elementType === "Image") {
                        let tempElement = element;
                        const elementAttrib = tempElement?.elementAttributes;

                        const dataUrlPrefix = `data:image/png;base64,`;
                        const image_data = {
                            duonut_id: duonutId,
                            name: `${duonutId}-${i}`,
                            uri: dataUrlPrefix + gptImageData[imageCount],
                        }
                        axios({
                            method: "POST",
                            url: `${domain}/duonut/upload_files`,
                            headers: {
                                "Content-Type": "application/json"
                            },
                            data: JSON.stringify(image_data),
                        }).then((res) => {
                            //console.log(res.data.file_url);
                            tempElement = {
                                ...tempElement,
                                elementAttributes: {
                                    ...elementAttrib,
                                    url_data: {},
                                    url: res.data.file_url,
                                },
                            };
                            dispatch(
                                updateUserActivityElements({
                                    selectedActivityIdx: i,
                                    selectedElementIdx: elementIndex,
                                    element: tempElement,
                                })
                            );
                            imageCount++;

                        }).catch((e) => {
                            console.log(e.message, e);
                        });

                    }
                });

            })

            setTimeout(() => {
                navigate(`/design/${duonutId}`);
            }, 1000);

        }

        if (selectedCategory === 4 && userActivities.length > 0 && isUpdateSlides && duonutId) {
            // console.log("form inside");
            isUpdateSlides = false;

            var elementsPerActivity = 3;
            var currentIndex = 0;

            userActivities.map((activity, i) => {
                var idNum = 2;
                var elementsAdded = 0;

                while (elementsAdded < elementsPerActivity && currentIndex < gptData[0].length) {
                    const jsonElement = gptData[0][currentIndex];
                    // console.log("jsonElement", jsonElement);
                    const element = selectFields(jsonElement, idNum);

                    // console.log("element", element);
                    // Check if element is not an empty object
                    if (Object.keys(element).length !== 0) {
                        dispatch(addElementInUserActivity({ activity: element, selectedActivityIdx: i }));
                        elementsAdded++;
                        idNum++;
                    }

                    currentIndex++;
                }
            })

            // filter not working
            // const filteredActivities = userActivities.filter((activity) => activity.elements.length !== 2);
            // dispatch(setUserActivity(filteredActivities));

            // console.log("userActivities", userActivities)

            setTimeout(() => {
                // console.log("userActivities1", userActivities)
                navigate(`/design/${duonutId}`);
            }, 1000);
        }


    }, [userActivities, isUpdateSlides]);


    return (
        <div className='AI1App'
            style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
        >
            <button ref={msgref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#msgModal">
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id="msgModal" tabIndex="-1" aria-labelledby="msgModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content LB1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={msgref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div style={{ margin: "2vh 0vh", fontSize: "1.4rem", fontWeight: "400" }}> Type a question to generate content for you.</div>
                            <br></br>
                            <button className="btn-primary" style={{ margin: "2vh", border: "1px solid black" }}
                                onClick={() => continueBtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='AI1template'>
                {selectedTab === 1 && <>
                    <div className="AI1headingContainer">
                        <h1 className="AI1heading">Supercharge your forms and workflows with AI.</h1>
                        <div style={{ fontSize: "1.2rem" }}>Duonut AI lets you create high quality forms and workflows in seconds.<br></br> You can always polish the final experience in our powerful Editor.</div>
                    </div>

                    {/* <div>
                        <button type='submit' id="gptBtn" className='GPT-submitBtn' onClick={() => handleClickDemo()}>Get Early Access</button>
                    </div> */}

                    <div style={{ backgroundColor: "rgba(8,17,47,.07)", padding: "10px", borderRadius: "16px", boxShadow: "0 22px 44px -12px rgba(25,47,89,.38)" }}>
                        <div className="AI1container">
                            <h3 style={{ paddingBottom: "1rem" }}> AI workflow generator </h3>
                            <div className="AI1btnContainer" style={{ marginBottom: "1rem", paddingTop: "0" }}>
                                <label>
                                    <input type="radio" id="Text" name="gptType" onChange={() => setgptType(1)} checked={gptType === 1} />
                                    <label for="Text" style={{ paddingLeft: "8px" }}>Text</label>
                                </label>
                                <label>
                                    <input type="radio" id="Audio/Video" name="gptType" onChange={() => setgptType(2)} checked={gptType === 2} />
                                    <label for="Audio/Video" style={{ paddingLeft: "8px" }}>Audio</label>
                                </label>
                                <label>
                                    <input type="radio" id="Document" name="gptType" onChange={() => setgptType(3)} checked={gptType === 3} />
                                    <label for="Document" style={{ paddingLeft: "8px" }}>Document</label>
                                </label>
                            </div>
                            {gptType === 1 && <input className='GPT-input_box'
                                id="GPT-input_box"
                                type="text"
                                placeholder="Type your question"
                                // value={gptQuestion}
                                onKeyUp={e => handleGPTQuestionChange(e)}
                            />}
                            {gptType === 2 && <input className='GPT-input_box'
                                id="GPT-input_box"
                                type="file"
                                accept={"audio/*"}
                                onChange={e => handleUploadFile(e)}
                            />}
                            {gptType === 3 && <input className='GPT-input_box'
                                id="GPT-input_box"
                                type="file"
                                accept=".pdf, .doc, .docx"
                                onChange={e => handleUploadFile(e)}
                            />}
                            {errMsg && <div style={{ color: 'red', padding: '10px' }}>{errMsg}</div>}
                            <div className="AI1btnContainer">
                                <div>Category
                                    <select id="mySelect" style={{ padding: "5px", marginLeft: "0.6rem", width: "8rem" }} value={selectedCategory}
                                        onChange={e => handleCategoryChange(e)}>
                                        <option selected value="1">Survey</option>
                                        <option value="2">MCQ</option>
                                        <option value="3">Story</option>
                                        <option value="4">Form</option>
                                    </select>
                                </div>
                                <div style={{ paddingLeft: "1rem" }}>Slide
                                    <select id="mySelect1" style={{ padding: "5px", marginLeft: "0.6rem", width: "8rem" }} value={selectedSlideNo}
                                        onChange={e => handleSlideNoChange(e)}>
                                        <option selected value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                                <button type='submit' id="gptBtn" className='GPT-submitBtn' onClick={() => handleGPTSubmit()}>Generate</button>
                            </div>
                        </div>
                    </div>

                    {/* <div style={{ padding: "2rem 0rem" }}>
                        <h2 style={{ margin: "1rem" }}>
                            <svg style={{ marginRight: "10px" }} width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.60979 13.1854L10.3203 9.12286L14.6744 7.62615C15.0631 7.55488 15.2964 7.19852 15.2964 6.84216C15.2964 6.55708 15.0631 6.20072 14.6744 6.12944L10.3203 4.56146L8.60979 0.570236C8.53204 0.213876 8.14328 6.10476e-05 7.83228 6.10352e-05C7.44352 6.10196e-05 7.05477 0.213876 6.97702 0.570236L5.34424 4.56146L0.912421 6.12944C0.601416 6.20072 0.368164 6.55707 0.368164 6.84216C0.368164 7.19852 0.601416 7.55488 0.912421 7.62615L5.34424 9.12286L6.97702 13.1854C7.05477 13.5417 7.44352 13.6843 7.83228 13.6843C8.14328 13.6843 8.53204 13.5417 8.60979 13.1854ZM16.9228 19.693L17.9754 17.193L20.6548 16.272C20.8941 16.2281 21.0376 16.0088 21.0376 15.7895C21.0376 15.6141 20.8941 15.3948 20.6548 15.3509L17.9754 14.386L16.9228 11.9299C16.8749 11.7106 16.6357 11.579 16.4443 11.579C16.2051 11.579 15.9658 11.7106 15.918 11.9299L14.9132 14.386L12.1859 15.3509C11.9945 15.3948 11.851 15.6141 11.851 15.7895C11.851 16.0088 11.9945 16.2281 12.1859 16.272L14.9132 17.193L15.918 19.693C15.9658 19.9123 16.2051 20 16.4443 20C16.6357 20 16.8749 19.9123 16.9228 19.693ZM23.3342 9.4737L22.5447 11.3487C22.5089 11.5132 22.3294 11.579 22.1859 11.579C22.0065 11.579 21.827 11.5132 21.7912 11.3487L21.0376 9.4737L18.9921 8.78291C18.8486 8.75001 18.7409 8.58554 18.7409 8.42107C18.7409 8.28949 18.8486 8.12501 18.9921 8.09212L21.0376 7.36843L21.7912 5.52633C21.827 5.36186 22.0065 5.26317 22.1859 5.26317C22.3294 5.26317 22.5089 5.36186 22.5447 5.52633L23.3342 7.36843L25.3438 8.09212C25.5232 8.12501 25.6309 8.28949 25.6309 8.42107C25.6309 8.58554 25.5232 8.75001 25.3438 8.78291L23.3342 9.4737Z"
                                    fill="#E2626E"></path>
                            </svg>
                            Try one of these in our Beta product:</h2>

                        <div className="AI1ExapmpleQue" onClick={() => handleClickExample("A survey to collect feedback on quality of delivery")}>
                            A survey to collect feedback on quality of delivery
                        </div>
                        <div className="AI1ExapmpleQue" onClick={() => handleClickExample("Questions about employee feedback")}>
                            Questions about employee feedback
                        </div>
                        <div className="AI1ExapmpleQue" onClick={() => handleClickExample("Facts about having health lifestyle")}>
                            Facts about having health lifestyle
                        </div>
                    </div> */}

                    <div className="home_page_integration_section" style={{ marginTop: "8rem" }}>
                        <div className="home_page_section_header">Trusted by Startups & Enterprises</div>

                        <div className="home_page_integration_section_images">
                            <a href="https://www.narayanahealth.org/" target="_blank"><img src="/assets/company/Narayana.png" loading="lazy" alt="duonut" /></a>
                            <img src="/assets/company/xe.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/mintorie.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/ourlearn.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/uleash.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/vivahit1.png" loading="lazy" alt="duonut" />
                            <a href="https://shawarmer.com/" target="_blank"><img style={{ width: "145px" }} src="/assets/company/Shawarmer.png" loading="lazy" alt="duonut" /></a>

                        </div>

                    </div>

                    <div className="home_page_integration_section">
                        <div className="home_page_section_header">Who else hit it off with Duonut?</div>

                        <div className="home_page_integration_section_images">
                            <img src="/assets/company/hubspot.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/forms.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/slack.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/gmail.png" loading="lazy" alt="duonut" />
                        </div>
                    </div>

                    <div style={{ marginBottom: "8rem", width: "95vw" }}>
                        <div className="home_page_section_header" style={{ paddingBottom: "2rem" }}>What People Are Saying</div>
                        <Slider {...sliderSettings}>
                            <div className="home_page_customer_review_Container">
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    I absolutely love using Duonut! It's so easy to create customized forms and workflows without needing any technical skills. The variety of templates makes it simple to collect all kinds of information. I highly recommend it to anyone looking for a user-friendly form builder.
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://www.producthunt.com/posts/duonut-2/launch-day/")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/customer_review3.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Nicola Lanzilotto</div>
                                        <div>Senior Frontend Developer @Tiledesk</div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_page_customer_review_Container">
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    Good data and customer feedback as all we need to build quality products. These forms help with that 👍
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://www.producthunt.com/posts/duonut-2/launch-day")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/customer_review4.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Niklas Buchfink</div>
                                        <div>Design Engineer</div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_page_customer_review_Container">
                                <div>
                                    <img style={{ width: "12rem", cursor: "pointer" }} onClick={() => window.open("https://shawarmer.com/")} src="/assets/company/shawarmer_logo.png" loading="lazy" alt="duonut" />
                                </div>
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    As a leading restaurant chain in the Middle East, at Shwarmer we are innovating new ways to engage with our customers effectively. Duonut has been a great partner in this journey. We can quickly create personalized customer workflows and collect quality feedback with a much higher completion rate.
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://shawarmer.com/")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/shawarmer_profile.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Don Sajith</div>
                                        <div>E-commerce Manager (Strategy, Marketing, Loyalty, Delivery, CRM & Transformation)</div>
                                        <div>Shawarmer. Saudi Arabia</div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_page_customer_review_Container">
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    I became a member and reviewed the tools. I didn't expect preparing a form could be this easy. It's great.
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://www.producthunt.com/posts/duonut-2/launch-day")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/customer_review2.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Ayhan Ergezen</div>
                                        <div>Founder at LivelyPencil</div>
                                    </div>
                                </div>
                            </div>
                        </Slider>

                    </div>

                    <div className="my-container" id="wrapper">
                        <div id="content" >

                            <div style={{ padding: "6rem 0rem", backgroundColor: "rgb(244, 249, 252)" }}>
                                <div className="home_page_section_header" style={{ paddingBottom: "2rem" }}>Built for your use case</div>

                                <div className="home_page_casestudy_HeaderTab">
                                    <div className="home_page_casestudy_HeaderTabInner">
                                        <label className="home_page_casestudy_header-label" style={settingTabNum === 1 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(1)}>
                                            Insurance
                                        </label>
                                        <label className="home_page_casestudy_header-label" style={settingTabNum === 2 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(2)}>
                                            Healthcare
                                        </label>
                                        <label className="home_page_casestudy_header-label" style={settingTabNum === 3 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(3)}>
                                            Manufacturing
                                        </label>
                                        <label className="home_page_casestudy_header-label" style={settingTabNum === 4 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(4)}>
                                            Education
                                        </label>
                                        {/* <label className="home_page_casestudy_header-label" style={settingTabNum === 5 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(5)}>
                                    Payments
                                </label> */}
                                    </div>
                                </div>

                                {settingTabNum === 1 &&
                                    <div className="home_page_casestudy_mainConatiner" style={{ height: "28rem" }}>
                                        <div className="home_page_casestudy_innerConatiner">
                                            <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Insurance</div>
                                            <br></br>
                                            <ul style={{ listStyle: "unset", paddingLeft: "1rem", fontSize: "1.3rem", textAlign: "left" }}>
                                                <li>Optimize insurance quote forms </li>
                                                <br></br>
                                                <li>Streamline broker and carrier underwriting workflows  </li>
                                            </ul>
                                            <br></br>
                                            <br></br>
                                            <button onClick={() => window.open("usecases/insurance")} className="UCI1Button" style={{ marginRight: "1rem" }}>
                                                Learn more &nbsp; &rarr;
                                            </button>
                                        </div>
                                        <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                            <img style={{}} src="/assets/usecase/duonut_insurance.png" loading="lazy" alt="duonut" />
                                        </div>
                                    </div>
                                }
                                {settingTabNum === 2 &&
                                    <div className="home_page_casestudy_mainConatiner" style={{ height: "28rem" }}>
                                        <div className="home_page_casestudy_innerConatiner">
                                            <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Healthcare</div>
                                            <br></br>
                                            <ul style={{ listStyle: "unset", paddingLeft: "1rem", fontSize: "1.3rem", textAlign: "left" }}>
                                                <li>Seamless Healthcare Marketing </li>
                                                <br></br>
                                                <li>Transform Patient onboarding process </li>
                                                <br></br>
                                                <li>Medical Office management </li>
                                            </ul>
                                            <br></br>
                                            <br></br>
                                            <button onClick={() => window.open("usecases/healthcare")} className="UCI1Button" style={{ marginRight: "1rem" }}>
                                                Learn more &nbsp; &rarr;
                                            </button>
                                        </div>
                                        <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                            <img style={{}} src="/assets/usecase/duonut_patient_registration process.png" loading="lazy" alt="duonut" />
                                        </div>
                                    </div>
                                }
                                {settingTabNum === 3 &&
                                    <div className="home_page_casestudy_mainConatiner" style={{ height: "28rem" }}>
                                        <div className="home_page_casestudy_innerConatiner">
                                            <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Manufacturing</div>
                                            <br></br>
                                            <ul style={{ listStyle: "unset", paddingLeft: "1rem", fontSize: "1.3rem", textAlign: "left" }}>
                                                <li>Reduce manual errors by creating effective checklist and SOPs </li>
                                                <br></br>
                                                <li>Collect & manage data with paperless workflows </li>
                                            </ul>
                                            <br></br>
                                            <br></br>
                                            <button onClick={() => window.open("usecases/manufacturing")} className="UCI1Button" style={{ marginRight: "1rem" }}>
                                                Learn more &nbsp; &rarr;
                                            </button>
                                        </div>
                                        <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                            <img style={{ width: "25rem" }} src="/assets/usecase/duonut_checklist.jpg" loading="lazy" alt="duonut" />
                                        </div>
                                    </div>
                                }
                                {settingTabNum === 4 &&
                                    <div className="home_page_casestudy_mainConatiner" style={{ height: "28rem" }}>
                                        <div className="home_page_casestudy_innerConatiner">
                                            <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Education</div>
                                            <br></br>
                                            <ul style={{ listStyle: "unset", paddingLeft: "1rem", fontSize: "1.3rem", textAlign: "left" }}>
                                                <li>Student engagement, servicing and intake forms. </li>
                                                <br></br>
                                                <li>Streamline work across departments. </li>
                                                <br></br>
                                                <li>Prospective student and alumni registration. </li>
                                            </ul>
                                            <br></br>
                                            <br></br>
                                            <button onClick={() => window.open("usecases/education")} className="UCI1Button" style={{ marginRight: "1rem" }}>
                                                Learn more &nbsp; &rarr;
                                            </button>
                                        </div>
                                        <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                            <img style={{}} src="/assets/usecase/duonjut_education.png" loading="lazy" alt="duonut" />
                                        </div>
                                    </div>
                                }
                                {settingTabNum === 5 &&
                                    <div className="home_page_casestudy_mainConatiner" style={{ height: "28rem" }}>
                                        <div className="home_page_casestudy_innerConatiner">
                                            <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Payments</div>
                                            <div>
                                                Create custom payment flows with advanced product selection controls and either built-in or custom payment collection interfaces. Utilize intuitive no-code calculations to display prices, and embed your flow within your application or website to provide a seamless checkout experience for all customers.
                                            </div>
                                        </div>
                                        <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                            <img style={{}} src={require("../../Components/HomePage/images/usecase5.png")} loading="lazy" alt="duonut" />
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="home_page_integration_section" style={{ margin: "2.5rem auto" }}>
                                <div className="home_page_section_header" style={{ paddingBottom: "1rem" }}>Backed by</div>
                                <div className="home_page_integration_section_images"
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img style={{ marginRight: "3rem" }} src="/assets/company/backendby1.png" loading="lazy" alt="duonut" />
                                    <img src="/assets/company/backendby2.png" loading="lazy" alt="duonut" />

                                </div>
                            </div>

                            <div id="services" className="scrollto clearfix" style={{ backgroundColor: "white", position: "relative", zIndex: "1", width: width > 800 ? "80%" : "95%", padding: width > 800 ? "2rem" : "1rem", margin: "auto", boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.1)", borderRadius: "50px" }}>

                                <div className="row rowContainer no-padding-bottom clearfix" style={{ padding: "0px" }}>

                                    <div className="col-2" style={{ textAlign: "left" }}>
                                        <div className="section-heading section-heading2">
                                            {/* <h3 style={{ fontSize: "16px", letterSpacing: "10px" }}>BELIEVING</h3> */}
                                            <h2 className="section-title">Market research automation that helps you see the big picture:</h2>
                                            <p className="section-subtitle">Create research efforts by using no code builders for various types of surveys, forms, NPS surveys and more. Gather quality data instantly and use them to automate market research, as it is the only key to streamlining data collection, analysis, and reporting.
                                                <br></br><br></br>Drive high-quality leads and more inbound sourced revenue with effective lead generation.
                                            </p>
                                        </div>
                                        <button onClick={() => handleClickCreateFromScratch()} data-videoid="UYJ5IjBRlW8" data-videosite="youtube" className="button video link-lightbox">
                                            START FOR FREE
                                            {/* <i className="fa fa-play" aria-hidden="true"></i> */}
                                        </button>
                                    </div>
                                    <div className="col-2" style={width > 800 ? { display: "flex", flexDirection: "column", justifyContent: "center" } : { order: "2" }}>
                                        <img loading="lazy"
                                            // style={{ boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.1)", borderRadius: "16px" }}
                                            src={require("../../Components/HomePage/images/17.png")} alt="duonut" />
                                    </div>

                                </div>

                            </div>

                            <div id="services" className="scrollto clearfix" style={{ backgroundColor: "#f4f9fc", marginTop: width > 800 ? "-58rem" : "0px", paddingBottom: "5rem" }}>

                                <div className="row rowContainer no-padding-bottom clearfix" style={{ paddingTop: width > 800 ? "65rem" : "0px" }}>

                                    <div className="col-2" style={width > 800 ? {} : { order: "2" }}>
                                        <img loading="lazy"
                                            // style={{ boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.1)", borderRadius: "16px" }}
                                            src={require("../../Components/HomePage/images/18.png")} alt="duonut" />
                                    </div>

                                    <div className="col-2" style={{ textAlign: "left" }}>
                                        <div className="section-heading section-heading2">
                                            {/* <h3 style={{ fontSize: "16px", letterSpacing: "10px" }}>BELIEVING</h3> */}
                                            <h2 className="section-title">Automate your sales workflows:</h2>
                                            <p className="section-subtitle">Enhance your sales efficiency and close deals faster with automated lead routing. Streamline your prospecting process and seamlessly integrate AI into your existing sales stack. In no time, you’ll automate routine daily prospecting tasks, allowing you to focus on what truly matters: closing more deals.
                                            </p>
                                        </div>
                                        <button onClick={() => handleClickCreateFromScratch()} data-videoid="UYJ5IjBRlW8" data-videosite="youtube" className="button video link-lightbox">
                                            START FOR FREE
                                            {/* <i className="fa fa-play" aria-hidden="true"></i> */}
                                        </button>
                                    </div>
                                </div>


                            </div>

                            <div style={{
                                // backgroundColor: "rgb(244, 249, 252)"
                            }}>
                                <div className='HPS1secMain' style={{ width: width > 800 ? "80%" : "95%", margin: "auto", padding: "4rem 0" }}>
                                    <div className='HPS1secContainer'>
                                        <div className='HPS1secImgContainer'>
                                            <img className='HPS1secimage' src="/assets/code.svg" alt="duonut" loading="lazy" />
                                        </div>
                                        <h3 style={{ padding: "10px 0px", fontSize: "1.2rem" }}>Embed</h3>
                                        <div style={{ textAlign: "center" }}>
                                            Integrate Duonut forms seamlessly into any website or landing page, be it Unbounce, WordPress, Webflow, Framer, or other builders.
                                        </div>
                                    </div>

                                    <div className='HPS1secContainer'>
                                        <div className='HPS1secImgContainer'>
                                            <img className='HPS1secimage' src="/assets/link.svg" alt="duonut" loading="lazy" />
                                        </div>
                                        <h3 style={{ padding: "10px 0px", fontSize: "1.2rem" }}>Duonut Links</h3>
                                        <div style={{ textAlign: "center" }}>
                                            Share your unique Duonut link with anyone in any platform.
                                        </div>
                                    </div>

                                    <div className='HPS1secContainer'>
                                        <div className='HPS1secImgContainer'>
                                            <img className='HPS1secimage' src="/assets/dynamic-feed.svg" alt="duonut" loading="lazy" />
                                        </div>
                                        <h3 style={{ padding: "10px 0px", fontSize: "1.2rem" }}>Popup</h3>
                                        <div style={{ textAlign: "center" }}>
                                            Create popup forms wherever you want on your website in seconds.
                                        </div>
                                    </div>

                                    <div className='HPS1secContainer'>
                                        <div className='HPS1secImgContainer'>
                                            <img className='HPS1secimage' src="/assets/captive-portal.svg" alt="duonut" loading="lazy" />
                                        </div>
                                        <h3 style={{ padding: "10px 0px", fontSize: "1.2rem" }}>Custom Domains</h3>
                                        <div style={{ textAlign: "center" }}>
                                            Host forms on your own (sub)domain to create branded form links.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section id="pricing" className="text-center scrollto clearfix ">
                                <div className="row clearfix">

                                    {/* <div className='pricingFreeContainerMain' style={{ backgroundColor: "white", position: "relative", zIndex: "1", width: "85%", padding: "1.5rem", margin: "1rem", margin: "auto", borderRadius: "50px", border: "4px solid #F4F9FC" }}>
                                <h2 style={{ fontWeight: "400", paddingBottom: "2rem" }}>All Duonut <label style={{ color: "#3184C9" }}>Form</label> features are freely available to every user.</h2>
                                <div className='pricingFreeContainer'>
                                    <div className='pricingFreeblock'>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Unlimited submissions</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Unlimited Templates</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Form Logic</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Custom `Thank You` page</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> File upload</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Unlimited Questions</div>
                                    </div>
                                    <div className='pricingFreeblock'>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Email notifications</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Redirect on completion</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Answer piping</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Schedule a close date</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Hundreds of Templates</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Custom CSS</div>
                                    </div>
                                    <div className='pricingFreeblock'>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Close on submission limit</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Custom closed message</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Slack Integration</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Hubspot Integration</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Webhooks</div>
                                    </div>
                                </div>
                                <a onClick={() => handleClickCreateFromScratch()} className="button" style={{ margin: "2rem 0rem" }}>START FOR FREE</a>
                            </div> */}

                                    {/* <div style={{ backgroundColor: "#f4f9fc", borderRadius: "120px 120px 0px 0px", paddingBottom: "4rem" }}>
                                        <div className="section-heading">
                                            <h2 className="section-title" style={{ padding: "2rem" }}>Pricing</h2>
                                        </div>

                                        <div style={{ width: "90%", margin: "auto", paddingBottom: "2rem", display: "flex", justifyContent: "center", flexDirection: width > 800 ? "unset" : "column" }}>

                                            <div className="pricing-block featured col-3 wow fadeInUp" data-wow-delay="0.6s">
                                                <div className="pricing-block-content">
                                                    <div className="pricing" style={{ backgroundColor: "rgb(49, 132, 201)", color: "white" }}>
                                                        <h3>DUONUT</h3>
                                                        <p className="pricing-sub" style={{ marginBottom: "2rem" }}>For Individuals</p>
                                                        <div className="price" style={{ color: "rgb(14, 44, 69)", fontSize: "5rem" }}>Free</div>
                                                        <div>FOREVER</div>
                                                        <div style={{ marginTop: "2.8rem", fontSize: "1rem" }}>All Duonut Form features are freely <br></br>available to every user.</div>
                                                    </div>
                                                    <ul>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Unlimited templates</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Unlimited questions</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Unlimited pages</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Unlimited styles & components</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  1,500 monthly submissions</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Up to 5 live forms</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  100 emails / month</li>

                                                  </ul>
                                                    <button onClick={() => handleClickCreateFromScratch()} className="createFreeFormButton" style={{ width: "80%", display: "inline-block", marginBottom: "2rem", fontSize: "16px" }}>START FOR FREE</button>
                                                </div>
                                            </div>

                                            <div className="pricing-block col-3 wow fadeInUp" data-wow-delay="0.4s">
                                                <div className="pricing-block-content">
                                                    <div className="pricing" style={{ backgroundColor: "rgb(14, 44, 69)", color: "white" }}>
                                                        <h3>DUONUT PRO</h3>
                                                        <p className="pricing-sub" style={{ marginBottom: "2rem" }}>For Small Startups</p>
                                                        <div className="price" style={{ color: "rgb(49, 132, 201)" }}>$29</div>
                                                        <div>PER MONTH</div>
                                                        <p style={{ fontWeight: "600", marginBottom: "0.2rem" }}>Billed annually</p>
                                                        <div>Create your own branded forms<br></br> and workflows</div>
                                                    </div>
                                                    <ul>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Up to 10 live forms</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Remove Duonut branding</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Custom HTML & CSS</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Custom userflow logic</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Custom Thank you Page</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Redirect on completion</li>
                                                        <li><img className="tickmark-icon" src={tickmark} />  Answer piping & more</li>
                                                    </ul>
                                                    <button onClick={() => buyBtn(1)} className="createFreeFormButton" style={{ width: "80%", display: "inline-block", marginBottom: "2rem", fontSize: "16px" }}>BUY TODAY</button>
                                                </div>
                                            </div>

                                            <div className="pricing-block col-3 wow fadeInUp" data-wow-delay="0.8s">
                                                <div className="pricing-block-content" style={{ height: "100%" }}>
                                                    <div className="pricing" style={{ backgroundColor: "#d1eaff", color: "black", borderRadius: "40px" }}>
                                                        <h3 style={{ color: "black" }}>DUONUT BUSINESS</h3>
                                                        <p className="pricing-sub" style={{ color: "black", marginBottom: "2rem" }}>For Large teams</p>

                                                        <a className="button" onClick={() => handleClickDemo()} style={{ width: "80%", fontSize: "1rem", margin: "4.8rem auto", padding: "1rem 1.5rem" }} >Contact Us</a>
                                                    </div>

                                                    <ul>
                                                        <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Advanced logic & API</li>
                                                        <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Custom subdomain</li>
                                                        <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  White Glove Support</li>
                                                        <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Enterprise SLAs</li>
                                                        <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Workspaces</li>
                                                        <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Custom Form Services</li>
                                                        <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Unlimited Workspaces</li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </section>

                            <HomePageFooter />


                        </div>
                    </div>

                </>}

                {selectedTab === 2 && <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100vh", background: "white", zIndex: "999999999" }}>
                    <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "5rem" }} />
                </div>}

            </div>
        </div>
    );

}

export default AiPage;