import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements,    updateElementInputFocus } from '../../redux/slices/userActivity';
import {  useLocation, } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import axios from "axios";
import { domain } from "../../services/constants";

const Dropdown = ({
    id,
    elementAttr: {
        color, fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius,
        options, required, fieldPosition, gap,
    },
    conditions,
    language,
    slideId,
}) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity,  selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const [dropdown, setdropdown] = useState('');
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name, viewer_url_params } = useSelector((state) => state.duonut);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [inputText, setInputText] = useState(text);
    const [optionsText, setoptionsText] = useState([]);

    useEffect(() => {
        if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
            fetchText(text);
            fetchOptionsText(options);
        } else {
            setInputText(text);
            setoptionsText(options);
        }
    }, [text, options]);

    const fetchOptionsText = async (texts) => {
        const translatedTexts = []; // Initialize an array to hold translated texts
        const delimiter = "|;|"; // Delimiter to separate texts

        // Combine all texts into a single string separated by semicolons
        const textToTranslate = texts.join(delimiter);

        // Translate the combined text
        const translated = await translateText(textToTranslate, language);

        // Split the translated text back into individual parts using the semicolon delimiter
        const translatedArray = translated.split(delimiter);

        // Push the translated texts into the array
        translatedTexts.push(...translatedArray); // Spread the array into the translatedTexts

        setoptionsText(translatedTexts); // Update the state with the translated texts
    };
    const fetchText = async (texts) => {
        const t_text = await translateText(texts, language);
        setInputText(t_text);
    };
    const translateText = async (texts, targetLanguage) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${domain}/duonut/language_translator`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    source: "en",
                    target: targetLanguage,
                    query: texts,
                },
            });

            if (response.status === 200) {
                // console.log("Translation:", response);
                return response?.data.translation; // return the translated text
            } else {
                // Handle unsuccessful status codes
                return "Error: Translation failed. Please try again.";
            }
        } catch (error) {
            console.error('Error in translation API:', error);
            return "Error translating text. Please try again."; // Handle API errors gracefully
        }
    };

    const handleDropdownTextChange = (e) => {
        // console.log(e.target.value);
        setInputText(e.target.value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    text: e.target.value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleDropdownSelectChange = (e) => {
        if (location.pathname.includes("/duonut/")) {
            setdropdown(e.target.value);
            submitResponse(e.target.value);
        }
    }

    const submitResponse = (value) => {
        if (location.pathname.includes("/duonut/")) {
            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: value ? 1 : 0,
                question: text,
                answered_text: value,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "Dropdown",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                url_params: viewer_url_params,
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => {
                const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                formData.append(key, value);
            });

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "Dropdown",
                step: selectedSlide + 1,
                question: text,
                answered_text: value,
            }
            dispatch(saveQuizResponse(save_response));
        }
    }

    const handleInputFocus = () => {
        dispatch(updateElementInputFocus(true));
    }
    const handleInputBlur = () => {
        dispatch(updateElementInputFocus(false));
    }

    return (
        <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: fieldPosition ? fieldPosition : "column", gap: gap ? gap : fieldPosition === 'row' ? '20px' : '0' }}>
            <EditText
                id={`dropdown-input-${id}`}
                value={required && location.pathname.includes("/duonut/") ? inputText + " *" : inputText}
                className='edit-input-text'
                style={{
                    backgroundColor: "transparent",
                    border: "0",
                    outline: "none",
                    // display: "flex",
                    // alignItems: "center",
                    // width: "max-content",
                    color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                    textAlign, lineHeight, letterSpacing, padding
                }}
                onEditMode={() => {
                    handleInputFocus();
                    // setTimeout(() => document.querySelector(`#dropdown-input-${id}`).select(), 100); 
                }}
                onChange={(e) => handleDropdownTextChange(e)}
                onBlur={handleInputBlur}
                readonly={(location.pathname).includes("/duonut/") || viewType === 3 ? true : false}
            />
            <select value={dropdown}
                style={{
                    width: "100%", minHeight: "40px",
                    color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                    textAlign, lineHeight, letterSpacing, padding,
                    backgroundColor, border: border ? border : "0", margin, borderRadius,
                }}
                onChange={e => handleDropdownSelectChange(e)}
                onMouseDown={e => {
                    if (!location.pathname.includes("/duonut/")) {
                        e.preventDefault();
                    }
                }}
                onClick={e => {
                    if (!location.pathname.includes("/duonut/")) {
                        e.preventDefault();
                    }
                }}>
                <option key={"select option"} value="" style={{ display: "none", color: "#44474d" }} selected disabled> {language === "hi" ? "चुनना..." :
                    language === "es" ? "Seleccionar..." :
                        language === "ja" ? "選択してください..." :
                            language === "ar" ? "يختار..." :
                                language === "fr" ? "Sélectionner..." :
                                    language === "nl" ? "Selecteer..." :
                                        language === "pt" ? "Selecione..." : "Select..."} </option>
                {options.map((option, idx) => {
                    return <option key={option} value={option} > {optionsText[idx]} </option>
                }
                )}
            </select>
        </div>
    );
}

export default Dropdown;
