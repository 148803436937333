import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements } from '../../../redux/slices/userActivity';
import { EditText } from "react-edit-text";

export default function MinMaxLength() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [minLength, setMinLength] = useState(null);
    const [maxLength, setMaxLength] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const minLength = tempElement?.elementAttributes?.minLength;
            const maxLength = tempElement?.elementAttributes?.maxLength;
            if (minLength) {
                setMinLength(minLength);
            } else {
                setMinLength(null);
            }
            if (maxLength) {
                setMaxLength(maxLength);
            } else {
                setMaxLength(null);
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleMinLengthChange = (e) => {
        // console.log(e)
        const value = parseInt(e.target.value)
        setMinLength(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    minLength: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleMaxLengthChange = (e) => {
        // console.log(e)
        const value = parseInt(e.target.value)
        setMaxLength(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    maxLength: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    return (
        <div className="EditorRowContainer">
            <div style={{ marginRight: "0.5rem" }}>Min Length</div>
            <EditText
                id="minLenght-input-field"
                value={minLength}
                type="number"
                style={{ border: "1px solid #11111333", borderRadius: "5px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", width: "3rem", height: "1.8rem", padding: "0" }}
                onChange={(e) => (e.target.value.length > 3 ? null : handleMinLengthChange(e))}
            />

            <div style={{ margin: " 0px 0.5rem" }}>Max Length</div>
            <EditText
                id="maxLenght-input-field"
                value={maxLength}
                type="number"
                style={{ backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", width: "3rem", height: "1.8rem", padding: "0" }}
                onChange={(e) => (e.target.value.length > 3 ? null : handleMaxLengthChange(e))}
            />
        </div>
    )
}

