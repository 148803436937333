import React from 'react';
import { useLocation } from "react-router-dom";
import audioPreview from '../../assets/Images/audio-preview.jpg';
import { useSelector } from "react-redux";

const Audio = ({
  id,
  elementAttr: {
    url, border, margin, padding,
  },
}) => {
  const location = useLocation();
  // const { viewType } = useSelector((state) => state.duonutSettings);


  const clickAudio = (e) => {
    e.preventDefault()
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", width: '100%', height: '100%', position: "relative" }}
      onClick={(e) => clickAudio(e)}>
      {url ? <>
        <audio
          id="aud"
          style={{
            maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%',
            border, margin, padding
          }}
          controls
          key={id}>
          <source src={url} type="audio/mp3" />
        </audio>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            display: (location.pathname).includes("/duonut/") ? "none" : 'block'
            // cursor: 'not-allowed'
          }}
          onClick={clickAudio}
        /></>
        : <img src={audioPreview} style={{
          maxWidth: '100%', maxHeight: '100%', objectFit: "cover",
          cursor: (location.pathname).includes("/duonut/") ? "default" : 'default',
          border, margin, padding
        }} alt="" />}
    </div>
  );
}

export default Audio;
