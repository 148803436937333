import React, { useEffect, useState } from 'react';
import './TemplateDetailsPage.css';
import {  frontendUrl } from "../../services/constants";
import {  useDispatch } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { getAdminDuonuts } from '../../services/duonut'
// import HomePageFooter from '../../components/HomePageFooter/HomePageFooter';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { toggleChangeDuonutNameModal, updateDuonutDefaultName, updateDuonutNavigateUrl } from "../../redux/slices/DuonutNameReducer";
// import { Navigation, Pagination, Scrollbar, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";

const TemplateDetailsPage = () => {
    const dispatch = useDispatch();
    const paramsid = useParams();
    const duonut_name = paramsid.anotherId;
    const duonut_category = paramsid.id;
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const [selectedData, setselectedData] = useState("");
    const [selectedItem, setselectedItem] = useState(1);
    // console.log("paramsid", paramsid);

    const searchWords = duonut_name.split("-");
    const searchRegexes = searchWords.map((word) => new RegExp(word, "i"));

    window.scrollTo(0, 0);

    useEffect(() => {
        getAdminDuonuts((err, res) => {
            if (err) console.log(err)
            else {
                if (res.data.length === 0) return
                var allData = res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1);
                // console.log("AdminDuonut", res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1))
                allData.map((itemData, i) => {
                    let itemDataName = itemData.name.replace(/[^\w\s]/gi, '').toLowerCase()
                    if (duonut_category === itemData.duonut_category_id.name.toLowerCase().replace(/ /g, "_") &&
                        (searchRegexes.every((regex) => itemDataName?.match(regex)) || searchRegexes.every((regex) => itemData.keywords?.some(keyword => keyword.match(regex))))) {
                        // console.log("inside", itemData);
                        setselectedData(itemData);
                    }
                    return null;
                });
            }
        })
        // console.log("inside");
    }, [dispatch]);

    useEffect(() => {
        if (width <= 768) {
            setselectedItem(2);
        }
    }, [width]);

    const onClickTemplateButton = () => {
        dispatch(updateDuonutDefaultName(selectedData.name));
        dispatch(updateDuonutNavigateUrl(`/design?selectedDuonutId=${selectedData.id}`));
        dispatch(toggleChangeDuonutNameModal(true));
        // window.open(`/design?selectedDuonutId=${selectedData.id}`);
    }
    const onClickIcon = (num) => {
        setselectedItem(num);
    }
    return (
        <div className='ADP1App'>
            <div className='ADP1backBtn' onClick={() => { navigate("/templates/all") }}>
                <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to templates
            </div>
            {selectedData ? <div className='ADP1template'>
                <div className='ADP1container'>
                    <div className='ADP1templatetext'>TEMPLATES</div>
                    <h1 style={{ fontSize: width >= 768 ? "3.5rem" : "" }}>{selectedData.name}</h1>
                    {selectedData.description ? <div style={{ padding: "4vh", fontSize: "1.4rem" }}>{selectedData.description}</div>
                        : <div style={{ padding: "4vh", fontSize: "1.4rem" }}>Customise this template to create your own workflow in minutes and start using in your app/website or directly share.
                            {/* <br></br><br></br>DIY no-code platform. No-design experience required. Feedback: hello@duonut.com */}
                        </div>}
                    <button className='ADP1Button' onClick={() => onClickTemplateButton()}>Start with this template</button>
                </div>

                {selectedItem === 1 && <div style={{ padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <iframe className='ADP1iframe' id="iframeid" src={`${frontendUrl}/duonut/${selectedData.display_id}`} title="Duonut Iframe"></iframe>
                </div>}
                {selectedItem === 2 && <div style={{ padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {/* <img src="../assets/glow-left.png" className="ADP1glow" /> */}

                    <div className='ADP1mobileScreen'></div>
                    <iframe className='ADP1iframeMob' id="iframeid" src={`${frontendUrl}/duonut/${selectedData.display_id}`} title="Duonut Iframe"></iframe>
                </div>}
                {(selectedItem === 3 && selectedData.duonutthumbnail_set.length > 0) && <div className='ADP1movingThumbnail'>
                    <Swiper
                        id={selectedData.id}
                        className=""
                        style={{ margin: "0" }}
                        virtual
                        autoplay
                        onSlideChange={(swiperCore) => console.log(swiperCore)}
                    >
                        {selectedData.duonutthumbnail_set.map((thumbnail_set, i) => {
                            return <SwiperSlide key={`slide-${thumbnail_set.id}-${i}`} style={{ listStyle: "none" }}>
                                <img src={thumbnail_set.thumbnail} className="card-img-top ADP1movingImg" loading="lazy" />
                            </SwiperSlide>;
                        })}
                    </Swiper>
                </div>}

                <div style={{ display: "flex", justifyContent: "center", margin: "2rem" }}>
                    {width >= 768 && <div className='ADP1icon' style={{ backgroundColor: selectedItem === 1 ? "rgba(186, 193, 196, 0.7)" : "", padding: "10px 13px 15px 13px" }} onClick={() => onClickIcon(1)}> <i className="fa fa-desktop" aria-hidden="true" ></i></div>}
                    <div className='ADP1icon' style={{ backgroundColor: selectedItem === 2 ? "rgba(186, 193, 196, 0.7)" : "" }} onClick={() => onClickIcon(2)}><i className="fa fa-mobile" style={{ fontSize: "2rem" }} aria-hidden="true" ></i></div>
                    {/* <i className="fa fa-link ADP1icon" aria-hidden="true" style={{ backgroundColor: selectedItem === 3 ? "#0B1350" : "" }} onClick={() => onClickIcon(3)}></i> */}
                </div>

                <div className='ADP1getStart' onClick={() => onClickTemplateButton()}>
                    <span className='ADP1getStartBtn'>Get Started</span>
                </div>
            </div>
                : <div style={{ padding: "5rem" }}>
                    <div className='ADP1skeleton'></div>
                    {/* <img alt="loader" src={Loader} height={50} width={50} /> */}
                </div>}

            {/* <HomePageFooter /> */}
        </div>
    );

}

export default TemplateDetailsPage;