import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateSelectedComponent } from '../../../redux/slices/userActivity';

export default function ChangeElement() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [elementType, setElementType] = useState('');
    const [text, setText] = useState("");
    const [feedback, setFeedback] = useState("");
    const [objective, setObjective] = useState("");
    const [openEnded, setOpenEnded] = useState("");
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            console.log("change-", tempElement);
            const elementType = tempElement?.elementType;
            const text = tempElement?.elementAttributes?.text;
            const feedback = tempElement?.elementAttributes?.openEndedFeedbackDesired;
            const objective = tempElement?.elementAttributes?.openEndedObjective;
            const openEnded = tempElement?.elementAttributes?.openEnded;
            console.log(elementType, text, feedback, objective);
            if (elementType) {
                setElementType(elementType);
                setText(text);
                setFeedback(feedback);
                setObjective(objective);
                setOpenEnded(openEnded);
            } else {
                setElementType("");
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleElementChange = e => {
        const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            console.log(tempElement, elementAttrib, text, feedback, objective);
            if (value === "Nps") {
                const npsElement = {
                    id: tempElement?.id,
                    elementType: 'Nps',
                    transparency: 1,
                    zIndex: tempElement?.zIndex,
                    order: tempElement?.order,
                    posX: 10,
                    posY: 200,
                    height: "auto",
                    width: 1000,
                    elementAttributes: {
                        ...elementAttrib,
                        text: text,
                        color: "white",
                        maxLength: 5,
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: 18,
                        backgroundColor: "#4a90e2",
                        textAlign: "center",
                        borderRadius: '10px',
                        margin: "5px 5px 5px 5px",
                        padding: "0px 0px 0px 0px",
                        labelFontSize: 22,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        size: 70,
                        gap: "50px",
                        openEnded: openEnded,
                        openEndedObjective: objective,
                        openEndedFeedbackDesired: feedback,
                    },
                    elementHoverAttributes: {
                        backgroundColor: "black", selectedBackgroundColor: "#7ed321"
                    },
                };

                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: npsElement,
                    })
                );
            }
            if (value === "RadioGroup") {
                const radioGroupElement = {
                    id: tempElement?.id,
                    elementType: 'RadioGroup',
                    transparency: 1,
                    zIndex: tempElement?.zIndex,
                    order: tempElement?.order,
                    posX: 300,
                    posY: 150,
                    height: "auto",
                    width: 400,
                    elementAttributes: {
                        text: text,
                        options: ["Option 1", "Option 2", "Option 3"],
                        other: false,
                        color: '#000000', fontFamily: "Poppins",
                        fontWeight: 600, fontSize: 16,
                        backgroundColor: "#ffffff",
                        border: "1px solid #000000",
                        textAlign: "center", borderRadius: "5px",
                        margin: "5px 5px 5px 5px",
                        padding: "10px 10px 10px 10px",
                        labelFontSize: 22,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        gap: "25px",
                        openEnded: openEnded,
                        openEndedObjective: objective,
                        openEndedFeedbackDesired: feedback,
                    },
                    elementHoverAttributes: {
                        color: '#ffffff', backgroundColor: "#3184c9", border: "1px solid #000000"
                    },
                };
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: radioGroupElement,
                    })
                );
            }
            if (value === "RadioImage") {
                const radioImageElement = {
                    id: tempElement?.id,
                    elementType: 'RadioImage',
                    transparency: 1,
                    zIndex: tempElement?.zIndex,
                    order: tempElement?.order,
                    posX: 10,
                    posY: 100,
                    height: "auto",
                    width: 1000,
                    elementAttributes: {
                        text: text,
                        options: ["Strut", "LuxeStep", "Velvet"],
                        optionsImage: ["https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/17.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/18.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/19.png"],
                        maxLength: null,
                        color: '#000000', fontFamily: "Poppins",
                        fontWeight: 600, fontSize: 16, backgroundColor: "#FFFFFF",
                        border: "0px solid #000000",
                        textAlign: "center", borderRadius: "5px",
                        margin: "5px 5px 5px 5px",
                        padding: "5px 5px 5px 5px",
                        labelFontSize: 22,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        imageWidth: "150px",
                        imagePadding: "5px 15px 5px 5px",
                        fieldPosition: "row",
                        gap: "25px",
                        openEnded: openEnded,
                        openEndedObjective: objective,
                        openEndedFeedbackDesired: feedback,
                    },
                    elementHoverAttributes: {
                        color: '#000000', backgroundColor: "#FFFFFF", border: "0px solid #000000"
                    },
                };
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: radioImageElement,
                    })
                );
            }
            if (value === "QuestionAnswer") {
                const questionanswer = {
                    id: tempElement?.id,
                    elementType: 'QuestionAnswer',
                    transparency: 1,
                    zIndex: tempElement?.zIndex,
                    order: tempElement?.order,
                    posX: 100,
                    posY: 100,
                    height: "auto",
                    width: 800,
                    elementAttributes: {
                        text: text,
                        height: "400px",
                        color: '#000000', fontFamily: "Poppins",
                        fontWeight: 600, fontSize: 22,
                        backgroundColor: "#ffffff",
                        border: "1px solid #F0EAEA",
                        textAlign: "left", borderRadius: "0px",
                        placeholder: "Type your answer here.",
                        openEnded: openEnded,
                        openEndedObjective: objective,
                        openEndedFeedbackDesired: feedback,
                    },
                    elementHoverAttributes: {
                        color: '#000000', backgroundColor: "#FFFFFF", border: "0px solid #000000"
                    },
                };
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: questionanswer,
                    })
                );
            }
            if (value === "RadioMultiImage") {
                const radioMultiImageElement = {
                    id: tempElement?.id,
                    elementType: 'RadioMultiImage',
                    transparency: 1,
                    zIndex: tempElement?.zIndex,
                    order: tempElement?.order,
                    posX: 50,
                    posY: 10,
                    height: "auto",
                    width: '920px',
                    elementAttributes: {
                        text: text,
                        options: ["Strut", "LuxeStep", "Velvet", "Gait", "Solace", "Stride", "Evoque", "Arcus"],
                        description: ["Step out with bold confidence in every stride.", "Luxury meets comfort in every graceful step.", "Smooth as velvet, crafted for elegance and ease.", "Perfectly balanced to match your every move.", "Find comfort and style in perfect harmony.", "Empower your steps with unmatched precision.", "Designed to evoke style and sophistication.", "Inspired by the beauty of arches in motion."],
                        description2: ["Price: $85", "Price: $120", "Price: $100", "Price: $95", "Price: $110", "Price: $90", "Price: $130", "Price: $105"],
                        optionsImage: ["https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/17.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/18.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/19.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/20.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/21.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/22.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/23.png", "https://duonutwebsitevideo.s3.us-east-2.amazonaws.com/icons_for_elements/24.png"],
                        maxLength: null,
                        color: '#000000', fontFamily: "Poppins",
                        fontWeight: 400, fontSize: 16, backgroundColor: "#FFFFFF",
                        border: "0px solid #000000",
                        textAlign: "center", borderRadius: "5px",
                        margin: "5px 5px 5px 5px",
                        padding: "5px 5px 5px 5px",
                        labelFontSize: 22,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        imageWidth: "130px",
                        imagePadding: "5px 15px 5px 5px",
                        fieldPosition: "row",
                        gap: "20px",
                        descriptionFontSize: 12,
                        descriptionFontWeight: "400",
                        descriptionColor: '#9B9B9B',
                        description2FontSize: 16,
                        description2FontWeight: "400",
                        description2Color: '#000000',
                        openEnded: openEnded,
                        openEndedObjective: objective,
                        openEndedFeedbackDesired: feedback,
                    },
                    elementHoverAttributes: {
                        color: '#000000', backgroundColor: "#FFFFFF", border: "0px solid #000000"
                    },
                };
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: radioMultiImageElement,
                    })
                );
            }
            if (value === "CheckboxGroup") {
                const radioMultiImageElement = {
                    id: tempElement?.id,
                    elementType: 'CheckboxGroup',
                    transparency: 1,
                    zIndex: tempElement?.zIndex,
                    order: tempElement?.order,
                    posX: 300,
                    posY: 150,
                    height: "auto",
                    width: 400,
                    elementAttributes: {
                        text: text,
                        options: ["Option 1", "Option 2", "Option 3"],
                        other: false,
                        maxLength: null,
                        color: '#000000', fontFamily: "Poppins",
                        fontWeight: 600, fontSize: 16,
                        backgroundColor: "#ffffff",
                        border: "1px solid #000000",
                        textAlign: "center", borderRadius: "5px",
                        margin: "5px 5px 5px 5px",
                        padding: "10px 10px 10px 10px",
                        labelFontSize: 22,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        gap: "25px",
                        openEnded: openEnded,
                        openEndedObjective: objective,
                        openEndedFeedbackDesired: feedback,
                    },
                    elementHoverAttributes: {
                        color: '#000000', backgroundColor: "#FFFFFF", border: "0px solid #000000"
                    },
                };
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: radioMultiImageElement,
                    })
                );
            }
            if (value === "CheckboxImage") {
                const radioMultiImageElement = {
                    id: tempElement?.id,
                    elementType: 'CheckboxImage',
                    transparency: 1,
                    zIndex: tempElement?.zIndex,
                    order: tempElement?.order,
                    posX: 10,
                    posY: 100,
                    height: "auto",
                    width: 1000,
                    elementAttributes: {
                        text: text,
                        options: ["Option 1", "Option 2", "Option 3"],
                        optionsImage: ["", "", ""],
                        maxLength: null,
                        color: '#000000', fontFamily: "Poppins",
                        fontWeight: 600, fontSize: 16, backgroundColor: "#FFFFFF",
                        border: "0px solid #000000",
                        textAlign: "center", borderRadius: "5px",
                        margin: "5px 5px 5px 5px",
                        padding: "5px 5px 5px 5px",
                        labelFontSize: 22,
                        labelFontWeight: "400",
                        labelColor: '#000000',
                        imageWidth: "150px",
                        imagePadding: "5px 15px 5px 5px",
                        fieldPosition: "row",
                        gap: "25px",
                        openEnded: openEnded,
                        openEndedObjective: objective,
                        openEndedFeedbackDesired: feedback,
                    },
                    elementHoverAttributes: {
                        color: '#000000', backgroundColor: "#FFFFFF", border: "0px solid #000000"
                    },
                };
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: radioMultiImageElement,
                    })
                );
            }
        }

        dispatch(
            updateSelectedComponent({
                type: "Slide",
                name: "Container",
                target: "",
                target_id: null,
            })
        );


    }
    return (<>
        <div className="EditorRowContainer">
            <div style={{ marginRight: "1rem" }}>Change Element</div>
            <select value={elementType} className='text_editor_dropdown' onChange={e => handleElementChange(e)} >
                <option value='QuestionAnswer' > Question Answer </option>
                <option value='RadioGroup'> Radio Group </option>
                <option value='RadioImage' > Radio Image </option>
                <option value='RadioMultiImage' > Radio Multi Image </option>
                <option value='Nps' > NPS </option>
                <option value='CheckboxGroup' > Checkbox Group </option>
                <option value='CheckboxImage' > Checkbox Image </option>
            </select>
        </div>
    </>
    )
}

