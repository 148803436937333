import React from 'react';
import './HubspotHelp.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
// import useWindowDimensions from "../../hooks/useWindowDimensions";
import YouTube from "react-youtube";

const HubspotHelp = () => {
    // const { width, height } = useWindowDimensions();
    // const [selected, setSelected] = useState(0);
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            playsinline: 1
        }
    };

    return (
        <div className='HHS1App'>
            <div className='HHS1template'>
                <h1 style={{ fontSize: "2.5rem", padding: "2rem 0rem" }}>How to Integrate Duonut with HubSpot CRM</h1>
                <div>
                    <a href='https://www.hubspot.com' target="_blank">HubSpot </a> has been a house hold name and the most powerful CRM. <a href='https://www.hubspot.com/products/crm' target="_blank">With HubSpot CRM</a>, you can manage all your customers and business contacts in one place.
                </div>
                <br></br>
                <YouTube
                    videoId="C0oEJSe60as"
                    id='player'
                    containerClassName="embed embed-youtube"
                    opts={opts}
                    className='HHS1section-1'
                    style={{ height: "20rem" }}
                />
                <br></br>
                <br></br>
                <div>
                    The HubSpot integration on DuoNut connects seamlessly to your HubSpot CRM account and automatically syncs your business and customer contacts.

                </div>
                <br></br>

                <div>
                    This guide will help you to connect your DuoNut to HubSpot, and it should only take a couple of minutes.
                </div>
                <br></br>
                <div>
                    1.  Go to the <b>Design page</b> of your selected activity.
                </div>

                <br></br>
                <div>
                    2.  Click <b>Integrations</b> tab on the top of the screen.
                </div>
                <br></br>
                <div className='HHS1section-1'>
                    <img className='HHS1section-1-image' src="/assets/help/hubspothelp1.png" alt="" />
                </div>

                <br></br>
                <div>
                    3.  Select <b>HubSpot</b>.
                </div>
                <br></br>
                <div className='HHS1section-1'>
                    <img className='HHS1section-1-image' src="/assets/help/hubspothelp2.png" alt="" />
                </div>

                <br></br>
                <div>
                    4.  Click on <b>Authenticate </b>  and complete the authentication process with your HubSpot account.
                </div>
                <br></br>
                <div className='HHS1section-1'>
                    <img className='HHS1section-1-image' src="/assets/help/hubspothelp3.png" alt="" />
                </div>
                <br></br>

                <div>
                    5.  Next, <b>map</b> your HubSpot CRM fields to your form fields one by one. Click on +  icon to add more fields.
                </div>
                <br></br>
                <div className='HHS1section-1'>
                    <img className='HHS1section-1-image' src="/assets/help/hubspothelp4.png" alt="" />
                </div>
                <br></br>

                <div>
                    6.  Click <b>Publish</b> to save the changes.
                </div>

                <br></br>
                <div className='HHS1section-1'>
                    <img className='HHS1section-1-image' src="/assets/help/hubspothelp5.png" alt="" />
                </div>
                <br></br>


                <br></br>
            </div>
            <HomePageFooter />
        </div>
    );

}

export default HubspotHelp;