import React from 'react';
import './HealthCare.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import { openModal, showSignUpForm } from "../../redux/slices/formDetails";
import { useSelector, useDispatch } from "react-redux";
import { toggleDemoModalDuonut } from "../../redux/slices/DemoReducer";

const HealthCare = () => {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((state) => state.user);

    const handleClick = () => dispatch(openModal());
    const handleClickSignUp = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignUpForm());
        } else {
            navigate("/design");
        }

    };

    const handleClickDemo = () => {
        dispatch(toggleDemoModalDuonut(true));
    };

    return (
        <div className='UCI1App'>
            <div className='UCI1template'>

                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'contain' }}
                            src="/assets/usecase/duonut_Medical_practice_automation.png" alt="" />
                    </div>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingLeft: "4rem" }}>
                        <h1 style={{ fontSize: "3rem", lineHeight: "50px" }}>Automate Patient Experiences</h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Use electronic healthcare forms to simplify your medical office management and save hundreds of hours each month with healthcare marketing automation.
                        </div>
                        <br></br>
                        <button onClick={() => handleClickDemo()} className="UCI1Button" style={{ marginRight: "1rem" }}>
                            Book a demo
                        </button>
                        <button className="button" style={{ padding: "16px 40px", fontSize: "1.2rem" }} onClick={() => handleClickSignUp()} >
                            Sign up now
                        </button>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div className="home_page_integration_section">
                    <div className="home_page_section_header">Trusted by Startups & Enterprises</div>

                    <div className="home_page_integration_section_images">
                        <a href="https://shawarmer.com/" target="_blank"><img style={{ width: "145px" }} src="/assets/company/Shawarmer.png" loading="lazy" alt="duonut" /></a>
                        <a href="https://www.narayanahealth.org/" target="_blank"><img src="/assets/company/Narayana.png" loading="lazy" alt="duonut" /></a>
                        <img src="/assets/company/xe.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/mintorie.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/ourlearn.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/uleash.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/vivahit1.png" loading="lazy" alt="duonut" />
                    </div>
                </div>


                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'cover' }}
                            src="/assets/usecase/duonut_Optimize your healthcare marketing.png" alt="" />
                    </div>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingLeft: "4rem" }}>
                        <h1>Optimize your healthcare marketing automation efforts</h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Make smarter decisions with healthcare marketing automation tools that help you simplify workflows and turn more leads into long-term customers.
                        </div>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            More patients expect to be served through the digital space. With Duonut’s  healthcare marketing automation tools, you can simplify and automate tedious workflows, simplify workflows, improve patient engagement, and make smarter decisions.
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingRight: "4rem" }}>
                        <h1>Transform your patient registration process</h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            It’s time to eliminate paper from patient onboarding. Boost productivity and enhance care with Duonut. Streamline processes like patient onboarding, prescription refills, appointment bookings, patient check-in, and more!
                        </div>
                    </div>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'cover' }}
                            src="/assets/usecase/duonut_patient_registration process.png" alt="" />
                    </div>
                </div>


                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'cover' }}
                            src="/assets/usecase/duonut_Medical_practice_automation.png" alt="" />
                    </div>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingLeft: "4rem" }}>
                        <h1>Medical practice automation:</h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Duonut simplifies medical office management so you can be more productive and meet patient needs.
                        </div>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Shift to mobile-friendly healthcare forms to reduce manual data entry and save hundreds of hours each month. Printing, completing, scanning, faxing, mailing, and shredding forms takes away important time from your staff.
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "8rem" }}>
                    <h1 style={{ fontSize: "2.5rem", paddingBottom: "3rem" }}>Featured HealthCare Templates</h1>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className='UCI1template_container'
                            onClick={() => window.open("https://duonut.com/templates/general/infection-control-protocols")}>
                            <img style={{ width: "280px", height: "185px", borderRadius: "15px", objectFit: "cover" }}
                                src="/assets/usecase/templates/healthcare1.png" alt="" />
                            <div style={{
                                display: "flex", alignItems: "center", height: "6rem",
                                fontSize: "1.3rem", fontWeight: "700"
                            }}>Infection Control Protocols in hospitals</div>
                        </div>
                        <div className='UCI1template_container'
                            onClick={() => window.open("https://duonut.com/templates/general/new-patient-health-history-form")}>
                            <img style={{ width: "280px", height: "185px", borderRadius: "15px", objectFit: "cover" }}
                                src="/assets/usecase/templates/healthcare2.png" alt="" />
                            <div style={{
                                display: "flex", alignItems: "center", height: "6rem",
                                fontSize: "1.3rem", fontWeight: "700"
                            }}>New Patient Health History Form</div>
                        </div>
                        <div className='UCI1template_container'
                            onClick={() => window.open("https://duonut.com/templates/general/contact-us-form-ii")}>
                            <img style={{ width: "280px", height: "185px", borderRadius: "15px", objectFit: "cover" }}
                                src="/assets/usecase/templates/contact_us.png" alt="" />
                            <div style={{
                                display: "flex", alignItems: "center", height: "6rem",
                                fontSize: "1.3rem", fontWeight: "700"
                            }}>Contact us Form</div>
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h1 style={{ fontSize: "3rem", padding: "1rem" }}>Get started today.</h1>
                    <div>
                        <button onClick={() => handleClickDemo()} className="UCI1Button" style={{ marginRight: "1rem" }}>
                            Book a demo
                        </button>
                        <button className="button" style={{ padding: "16px 40px", fontSize: "1.2rem" }} onClick={() => handleClickSignUp()} >
                            Sign up now
                        </button>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

            </div>
            <HomePageFooter />
        </div>
    );

}

export default HealthCare;