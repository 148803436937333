import React, { useRef, useState, useEffect, useCallback } from "react";
import "./ThankyouPage.css";
import background from '../../../assets/Images/mcqWithTimer.svg';
// import VolumeUpIcon from '@mui/icons-material/VolumeUp';
// import VolumeMuteIcon from '@mui/icons-material/VolumeOff';
// import { useNavigate } from 'react-router-dom';
import { showduonutData, showDuonutresponse, saveAnalyticsAsync } from '../../../redux/slices/DuonutReducer';
import { useSelector, useDispatch } from "react-redux";
// import FeedBackModel from '../../viewerComponents/ModalBox/FeedBackModel';
// import { useAudio } from '../../viewerComponents/BGAudio/useAudio';
// import ActivityRecommendation from '../../viewerComponents/ActivityRecommendation/ActivityRecommendation.js';
import { openModal, showSignInForm } from "../../../redux/slices/formDetails";
// import { saveLikeAsync } from '../../redux/slices/LikeReducer';
import { domain,  frontendUrl } from "../../../services/constants";
// import { showgeoLocation } from '../../redux/slices/GeoLocationReducer';
import ReactCanvasConfetti from "react-canvas-confetti";
import leaderboardaudio from '../../../assets/audio/appluase.mp3';
import axios from "axios";

import ConfettiExplosion from 'react-confetti-explosion';

function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
}

const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
};

function getAnimationSettings(originXA, originXB) {
    return {
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        zIndex: 0,
        particleCount: 150,
        origin: {
            x: randomInRange(originXA, originXB),
            y: Math.random() - 0.2
        }
    };
}

export default function ThankyouPage(props) {
    const nickname = props.nickname;
    // const queryParamsObject = props.details.queryParamsObject;
    const { save_response, save_form_data } = useSelector(state => state.duonut);
    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];
    const duonutName = duonutDataMain[0].name;
    const displayId = duonutDataMain[0].display_id;
    const duonutId = duonutDataMain[0].id;
    // const isAudio = duonutDataMain[0].setting.audio;
    // const show_recommendation = duonutDataMain[0].show_recommendation;
    const show_create_button = duonutDataMain[0].setting.show_create_button;
    const show_like_button = duonutDataMain[0].setting.show_like_button;
    const show_share_button = duonutDataMain[0].setting.show_share_button;
    const show_next_duonut_button = duonutDataMain[0].setting.show_next_duonut_button;
    const show_custom_button = duonutDataMain[0].setting.show_custom_button;
    const custom_button_message = duonutDataMain[0].setting.custom_button_message;
    const custom_button_url = duonutDataMain[0].setting.custom_button_url;
    const liked = duonutDataMain[0].setting.liked;
    const coupon_code = duonutDataMain[0].setting.coupon_code;
    // const show_feedback = duonutDataMain[0].setting.show_feedback;

    const show_endpage = duonutDataMain[0].setting.show_endpage;
    const leaderboard_bgcolor = duonutDataMain[0].setting.leaderboard_bgcolor;
    const leaderboard_logo = duonutDataMain[0].setting.leaderboard_logo;
    const leaderboard_title = duonutDataMain[0].setting.leaderboard_title;
    const leaderboard_description = duonutDataMain[0].setting.leaderboard_description;
    const leaderboard_fontcolor = duonutDataMain[0].setting.leaderboard_fontcolor;
    const referral = duonutDataMain[0].setting.referral;
    const sub_domain = duonutDataMain[0].created_by.sub_domain;
    const show_confetti = duonutDataMain[0].setting.show_confetti;
    const play_audio = duonutDataMain[0].setting.play_audio;
    const redirect_url = duonutDataMain[0].setting.redirect_url;
    const slack_integration = duonutDataMain[0].slack_integration;
    const hubspot_integration = duonutDataMain[0].hubspot_integration;
    const gmail_integration = duonutDataMain[0].gmail_integration;
    const scheduled_workflow = duonutDataMain[0].scheduled_workflow;

    const save_analytics_response = useSelector(showDuonutresponse);
    // const location = useSelector(showgeoLocation);

    var frontend_url = "";
    if (sub_domain) {
        frontend_url = `https://${sub_domain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = () => dispatch(openModal());
    const { isLoggedIn } = useSelector((state) => state.user);

    // const [playing, toggle] = useAudio();
    const [audioStatus, setAudioStatus] = useState(false);
    const ref = useRef(null);
    const ref1 = useRef(null);
    const audioRef = useRef(null);
    const [iscopy, setiscopy] = useState(false);
    const [iscopyCode, setiscopyCode] = useState(false);
    const [iscopyReferralCode, setiscopyReferralCode] = useState(false);
    const [like, setlike] = useState(false);
    const [showFeedback, setShowFeedback] = useState(0);
    const [customLeaderboard, setcustomLeaderboard] = useState(false);
    const [isInIframe, setIsInIframe] = useState(false);
    const [referralLink, setreferralLink] = useState("");
    const [startAnim, setstartAnim] = useState(false);

    const source = {
        position: "absolute",
        right: "50%",
        left: "50%",
        top: "-150px",
        zIndex: "5000"
    };
    const bigExplodeProps = {
        force: 0.6,
        duration: 3000,
        particleCount: 200,
        floorHeight: 1600,
        floorWidth: 1600
    };
    //////////////////////////////////////////
    const refAnimationInstance = useRef(null);
    const [intervalId, setIntervalId] = useState();

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const nextTickAnimation = useCallback(() => {
        if (refAnimationInstance.current) {
            refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
            refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
        }
    }, []);

    // const startAnimation = useCallback(() => {
    //     if (!intervalId) {
    //         setIntervalId(setInterval(nextTickAnimation, 400));
    //     }
    // }, [intervalId, nextTickAnimation]);

    // const pauseAnimation = useCallback(() => {
    //     clearInterval(intervalId);
    //     setIntervalId(null);
    // }, [intervalId]);

    // const stopAnimation = useCallback(() => {
    //     clearInterval(intervalId);
    //     setIntervalId(null);
    //     refAnimationInstance.current && refAnimationInstance.current.reset();
    // }, [intervalId]);

    useEffect(() => {
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalId]);

    ////////////////////////////////

    useEffect(() => {
        setlike(liked);

        // if (show_confetti) {
        //     startAnimation();
        // }
        if (show_confetti) {
            setstartAnim(true);
        }
        if (play_audio) {
            audioRef.current.play();
            audioRef.current.volume = 0.1;
        }
        setTimeout(() => {
            // pauseAnimation();
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            // stopAnimation();
            setShowFeedback(1);
            setstartAnim(false);
        }, 5000); //miliseconds


        if (leaderboard_logo || leaderboard_title || leaderboard_description) {
            setcustomLeaderboard(true);
        } else {
            setcustomLeaderboard(false);
        }

        var save_analytics = {
            display_id: displayId,
            watch_id: save_analytics_response[0] ? save_analytics_response[0].watch_id : "",
            status: "Complete",
            end: "1",
            nickname: nickname,
            step: "EndPage",
            // country: location[0] ? location[0].address?.country : "",
            // city: location[0] ? location[0].address?.state_district : "",
            // location: location[0] ? location[0].display_name : ""
        }
        dispatch(saveAnalyticsAsync(save_analytics));


        // console.log("save_response", save_response);
        if (slack_integration?.access_key) {
            var blocks = [{
                "type": "header",
                "text": {
                    "type": "plain_text",
                    "text": `Hey, User ${nickname} has taken the ${duonutName} duonut.`
                }
            }];

            for (let i = 0; i < save_response.length; i++) {
                blocks.push(
                    {
                        "type": "divider"
                    },
                    {
                        "type": "section",
                        "text": {
                            "type": "mrkdwn",
                            "text": `*Step*: ${save_response[i]?.step}\n\n*Question*: ${save_response[i]?.question}\n\n*Answer*: ${save_response[i]?.answered_text}`
                        }
                        // "fields": [
                        //     // {
                        //     //     "type": "mrkdwn",
                        //     //     "text": `*Type* :- ${save_response[i]?.type}`
                        //     // },
                        //     {
                        //         "type": "mrkdwn",
                        //         "text": `*Step* :- ${save_response[i]?.step}`
                        //     },
                        //     {
                        //         "type": "mrkdwn",
                        //         "text": `*Question* :- ${save_response[i]?.question}`
                        //     },
                        //     {
                        //         "type": "mrkdwn",
                        //         "text": `*Answer* :- ${save_response[i]?.answered_text}`
                        //     }
                        // ]
                    }
                )
            }

            // console.log("blocks", blocks);
            const payload = {
                token: slack_integration.access_key,
                channel: slack_integration.channel_id,
                // text: textdata,
                blocks: JSON.stringify(blocks),
            }
            axios({
                method: "POST",
                url: "https://slack.com/api/chat.postMessage",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: new URLSearchParams(payload),
            }).then((res) => {
                // console.log(res);
            }).catch((e) => {
                console.log(e.message, e);
            });
        }
        if (hubspot_integration?.access_key) {
            var contactData = [];
            hubspot_integration.contact_data?.map((item, i) => {
                var cData = { value: save_form_data[item.value] ? save_form_data[item.value] : "", property: item.property };
                contactData.push(cData);

            })
            // console.log("contactData", contactData, save_form_data, hubspot_integration.contact_data);
            const payload = {
                access_token: hubspot_integration.access_key,
                refresh_token: hubspot_integration.refresh_key,
                contact_data: contactData,
                duonut_id: duonutId,
            }
            axios({
                method: "POST",
                url: `${domain}/duonut/hubspot_crm_api`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(payload),
            }).then((res) => {
                // console.log(res);
            }).catch((e) => {
                console.log(e.message, e);
            });
        }
        if (gmail_integration?.access_key) {

            if (gmail_integration?.trigger === "Form Completed") {

                var email_id = gmail_integration?.to?.replace(/{{(.*?)}}/g, (match, captured) => {
                    const lowerCaptured = captured.toLowerCase();
                    // Convert keys in save_form_data to lowercase for case-insensitive check
                    const lowerSaveFormData = Object.fromEntries(
                        Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                    );
                    if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                        return lowerSaveFormData[lowerCaptured];
                    } else {
                        return match; // if no replacement found, keep the original placeholder
                    }
                });
                var subject = gmail_integration?.subject?.replace(/{{(.*?)}}/g, (match, captured) => {
                    const lowerCaptured = captured.toLowerCase();
                    // Convert keys in save_form_data to lowercase for case-insensitive check
                    const lowerSaveFormData = Object.fromEntries(
                        Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                    );
                    if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                        return lowerSaveFormData[lowerCaptured];
                    } else {
                        return match; // if no replacement found, keep the original placeholder
                    }
                });
                var message = gmail_integration?.message?.replace(/{{(.*?)}}/g, (match, captured) => {
                    const lowerCaptured = captured.toLowerCase();
                    // Convert keys in save_form_data to lowercase for case-insensitive check
                    const lowerSaveFormData = Object.fromEntries(
                        Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                    );
                    if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                        return lowerSaveFormData[lowerCaptured];
                    } else {
                        return match; // if no replacement found, keep the original placeholder
                    }
                });
                // console.log("data", email_id, subject, message);

                sendEmail(gmail_integration?.access_key, gmail_integration?.refresh_key, email_id, subject, message);
            }
        }
        if (scheduled_workflow?.access_key) {

            if (scheduled_workflow?.trigger === "Form Completed") {
                var email_id = scheduled_workflow?.to?.replace(/{{(.*?)}}/g, (match, captured) => {
                    const lowerCaptured = captured.toLowerCase();
                    // Convert keys in save_form_data to lowercase for case-insensitive check
                    const lowerSaveFormData = Object.fromEntries(
                        Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                    );
                    if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                        return lowerSaveFormData[lowerCaptured];
                    } else {
                        return match; // if no replacement found, keep the original placeholder
                    }
                });
                var subject = scheduled_workflow?.subject?.replace(/{{(.*?)}}/g, (match, captured) => {
                    const lowerCaptured = captured.toLowerCase();
                    // Convert keys in save_form_data to lowercase for case-insensitive check
                    const lowerSaveFormData = Object.fromEntries(
                        Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                    );
                    if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                        return lowerSaveFormData[lowerCaptured];
                    } else {
                        return match; // if no replacement found, keep the original placeholder
                    }
                });
                var message = scheduled_workflow?.message?.replace(/{{(.*?)}}/g, (match, captured) => {
                    const lowerCaptured = captured.toLowerCase();
                    // Convert keys in save_form_data to lowercase for case-insensitive check
                    const lowerSaveFormData = Object.fromEntries(
                        Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                    );
                    if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                        return lowerSaveFormData[lowerCaptured];
                    } else {
                        return match; // if no replacement found, keep the original placeholder
                    }
                });
                console.log("data", email_id, subject, message);

                // sendEmail(scheduled_workflow?.access_key, scheduled_workflow?.refresh_key, email_id, subject, message);
                const data = {
                    name: scheduled_workflow?.name,
                    start_date: scheduled_workflow?.start_date,
                    run: scheduled_workflow?.run,
                    action: {
                        "to": `${email_id}`,
                        "message": `${message}`,
                        "subject": `${subject}`,
                        "access_key": `${scheduled_workflow?.access_key}`,
                        "refresh_key": `${scheduled_workflow?.refresh_key}`
                    },
                };
                fetch(`${domain}/duonut/scheduled_email`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                }).then((res) => {
                    console.log(res);

                }).catch((e) => {
                    console.log(e.message, e);
                });
            }
        }


        if (!show_endpage) {
            if (redirect_url && gmail_integration?.trigger !== "Form Completed") {
                setTimeout(() => {
                    window.location = redirect_url;
                }, 1000); //miliseconds
            }
        }

        // eslint-disable-next-line
    }, []);

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post(
                'https://oauth2.googleapis.com/token',
                new URLSearchParams({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET, // Replace with your client secret
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                })
            );
            // console.log(response.data)
            return response.data.access_token;
        } catch (error) {
            console.error('Error refreshing access token', error);
            throw error;
        }
    };

    const sendEmail = async (accessToken, refreshToken, email_id, subject, message) => {
        console.log("data", accessToken, email_id, subject, message);

        const email = [
            `To: ${email_id}`,
            `Subject: ${subject}`,
            '',
            `${message}`,
        ].join('\n');

        const base64EncodedEmail = btoa(unescape(encodeURIComponent(email)));

        try {
            await axios.post(
                'https://www.googleapis.com/gmail/v1/users/me/messages/send',
                {
                    raw: base64EncodedEmail,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Email sent successfully');
            if (!show_endpage) {
                if (redirect_url) {
                    setTimeout(() => {
                        window.location = redirect_url;
                    }, 1000); //miliseconds
                }
            }

        } catch (error) {
            console.error('Error sending email', error);
            if (error.response && error.response.status === 401) { // Access token expired
                try {
                    const newAccessToken = await refreshAccessToken(refreshToken);
                    await sendEmail(newAccessToken, refreshToken, email_id, subject, message);
                } catch (refreshError) {
                    console.error('Error refreshing access token', refreshError);
                }
            } else {
                console.error('Error sending email', error);
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            var appId = localStorage.getItem("appId");
            // console.log(appId)
            if (referral && isLoggedIn && appId) {
                setreferralLink(`${frontend_url}/duonut/${displayId}?refl=${appId}${duonutId}`);
            }
        }, 2000); //miliseconds
        // eslint-disable-next-line
    }, [isLoggedIn]);

    const bgAudio = () => {
        setAudioStatus(true);
    }

    // useEffect(() => {
    //     if (audioStatus) {
    //         toggle();
    //         setAudioStatus(false);
    //         // console.log("playing", audioStatus)
    //     }
    //     // eslint-disable-next-line
    // }, [audioStatus]);

    useEffect(() => {
        setIsInIframe(window.self !== window.top);
    }, []);

    const likebtn = () => {
        // if (!isLoggedIn) {
        //     handleClick();
        //     dispatch(showSignInForm());
        // } else {
        //     var like_data = {
        //         display_id: displayId,
        //         likes: like ? 0 : 1
        //     }
        //     dispatch(saveLikeAsync(like_data));
        //     setlike(!like);
        // }
    }

    const showReferral = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
        }
    }

    const createDuonut = () => {
        window.open('/create');
        // window.location.reload();
    }
    const NextQuiz = () => {
        // navigate('/duonut/' + next_package_id);
        // window.location.reload();
    }
    const CustomBtn = () => {
        let url
        const regex = new RegExp("^[0-9]{10}$");
        const regex1 = new RegExp("^\\+91[0-9]{10}$");
        if (regex.test(custom_button_url) || regex1.test(custom_button_url)) {
            window.open("tel:" + custom_button_url);
        } else if (custom_button_url.toString().includes("http")) {
            url = custom_button_url;
            window.open(url);
        } else {
            url = 'https://' + custom_button_url;
            window.open(url);
        }

        var save_analytics = {
            display_id: displayId,
            watch_id: save_analytics_response[0] ? save_analytics_response[0].watch_id : "",
            end: "1",
            nickname: nickname,
            step: "EndPage"
            // country: location[0] ? location[0].address?.country : "",
            // city: location[0] ? location[0].address?.state_district : "",
            // location: location[0] ? location[0].display_name : ""
        }
        dispatch(saveAnalyticsAsync(save_analytics));
    }
    const onclickembedcode = () => {
        ref.current.click();
    }
    const continuebtn = () => {
        ref1.current.click();
        setiscopy(false);
    }

    const codeCopybtn = () => {
        var copyText = coupon_code
        // console.log(copyText)
        navigator.clipboard.writeText(copyText);
        setiscopyCode(true);
    }

    const referralCodeCopybtn = () => {
        var copyText = referralLink
        // console.log(copyText)
        navigator.clipboard.writeText(copyText);
        setiscopyReferralCode(true);
    }

    const copycode = () => {
        var copyText = document.getElementById("iframearea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setiscopy(true);
    }

    const shareLink = `${frontend_url}/duonut/${displayId}`;

    const iframe = `<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; min-height: 80vh;" title="Duonut Iframe"></iframe>`;

    return (
        <div style={(!show_endpage && redirect_url) ? { display: "none" } : {}}>
            <button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content LB1modalMain" style={{ backgroundColor: "white", color: "black" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className='LB1shareTitle'> Share with your Friends</div>
                            {/* <div className='LB1sharemsg'>YOU CAN SHARE THIS QUIZ TO YOUR FAMILY OR FRIENDS.</div> */}
                            <textarea id='iframearea' rows="4" key={iframe}
                                style={{ width: "100%", padding: "5px", margin: "1vh auto", fontSize: "14px", height: "132px", display: "block" }}>
                                {iframe}
                            </textarea>
                            <button className='SL1Continuebtn' onClick={() => copycode()}>{iscopy ? "Copied" : "Copy"}</button>
                            <button className='SL1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='leaderboardPage' style={
                show_endpage
                    ? { backgroundColor: leaderboard_bgcolor }
                    : { backgroundImage: `url(${background})` }}>

                {/* {showFeedback === 1 && show_feedback && <FeedBackModel nickname={nickname} displayId={displayId} />} */}

                <div className="ledearboardInner" style={isInIframe ? { minHeight: "100vh" } : {}}>
                    <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
                    {startAnim && <div style={source}>
                        <ConfettiExplosion {...bigExplodeProps} />
                    </div>}
                    <audio
                        src={leaderboardaudio}
                        ref={audioRef}
                    ></audio>
                    <div className="ledearboardInner1">
                        {/* {isAudio && <div className='SL1icons'>
                            {playing ? <VolumeUpIcon className='icon SL1icon' onClick={() => bgAudio()} />
                                : <VolumeMuteIcon className='icon SL1icon' onClick={() => bgAudio()} />}
                        </div>} */}

                        {leaderboard_logo && show_endpage ? <div className='SL1Img'>
                            <img className='SL1showImg' src={leaderboard_logo} alt="" />
                        </div> : <div></div>}
                        {leaderboard_title && show_endpage ? <div className='SL1title' style={{ color: leaderboard_fontcolor }}>
                            {leaderboard_title.replace(/{{(.*?)}}/g, (match, captured) => {
                                const lowerCaptured = captured.toLowerCase();
                                // Convert keys in save_form_data to lowercase for case-insensitive check
                                const lowerSaveFormData = Object.fromEntries(
                                    Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                                );
                                if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                                    return lowerSaveFormData[lowerCaptured];
                                }
                                //  else if (queryParamsObject.hasOwnProperty(captured)) {
                                //     return queryParamsObject[captured];
                                // }
                                else {
                                    return match; // if no replacement found, keep the original placeholder
                                }
                            })}
                        </div>
                            : <div style={{ display: "none" }}></div>}
                        {!show_endpage ? <div className='SL1title' style={{ color: "white" }}>Thank you</div>
                            : <div style={{ display: "none" }}></div>}
                        {leaderboard_description && show_endpage ?
                            <div className='SL1description' style={{ color: leaderboard_fontcolor }}>{leaderboard_description}</div>
                            : <div style={{ display: "none" }}></div>}

                        {coupon_code && show_endpage ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "2vh 2vh 2vh 3.5rem" }}>
                                <div className='SL1couponcode' style={{ backgroundColor: leaderboard_fontcolor, color: leaderboard_bgcolor }}> {coupon_code}</div>
                                <span style={{ marginLeft: "1rem", cursor: "pointer", fontSize: "0.8rem", color: leaderboard_fontcolor }} onClick={() => codeCopybtn()}>{iscopyCode ? "Copied" : "Copy"}</span>
                            </div>
                            : <div style={{ display: "none" }}></div>}

                        {referral && !isLoggedIn && <button className='SL1button' onClick={() => showReferral()}>Get Referral Link</button>}
                        {referral && isLoggedIn && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "2vh 2vh 2vh 3.5rem" }}>
                            <div className='SL1couponcode'>
                                <div>Referral Link - </div>
                                {referralLink}
                            </div>
                            <span style={{ marginLeft: "1rem", cursor: "pointer", fontSize: "0.9rem" }} onClick={() => referralCodeCopybtn()}>{iscopyReferralCode ? "Copied" : "Copy"}</span>
                        </div>}

                        <div className="SL1buttons">
                            {show_like_button && <button className='SL1button' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }} onClick={() => likebtn()}>
                                <i className="fa fa-thumbs-o-up" aria-hidden="true" style={{ fontSize: "2.4vh", paddingRight: "5px" }}></i>
                                {like && isLoggedIn ? "Liked" : "Like"} </button>}
                            {show_create_button && <button className='SL1button' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }} onClick={() => createDuonut()}> Create Duonut </button>}
                            {show_next_duonut_button && <button className='SL1button' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }} onClick={() => NextQuiz()}> Next Duonut </button>}
                            {show_custom_button && show_endpage && <button className='SL1button' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }} onClick={() => CustomBtn()}>{custom_button_message} </button>}
                        </div>

                        {show_share_button && <div className="SL1circle_buttons">
                            <label className='SL1iconCircle' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }} onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${frontend_url}/duonut/${displayId}?page=fb`)}>
                                <i className="fa fa-facebook" aria-hidden="false"></i>
                            </label>
                            <label className='SL1iconCircle' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }} onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${frontend_url}/duonut/${displayId}?page=ld`)}>
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </label>
                            <label className='SL1iconCircle' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }} onClick={() => window.open(`https://twitter.com/share?text=${shareLink}`)} >
                                <svg width="16" height="16" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.8574 11.606L25.0721 1.125H22.8888L14.8841 10.2236L8.49534 1.125H1.125L10.7882 14.885L1.125 25.875H3.30831L11.7564 16.2643L18.5047 25.875H25.875L15.8574 11.606ZM12.8661 15.0058L11.8855 13.6347L4.09566 2.73556H7.44967L13.7382 11.5348L14.7146 12.9058L22.8878 24.3429H19.5338L12.8661 15.0058Z" fill={leaderboard_fontcolor} />
                                </svg>
                            </label>
                            <label className='SL1iconCircle' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }} onClick={() => window.open(`https://api.whatsapp.com/send/?text=${shareLink}`)}>
                                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                            </label>
                            <label className='SL1iconCircle' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }} onClick={() => onclickembedcode()}>
                                <i className="fa fa-link" aria-hidden="true"></i>
                            </label>
                        </div>}

                    </div>
                    {/* {show_recommendation && <ActivityRecommendation background={"#0B1350"} border={"#2c3366"} />} */}
                </div>
            </div>
        </div>
    );
}