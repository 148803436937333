import React, { useState, useEffect } from 'react';
import './Element.css';
// import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements,   updateGoToSlide, } from '../../redux/slices/userActivity';
import {  useLocation, } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import { showduonutData } from '../../redux/slices/DuonutReducer';
import imgPreview from '../../assets/Images/image-preview.png';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import axios from "axios";
import { domain } from "../../services/constants";

const McqImage = ({
    id,
    elementAttr,
    elementHoverAttr,
    conditions,
    setShowModel,
    setCorrectAnswer,
    language,
    slideId,
}) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity,  selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name, viewer_url_params } = useSelector((state) => state.duonut);
    const { text, options, correctAnswer, fontFamily, fontSize, fontWeight, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, margin, padding, borderRadius, color, backgroundColor, border, gap,
        labelFontSize, labelFontWeight, labelColor, optionsImage, imageWidth, imagePadding, fieldPosition
    } = elementAttr;

    const { width } = useWindowDimensions();
    const { viewType } = useSelector((state) => state.duonutSettings);
    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];
    // console.log(duonutData)

    const [hover, setHover] = useState(-1);
    const [inputText, setInputText] = useState("");
    const [optionsText, setoptionsText] = useState([]);

    useEffect(() => {
        if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
            fetchText(text);
            fetchOptionsText(options);
        } else {
            setInputText(text);
            setoptionsText(options);
        }
    }, [text, options]);

    const fetchOptionsText = async (texts) => {
        const translatedTexts = []; // Initialize an array to hold translated texts
        const delimiter = "|;|"; // Delimiter to separate texts

        // Combine all texts into a single string separated by semicolons
        const textToTranslate = texts.join(delimiter);

        // Translate the combined text
        const translated = await translateText(textToTranslate, language);

        // Split the translated text back into individual parts using the semicolon delimiter
        const translatedArray = translated.split(delimiter);

        // Push the translated texts into the array
        translatedTexts.push(...translatedArray); // Spread the array into the translatedTexts

        setoptionsText(translatedTexts); // Update the state with the translated texts
    };
    const fetchText = async (texts) => {
        const t_text = await translateText(texts, language);
        setInputText(t_text);
    };
    const translateText = async (texts, targetLanguage) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${domain}/duonut/language_translator`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          source: "en",
          target: targetLanguage,
          query: texts,
        },
      });

      if (response.status === 200) {
        // console.log("Translation:", response);
        return response?.data.translation; // return the translated text
      } else {
        // Handle unsuccessful status codes
        return "Error: Translation failed. Please try again.";
      }
    } catch (error) {
      console.error('Error in translation API:', error);
      return "Error translating text. Please try again."; // Handle API errors gracefully
    }
  };

    const submitResponse = (item) => {
        if (location.pathname.includes("/duonut/")) {

            const originalIndex = options.indexOf(item);
            document.getElementById(`mcqoption-${originalIndex}`).style.border = '1px solid blue'

            var correct = item === options[correctAnswer - 1]
            if (correct) {
                setShowModel(1);
            } else {
                setShowModel(2);
                setCorrectAnswer(options[correctAnswer - 1]);
            }
            // console.log("ans", item, correct)

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: correct ? 1 : 0,
                question: text ? text : "",
                answered_text: item,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "McqImage",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                url_params: viewer_url_params,
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => {
                const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                formData.append(key, value);
            });

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                type: "McqImage",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: item,
            }
            dispatch(saveQuizResponse(save_response));

            var nextPage = selectedSlide + 1;
            duonutData?.user_activity[selectedSlide]?.condition?.map(node => {
                if (node?.condition?.some(condition => condition.toLowerCase() === (item).toLowerCase()) || node?.always) {
                    nextPage = node.target - 1;
                }
            })

            setTimeout(() => {
                setShowModel(0);
                dispatch(updateGoToSlide(nextPage));
            }, 3100);

        }
    }

    const handelCorrectAnswer = (item) => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    correctAnswer: item,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    }

    return (
        <> <div style={{
            display: "flex", flexDirection: "column", gap: gap
        }}>
            <div
                style={{
                    width: "100%", paddingBottom: '10px',
                    color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                    textAlign, lineHeight, letterSpacing,
                }}>{inputText}</div>

            <div style={{ display: "flex", justifyContent: textAlign, alignItems: 'center', flexDirection: (viewType === 3 || width < 800) ? "column" : fieldPosition }}>
                {options.map((item, i) => {
                    return <div id={`mcqoption-${i}`}
                        style={{
                            display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight,
                        }}>
                        {!(location.pathname).includes("/duonut/") && <input type="radio" id={"correct_answer" + i} name="correct_answer"
                            checked={correctAnswer === i + 1 ? true : false}
                            style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px", accentColor: "green" }}
                            onChange={() => handelCorrectAnswer(i + 1)} />}
                        <label for={"correct_answer" + i}
                            style={{
                                backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                                border: hover === i ? elementHoverAttr?.border : border ? border : "0",
                                color: hover === i ? elementHoverAttr?.color : color,
                                borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                                textTransform, textAlign, letterSpacing, padding, margin,
                                cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                                width: "100%", height: "100%"
                            }} key={i}
                            onMouseEnter={() => setHover(i)}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => { submitResponse(item) }}
                        >
                            <img src={optionsImage[i] ? optionsImage[i] : imgPreview} style={{
                                width: imageWidth, height: imageWidth, objectFit: "cover",
                                padding: imagePadding
                            }} alt="" />
                            <div style={{}}>{optionsText[i]}</div>
                        </label>
                    </div>
                })}
            </div>
        </div>
        </>
    );
}

export default McqImage;
