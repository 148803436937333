import React, { useState, useEffect } from 'react';
import './Element.css';
import "react-edit-text/dist/index.css";
import { useSelector } from "react-redux";
// import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { useLocation, } from "react-router-dom";
import { showduonutData } from '../../redux/slices/DuonutReducer';
import axios from "axios";
import { domain } from "../../services/constants";

const FlipCard = ({
    id,
    elementAttr,
    elementHoverAttr,
    // conditions,
    language,
    slideId,
}) => {
    // const dispatch = useDispatch();
    const { selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const { text, fontFamily, fontSize, fontWeight, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, margin, padding, borderRadius, color, backgroundColor, border, gap,
        labelFontSize, labelFontWeight, labelColor, f_text, b_text, f_image, b_image
    } = elementAttr;

    // const { viewType } = useSelector((state) => state.duonutSettings);
    const duonutDataMain = useSelector(showduonutData);
    // const duonutData = duonutDataMain[0];

    const [side, setSide] = useState(false);
    const [inputText, setInputText] = useState("");
    const [f_textText, setf_textText] = useState("");
    const [b_textText, setb_textText] = useState("");

    useEffect(() => {
        if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
            fetchText(text);
            fetchf_textText(f_text);
            fetchb_textText(b_text);
        } else {
            setInputText(text);
            setf_textText(f_text);
            setb_textText(b_text);
        }

    }, [text, f_text, b_text]);

    const fetchText = async (texts) => {
        const t_text = await translateText(texts, language);
        setInputText(t_text);
    };
    const fetchf_textText = async (texts) => {
        const t_text = await translateText(texts, language);
        setf_textText(t_text);
    };
    const fetchb_textText = async (texts) => {
        const t_text = await translateText(texts, language);
        setb_textText(t_text);
    };

    const translateText = async (texts, targetLanguage) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${domain}/duonut/language_translator`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    source: "en",
                    target: targetLanguage,
                    query: texts,
                },
            });

            if (response.status === 200) {
                // console.log("Translation:", response);
                return response?.data.translation; // return the translated text
            } else {
                // Handle unsuccessful status codes
                return "Error: Translation failed. Please try again.";
            }
        } catch (error) {
            console.error('Error in translation API:', error);
            return "Error translating text. Please try again."; // Handle API errors gracefully
        }
    };

    function handleClick() {
        // console.log("clicked!");
        setSide(!side);
        // show = false;
        // console.log(side);
    }

    return (<div style={{
        borderRadius, display: "flex", flexDirection: "column", alignItems: 'center', gap: gap
    }}>
        <div
            style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing,
            }}>{inputText}</div>

        <div className={`FC5card ${side ? "FC5side" : ""}`}
            style={{
                backgroundColor: side ? elementHoverAttr?.backgroundColor : backgroundColor,
                border: side ? elementHoverAttr?.border : border ? border : "0",
                color: side ? elementHoverAttr?.color : color,
                borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                textTransform, textAlign, letterSpacing, padding, margin,
                cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
            }}
            onClick={handleClick}>
            <div className="FC5front">
                {f_image ? <div className="FC5cardImg">
                    <img className='FC5cardImgShow' style={{ borderRadius }} key={f_image} src={f_image} alt="" />
                </div>
                    : <div style={{ padding: "10px", whiteSpace: "pre-wrap" }}>{f_textText}</div>}
            </div>
            <div className="FC5back">
                {b_image ? <div className="FC5cardImg">
                    <img className='FC5cardImgShow' style={{ borderRadius }} key={b_image} src={b_image} alt="" />
                </div>
                    : <div style={{ padding: "10px", whiteSpace: "pre-wrap" }}>{b_textText}</div>}
            </div>

            <div className={!side ? "FC5msg" : "FC5msgnone"} style={{
                color: color, borderRadius,
                backgroundColor: side ? elementHoverAttr?.backgroundColor : backgroundColor
            }}>{language === "hi" ? "पलटने के लिए क्लिक करें" :
                language === "es" ? "Haga clic para voltear" :
                    language === "ja" ? "クリックして反転" :
                        language === "ar" ? "انقر للقلب" :
                            language === "fr" ? "Cliquez pour retourner" :
                                language === "nl" ? "Klik om te spiegelen" :
                                    language === "pt" ? "Clique para virar" : "Click to flip"}</div>

        </div>
    </div>
    );
}

export default FlipCard;
