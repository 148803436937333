import React, {  useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./AiSettingNew.css";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { toggleAiNewModal, updateAiNewData } from "../../../redux/slices/AiSettingNewReducer";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import { frontendUrl } from "../../../services/constants";
import QRCode from "react-qr-code";
// import ProlificPanel from "../Panel/ProlificPanel";
import RespondentPanel from "../Panel/RespondentPanel";
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { editAiDuonutNewAsync } from "../../../redux/slices/AiDuonutNewReducer";
import { saveAiNewAsync } from "../../../redux/slices/AiNewReducer";
import { creditDeduction } from "../../../services/duonut";
import { AnimatePresence, motion } from 'framer-motion';
import { getUserDetails } from "../../../services/auth";
import { updateCredit, updateTotalCredit } from "../../../redux/slices/user";

export default function AiSettingNew() {
    const { active: aiActive, aiData } = useSelector((state) => state.AiSettingNew.aiNewModalSetting);
    const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
    const { credit } = useSelector((state) => state.user);

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    var userEmail = localStorage.getItem("userEmail");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    // console.log("aiData", aiData);
    const dispatch = useDispatch();
    // const { width } = useWindowDimensions();
    const creditref = useRef(null);
    const creditref1 = useRef(null);
    const modelRef = useRef(null);
    const qrref = useRef(null);
    const qrref1 = useRef(null);
    const panelref = useRef(null);
    const panelref1 = useRef(null);
    const [iscopylink, setiscopylink] = useState(false);
    const [iscopylink1, setiscopylink1] = useState(false);
    const [iscopyiframe, setiscopyiframe] = useState(false);
    const [iscopyhtml, setiscopyhtml] = useState(false);
    const [openPreview, setopenPreview] = useState(false);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [panelType, setpanelType] = useState(2);
    const [openSettings, setIsOpenSettings] = useState("");
    const [conversationLimit, setconversationLimit] = useState(2);
    const [totalUserResponse, settotalUserResponse] = useState(10);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [error_message, setErrorMessage] = useState("");

    const [eventNo, seteventNo] = useState(0);

    useEffect(() => {
        const storedEmail = localStorage.getItem("userEmail");
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []);

    useEffect(() => {
        // console.log("C-D", aiData);
        if (aiData) {
            setconversationLimit(aiData?.conversation_depth);
            settotalUserResponse(aiData?.total_user_response);
        }
    }, [aiData]);

    const handleClose = () => {
        dispatch(toggleAiNewModal(false));
        dispatch(updateAiNewData({}));
        setiscopylink(false);
        setiscopyiframe(false);
        setiscopyhtml(false);
        setopenPreview(false);
    }
    useOutsideAlerter(modelRef, handleClose);

    const copylink = () => {
        var copyText = `${frontend_url}/survey/${aiData?.display_id}`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopylink(true);
    }
    const copylink1 = () => {
        var copyText = `${frontend_url}/survey/${aiData?.display_id}`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopylink1(true);
    }

    const copyiframe = () => {
        var copyText = `<iframe
        src="${frontend_url}/survey/embed/${aiData?.display_id}"
        title="Survey"
        frameborder="0"
        scrolling="no"
        style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:400px;width:500px;position:absolute;left:0;top:0">
            <p>Your browser does not support iframes.</p>
        </iframe>`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopyiframe(true);
    }



    const copyHtml = () => {
        var copyText = `
        <html dir="ltr" lang="en">
        <head>
            <meta content="text/html; charset=UTF-8" http-equiv="Content-Type"/>
            <meta name="x-apple-disable-message-reformatting"/>
            <style>
                @font-face {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                mso-font-alt: 'Verdana';
                src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
                }
                * {
                font-family: 'Roboto', Verdana;
                }
            </style>
        </head>
        <table align="center" width="100%" class="p-0.5/2" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="font-family:Roobert, Inter, ui-sans-serif, system-ui, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;;margin:auto;width:fit-content;border-radius:0.5rem;background-color:rgb(243,244,246)">
            <tbody>
                <tr>
                    <td>
                    <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="margin:0.25rem;width:fit-content;border-radius:0.5rem;background-color:rgb(255,255,255);padding-bottom:1.25rem;padding-left:0.125rem;padding-right:0.125rem;padding-top:1.25rem">
                        <tbody>
                            <tr>
                                <td>
                                <p style="font-size:1.25rem;line-height:1.75rem;margin:0px;margin-bottom:2rem;text-align:center;font-weight:600">${aiData?.question}</p>
                                <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="text-align:center;height:3rem;">
                                    <tbody>
                                        <tr>
                                            <td>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=1" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                                
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">1</span>
                                               
                                            </a>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=2" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                              
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">2</span>
                                               
                                            </a>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=3" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                               
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">3</span>
                                              
                                            </a>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=4" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                               
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">4</span>
                                               
                                            </a>
                                            <a href="${frontend_url}/survey/${aiData?.display_id}?rating=5" rel="noopener noreferrer" style="line-height:100%;text-decoration:none;max-width:100%;margin:0.25rem;cursor:pointer;user-select:none;border-radius:0.75rem;border-width:1px;border-style:solid;border-color:rgb(229,231,235);color:rgb(0,0,0);text-decoration-line:none;box-shadow:0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0,0,0,0.05);padding:12px 15px" target="__blank">
                                              
                                                <span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:0">5</span>
                                              
                                            </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                 <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="max-width:240px;width:100%">
                                    <tbody style="width:100%">
                                        <tr style="width:100%">
                                            <td data-id="__react-email-column" style="text-align:left;font-size:0.75rem;line-height:1rem;text-transform:uppercase">Negative</td>
                                            <td data-id="__react-email-column" style="text-align:right;font-size:0.75rem;line-height:1rem;text-transform:uppercase">Positive</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="margin-top:2rem">
                                    <tbody style="width:100%">
                                        <tr style="width:100%">
                                            <td data-id="__react-email-column">
                                            <p style="font-size:0.75rem;line-height:1rem;margin:0px;text-align:center;color:rgb(156,163,175)">Powered by</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                                    <tbody style="width:100%">
                                        <tr style="width:100%">
                                            <td data-id="__react-email-column" style="text-align:center"><img alt="Duonut Logo" src="https://app.duonut.com/assets/duonut_logo.png" style="margin-left:auto;margin-right:auto;height:auto;width:8rem"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                                    <tbody style="width:100%">
                                        <tr style="width:100%">
                                            <td data-id="__react-email-column">
                                            <p style="font-size:0.75rem;line-height:1rem;margin:0px;text-align:center;color:rgb(156,163,175)">Sign up <a href="https://duonut.com" style="color:inherit;text-decoration:none;user-select:none;text-decoration-line:underline" target="_blank">here</a> to send your first survey.</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </td>
                </tr>
            </tbody>
        </table>
        </html>
        `;
        navigator.clipboard.writeText(copyText);
        setiscopyhtml(true);
    }

    const continueBtn = () => {
        qrref1.current.click();
    }

    const downloadbtn = () => {
        // Get the SVG element
        const svg = document.getElementById("qr-gen");

        // Check if SVG element exists and aiData.display_id is defined
        if (!svg) {
            console.error("SVG element is missing");
            return;
        }

        // Create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas size based on SVG's bounding box
        const svgSize = svg.getBoundingClientRect();
        canvas.width = svgSize.width;
        canvas.height = svgSize.height;

        // Serialize SVG to a string and create a Blob
        const svgData = new XMLSerializer().serializeToString(svg);
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(svgBlob);

        // Create a new image and draw the SVG onto the canvas
        const img = new Image();
        img.onload = () => {
            // Draw the SVG onto the canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);  // Use canvas dimensions
            URL.revokeObjectURL(url);  // Clean up URL

            // Convert canvas to data URL and trigger download
            const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            const downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `qrcode.png`;  // Ensure filename is set correctly
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        img.onerror = (err) => {
            console.error("Error generating PNG:", err);
        };

        img.src = url;
    }

    const updateConversationLimit = (value) => {
        if (parseInt(value) >= 2) {
            var aiDuonutData = {
                id: aiData.id,
                conversation_depth: parseInt(value),
            }
            dispatch(editAiDuonutNewAsync(aiDuonutData)).then((res) => {
                // console.log(res);
                if (res.status === 201) {
                    dispatch(saveAiNewAsync());
                }

            }).catch((e) => {
                console.log(e.message, e);
            });
        }

    }

    const updateNoOfUserViews = (value) => {
        if (parseInt(value) >= 10) {
            var aiDuonutData = {
                id: aiData.id,
                total_user_response: parseInt(value),
            }
            dispatch(editAiDuonutNewAsync(aiDuonutData)).then((res) => {
                // console.log(res);
                if (res.status === 201) {
                    dispatch(saveAiNewAsync());
                }

            }).catch((e) => {
                console.log(e.message, e);
            });
        }

    }
    const [isEditingConversation, setIsEditingConversation] = useState(false);
    const [isEditingUserViews, setIsEditingUserViews] = useState(false);

    var costConversationLimit = 0;
    var perConversationCost = 2;
    var costUserResponse = 0;
    var perUserCreditCost = 2;

    const newLimitChange = conversationLimit - aiData?.conversation_depth;
    // console.log("New Limit", newLimitChange, conversationLimit, aiData?.conversation_depth);
    if (newLimitChange < 0) { costConversationLimit = 0; }
    else {
        const applicableIncrements_conversation = Math.ceil((newLimitChange) / 2);
        costConversationLimit = perConversationCost * applicableIncrements_conversation;
    }

    const saveConversationLimit = () => {
        // console.log(costConversationLimit, credit, aiData, aiData?.websiteData, aiData?.websiteData?.company_name);
        if (credit >= costConversationLimit) {
            setIsEditingConversation(false);
            updateConversationLimit(conversationLimit);

            var name = aiData.aiData?.websiteData?.company_name ? aiData.aiData?.websiteData?.company_name : aiData.aiData?.title;

            var creditData = {
                name: `AI scan - Conversation Depth - ${name}`,
                message: `Conversation Depth : ${name} - ${aiData?.display_id}`,
                cost: costConversationLimit,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
            });
            if (costConversationLimit === 0) {
                setMessage('No Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);

            }
            getUserDetails((err, userDetails) => {
                if (err) return;
                dispatch(updateTotalCredit(userDetails.data.total_credit));
                dispatch(updateCredit(userDetails.data.credit));

            });
        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    };

    const newUserResponses = totalUserResponse - aiData?.total_user_response;
    if (newUserResponses < 0) { costUserResponse = 0; }
    else {
        const applicableIncrements_userResponse = Math.ceil((newUserResponses) / 2);
        costUserResponse = perUserCreditCost * applicableIncrements_userResponse;
    }
    const saveUserViews = () => {
        if (credit >= costUserResponse) {
            setIsEditingUserViews(false);
            updateNoOfUserViews(totalUserResponse);
            var name = aiData.aiData?.websiteData?.company_name ? aiData.aiData?.websiteData?.company_name : aiData.aiData?.title;
            var creditData = {
                name: `AI scan - Number of User Views - ${name}`,
                message: `Number of User Views : ${name} - ${aiData?.display_id}`,
                cost: costUserResponse,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
            });
            if (costUserResponse === 0) {
                setMessage('No Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);

            }
            getUserDetails((err, userDetails) => {
                if (err) return;
                dispatch(updateTotalCredit(userDetails.data.total_credit));
                dispatch(updateCredit(userDetails.data.credit));

            });
        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    };

    const creditDeduct = () => {
        if (eventNo === 8) {
            saveConversationLimit();
        } else if (eventNo === 9) {
            saveUserViews();
        }
        creditref1.current.click();
    };

    return (
        <>
            <div style={aiActive ? { backgroundColor: "rgba(30, 29, 29, 0.42)", position: "fixed", top: "0", left: "0", width: "100%", height: "100%" } : {}}>
                <div ref={modelRef} className={aiActive ? "ASN1collaps-sidebar-open" : "ASN1collaps-sidebar-close"}>
                    <button ref={qrref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#qrModal">
                    </button>
                    <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="qrModal" tabIndex="-1" aria-labelledby="qrModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content" style={{ marginTop: "15vh", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                                <div className="modal-body">
                                    <button ref={qrref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <h2 style={{ margin: "2vh 0vh" }}> QR Code</h2>
                                    <br></br>
                                    <div style={{ height: "auto", margin: "0 auto", maxWidth: "200px", width: "100%" }}>
                                        <QRCode
                                            id="qr-gen"
                                            // size={456}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={`${frontend_url}/survey/${aiData?.display_id}`}
                                        // viewBox={`0 0 456 456`}
                                        />
                                    </div>
                                    <br></br>

                                    <button className='QRC1Continuebtn' onClick={() => copylink1()}>{iscopylink1 ? "Copied" : "Copy Link"}</button>
                                    <button className='QRC1Continuebtn' onClick={() => downloadbtn()}>Download</button>
                                    <button className="QRC1Continuebtn" style={{ margin: "2vh", border: "1px solid black" }}
                                        onClick={() => continueBtn()}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button ref={panelref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#panel"></button>
                    <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="panel" tabIndex="-1" aria-labelledby="panelLabel" aria-hidden="true">
                        <div className="modal-dialog" style={{ maxWidth: "100%", maxHeight: "100%" }}>
                            <div className="modal-content" style={{ margin: "auto", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px", width: "65vw" }}>
                                <div className="modal-body row">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #ccc", paddingBottom: "10px"
                                    }}>
                                        <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20px" }}>Panel</h2>
                                        <p style={{ fontSize: "16px", color: "grey" }}>(Your Credit : {credit})</p>
                                        <button ref={panelref1} style={{ width: "5rem", height: "2rem", display: "flex", alignItems: "center", justifyContent: "center" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        {/* <h2 style={{ margin: "2vh 0vh", borderBottom: "1px solid grey" }}> Panel</h2> */}

                                    </div>

                                    {/* {panelType === 1 && <ProlificPanel link={`${frontend_url}/survey/${aiData?.display_id}`} />} */}
                                    {panelType === 2 && <RespondentPanel link={`${frontend_url}/survey/${aiData?.display_id}`} />}
                                </div>

                            </div>
                        </div>
                    </div>
                    <button ref={creditref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#creditModal">
                    </button>
                    <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="creditModal" tabIndex="-1" aria-labelledby="creditModalLabel" aria-hidden="true">
                        <div className="modal-dialog" style={{ maxWidth: "40vw" }}>
                            <div className="modal-content" style={{ marginTop: "30vh", textAlign: "center", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                                <div className="modal-body">
                                    <button ref={creditref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <h2 style={{ margin: "2vh 0vh" }}> Credit Deduct</h2>
                                    <br></br>
                                    <div style={{}}>
                                        {eventNo === 8 && (
                                            <label style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img src="../../assets/icons/coin.svg" style={{ width: "2rem", height: "2rem" }} alt="coin icon" />&nbsp;
                                                {costConversationLimit} coins will be used to perform this operation.
                                            </label>
                                        )}
                                        {eventNo === 9 && (
                                            <label style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img src="../../assets/icons/coin.svg" style={{ width: "2rem", height: "2rem" }} alt="coin icon" />&nbsp;
                                                {costUserResponse} coins will be used to perform this operation.
                                            </label>
                                        )}
                                    </div>
                                    <br></br>
                                    <button className='QRC1Continuebtn' onClick={() => creditDeduct()}>Ok</button>
                                    <button className="QRC1Continuebtn" style={{ margin: "2vh", border: "1px solid black" }}
                                        onClick={() => creditref1.current.click()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={aiActive ? "ASN1collaps-sidebar-container-open" : "ASN1collaps-sidebar-container-close"}>

                        <div style={{ zIndex: "2", position: "fixed", margin: "10px", padding: "5px", cursor: "pointer" }} onClick={handleClose}>
                            <img src="/assets/icons/close.svg" />
                        </div>

                        <div style={{ padding: "0px 10px 10px 10px" }}>

                            <div style={{ zIndex: "1", position: "fixed", top: "0", width: "29rem", backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", padding: "10px" }}>
                                <h2 style={{ textAlign: "center", padding: "25px 10px 10px 10px" }}>Create Standalone Links</h2>
                                <div style={{ textAlign: "center" }}>Embed an iFrame or HTML code or share the link standalone.</div>
                                <br></br>
                            </div>
                            <div style={{ marginTop: "8rem" }}>
                                <br></br>

                                <h3 style={{ textAlign: "left", paddingLeft: "8px" }}>Instructions:</h3>
                                <br></br>
                                <div style={{ textAlign: "left", paddingLeft: "8px" }}>There are multiple ways to share your surveys with your customers. A variety of formats are provided by clicking the appropriate link below.</div>
                                <br></br>
                                <div style={{ textAlign: "left", paddingLeft: "8px" }}>You may insert the iFrame or HTML code onto your website or into your email platform of choice. Shareable Links are also provided for SMS or email hyperlinks. QR Codes may be used on signage or at events to collect feedback.</div>

                                <br></br>
                                <div style={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
                                    <div
                                        onClick={() => window.open(`${frontendUrl}/survey/${aiData.display_id}`)}>
                                        <button className='AIDPN1button' style={{ fontSize: "16px", padding: "15px", margin: "1rem 0rem" }}>Preview &nbsp; <i class="fa fa-external-link" aria-hidden="true"></i></button></div>
                                    {/* <div style={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
                                        <div className='ADP1icon' style={{ backgroundColor: selectedItem === 1 ? "rgba(186, 193, 196, 0.7)" : "", padding: "10px 13px 15px 13px" }} onClick={() => { setselectedItem(1); setopenPreview(true); }}> <i className="fa fa-desktop" aria-hidden="true" ></i></div>
                                        <div className='ADP1icon' style={{ backgroundColor: selectedItem === 2 ? "rgba(186, 193, 196, 0.7)" : "" }} onClick={() => { setselectedItem(2); setopenPreview(true) }}><i className="fa fa-mobile" style={{ fontSize: "2rem" }} aria-hidden="true" ></i></div>
                                    </div> */}
                                </div>
                                {/* <div style={{
                                    padding: "10px",
                                    backgroundColor: "transparent",
                                    // border: "1px solid #cfcfcf",
                                    borderRadius: "6px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "5px"
                                }}>
                                    <div style={{
                                        width: "100%",
                                        padding: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        cursor: "pointer"
                                    }} onClick={() => setopenPreview(!openPreview)}>
                                        <div>See Preview</div>
                                        <svg aria-hidden="true" style={{ height: "1rem", transform: openPreview ? "rotate(180deg)" : "rotate(0deg)" }} focusable="false" data-prefix="fal" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"></path></svg>
                                    </div>
                                    {openPreview && <div style={{ width: "100%" }}>
                                        <hr></hr>
                                        {selectedItem === 1 && <div>
                                            <iframe style={{ minHeight: "65vh", width: '100%' }} id="iframeid" src={`${frontendUrl}/survey/embed/${aiData.display_id}`} title="Duonut Iframe"></iframe>

                                        </div>}
                                        {selectedItem === 2 && <div style={{ padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <div className='ADP1mobileScreen'></div>
                                            <div className='ADP1iframeMob' style={{ display: "flex", alignItems: "center", width: "24rem" }}>
                                                <iframe style={{ width: "24rem", height: "63vh" }} id="iframeid" src={`${frontendUrl}/survey/embed/${aiData.display_id}`} title="Duonut Iframe"></iframe>

                                            </div>
                                        </div>}
                                    </div>}
                                </div> */}

                                <br></br>
                                {/* <div className="ASN1row_container">
                                    <div className="ASN1row">
                                        <h3>Panel</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Distribute to your customers based on demographic criteria</label>
                                    </div>
                                    <div className="">
                                        <button className='AIDPN1button' onClick={() => { setiscopylink1(false); .currentpanelref.click(); }}>Panel</button>
                                    </div>
                                </div> */}
                                <ReactTooltip id="settingGuide" place="top" effect="solid" delayHide={900} interactive={true} > </ReactTooltip>

                                <div className="ASN1row_container">
                                    <div className="ASN1row ">
                                        <h3>Share</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Share a link with your customer via text, email or based on demographic criteria</label>
                                        <div className="type-options" style={{ margin: "10px 0px" }}>
                                            {/* <div>
                                                <input
                                                    type="radio"
                                                    id="prolific"
                                                    name="panelType"
                                                    value={1}
                                                    checked={panelType === 1}
                                                    onChange={() => setpanelType(1)}
                                                    style={{ border: '2px solid #007BFF' }}
                                                />
                                                &nbsp;
                                                <label htmlFor="prolific">
                                                    Prolific &nbsp;
                                                    <span style={{ cursor: "pointer", }}
                                                        data-tooltip-html={`Hire real participants from Prolfiic</br> to conduct your research with real participants.<br/> Visit <a href='https://www.prolific.com/'  target='_blank' style={{cursor:"pointer"}}>Prolific</a> for more details.`}
                                                        data-tooltip-content={`Hire real participants from Prolfiic</br> to conduct your research with real participants.<br/> Visit <a href='https://www.prolific.com/'  target='_blank' >Prolific</a> for more details.`}
                                                        data-tooltip-id="settingGuide">
                                                        <i class="fa fa-info-circle" aria-hidden="true" style={{ color: "grey" }}></i>
                                                    </span>
                                                </label><br />
                                            </div> */}
                                            <div>
                                                {/* <input
                                                    type="radio"
                                                    id="respondent"
                                                    name="panelType"
                                                    value={2}
                                                    checked={panelType === 2}
                                                    onChange={() => setpanelType(2)}
                                                /> 
                                                &nbsp; */}
                                                <label htmlFor="respondent">
                                                    Respondent &nbsp;
                                                    <span style={{ cursor: "pointer", }}
                                                        data-tooltip-html={`Hire real participants from Respondent.io</br> to conduct your research with real participants.<br/> Visit <a href='https://www.respondent.io/'  target='_blank'  style={{cursor:"pointer"}}>Respondent.io</a> for more details.`}
                                                        data-tooltip-content={`Hire real participants from Respondent.io</br> to conduct your research with real participants.<br/> Visit <a href='https://www.respondent.io/'  target='_blank' >Respondent.io</a> for more details.`}
                                                        data-tooltip-id="settingGuide">
                                                        <i class="fa fa-info-circle" aria-hidden="true" style={{ color: "grey" }}></i>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="">
                                            <button className='AIDPN1button' onClick={() => copylink()}>{iscopylink ? "Copied" : "Copy Link"}</button>
                                        </div>&nbsp;
                                        <div className="">
                                            <button className='AIDPN1button' onClick={() => { setiscopylink1(false); panelref.current.click(); }} style={{ width: "100%" }}>Panel</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="ASN1row_container">
                                    <div className="ASN1row">
                                        <h3>iFrame Embed code</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Put a clickable survey onto your website</label>
                                    </div>
                                    <div className="">
                                        <button className='AIDPN1button' onClick={() => copyiframe()}>{iscopyiframe ? "Copied" : "Copy iFrame"}</button>
                                    </div>
                                </div>

                                <div className="ASN1row_container">
                                    <div className="ASN1row">
                                        <h3>HTML Embed code</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Put a clickable survey into Klaviyo, Mailchimp, Hubspot, Zendesk and more</label>
                                    </div>
                                    <div className="">
                                        <button className='AIDPN1button' onClick={() => copyHtml()}>{iscopyhtml ? "Copied" : "Copy HTML"}</button>
                                    </div>
                                </div>

                                <div className="ASN1row_container">
                                    <div className="ASN1row">
                                        <h3>QR Code</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>Share a QR code with your customers on signs or at events</label>
                                    </div>
                                    <div className="">
                                        <button className='AIDPN1button' onClick={() => { setiscopylink1(false); qrref.current.click(); }}>Generate QR Code</button>
                                    </div>
                                </div>
                                <div className="ASN1row_container" style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                        <div className="ASN1row">
                                            <h3>Settings</h3>
                                            <label style={{ textAlign: 'left', fontSize: "14px" }}></label>
                                        </div>
                                        <div className="">
                                            <button className='AIDPN1button' onClick={() => setIsOpenSettings(!openSettings)}>{openSettings ? "Close Settings" : "Open Settings"}</button>
                                        </div>
                                    </div>
                                    {openSettings &&
                                        <div style={{
                                            background: '#CFD8DC',
                                            margin: '12px 5px',
                                            padding: '10px',
                                            borderRadius: '5px',
                                            width: '95%'
                                        }}>
                                            <div className="form_field">
                                                <div className="setting-checkbox" style={{ textAlign: "left" }}>
                                                    {/* Conversation Depth Setting */}
                                                    <label>
                                                        Conversation Depth
                                                        <span
                                                            style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="Conversation Limit"
                                                            data-tooltip-id="settingGuide"
                                                        >
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                        {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    fill="#fec208"
                                                                    d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"
                                                                ></path>
                                                            </svg>
                                                        </span> */}
                                                        &nbsp;
                                                        <img src="../../assets/icons/coin.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
                                                    </label>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                        <input
                                                            type="number"
                                                            className="password_input"
                                                            style={{ width: "6rem", textAlign: "center", padding: "6px" }}
                                                            disabled={!isEditingConversation || !userProAccess}
                                                            value={conversationLimit}
                                                            onChange={(e) => {
                                                                const value = Math.max(0, Number(e.target.value)); // Ensure the value is not below 0
                                                                setconversationLimit(value);
                                                            }}
                                                        />
                                                        {!isEditingConversation ? (
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => setIsEditingConversation(true)}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-floppy-o"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    if (costConversationLimit === 0) {
                                                                        setIsEditingConversation(false);
                                                                        seteventNo(8);
                                                                    } else {
                                                                        setIsEditingConversation(false);
                                                                        seteventNo(8);
                                                                        creditref.current?.click();
                                                                    }
                                                                }}
                                                            ></i>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Number of User Views Setting */}
                                                <div className="setting-checkbox" style={{ textAlign: "left", marginTop: "1rem" }}>
                                                    <label>
                                                        Number of user views
                                                        <span
                                                            style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="User views per duonut"
                                                            data-tooltip-id="settingGuide"
                                                        >
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                        {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    fill="#fec208"
                                                                    d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"
                                                                ></path>
                                                            </svg>
                                                        </span> */}
                                                        &nbsp;
                                                        <img src="../../assets/icons/coin.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
                                                    </label>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                                        <input
                                                            type="number"
                                                            className="password_input"
                                                            style={{ width: "6rem", textAlign: "center", padding: "6px" }}
                                                            disabled={!isEditingUserViews || !userProAccess}
                                                            value={totalUserResponse}
                                                            onChange={(e) => {
                                                                const value = Math.max(0, Number(e.target.value));
                                                                settotalUserResponse(value);
                                                            }}
                                                        />
                                                        {!isEditingUserViews ? (
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => setIsEditingUserViews(true)}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-floppy-o"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    if (costUserResponse === 0) {
                                                                        setIsEditingUserViews(false);
                                                                        seteventNo(8);
                                                                    } else {
                                                                        setIsEditingUserViews(false);
                                                                        seteventNo(9);
                                                                        creditref.current?.click();
                                                                    }
                                                                }}
                                                            ></i>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </div>

                            </div>

                        </div>

                    </div>
                </div >
            </div >
            <AnimatePresence>
                {showSuccessMessage && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        style={{
                            position: "fixed",
                            top: "5%",
                            left: "40%",
                            transform: "translateX(-50%)",
                            background: "#4CAF50",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            fontSize: "16px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                        }}
                    >
                        {message}
                    </motion.div>
                )}
                {showErrorMessage && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        style={{
                            position: "fixed",
                            top: "5%",
                            left: "40%",
                            transform: "translateX(-50%)",
                            background: "#DC143C",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            fontSize: "16px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                        }}
                    >
                        {error_message}
                    </motion.div>
                )}
            </AnimatePresence>
            <ReactTooltip
                id="analyticspage2"
                place="bottom"
                effect="solid"
                className="custom-tooltip"
            >
                <p style={{ display: "flex", alignItems: "center" }}>
                    <img src="../../assets/icons/coin.svg" style={{ width: "1.2rem", height: "1.2rem" }} alt="coin icon" />
                    &nbsp; 2 credit
                </p>
            </ReactTooltip>
        </>
    );
}
