import React from 'react';
import './CutPatientOnboarding.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
// import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";

const CutPatientOnboarding = () => {
    // const { width, height } = useWindowDimensions();
    // const [selected, setSelected] = useState(0);
    const navigate = useNavigate();

    return (
        <div className='BCPO1App'>
            <div className='BCPO1template'>
                <h1 style={{ fontSize: "2.5rem", padding: "2rem 0rem" }}>
                    Cut Patient onboarding time by 25 minutes
                </h1>

                <h2>Goals:</h2>
                <br></br>
                <ul style={{ listStyle: "unset" }}>
                    <li>To make patient paperwork more convenient.</li>
                    <br></br>
                    <li>The Director of Digital Management knew this was the best solution to streamline patient onboarding.
                    </li>
                </ul>
                <br></br>

                <div className='BCPO1section-1'>
                    <img className='BCPO1section-1-image' src="/assets/blogs/bcpo1.png" alt="" />
                </div>

                <br></br>
                <div>
                    When the team was working on their goals for the year, healthcare providers and back office staff decided it was time to improve their <a href='https://duonut.com/templates/health_&_life_style/patient-intake-form' target="_blank"> patient onboarding process</a>. They started looking for a software that was highly secure, <a href='https://duonut.com/design/2045' target="_blank">simple to learn</a>, and easy to integrate with their existing internal system.
                </div>
                <br></br>
                <div>
                    When they discovered Duonut’s <a href='https://duonut.com/templates/health_&_life_style' target="_blank"> Forms products</a>, they knew they have found the right solution. Team started quickly and easily started building everything from new patient onboarding forms and insurance verification to official health record documents and patient surveys.
                </div>
                <br></br>

                <div className='BCPO1section-1'>
                    <img className='BCPO1section-1-image' src="/assets/blogs/bcpo2.png" alt="" />
                </div>
                <br></br>
                <div>
                    As part of a small department, team started loving how easy it is to build out new processes within <a href='https://duonut.com/' target="_blank"> Duonut</a>. Many different departments now contact them weekly to help them replace paperwork with more convenient digital patient experiences built with Duonut.
                </div>

                <br></br>
                <br></br>

                <h2 id='BlogFeedback3'>Related Templates</h2>
                <br></br>

                <div className='BCPO1MainContainer'>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo3.png" alt="" />
                        </div>
                        <div>
                            New patient intake form
                        </div>
                        <a href='https://duonut.com/duonut/4244281473' target="_blank">Get started with this Template </a>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo3.png" alt="" />
                        </div>
                        <div>
                            Medical Insurance verification form
                        </div>
                        <a href='https://duonut.com/templates/health_&_life_style/insurance-verification-form' target="_blank">Get started with this Template </a>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo3.png" alt="" />
                        </div>
                        <div>
                            Patient health history form
                        </div>
                        <a href='https://duonut.com/templates/health_&_life_style/new-patient-health-history-form' target="_blank">Get started with this Template </a>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo3.png" alt="" />
                        </div>
                        <div>
                            Patient satisfaction form
                        </div>
                        <a href='https://duonut.com/templates/health_&_life_style/patient-satisfaction-survey' target="_blank">Get started with this Template </a>
                    </div>
                </div>
                <br></br>
                <br></br>
                <h2 id='BlogFeedback3'>Results:</h2>
                <br></br>

                <div className='BCPO1MainContainer'>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo4.png" alt="" />
                        </div>
                        <div>
                            Huge improvement in patient experience
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "400" }}>
                            Made patient forms more convenient and accessible with digital workflows
                        </div>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo4.png" alt="" />
                        </div>
                        <div>
                            Streamlined processes at multikple locations
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "400" }}>
                            Launched new patient processes quickly across hundreds of locations
                        </div>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo4.png" alt="" />
                        </div>
                        <div>
                            Accelerated work
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "400" }}>
                            Reduced  two weeks of  survey and workflow development time
                        </div>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo4.png" alt="" />
                        </div>
                        <div>
                            Saved time
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "400" }}>
                            Reduced patient onboarding time by 25 minutes
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>

                <h2>Related Blogs</h2>
                <br></br>
                <div className='BLG1blogs'>
                        <div className='BLG1blog' onClick={() => navigate('/blog/story-telling')}>
                            <div className='BLG1blogImgContainer'>
                                <img className='BLG1blogimage' src="/assets/blogs/bst1.png" alt="" />
                            </div>
                            <h2 style={{ padding: "10px 0px" }}>What is storytelling? How to tell great stories</h2>
                            <div>
                                Storytelling, the timeless craft of narrating tales, is deeply ingrained in human history...
                            </div>
                        </div>

                        <div className='BLG1blog' onClick={() => navigate('/blog/to-do-in-2024')}>
                            <div className='BLG1blogImgContainer'>
                                <img className='BLG1blogimage' src="/assets/blogs/td1.png" alt="" />
                            </div>
                            <h2 style={{ padding: "10px 0px" }}>10 things to do in 2024 that will change your life forever!</h2>
                            <div>
                                It is that time of the year where you make new year resolutions that can bring more inner peace and overall well-being...
                            </div>
                        </div>

                        <div className='BLG1blog' onClick={() => navigate('/blog/improve-healthcare-workflows')}>
                            <div className='BLG1blogImgContainer'>
                                <img className='BLG1blogimage' src="/assets/blogs/bihw1.png" alt="" />
                            </div>
                            <h2 style={{ padding: "10px 0px" }}>Improve Healthcare Workflows and Save more than $35,000</h2>
                            <div>
                                Healthcare services requires the employers to collect a lot of patient data, which focuses on building better systems...
                            </div>
                        </div>


                    </div>
                <br></br>
                <br></br>
            </div>
            <HomePageFooter />
        </div>
    );

}

export default CutPatientOnboarding;