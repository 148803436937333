import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateGoToSlide, } from '../../redux/slices/userActivity';
import { useLocation, } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import { showduonutData } from '../../redux/slices/DuonutReducer';
import imgPreview from '../../assets/Images/image-preview.png';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from "axios";
import { domain } from "../../services/constants";

const ReorderImage = ({
    // id,
    elementAttr,
    elementHoverAttr,
    // conditions,
    setShowModel,
    setCorrectAnswer,
    language,
    slideId,
}) => {
    const dispatch = useDispatch();
    const { selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name, viewer_url_params } = useSelector((state) => state.duonut);
    const { text, options, fontFamily, fontSize, fontWeight, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, margin, padding, borderRadius, color, backgroundColor, border, gap,
        labelFontSize, labelFontWeight, labelColor, optionsImage, imageWidth, imagePadding, fieldPosition
    } = elementAttr;

    const { width } = useWindowDimensions();
    const { viewType } = useSelector((state) => state.duonutSettings);
    // const duonutDataMain = useSelector(showduonutData);
    // const duonutData = duonutDataMain[0];
    // console.log(duonutData)

    const [hover, setHover] = useState(-1);
    const [reorderOption, updateReorderOption] = useState([]);
    const [answer, setAnswer] = useState("");
    const [inputText, setInputText] = useState("");
    const [optionsText, setoptionsText] = useState([]);

    useEffect(() => {
        if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
            fetchText(text);
            // fetchOptionsText(reorderOption);
        } else {
            setInputText(text);
            // setoptionsText(reorderOption);
        }
    }, [text, reorderOption]);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const fetchOptionsText = async (texts) => {
        const translatedTexts = []; // Initialize an array to hold translated texts
        for (const item of texts || []) {
            const translated = await translateText(item, language);
            translatedTexts.push(translated); // Push translated text to the array
            await delay(1000); // Delay of 500ms between requests
        }
        setoptionsText(translatedTexts); // Update the state with the translated texts
    };
    const fetchText = async (texts) => {
        const t_text = await translateText(texts, language);
        setInputText(t_text);
    };
    const translateText = async (texts, targetLanguage) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${domain}/duonut/language_translator`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    source: "en",
                    target: targetLanguage,
                    query: texts,
                },
            });

            if (response.status === 200) {
                // console.log("Translation:", response);
                return response?.data.translation; // return the translated text
            } else {
                // Handle unsuccessful status codes
                return "Error: Translation failed. Please try again.";
            }
        } catch (error) {
            console.error('Error in translation API:', error);
            return "Error translating text. Please try again."; // Handle API errors gracefully
        }
    };

    function shuffle(array) {
        let shuffledArray = [];
        let usedIndexes = [];
        let i = 0;
        while (i < array.length) {
            let randomNumber = Math.floor(Math.random() * array.length);
            // console.log(randomNumber, i);
            if (!usedIndexes.includes(randomNumber) && i !== randomNumber) {
                shuffledArray.push(array[randomNumber]);
                usedIndexes.push(randomNumber);
                i++;
            } else if (!usedIndexes.includes(randomNumber) && i === array.length - 1) {
                shuffledArray.push(shuffledArray[0]);
                shuffledArray[0] = array[randomNumber];
                i++;
            }
        }
        // console.log(shuffledArray);

        return shuffledArray;
    }

    useEffect(() => {
        if (location.pathname.includes("/duonut/")) {
            const objCopy = [...options];
            updateReorderOption(shuffle(objCopy));
        } else {
            updateReorderOption(options);
        }

        var answerText = "";
        options.map(option => {
            if (answerText === "") {
                answerText = option;
            } else {
                answerText = answerText + ', ' + option;
            }
        })
        setAnswer(answerText);
    }, [options]);

    const getItemStyle = (isDragging, draggableStyle) => ({

        // background: isDragging ? elementHoverAttr?.backgroundColor : '',

        ...draggableStyle,
    });

    function handleOnDragEnd(result) {
        // console.log(result);
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        const items = Array.from(reorderOption);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateReorderOption(items);
        // console.log(reorderOption);

        if (JSON.stringify(options) === JSON.stringify(items)) {
            setCorrectAnswer(answer);
            setShowModel(1);

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 1,
                question: text ? text : "",
                answered_text: "",
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "ReorderImage",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                url_params: viewer_url_params,
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => {
                const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                formData.append(key, value);
            });

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                type: "ReorderImage",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: "",
            }
            dispatch(saveQuizResponse(save_response));

            var nextPage = selectedSlide + 1;

            setTimeout(() => {
                setShowModel(0);
                dispatch(updateGoToSlide(nextPage));
            }, 3100);


        }


    }

    return (
        <>
            <div style={{
                borderRadius, display: "flex", flexDirection: "column", gap: gap
            }}>
                <div
                    style={{
                        width: "100%", paddingBottom: '10px',
                        color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                        textAlign, lineHeight, letterSpacing,
                    }}>{inputText}</div>

                <div className='remove-gutter' style={{
                    display: "flex", justifyContent: textAlign, alignItems: 'center',
                    marginTop: "0", marginBottom: "0", width: "100%",
                    flexDirection: (viewType === 3 || width < 800) ? "column" : fieldPosition
                }}>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="dragOpt" type='dragOpt' direction={(viewType === 3 || width < 800) ? 'vertical' : fieldPosition === "row" ? "horizontal" : " vertical"}>
                            {(provided, snapshot) => (
                                <div style={{ display: "flex", flexDirection: (viewType === 3 || width < 800) ? "column" : fieldPosition }} {...provided.droppableProps} ref={provided.innerRef}>

                                    {reorderOption.map((item, i) => {
                                        // Find the original index of the shuffled option
                                        const originalIndex = options.indexOf(item);
                                        return (
                                            <Draggable key={i} draggableId={(i).toString()} index={i} isDragDisabled={(location.pathname).includes("/duonut/") ? false : true}>
                                                {(provided, snapshot) => {
                                                    if (snapshot.isDragging && (width > 800 && fieldPosition === "row")) {
                                                        const offset = { x: 200, y: 20 }          // your fixed container left/top position
                                                        const x = provided.draggableProps.style.left - offset.x;
                                                        const y = provided.draggableProps.style.top - offset.y;
                                                        provided.draggableProps.style.left = x;
                                                        provided.draggableProps.style.top = y;
                                                    }
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style),

                                                                backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                                                                border: hover === i ? elementHoverAttr?.border : border ? border : "0",
                                                                color: hover === i ? elementHoverAttr?.color : color,
                                                                borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                                                                textTransform, textAlign, letterSpacing, padding, margin, lineHeight,
                                                                cursor: (location.pathname).includes("/duonut/") ? "grab" : "default"
                                                            }}
                                                            onMouseEnter={() => setHover(i)}
                                                            onMouseLeave={() => setHover(-1)}
                                                        >
                                                            <img src={optionsImage[originalIndex] ? optionsImage[originalIndex] : imgPreview} style={{
                                                                width: imageWidth, height: imageWidth, objectFit: "cover",
                                                                padding: imagePadding
                                                            }} alt="" />
                                                            <div style={{}}>{item}</div>
                                                        </div>
                                                    )
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </>
    );
}

export default ReorderImage;
