import React, { useEffect } from "react";
import "./Activity.css";
import { useSelector, useDispatch } from "react-redux";
import Editors from "./Editors";
import { updateSelectedComponent } from "../../redux/slices/userActivity";
import "intro.js/introjs.css";

const Activity = () => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity } = useSelector((state) => state.userActivity);
    const activity = userActivities[selectedUserActivity];

    // Handle tour based on introCompleted state


    useEffect(() => {
        // console.log(activity);
        if (activity.elements.length === 0) {
            dispatch(
                updateSelectedComponent({
                    type: "Slide",
                    name: "Container",
                    target: "",
                    target_id: null,
                })
            );
        }
    }, [selectedUserActivity, activity]);

    return (
        <>
            {activity?.elements && ((activity?.elements).length < 1 || !activity?.elements) ? (
                <div className="designMainContainer">
                    <div className="elementsContainer"></div>
                </div>
            ) : (
                <Editors className="EditorContainer" master={activity} />
            )}
        </>
    );
}

export default Activity;
