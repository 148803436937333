import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements } from '../../../redux/slices/userActivity';
import { EditText } from "react-edit-text";
import { getFileSizeFromBase64, toDataUrl } from "../../../utils";
import axios from "axios";
import Resizer from 'react-image-file-resizer';
import FlipcardImageModal from './FlipcardImageModal';
import { domain } from "../../../services/constants";
import { useLocation } from "react-router-dom";

export default function FrontText() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [text, settext] = useState(null);
    const dispatch = useDispatch()
    const location = useLocation();
    const inputRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paramsid, setparamsid] = useState("");

    useEffect(() => {

        // Get the current pathname from the window location
        const pathname = location.pathname;

        // Split the pathname into an array of words
        const words = pathname.split('/');

        // Get the last word (assuming the URL ends with a word)
        const lastWord = words[words.length - 1];

        // Use a regular expression to match and capture the numeric part of the URL
        const matchResult = pathname.match(/\/design\/(\d+)/);

        // Check if there is a match and retrieve the captured number
        if (matchResult && matchResult[1]) {
            const extractedNumber = parseInt(matchResult[1]);
            // console.log('Extracted Number:', extractedNumber);
            setparamsid(extractedNumber);
        }
        // Log or use the last word as needed
        // console.log('Last word:', lastWord);

    }, [location.pathname]);

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const f_text = tempElement?.elementAttributes?.f_text;

            if (f_text) {
                settext(f_text);
            } else {
                settext(null);
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleTextChange = (e) => {
        console.log(e)
        const value = e.target.value;
        settext(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    f_text: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    const removeImage = () => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    f_image: "",
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleClick = () => {
        inputRef.current.value = null; // reset the value of the file input
        inputRef.current.click();
    };

    const updateFlipCardImage = async (e) => {
        const imageFile = e.target.files[0];

        let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
        const elementAttrib = tempElement?.elementAttributes;

        if (imageFile.type === "image/png") {
            Resizer.imageFileResizer(
                imageFile,
                1000,
                1000,
                'PNG',
                90,
                0,
                uri => {
                    const image_data = {
                        duonut_id: paramsid,
                        name: e.target.files[0].name,
                        uri: uri,
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);

                        tempElement = {
                            ...tempElement,
                            elementAttributes: {
                                ...elementAttrib,
                                f_image: res.data.file_url,
                            },
                        };
                        dispatch(
                            updateUserActivityElements({
                                selectedActivityIdx: selectedUserActivity,
                                selectedElementIdx: selectedComponent.target_id,
                                element: tempElement,
                            })
                        );
                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    getFileSizeFromBase64(uri)
                },
                'base64'
            );

        } else if (imageFile.type === "image/gif") {
            toDataUrl(e.target.files[0], (res) => {
                let result = res;

                const image_data = {
                    duonut_id: paramsid,
                    name: e.target.files[0].name,
                    uri: result,
                }
                axios({
                    method: "POST",
                    url: `${domain}/duonut/upload_files`,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify(image_data),
                }).then((res) => {
                    //console.log(res.data.file_url);
                    tempElement = {
                        ...tempElement,
                        elementAttributes: {
                            ...elementAttrib,
                            f_image: res.data.file_url,
                        },
                    };
                    dispatch(
                        updateUserActivityElements({
                            selectedActivityIdx: selectedUserActivity,
                            selectedElementIdx: selectedComponent.target_id,
                            element: tempElement,
                        })
                    );
                }).catch((e) => {
                    console.log(e.message, e);
                });

            });
        } else {
            Resizer.imageFileResizer(
                imageFile,
                1000,
                1000,
                'JPEG',
                90,
                0,
                uri => {
                    const image_data = {
                        duonut_id: paramsid,
                        name: e.target.files[0].name,
                        uri: uri,
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);
                        tempElement = {
                            ...tempElement,
                            elementAttributes: {
                                ...elementAttrib,
                                f_image: res.data.file_url,
                            },
                        };
                        dispatch(
                            updateUserActivityElements({
                                selectedActivityIdx: selectedUserActivity,
                                selectedElementIdx: selectedComponent.target_id,
                                element: tempElement,
                            })
                        );
                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    getFileSizeFromBase64(uri)
                },
                'base64'
            );
        }


    }

    const handleClick1 = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (<>
        {isModalOpen && <FlipcardImageModal handleClose={handleCloseModal} side="Front" />}

        <div className="EditorRowContainer">
            <div style={{ marginRight: "1rem" }}>Front Text</div>
            <EditText
                id="fronttext-input-field"
                value={text} c
                style={{ fontSize: "14px", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", alignItems: "center", width: "15rem", height: "28px", paddingLeft: "5px" }}
                onEditMode={() => { setTimeout(() => document.querySelector(`#fronttext-input-field`).select(), 100); }}
                onChange={(e) => (handleTextChange(e))}
                placeholder="Write your Front Text?"
            />
        </div>
        <h4>OR</h4>
        <div className="EditorRowContainer">
            <div style={{ marginRight: "1rem" }}>Front Image</div>
            <button className="EditorRowButton" onClick={() => handleClick()} style={{ marginRight: "10px" }}>
                <img src="/assets/icons/upload-image.svg" style={{ marginRight: "0.5rem" }} />
                Upload
            </button>
            <input type="file" ref={inputRef}
                onChange={(e) => updateFlipCardImage(e)}
                accept={"image/*"}
                onClick={(e) => { e.target.value = null }} name="postImages" style={{ display: "none" }} />

            <button className="EditorRowButton" onClick={() => handleClick1()}>
                <i className="fa fa-picture-o" aria-hidden="true"
                    style={{ marginRight: "0.6rem", fontSize: "1rem", opacity: "0.8" }}></i>
                Gallary
            </button>

            <i className="fa fa-trash-o" aria-hidden="true"
                style={{ marginLeft: "0.8rem", fontSize: "1rem", color: "#DB615C", cursor: "pointer" }} onClick={() => removeImage()}></i>
        </div>
    </>
    )
}

