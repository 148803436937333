import React, { useEffect, useRef, useState } from "react";
// import Hourglass from "../../../assets/icons/hourglass";
import { updateUserActivity } from "../../../redux/slices/userActivity";
import { useDispatch, useSelector } from "react-redux";

export default function Timer() {
  const [timer, setTimer] = useState(null);

  const [onSwitch, setOnSwitch] = useState(false);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [minutesFocusOn, setMinutesFocusOn] = useState(false)
  const [secondsFocusOn, setSecondsFocusOn] = useState(false)

  const minutesRef = useRef(null)
  const secondsRef = useRef(null)

  const dispatch = useDispatch();
  const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);

  useEffect(() => {
    if (userActivities[selectedUserActivity]) {
      let timer = userActivities[selectedUserActivity]?.timer;
      if (timer) {
        setTimer(timer);
      } else {
        setTimer(null);
      }
    }
  }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

  const resetTimer = () => {
    if (minutesFocusOn === false && secondsFocusOn === false) setOnSwitch(false);
    setMinutes(0);
    setSeconds(0);
  };

  useEffect(() => {
    const setTimerValue = () => {
      if (timer <= 59) {
        setMinutes(0);
        setSeconds(timer);
      }
      let min = Math.trunc(timer / 60);
      let sec = timer % 60;
      setMinutes(min);
      setSeconds(sec);
    };
    timer === 0 || timer === null ? resetTimer() : setTimerValue();
  }, [timer, selectedUserActivity, minutesFocusOn, secondsFocusOn]);

  const convertToSeconds = () => {
    const getSeconds = () => (parseInt(seconds) >= 0 ? parseInt(seconds) : 0);
    if (minutes > 0) {
      return parseInt(minutes) * 60 + getSeconds();
    } else {
      return getSeconds();
    }
  };

  useEffect(() => {
    let timer = convertToSeconds();
    if (userActivities[selectedUserActivity]) {
      dispatch(
        updateUserActivity({
          timer: timer,
        })
      );
    }
  }, [minutes, seconds]);

  const handleMinutesEnter = e => {
    if (e.key === "Enter") secondsRef.current.focus()
  };

  return (
    <div className="EditorRowContainer" style={{}}>
      <div style={{ marginRight: "1rem" }}>Slide Time</div>

      <div className="timer_container">
        <input
          onFocus={e => { setMinutesFocusOn(true); e.target.select() }}
          onBlur={() => setMinutesFocusOn(false)}

          ref={minutesRef}

          type="number"
          maxLength={2}
          value={minutes === 0 ? "" : minutes}
          placeholder='m'
          onKeyDown={e => handleMinutesEnter(e)}
          onChange={(e) => (e.target.value > 9 ? null : setMinutes(e.target.value))}
          style={{ width: "22px", height: "24px", background: "#fff", borderRadius: "4px", textAlign: "center", marginLeft: "0px" }}
        />
        <span style={{ margin: 0, marginLeft: "1px" }}>:</span>
        <input
          onFocus={e => { setSecondsFocusOn(true); e.target.select() }}
          onBlur={() => setSecondsFocusOn(false)}

          ref={secondsRef}
          type="number"
          maxLength={2}
          value={seconds === 0 ? "" : seconds}
          placeholder='ss'
          onChange={(e) => (e.target.value > 59 ? null : setSeconds(e.target.value))}
          style={{ width: "22px", height: "24px", background: "#fff", marginLeft: "1px", borderRadius: "4px", textAlign: "center", appearance: "none" }}
        />
      </div>
    </div>
  );
}
