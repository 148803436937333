import React, { useState, useEffect } from 'react'
import "./Workflow.css"
import { frontendUrl,} from "../../services/constants";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate,} from "react-router-dom";
import { createChannelAsync, showChannelresponse, saveChannelAsync } from "../../redux/slices/ChannelReducer";
// import { editUserDomainAsync } from "../../redux/slices/ProfileReducer";

const Workflow = () => {
    const userId = localStorage.getItem("userId");
    const { userProAccess,} = useSelector((state) => state.user);

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    useEffect(() => {
        if (!userProAccess) {
            window.location = '/';
        }
    }, []);

    const [channelName, setchannelName] = useState("");
    const [channelDescription, setchannelDescription] = useState("");
    // const [subdomainInput, setsubdomainInput] = useState("");
    const [message, setmessage] = useState("");
    const [createdChannel, setcreatedChannel] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(saveChannelAsync());
        // console.log("inside");
    }, []);
    const channelData = useSelector(showChannelresponse);
    const channelData1 = channelData[0];

    // console.log(channelData1);

    useEffect(() => {
        if (channelData1) {
            // console.log(channelData1);
            if (channelData1.empty_message) {
                setcreatedChannel(false);
            } else {
                setcreatedChannel(true);
            }
        }
    }, [channelData1])


    const onClickSaveButton = (event) => {
        event.preventDefault();
        // if (!createdChannel) {
        //     const formData = new FormData();
        //     formData.append('id', userId);
        //     formData.append('sub_domain', subdomainInput);

        //     dispatch(editUserDomainAsync(formData)).then((response) => {
        //         console.log(response);
        //         localStorage.setItem("subDomain", subdomainInput);

        //         var codeData = channelName.toLowerCase().replace(/\s/g, '')
        //         var channelData = {
        //             name: channelName,
        //             code: "@" + codeData + userId,
        //             description: channelDescription
        //         }
        //         dispatch(createChannelAsync(channelData));
        //         setmessage("Workspace Created successfully");
        //         setTimeout(() => {
        //             setmessage("");
        //             navigate('/workflow');
        //         }, 2000); //miliseconds
        //     }).catch(error => {
        //         console.log(error);
        //         setmessage("Subdomain is already exists");
        //         setTimeout(() => {
        //             setmessage("");
        //         }, 3000); //miliseconds
        //     });
        // } else {
        var codeData = channelName.toLowerCase().replace(/\s/g, '')
        var channelData = {
            name: channelName,
            code: "@" + codeData + userId,
            description: channelDescription
        }
        dispatch(createChannelAsync(channelData));
        setmessage("Workspace Created successfully");
        setTimeout(() => {
            setmessage("");
            navigate('/workflow');
        }, 2000); //miliseconds
        // }

    }


    return (
        <div className='WorkflowChannelApp'>
            <form onSubmit={onClickSaveButton}>
                <div className='WorkflowChannelContainer'>
                    <h1 style={{ width: "100%", textAlign: "center" }}>Create Workspace</h1>
                    <br></br>
                    <div className='WorkflowChannelRow'>
                        <div>Name </div>
                        <input className='WorkflowChannelInput'
                            required
                            onChange={(e) => (e.target.value.length > 100 ? null : setchannelName(e.target.value))} />
                    </div>
                    {/* <div className='WorkflowChannelRow'>
                        <div> Your URL </div>
                        <div>
                            {createdChannel ? <input className='WorkflowChannelInput'
                                style={{ width: "78%", borderRadius: "5px 0px 0px 5px", backgroundColor: createdChannel ? "rgb(237, 242, 247)" : "white" }}
                                disabled={createdChannel ? true : false}
                                value={createdChannel ? subDomain : ""}
                                required
                                onChange={(e) => (setsubdomainInput(e.target.value))} />
                                : <input className='WorkflowChannelInput'
                                    style={{ width: "78%", borderRadius: "5px 0px 0px 5px", backgroundColor: createdChannel ? "rgb(237, 242, 247)" : "white" }}
                                    disabled={createdChannel ? true : false}
                                    required
                                    onChange={(e) => (setsubdomainInput(e.target.value))} />}
                            <label style={{ background: '#EDF2F7', border: "1px solid black", borderRadius: "0px 5px 5px 0px", padding: "6px 8px", borderLeft: "0" }}>.duonut.com</label>
                        </div>
                    </div> */}
                    <div className='WorkflowChannelRow'>
                        <div>Description </div>
                        <textarea className='WorkflowChannelInput'
                            // required
                            onChange={(e) => (setchannelDescription(e.target.value))} />
                    </div>
                    <div style={{ width: "100%" }}>
                        {message && <div style={{ margin: "5px 1rem", color: message === "Subdomain is already exists" ? "red" : "green" }}>{message}</div>}
                        <button type='submit' className='WorkflowChannelSavebtn' >Confirm</button>
                        <button className='WorkflowChannelbtn' onClick={() => navigate('/workflow')} >Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    );

}

export default Workflow;