import React, { useEffect } from 'react'
export default function HubSpotSuccess() {

    useEffect(() => {
        function extractParamsFromURL() {
            const urlParams = new URLSearchParams(window.location.search);
            return {
                authCode: urlParams.get('code'),
                error: urlParams.get('error')
            };
        }

        // Extract the authorization code and error from the URL
        const { authCode, error } = extractParamsFromURL();

        if (error) {
            // Close the popup if there's an error
            // window.close();
            window.opener?.postMessage({ error }, window.location.origin);
        } else if (authCode) {
            // Send a message to the main window with the authorization code
            // console.log("success", authCode, window)
            if (window.opener) {
                window.opener.postMessage({ authCode }, window.location.origin);
            } else {
                // Store the auth code in local storage
                localStorage.setItem('authCode', authCode);
                window.close(); // Close the popup
            }
            // window.opener?.postMessage({ authCode }, window.location.origin);
        }
    }, []);

    return (
        <div className='payment_container'>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "91.5vh" }}>
                <img alt="loader" src="/assets/psuccess.gif" height={350} width={400} />
            </div>
        </div>
    )
}
