import React from 'react';
import './Manufacturing.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import { openModal, showSignUpForm } from "../../redux/slices/formDetails";
import { useSelector, useDispatch } from "react-redux";
import { toggleDemoModalDuonut } from "../../redux/slices/DemoReducer";

const Manufacturing = () => {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((state) => state.user);

    const handleClick = () => dispatch(openModal());
    const handleClickSignUp = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignUpForm());
        } else {
            navigate("/design");
        }

    };

    const handleClickDemo = () => {
        dispatch(toggleDemoModalDuonut(true));
    };

    return (
        <div className='UCI1App'>
            <div className='UCI1template'>

                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'contain' }}
                            src="/assets/usecase/duonut_workflow.png" alt="" />
                    </div>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingLeft: "4rem" }}>
                        <h1 style={{ fontSize: "3rem", lineHeight: "50px" }}>Significantly reducing manual work and error rates</h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Free your team from paper with streamlined workflows that let you collect and manage data anywhere, even for the desk-less workers
                        </div>
                        <br></br>
                        <button onClick={() => handleClickDemo()} className="UCI1Button" style={{ marginRight: "1rem" }}>
                            Book a demo
                        </button>
                        <button className="button" style={{ padding: "16px 40px", fontSize: "1.2rem" }} onClick={() => handleClickSignUp()} >
                            Sign up now
                        </button>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div className="home_page_integration_section">
                    <div className="home_page_section_header">Trusted by Startups & Enterprises</div>

                    <div className="home_page_integration_section_images">
                        <a href="https://shawarmer.com/" target="_blank"><img style={{ width: "145px" }} src="/assets/company/Shawarmer.png" loading="lazy" alt="duonut" /></a>
                        <a href="https://www.narayanahealth.org/" target="_blank"><img src="/assets/company/Narayana.png" loading="lazy" alt="duonut" /></a>
                        <img src="/assets/company/xe.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/mintorie.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/ourlearn.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/uleash.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/vivahit1.png" loading="lazy" alt="duonut" />
                    </div>
                </div>


                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'cover' }}
                            src="/assets/usecase/duonut_checklist.png" alt="" />
                    </div>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingLeft: "4rem" }}>
                        <h1>Real-time updates and easy tracking checklists</h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Checklists are vital tools in the manufacturing industry, helping ensure that all necessary steps are completed and nothing is overlooked. For instance, maintenance checklists ensure machinery is regularly serviced, while safety checklists help prevent workplace accidents.
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingRight: "4rem" }}>
                        <h1>Achieve uniformity in the performance using SOPs </h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Create SOPs for maintaining consistency, quality, and safety in manufacturing processes. Duonut’s SOPs are designed to achieve uniformity in the performance of specific functions in manufacturing industries.
                        </div>
                    </div>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'cover' }}
                            src="/assets/usecase/duonut_sop.png" alt="" />
                    </div>
                </div>


                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "8rem" }}>
                    <h1 style={{ fontSize: "2.5rem", paddingBottom: "3rem" }}>Featured Manufacturing Templates</h1>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className='UCI1template_container'
                            onClick={() => window.open("https://duonut.com/templates/general/manufacturing-facilities-maintenance-checklist")}>
                            <img style={{ width: "280px", height: "185px", borderRadius: "15px", objectFit: "cover" }}
                                src="/assets/usecase/templates/manufacturing1.png" alt="" />
                            <div style={{
                                display: "flex", alignItems: "center", height: "6rem",
                                fontSize: "1.3rem", fontWeight: "700"
                            }}>Checklists for error free process</div>
                        </div>
                        <div className='UCI1template_container'
                            onClick={() => window.open("https://duonut.com/templates/general/engineer-technical-support-level-1-checklist")}>
                            <img style={{ width: "280px", height: "185px", borderRadius: "15px", objectFit: "cover" }}
                                src="/assets/usecase/templates/manufacturing2.png" alt="" />
                            <div style={{
                                display: "flex", alignItems: "center", height: "6rem",
                                fontSize: "1.3rem", fontWeight: "700"
                            }}>SOP | Job profile</div>
                        </div>
                        <div className='UCI1template_container'
                            onClick={() => window.open("https://duonut.com/templates/general/safety-data-sheet")}>
                            <img style={{ width: "280px", height: "185px", borderRadius: "15px", objectFit: "cover" }}
                                src="/assets/usecase/templates/manufacturing3.png" alt="" />
                            <div style={{
                                display: "flex", alignItems: "center", height: "6rem",
                                fontSize: "1.3rem", fontWeight: "700"
                            }}>Safety Data Sheet</div>
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h1 style={{ fontSize: "3rem", padding: "1rem" }}>Get started today.</h1>
                    <div>
                        <button onClick={() => handleClickDemo()} className="UCI1Button" style={{ marginRight: "1rem" }}>
                            Book a demo
                        </button>
                        <button className="button" style={{ padding: "16px 40px", fontSize: "1.2rem" }} onClick={() => handleClickSignUp()} >
                            Sign up now
                        </button>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

            </div>
            <HomePageFooter />
        </div>
    );

}

export default Manufacturing;