import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import { domain } from "../../services/constants";
import './PanelData.css';
// import { ScreenSearchDesktopRounded } from '@mui/icons-material';
import { AnimatePresence, motion } from 'framer-motion';
// import RespondentPanel from '../AiDataNew/Panel/RespondentPanel';

const RespondentData = () => {

    const [respondentData, setrespondentData] = useState([]);
    const [respondentProjectData, setrespondentProjectData] = useState([]);
    const [screenerData, setScreenerData] = useState([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [ispublished, setispublished] = useState(false);
    const [openScreenerDropdownIndex, setScreenerDropdownIndex] = useState(null);
    const [activeProjectIndex, setActiveProjectIndex] = useState(null);
    const [expandedScreenerIndex, setExpandedScreenerIndex] = useState(null);
    const [copiedItemId, setCopiedItemId] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [message, setMessage] = useState("");

    const panelref = useRef(null);
    const panelref1 = useRef(null);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h12",
        }).format(date);
    };

    // Handle screener card click
    const handleScreenerClick = (index) => {
        if (expandedScreenerIndex === index) {
            setExpandedScreenerIndex(null); // Close if already expanded
        } else {
            setExpandedScreenerIndex(index); // Open details for clicked screener
        }
    };
    const toggleResponses = (index) => {
        // Toggle the active project index
        setActiveProjectIndex(activeProjectIndex === index ? null : index);
    };

    // Function to toggle dropdown for a specific item
    const toggleDropdown = (index) => {
        setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const toggleScreenerDropdown = (index) => {
        setScreenerDropdownIndex(
            openScreenerDropdownIndex === index ? null : index
        );
    }

    useEffect(() => {
        const token = localStorage.getItem("access");
        axios({
            method: "GET",
            url: `${domain}/duonut/get_respondent_data`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },

        }).then(data => {
            // console.log('Full API Data:', data);
            setrespondentData(data?.data);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });


    }, [ispublished]);


    const handlePublishProject = (project) => {
        // console.log(project);
        setOpenDropdownIndex(null);
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/publish_respondent`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: project.project_id,
            },
        })
            .then(response => {
                // console.log('API Data:', response);

                axios({
                    method: "PATCH",
                    url: `${domain}/duonut/edit_respondent_data`,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        // Add your payload data here
                        id: project.id,
                        project_status: "PUBLISHED",
                    },
                })
                    .then(response1 => {
                        // console.log('API Data:', response1);
                        setMessage('Published!');
                        setShowSuccessMessage(true);
                        setTimeout(() => setShowSuccessMessage(false), 5000);
                        setispublished(!ispublished);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                        setMessage(`Failed to publish the project "${project.title}". Please try again.`);
                        setShowSuccessMessage(true);
                        setTimeout(() => setShowSuccessMessage(false), 5000);
                    });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setMessage(`Failed to publish the project "${project.title}". Please try again.`);
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            });
    };
    const copyProject = (item) => {
        setOpenDropdownIndex(null);
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/copy_respondent`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: item.project_id,
                respondent_data_id: item.id,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log("copied");
                    setMessage('Project Copied!');
                    setShowSuccessMessage(true);
                    setTimeout(() => setShowSuccessMessage(false), 5000);
                    setispublished(!ispublished);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setMessage(`Failed to copy the project "${item.title}". Please try again.`);
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            });
    }

    const editProject = (item) => {
        setOpenDropdownIndex(null);
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/get_respondent_project`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: item.project_id,
            },
        })
            .then(response => {
                // console.log("get detail", response);
                if (response.status === 200) {
                    // console.log("get detail");
                    setrespondentProjectData(response?.data?.respondent_response);
                    panelref.current.click();
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setMessage(`Failed to delete the project "${item.title}". Please try again.`);
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            });
    }

    const deleteProject = (item) => {
        setOpenDropdownIndex(null);
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/delete_respondent`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: item.project_id,
                respondent_data_id: item.id,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log("deleted");
                    setMessage('Project Deleted!');
                    setShowSuccessMessage(true);
                    setTimeout(() => setShowSuccessMessage(false), 5000);
                    setispublished(!ispublished);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setMessage(`Failed to delete the project "${item.title}". Please try again.`);
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            });
    }
    const launchProject = (item) => {
        const token = localStorage.getItem("access");
        const newStatus = !item.pause;
        axios({
            method: "POST",
            url: `${domain}/duonut/launch_pause_respondent`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: item.project_id,
                respondent_data_id: item.id,
                status: newStatus,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log("launch successful");
                    setispublished(!ispublished);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    const projectResponses = (item) => {
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/get_respondent_response`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                project_id: item.project_id,
            },
        })
            .then(data => {
                // console.log('Screener Data:', data);
                setScreenerData(data?.data?.screener_response?.results);
                // console.log('Fetched Screener data:', data?.data?.screener_response?.results);
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const copyShareLink = (item, index) => {
        navigator.clipboard.writeText(item.invite_link)
            .then(() => {
                setCopiedItemId(index);
                setTimeout(() => setCopiedItemId(null), 2000);
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    }
    const inviteParticipant = (screener, item) => {
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/invite_participant`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: screener.projectId,
                screener_response_id: screener.id,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log("invited");
                    // setispublished(!ispublished);
                    projectResponses(item);
                }
            })
            .catch(error => {
                console.error('Error inviting participant :', error);
            });

    }
    const attendedParticipant = (screener, item) => {
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/attended_participant`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: screener.projectId,
                screener_response_id: screener.id,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log("attended");
                    // setispublished(!ispublished);
                    projectResponses(item);
                }
            })
            .catch(error => {
                console.error('Error attende participant:', error);
            });
    }
    const disqualifyParticipant = (screener, item) => {
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/disqualify_participant`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: screener.projectId,
                screener_response_id: screener.id,
                status: !screener.qualified,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log("disqualify");
                    // setispublished(!ispublished);
                    projectResponses(item);
                }
            })
            .catch(error => {
                console.error('Error disqualify participant:', error);
            });
    }
    const hideParticipant = (screener, item) => {
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/hide_participant`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: screener.projectId,
                screener_response_id: screener.id,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log("hidden");
                    setispublished(!ispublished);
                    projectResponses(item);
                }
            })
            .catch(error => {
                console.error('Error hidden participant:', error);
            });
    }
    const reportParticipant = (screener, item) => {
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/report_participant`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: screener.projectId,
                screener_response_id: screener.id,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log("report");
                    setispublished(!ispublished);
                    projectResponses(item);
                }
            })
            .catch(error => {
                console.error('Error report participant:', error);
            });
    }
    const cancelInviteParticipant = (screener, item) => {
        const token = localStorage.getItem("access");
        axios({
            method: "POST",
            url: `${domain}/duonut/cancel_invite_participant`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                // Add your payload data here
                project_id: screener.projectId,
                screener_response_id: screener.id,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    // console.log("cancel invite");
                    // setispublished(!ispublished);
                    projectResponses(item);
                }
            })
            .catch(error => {
                console.error('Error cancel invite participant:', error);
            });
    }
    return (
        <div>
            <button ref={panelref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#panel"></button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="panel" tabIndex="-1" aria-labelledby="panelLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "100%", maxHeight: "100%" }}>
                    <div className="modal-content" style={{ margin: "auto", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px", width: "65vw" }}>
                        <div className="modal-body row">
                            <div style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc", paddingBottom: "10px"
                            }}>
                                <button ref={panelref1} style={{ width: "5rem", height: "2rem", display: "flex", alignItems: "center", justifyContent: "center" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                {/* <h2 style={{ margin: "2vh 0vh", borderBottom: "1px solid grey" }}> Panel</h2> */}
                                <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20px" }}>Panel</h2>
                            </div>
                            {/* <RespondentPanel link={respondentProjectData?.unmoderatedStudyOrSurvey?.remoteCommunicationDetails} projectData={respondentProjectData} /> */}
                        </div>

                    </div>
                </div>
            </div>

            <div style={{ padding: "20px" }}>
                <h2 style={{ margin: "20px 0px", fontSize: "24px", fontWeight: "bold" }}>Respondent Data</h2>
                {respondentData && respondentData.length > 0 ? (
                    <div>
                        {respondentData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by createdAt (newest first)
                            .map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        textAlign: "left",
                                        background: "#ECEFF1",
                                        borderRadius: "5px",
                                        padding: "20px",
                                        margin: "10px 0px",
                                        position: "relative",
                                    }}
                                >
                                    {/* Header Section */}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                                            {item.title}
                                        </p>

                                        {/* Buttons */}
                                        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                            {/* Play/Pause Button */}
                                            <p onClick={() => copyShareLink(item, index)}
                                                style={{
                                                    textDecoration: "underline", fontSize: "14px",
                                                    color: copiedItemId === index ? "#4CAF50" : "#1565C0", cursor: "pointer"
                                                }}>
                                                {copiedItemId === index ? "Copied the link" : "Share with your own Participant"}
                                            </p>
                                            {item.project_status === "PUBLISHED" &&
                                                <button
                                                    className="dot-and-copy-btn"
                                                    onClick={() => launchProject(item)}
                                                >
                                                    {item.pause ? (
                                                        <i className="fa fa-play" aria-hidden="true"></i>
                                                    ) : (
                                                        <i className="fa fa-pause" aria-hidden="true"></i>
                                                    )}
                                                </button>
                                            }
                                            {/* Three Dots Dropdown */}
                                            <div className="btn-group" style={{ position: "relative" }}>
                                                <button
                                                    type="button"
                                                    className="dropdown-toggle dot-and-copy-btn"
                                                    aria-haspopup="true"
                                                    aria-expanded={openDropdownIndex === index}
                                                    onClick={() => toggleDropdown(index)}
                                                    style={{ zIndex: 1 }}
                                                >
                                                    <i
                                                        className="fa fa-ellipsis-h"
                                                        aria-hidden="true"
                                                        style={{ fontSize: "18px", color: "#333" }}
                                                    ></i>
                                                </button>

                                                {openDropdownIndex === index && (
                                                    <div
                                                        className="dropdown-menu dropdown-menu-end"
                                                        style={{
                                                            display: "block",
                                                            position: "absolute",
                                                            top: "100%",
                                                            left: "auto",
                                                            right: 0,
                                                            zIndex: 10,
                                                            marginTop: "4px",
                                                            width: "7rem",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        {item.project_status !== "PUBLISHED" && (
                                                            <><button
                                                                style={{
                                                                    background: "transparent",
                                                                    borderBottom: "1px solid #ccc",
                                                                    width: "100%",
                                                                    cursor: "pointer",
                                                                }}
                                                                className="dropdown-item custom-dropdown-item"
                                                                type="button"
                                                                onClick={() => handlePublishProject(item)}
                                                            >
                                                                Publish
                                                            </button>
                                                            </>)}
                                                        <button
                                                            style={{
                                                                background: "transparent",
                                                                borderBottom: "1px solid #ccc",
                                                                width: "100%",
                                                                cursor: "pointer",
                                                            }}
                                                            className="dropdown-item custom-dropdown-item"
                                                            type="button"
                                                            onClick={() => copyProject(item)}
                                                        >
                                                            Copy
                                                        </button>

                                                        {/* {item.project_status !== "PUBLISHED" && item.methodology === "survey" && <button
                                                            style={{
                                                                background: "transparent",
                                                                borderBottom: "1px solid #ccc",
                                                                width: "100%",
                                                                cursor: "pointer",
                                                            }}
                                                            className="dropdown-item custom-dropdown-item"
                                                            type="button"
                                                            onClick={() => editProject(item)}
                                                        >
                                                            Edit
                                                        </button>} */}

                                                        {item.project_status !== "PUBLISHED" && <button
                                                            style={{
                                                                background: "transparent",
                                                                width: "100%",
                                                                cursor: "pointer",
                                                            }}
                                                            className="dropdown-item custom-dropdown-item"
                                                            type="button"
                                                            onClick={() => deleteProject(item)}
                                                        >
                                                            Delete
                                                        </button>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Display Project Information */}
                                    <div>
                                        <p style={{ fontSize: "16px" }}>
                                            {/* {item.title} <br /> */}
                                            {item.survey_link} &nbsp;
                                            <button className="dot-and-copy-btn">
                                                <i
                                                    className="fa fa-clone"
                                                    aria-hidden="true"
                                                    style={{ fontSize: "14px" }}
                                                ></i>
                                            </button>
                                        </p>

                                        <span style={{ color: "grey", fontSize: "14px" }}>
                                            Created {formatDate(item.created_at)}
                                        </span>

                                        {/* Project Status */}
                                        <div>
                                            {item.project_status === "DRAFT" ? (
                                                <span style={{ color: "#0E2C45", fontWeight: "bold", fontSize: "16px" }}>
                                                    UNPUBLISHED
                                                </span>
                                            ) : item.project_status === "PUBLISHED" ? (
                                                <span style={{ color: "green", fontWeight: "bold", fontSize: "16px" }}>
                                                    PUBLISHED
                                                </span>
                                            ) : (
                                                <span>{item.project_status}</span>
                                            )}
                                        </div>
                                    </div>

                                    {/* Screener Data */}
                                    {item.project_status === "PUBLISHED" ? (
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <button
                                                style={{
                                                    background: "transparent", border: "1px solid #9ba4a9", padding: "8px", borderRadius: "5px", marging: "10px 0px",
                                                    cursor: "pointer",
                                                    width: "20%",
                                                    textAlign: "center",
                                                }}
                                                onClick={() => { toggleResponses(index); projectResponses(item); }}
                                            >
                                                {activeProjectIndex === index ? "Hide Responses" : "Show Responses"}
                                            </button>
                                        </div>) :
                                        (<></>)
                                    }
                                    {activeProjectIndex === index && (
                                        <div
                                            style={{
                                                overflow: "hidden",
                                                transition: "max-height 0.3s ease",
                                                marginTop: "15px", borderTop: "1px solid #ccc", paddingTop: "10px"
                                            }}
                                        >
                                            {/* Show loader while data is loading */}
                                            {!screenerData ? (
                                                <img
                                                    alt="loader"
                                                    src={require('../../assets/Images/loader.gif')}
                                                    style={{ margin: 'auto', height: '2.5rem', padding: "20px" }}
                                                />
                                            ) : screenerData.length > 0 ? (
                                                screenerData.map((screener, screenerIndex) => (
                                                    <div
                                                        key={screenerIndex}
                                                        style={{
                                                            background: "#F5F5F5",
                                                            padding: "15px",
                                                            margin: "10px 0px",
                                                            borderRadius: "5px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "space-between",
                                                            cursor: "pointer",
                                                            paddingBottom: "85px"
                                                        }}
                                                    // Toggle screener details
                                                    >
                                                        <div style={{
                                                            background: "#F5F5F5",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}>
                                                            <div onClick={() => handleScreenerClick(screenerIndex)}
                                                                className='row'
                                                                style={{ cursor: "pointer", width: "100%" }}>
                                                                <div className="col-lg-6" style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                                    <i className="fa fa-user-circle-o" aria-hidden="true" style={{ fontSize: "60px" }}></i>&nbsp;
                                                                    {screener.profile.firstName} {screener.profile.lastName}
                                                                </div>
                                                                <div className="col-lg-6" style={{ display: "flex", alignItems: "center" }}>
                                                                    {screener.status === "PENDING" ?
                                                                        <>{
                                                                            screener.qualified ? <span style={{ color: "green" }}>Qualified</span>
                                                                                : <span style={{ color: "#0E2C45" }}>Un-qualified</span>
                                                                        }</>
                                                                        : (
                                                                            <span style={{ color: "green" }}>{screener.status}</span>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "flex-end",
                                                                        gap: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    {screener.status === "PENDING" && screener.qualified ? (<button
                                                                        style={{
                                                                            border: "1px solid #ccc",
                                                                            padding: "10px 5px",
                                                                            fontSize: "14px",
                                                                            width: "100%",
                                                                            cursor: "pointer",
                                                                            borderRadius: "5px"
                                                                        }}
                                                                        onClick={() => { inviteParticipant(screener, item) }}
                                                                    >
                                                                        Invite
                                                                    </button>) : screener.status === "INVITED" ? (
                                                                        <button
                                                                            style={{
                                                                                border: "1px solid #ccc",
                                                                                padding: "10px 5px",
                                                                                fontSize: "14px",
                                                                                width: "100%",
                                                                                cursor: "pointer",
                                                                                borderRadius: "5px"
                                                                            }}
                                                                            onClick={() => attendedParticipant(screener, item)}
                                                                        >
                                                                            Mark as Complete
                                                                        </button>
                                                                    ) : (<></>)}
                                                                    <div
                                                                        className="btn-group"
                                                                        style={{ position: "relative", overflow: "visible" }}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="dropdown-toggle dot-and-copy-btn"
                                                                            aria-haspopup="true"
                                                                            aria-expanded={openScreenerDropdownIndex === screenerIndex}
                                                                            onClick={() => toggleScreenerDropdown(screenerIndex)}
                                                                            style={{ zIndex: 1, border: "1px solid #ccc" }}
                                                                        >
                                                                            <i
                                                                                className="fa fa-ellipsis-h"
                                                                                aria-hidden="true"
                                                                                style={{ fontSize: "18px", color: "#333" }}
                                                                            ></i>
                                                                        </button>

                                                                        {openScreenerDropdownIndex === screenerIndex && (
                                                                            <div
                                                                                className="dropdown-menu dropdown-menu-end"
                                                                                style={{
                                                                                    display: "block",
                                                                                    position: "absolute", // Keep this to position relative to the container
                                                                                    top: "100%",
                                                                                    left: "50%", // Start dropdown relative to its container's center
                                                                                    transform: "translateX(-90%)", // Center it horizontally
                                                                                    zIndex: 9999, // Ensure dropdown appears above everything
                                                                                    marginTop: "4px",
                                                                                    width: "160px", // Optional: Adjust the width if needed
                                                                                    padding: "15px",
                                                                                    backgroundColor: "#fff", // Add background color
                                                                                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
                                                                                    whiteSpace: "nowrap", // Prevent text wrapping
                                                                                }}
                                                                            >
                                                                                {(screener.status === "PENDING") && (
                                                                                    <button
                                                                                        style={{
                                                                                            background: "transparent",
                                                                                            borderBottom: "1px solid #ccc",
                                                                                            width: "100%",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        className="dropdown-item custom-dropdown-item"
                                                                                        type="button"
                                                                                        onClick={() => disqualifyParticipant(screener, item)}
                                                                                    >
                                                                                        {screener.qualified ? "Disqualify Participant" : "Qualify Participant"}
                                                                                    </button>
                                                                                )}
                                                                                {(screener.status === "PENDING") && (
                                                                                    <button
                                                                                        style={{
                                                                                            background: "transparent",
                                                                                            borderBottom: "1px solid #ccc",
                                                                                            width: "100%",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        className="dropdown-item custom-dropdown-item"
                                                                                        type="button"
                                                                                        onClick={() => hideParticipant(screener, item)}
                                                                                    >
                                                                                        Hide Participant
                                                                                    </button>
                                                                                )}
                                                                                {(screener.status === "ATTENDED" || screener.status === "INVITED") && (
                                                                                    <button
                                                                                        style={{
                                                                                            background: "transparent",
                                                                                            borderBottom: "1px solid #ccc",
                                                                                            width: "100%",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        className="dropdown-item custom-dropdown-item"
                                                                                        type="button"
                                                                                        onClick={() => cancelInviteParticipant(screener, item)}
                                                                                    >
                                                                                        Cancel Invite
                                                                                    </button>
                                                                                )}
                                                                                <button
                                                                                    style={{
                                                                                        background: "transparent",
                                                                                        width: "100%",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    className="dropdown-item custom-dropdown-item"
                                                                                    type="button"
                                                                                    onClick={() => reportParticipant(screener, item)}
                                                                                >
                                                                                    Report Participant
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <p style={{ fontSize: "12px", textAlign: "end" }}>
                                                                    Applied on &nbsp;
                                                                    {new Date(screener.createdAt).toLocaleDateString('en-US', {
                                                                        month: "short",
                                                                        day: "numeric",
                                                                        year: "numeric",
                                                                    })}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* Show expanded screener data below the clicked screener */}
                                                        {expandedScreenerIndex === screenerIndex && (
                                                            <div style={{ marginTop: '15px', backgroundColor: "#E8E8E8", padding: '15px', borderRadius: '5px' }}>
                                                                <h3>Details for {screener.profile.firstName} {screener.profile.lastName}</h3>
                                                                <p>{screener.percentage} % qualified on screener</p><br />
                                                                {/* Show responses for the selected screener */}
                                                                {screener.responses && screener.responses.map((response, responseIndex) => (
                                                                    <div key={responseIndex} style={{ marginBottom: "10px" }}>
                                                                        <div><strong>Question: </strong>{response.questionText}</div>
                                                                        <div><strong>Type: </strong>{response.questionType}</div>
                                                                        <div><strong>Answer: </strong>
                                                                            {Array.isArray(response.answers)
                                                                                ? response.answers.join(", ") // Display answers if it's an array
                                                                                : response.answers
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No screener data available.</p>
                                            )}
                                        </div>
                                    )}



                                </div>
                            ))}
                    </div>
                ) : (
                    <div>No data available</div>
                )}
            </div>
            <AnimatePresence>
                {showSuccessMessage && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        style={{
                            position: "fixed",
                            top: "5%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            background: "#4CAF50",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            fontSize: "16px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                        }}
                    >
                        {message}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>

    );
};

export default RespondentData;
