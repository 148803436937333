import React, { useRef, useState, useEffect } from 'react';
import './DuonutSettings.css';
// import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    updateDescription, updatePrivacy, updateTitle, updatePassword, updateCoverPhoto, updateAudio, updateViewerSliding,
    updateShowLeaderboard, updateShowStartpage, updateShowProgressbar, updateKeywords,
    updateShowCustomButton, updateCustomButtoMessage, updateCustomButtonUrl, updateShowEndpage,
    updateLeaderboardTitle, updateLeaderboardDescription, updateLeaderboardFontcolor, updateReferral, updateShowLocation,
    updateLeaderboardBgcolor, updateLeaderboardLogo, updateSlideLogo, updateCouponCode, updateCouponCodeFile, updateStartpageText, updateStartpageText2,
    updateStartpageTitle, updateStartpageDescription, updateStartpageFontcolor, updateStartpageBgcolor, updateStartpageLogo,
    updateShowConfetti, updatePlayAudio, updateRedirectUrl, updateShowViewerVertical, updateLock,
    updateIsDuonutCloseDate, updateIsDuonutCloseLimit, updateDuonutCloseDate, updateDuonutCloseLimit, updateSlideLogoAlignment,
    updateOpenEndedConversationLimit, updateShowAiWelcomepage, updateAiWelcomepageTitle, updateAiWelcomepageDescription,
    updateAiWelcomepageFontcolor, updateAiWelcomepageBgcolor, updateNoOfUserViews, updateRedirectUrlToggle
} from "../../redux/slices/duonutSettings";
import EditDuonutIcon from "../../assets/icons/edit-duonut.svg";
import { SketchPicker } from "react-color";
import Papa from 'papaparse';
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { toggleProMessageModalDuonut, updateProMessageModalMessage } from "../../redux/slices/ProMessageReducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { domain } from "../../services/constants";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Resizer from 'react-image-file-resizer';
import { getFileSizeFromBase64, toDataUrl } from "../../utils";
import Loader from '../../assets/Images/loader.gif';
// import { editAiDuonutNewAsync } from '../../redux/slices/AiDuonutNewReducer';
// import { saveAiNewAsync } from '../../redux/slices/AiNewReducer';
import { creditDeduction } from '../../services/duonut';
import { AnimatePresence, motion } from 'framer-motion';

const maxTitleChar = 50;
const maxDescChar = 200;
const maxPassChar = 6;

const DuonutSettings = () => {
    // const navigate = useNavigate();
    const inputRefFirst = useRef(null);
    const inputRefSecond = useRef(null);
    const inputFileRef = useRef(null);
    const inputFileLogoRef = useRef(null);
    const inputFileLogoRef1 = useRef(null);
    const inputFileLogoRef2 = useRef(null);
    const { width } = useWindowDimensions();

    const { show_leaderboard, show_startpage, show_progressbar, show_custom_button,
        custom_button_message, custom_button_url, show_endpage, leaderboard_title, leaderboard_description, leaderboard_fontcolor,
        leaderboard_bgcolor, coupon_code, startpage_text, startpage_text2,
        startpage_title, startpage_description, startpage_fontcolor, startpage_bgcolor, keywords,
        show_confetti, play_audio, redirect_url, is_duonut_close_date, duonut_close_date, slide_logo_alignment,
        is_duonut_close_limit, duonut_close_limit, show_viewer_vertical, viewer_sliding, open_ended_conversation_limit, no_of_user_views, is_redirect_url,
        coverPhoto, audio, slide_logo, leaderboard_logo, startpage_logo, coupon_code_file,
        show_ai_welcomepage, ai_welcomepage_title, ai_welcomepage_description, ai_welcomepage_fontcolor,
        ai_welcomepage_bgcolor } = useSelector((state) => state.duonutSettings.settings);
    const { title, description, isPublic, isPrivate, password, selectedTab, lock } = useSelector((state) => state.duonutSettings);
    const [error, setError] = useState(false)
    const dispatch = useDispatch();
    // console.log("duonut_id", coupon_code)

    const { textFormData } = useSelector((state) => state.userActivity);
    const [passwordCheck, setpasswordCheck] = useState(false);
    const [settingTabNum, setsettingTabNum] = useState(1);
    const colorPickerRef = useRef(null);
    const [isActiveColor, setIsActiveColor] = useState(0);
    const [showCoupon, setshowCoupon] = useState(false);
    const [suggestedVar, setSuggestedVar] = useState([]);
    const [popUpPositionX, setPopUpPositionX] = useState(null)
    const [popUpPositionY, setPopUpPositionY] = useState(null)
    const suggestionRef = useRef(null)
    const { userProAccess, credit } = useSelector((state) => state.user);

    const location = useLocation();
    const [paramsid, setparamsid] = useState("");
    const [loading, setloading] = useState(false);

    const creditref = useRef(null);
    const creditref1 = useRef(null);
    const [eventNo, seteventNo] = useState(0);
    const musicRef = useRef(null);
    // const logoRef = useRef(null);
    const [conversationLimit, setconversationLimit] = useState(open_ended_conversation_limit);
    const [totalUserResponse, settotalUserResponse] = useState(no_of_user_views);
    // const { active: aiActive, aiData } = useSelector((state) => state.AiSettingNew.aiNewModalSetting);
    const [isEditingUserViews, setIsEditingUserViews] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [error_message, setErrorMessage] = useState("");

    useEffect(() => {

        // Get the current pathname from the window location
        const pathname = location.pathname;

        // Split the pathname into an array of words
        const words = pathname.split('/');

        // Get the last word (assuming the URL ends with a word)
        // const lastWord = words[words.length - 1];

        // Use a regular expression to match and capture the numeric part of the URL
        const matchResult = pathname.match(/\/design\/(\d+)/);

        // Check if there is a match and retrieve the captured number
        if (matchResult && matchResult[1]) {
            const extractedNumber = parseInt(matchResult[1]);
            // console.log('Extracted Number:', extractedNumber);
            setparamsid(extractedNumber);
        }
        // Log or use the last word as needed
        // console.log('Last word:', lastWord);

    }, [location.pathname]);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    const handleOpenColor = (num) => {
        setIsActiveColor(num);
    };

    const handleCloseColor = () => setIsActiveColor(0);
    useOutsideAlerter(colorPickerRef, handleCloseColor);

    const handleImageChange = (e) => {
        // console.log(e.target.files)
        setloading(true);
        const imageFile = e.target.files[0];
        if (imageFile.type === "image/png") {
            Resizer.imageFileResizer(
                imageFile,
                1000,
                1000,
                'PNG',
                90,
                0,
                uri => {
                    const image_data = {
                        duonut_id: paramsid,
                        name: e.target.files[0].name,
                        uri: uri,
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);
                        dispatch(updateCoverPhoto({ coverPhoto: res.data.file_url }));

                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    getFileSizeFromBase64(uri)
                },
                'base64'
            );

        } else {
            Resizer.imageFileResizer(
                imageFile,
                1000,
                1000,
                'JPEG',
                90,
                0,
                uri => {
                    const image_data = {
                        duonut_id: paramsid,
                        name: e.target.files[0].name,
                        uri: uri,
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);
                        dispatch(updateCoverPhoto({ coverPhoto: res.data.file_url }));

                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    getFileSizeFromBase64(uri)
                },
                'base64'
            );

        }

    };
    // useEffect(() => {
    //     setTotalCredit(credit === null ? 100 : credit);
    // }, [credit])

    useEffect(() => {
        setsettingTabNum(selectedTab);
    }, [])

    useEffect(() => {
        setconversationLimit(open_ended_conversation_limit === null ? 2 : open_ended_conversation_limit);
    }, [open_ended_conversation_limit])

    useEffect(() => {
        settotalUserResponse(no_of_user_views === null ? 10 : no_of_user_views);
    }, [no_of_user_views])

    useEffect(() => {
        if (password) {
            setpasswordCheck(true);
        }
    }, [password])

    useEffect(() => {
        // console.log("coupon_code", coupon_code)
        if (coupon_code_file?.name || coupon_code) {
            setshowCoupon(true);
        }
    }, [coupon_code])

    const onClickTab = (tab) => {
        if (settingTabNum !== tab) {
            setsettingTabNum(tab);
        } else {
            setsettingTabNum(0);
        }
    };

    const onPrivateChange = () => {
        // if (!userProAccess) {
        //     dispatch(toggleProMessageModalDuonut(true));
        //     dispatch(updateProMessageModalMessage('private access'));
        //     return null;
        // }
        dispatch(updatePrivacy());
    };

    const onPasswordChange = () => {
        // if (!userProAccess) {
        //     dispatch(toggleProMessageModalDuonut(true));
        //     dispatch(updateProMessageModalMessage('password access'));
        //     return null;
        // }
        setpasswordCheck(!passwordCheck);
        dispatch(updatePassword(""));
        if (credit >= 2) {
            var creditData = {
                name: `${title} - Password protect this Duonut`,
                message: `${title} - Password protect this Duonut`,
                cost: 2,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
            });

            setMessage('Credit has been deducted');
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);
        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    };

    const onLocationChange = () => {
        // if (!userProAccess) {
        //     dispatch(toggleProMessageModalDuonut(true));
        //     dispatch(updateProMessageModalMessage('location access'));
        //     return null;
        // }
        dispatch(updateShowLocation());
    };

    const onRefferalChange = () => {
        // if (!userProAccess) {
        //     dispatch(toggleProMessageModalDuonut(true));
        //     dispatch(updateProMessageModalMessage('refferal access'));
        //     return null;
        // }
        dispatch(updateReferral())
    };

    const handleProSetingClick = (e, mess) => {
        if (!userProAccess) {
            dispatch(toggleProMessageModalDuonut(true));
            dispatch(updateProMessageModalMessage(`${mess} access`));
            e.preventDefault()
            return null;
        }
    }

    const handleAudioChange = (e) => {
        if (e.target.files[0]) {
            // console.log(URL.createObjectURL(e.target.files[0]));
            let aud = {};
            // console.log(e.target.files)
            toDataUrl(e.target.files[0], (res) => {
                let result = res;
                const audio_data = {
                    duonut_id: paramsid,
                    name: e.target.files[0].name,
                    uri: result,
                }
                axios({
                    method: "POST",
                    url: `${domain}/duonut/upload_files`,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify(audio_data),
                }).then((res) => {
                    //console.log(res.data.file_url);
                    dispatch(updateAudio({ audio: res.data.file_url }));

                }).catch((e) => {
                    console.log(e.message, e);
                });
            });
        }
        if (e.target.files[0] && credit >= 2) {
            var creditData = {
                name: `${title} - Added background music to this Duonut`,
                message: `${title} - Added background music to this Duonut`,
                cost: 2,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
            });
            setMessage('Credit has been deducted');
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);
        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    };

    const handleAudioDelete = (e) => {
        let aud = "";
        dispatch(updateAudio({ audio: aud }));
    };

    const handleSlideLogoChange = (e) => {
        // console.log(e.target.files)
        toDataUrl(e.target.files[0], (res) => {
            let result = res;
            const image_data = {
                duonut_id: paramsid,
                name: e.target.files[0].name,
                uri: result,
            }
            axios({
                method: "POST",
                url: `${domain}/duonut/upload_files`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify(image_data),
            }).then((res) => {
                //console.log(res.data.file_url);
                dispatch(updateSlideLogo({ slide_logo: res.data.file_url }));

            }).catch((e) => {
                console.log(e.message, e);
            });
        });
        if (e.target.files[0] && credit >= 2) {
            var creditData = {
                name: `${title} - Added Logo to this Duonut`,
                message: `${title} - Added Logo to this Duonut`,
                cost: 2,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
            });

            setMessage('Credit has been deducted');
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);
        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    };

    const handleSlideLogoDelete = (e) => {
        let slideLogo = "";
        dispatch(updateSlideLogo({ slide_logo: slideLogo }));
    };

    const handleCoverPhotoDelete = (e) => {
        let coverPhoto = "";
        dispatch(updateCoverPhoto({ coverPhoto: coverPhoto }));
    };

    const handleLeaderboardLogoChange = (e) => {
        // console.log(e.target.files)
        toDataUrl(e.target.files[0], (res) => {
            let result = res;
            const image_data = {
                duonut_id: paramsid,
                name: e.target.files[0].name,
                uri: result,
            }
            axios({
                method: "POST",
                url: `${domain}/duonut/upload_files`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify(image_data),
            }).then((res) => {
                //console.log(res.data.file_url);
                dispatch(updateLeaderboardLogo({ leaderboard_logo: res.data.file_url }));

            }).catch((e) => {
                console.log(e.message, e);
            });

        });
    };

    const handleLeaderboardLogoDelete = (e) => {
        let endpageLogo = "";
        dispatch(updateLeaderboardLogo({ leaderboard_logo: endpageLogo }));
    };

    const handleStartpageLogoChange = (e) => {
        // console.log(e.target.files)
        toDataUrl(e.target.files[0], (res) => {
            let result = res;
            const image_data = {
                duonut_id: paramsid,
                name: e.target.files[0].name,
                uri: result,
            }
            axios({
                method: "POST",
                url: `${domain}/duonut/upload_files`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify(image_data),
            }).then((res) => {
                //console.log(res.data.file_url);
                dispatch(updateStartpageLogo({ startpage_logo: res.data.file_url }));

            }).catch((e) => {
                console.log(e.message, e);
            });

        });
    };

    const handleStartpageLogoDelete = (e) => {
        let startpageLogo = "";
        dispatch(updateStartpageLogo({ startpage_logo: startpageLogo }));
    };

    const handleCustomButton = () => {
        // if (!userProAccess) {
        //     dispatch(toggleProMessageModalDuonut(true));
        //     dispatch(updateProMessageModalMessage('custom button access'));
        //     return null;
        // }
        if (show_custom_button) {
            var blank = "";
            dispatch(updateCustomButtoMessage(blank));
            dispatch(updateCustomButtonUrl(blank));
        }
        dispatch(updateShowCustomButton());
        if (credit >= 2) {
            var creditData = {
                name: `${title} - CTA Action`,
                message: `${title} - CTA Action`,
                cost: 2,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
            });
            setMessage('Credit has been deducted');
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);
        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    };

    const handleCouponButton = () => {
        // if (!userProAccess) {
        //     dispatch(toggleProMessageModalDuonut(true));
        //     dispatch(updateProMessageModalMessage('coupon access'));
        //     return null;
        // }
        // console.log(showCoupon);
        if (showCoupon) {
            // console.log(showCoupon);
            let couponCodeEmpty = {};
            dispatch(updateCouponCodeFile({ coupon_code_file: couponCodeEmpty }));
            dispatch(updateCouponCode(""));
        }
        setshowCoupon(!showCoupon);
    };

    const handleColorChange = (e, col) => {
        const updatedColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        // console.log(e, updatedColor);
        if (col == "font") {
            dispatch(updateLeaderboardFontcolor(e.hex))
        }
        if (col == "bg") {
            dispatch(updateLeaderboardBgcolor(e.hex))
        }
    }

    const handleColorChange1 = (e, col) => {
        const updatedColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        // console.log(e, updatedColor);
        if (col == "font") {
            dispatch(updateStartpageFontcolor(e.hex))
        }
        if (col == "bg") {
            dispatch(updateStartpageBgcolor(e.hex))
        }
    }
    const handleColorChange2 = (e, col) => {
        const updatedColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        // console.log(e, updatedColor);
        if (col == "font") {
            dispatch(updateAiWelcomepageFontcolor(e.hex))
        }
        if (col == "bg") {
            dispatch(updateAiWelcomepageBgcolor(e.hex))
        }
    }

    const handleCouponCodeFileUpload = (e) => {

        if (e.target.files[0]) {
            // console.log(URL.createObjectURL(e.target.files[0]));
            let CCfile = {};
            // console.log(e.target.files)
            toDataUrl(e.target.files[0], (res) => {
                let result = res;
                let resData = { name: e.target.files[0].name, uri: result };
                CCfile = { ...resData };
                dispatch(updateCouponCodeFile({ coupon_code_file: CCfile }));
            });
        }

        const file = e.target.files[0];

        Papa.parse(file, {
            header: false,
            delimiter: ',',
            complete: (result) => {

                // console.log(result);
                const extractedData = result.data;

                // Convert the extracted data into a comma-separated string
                const csvString = extractedData.map(row => Object.values(row).join(','));

                // Store the comma-separated string in the state variable
                // console.log(csvString);
                const arrayString = csvString.join(',');

                dispatch(updateCouponCode(arrayString));

                // console.log(arrayString);
            }
        });
        // if (e.target.files[0] && credit >= 2) {
        //     var creditData = {
        //         name: `${e.target.files[0].name} - Added Coupon Code to this Duonut`,
        //         message: `${e.target.files[0].name} - Added Coupon Code to this Duonut`,
        //         cost: 2,
        //     }
        //     creditDeduction(creditData, (err, res) => {
        //         if (err) return console.log(err.response);
        //         // console.log(res);
        //     });

        // }
        // else {
        //     alert("Insufficient credits");
        // }
    };


    const handleClosesug = () => setSuggestedVar([])
    useOutsideAlerter(suggestionRef, handleClosesug);

    const handleChangeEndPageTitle = (e) => {

        const inputValue = e.target.value;

        // Check if "@" is entered
        const atIndex = inputValue.indexOf("@");
        if (atIndex !== -1 && Object.keys(textFormData).length > 0) {
            // Extract the text after "@" until the cursor position
            const textAfterAt = inputValue.substring(atIndex + 1, e.target.selectionStart);

            // Filter formData keys that match the entered text
            const matchingKeys = Object.keys(textFormData).filter((key) =>
                key.toLowerCase().includes(textAfterAt.toLowerCase())
            );

            const cursorRect = e.target.getBoundingClientRect();
            // console.log(e, cursorRect)

            setPopUpPositionX(cursorRect.width);
            setPopUpPositionY(cursorRect.height);

            // Display suggestions
            setSuggestedVar(matchingKeys);
        } else {
            // Clear suggestions if "@" is not present
            setSuggestedVar([]);
        }

        dispatch(updateLeaderboardTitle(e.target.value))
    };

    const handleChooseSuggestion = (selectedSuggestion) => {
        // console.log("selectedSuggestion", selectedSuggestion)
        // Get the current input value
        const currentValue = leaderboard_title;
        // console.log("currentValue", currentValue)

        // Find the position of "@" in the current value
        const atIndex = currentValue.indexOf("@");

        // Replace the text after "@" until the cursor position with the selected suggestion
        const updatedValue = currentValue.substring(0, atIndex) + `{{${selectedSuggestion}}}` + currentValue.substring(atIndex + 1);

        // Clear suggestions
        setSuggestedVar([]);

        // Dispatch the updated question
        dispatch(updateLeaderboardTitle(updatedValue));
    };

    const handleIsDuonutCloseDate = () => {
        // if (!userProAccess) {
        //     dispatch(toggleProMessageModalDuonut(true));
        //     dispatch(updateProMessageModalMessage('Schedule a close date access'));
        //     return null;
        // }
        if (is_duonut_close_date) {
            const blank = new Date();
            dispatch(updateDuonutCloseDate(blank));
        }
        dispatch(updateIsDuonutCloseDate());
        if (credit >= 2) {
            var creditData = {
                name: `${title} - Schedule a close date in this Duonut`,
                message: `${title} - Schedule a close date in this Duonut`,
                cost: 2,
            }
            creditDeduction(creditData, (err, res) => {
                if (err) return console.log(err.response);
                // console.log(res);
            });
            setMessage('Credit has been deducted');
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 5000);
        }
        else {
            setErrorMessage('There is no sufficient credit to perform this operation');
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            // console.log("Insufficient credits");
        }
    };
    const handleCloseDateChange = (date) => {
        dispatch(updateDuonutCloseDate(date));
    };
    const handleIsDuonutCloseLimit = () => {
        // if (!userProAccess) {
        //     dispatch(toggleProMessageModalDuonut(true));
        //     dispatch(updateProMessageModalMessage('Close on submission limit access'));
        //     return null;
        // }
        if (is_duonut_close_limit) {
            var blank = 0;
            dispatch(updateDuonutCloseLimit(blank));
        }
        dispatch(updateIsDuonutCloseLimit());
    };

    var costConversationLimit = 0;
    var perConversationCost = 2;
    var costUserResponse = 0;
    var perUserCreditCost = 2;

    const newLimitChange = conversationLimit - open_ended_conversation_limit;
    if (newLimitChange < 0) { costConversationLimit = 0; }
    else {
        const applicableIncrements_conversation = Math.ceil((newLimitChange) / 2);
        costConversationLimit = perConversationCost * applicableIncrements_conversation;
    }


    const newUserResponses = totalUserResponse - no_of_user_views;
    if (newUserResponses < 0) { costUserResponse = 0; }
    else {
        const applicableIncrements_userResponse = Math.ceil((newUserResponses) / 10);
        costUserResponse = perUserCreditCost * applicableIncrements_userResponse;
    }

    const creditDeduct = () => {
        if (eventNo === 1) {
            dispatch(updateLock(!lock));
            if (credit >= 2) {
                var creditData = {
                    name: `${title} - Lock the Duonut`,
                    message: `${title} - Lock the Duonut`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });
                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        } else if (eventNo === 2) {
            onPrivateChange();
            if (credit >= 2) {
                var creditData = {
                    name: `${title} - Private Duonut`,
                    message: `${title} - Private Duonut`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });
                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        } else if (eventNo === 3) {
            onPasswordChange();

        } else if (eventNo === 4) {
            musicRef.current.click();
        } else if (eventNo === 5) {
            inputFileLogoRef2.current.click()
        } else if (eventNo === 6) {
            handleIsDuonutCloseDate();
        } else if (eventNo === 7) {
            // console.log("credict-deducted -event -7");
            handleIsDuonutCloseLimit();
            if (credit >= 2) {
                var creditData = {
                    name: `${title} - Added Submission Limit`,
                    message: `${title} - Added Submission Limit`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });
                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        } else if (eventNo === 8) {
            // console.log("open-ended");

            if (credit >= costConversationLimit) {
                setIsEditing(false);
                dispatch(updateOpenEndedConversationLimit(conversationLimit));
                var creditData = {
                    name: `${title} - Open Ended Follow-up Conversation Limit`,
                    message: `${title} - Open Ended Follow-up Conversation Limit`,
                    cost: costConversationLimit,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });
                if (costConversationLimit === 0) {
                    setMessage('0 Credit has been deducted');
                    setShowSuccessMessage(true);
                    setTimeout(() => setShowSuccessMessage(false), 5000);
                }
                else {
                    setMessage('Credit has been deducted');
                    setShowSuccessMessage(true);
                    setTimeout(() => setShowSuccessMessage(false), 5000);
                }
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        } else if (eventNo === 9) {
            // console.log("no of user views");


            if (credit >= costUserResponse) {
                setIsEditingUserViews(false);
                dispatch(updateNoOfUserViews(totalUserResponse));
                var creditData = {
                    name: `${title} - Number of User Views`,
                    message: `${title} - Number of User Views`,
                    cost: costUserResponse,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });
                if (costUserResponse === 0) {
                    setMessage('0 Credit has been deducted');
                    setShowSuccessMessage(true);
                    setTimeout(() => setShowSuccessMessage(false), 5000);
                }
                else {
                    setMessage('Credit has been deducted');
                    setShowSuccessMessage(true);
                    setTimeout(() => setShowSuccessMessage(false), 5000);
                }
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        } else if (eventNo === 10) {
            dispatch(updateShowStartpage());
            if (credit >= 2) {
                var creditData = {
                    name: `${title} - Cover Page for this Duonut`,
                    message: `${title} - Cover Page for this Duonut`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });

                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        } else if (eventNo === 11) {
            dispatch(updateShowLeaderboard());
            if (credit >= 2) {
                var creditData = {
                    name: `${title} - Leaderboard at the end`,
                    message: `${title} - Leaderboard at the end`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });

                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        } else if (eventNo === 12) {
            dispatch(updateShowConfetti());
            if (credit >= 2) {
                var creditData = {
                    name: `${title} - Shows Confetti at the end page`,
                    message: `${title} - Shows Confetti at the end page`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });

                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        } else if (eventNo === 13) {
            dispatch(updatePlayAudio());
            if (credit >= 2) {
                var creditData = {
                    name: `${title} - Plays a sound at the end`,
                    message: `${title} - Plays a sound at the end`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });

                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        } else if (eventNo === 14) {
            handleCustomButton();
        } else if (eventNo === 15) {
            handleCouponButton();
        } else if (eventNo === 16) {
            dispatch(updateRedirectUrlToggle());
            if (credit >= 2) {
                var creditData = {
                    name: `${title} - Redirection URL`,
                    message: `${title} - Redirection URL`,
                    cost: 2,
                }
                creditDeduction(creditData, (err, res) => {
                    if (err) return console.log(err.response);
                    // console.log(res);
                });

                setMessage('Credit has been deducted');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
            }
            else {
                setErrorMessage('There is no sufficient credit to perform this operation');
                setShowErrorMessage(true);
                setTimeout(() => setShowErrorMessage(false), 5000);
                // console.log("Insufficient credits");
            }
        }
        creditref1.current.click();
    };


    return (<>
        <button ref={creditref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#creditModal">
        </button>
        <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="creditModal" tabIndex="-1" aria-labelledby="creditModalLabel" aria-hidden="true">
            <div className="modal-dialog" style={{ maxWidth: "40vw" }}>
                <div className="modal-content" style={{ marginTop: "30vh", textAlign: "center", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                    <div className="modal-body">
                        <button ref={creditref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <h2 style={{ margin: "2vh 0vh" }}> Credit Deduct</h2>
                        <br></br>
                        <div style={{}}>
                            {/* {eventNo === 1 && <label>2 credit required for Lock this Duonut</label>}
                            {eventNo === 2 && <label>2 credit required for Private this Duonut</label>}
                            {eventNo === 3 && <label>2 credit required for password protected in this Duonut</label>}
                            {eventNo === 4 && <label>2 credit required for Add background music in this Duonut</label>}
                            {eventNo === 5 && <label>2 credit required for Upload your logo in this Duonut</label>}
                            {eventNo === 6 && <label>2 credit required for Schedule a close date in this Duonut</label>}
                            {eventNo === 7 && <label>2 credit required for Close on submission limit in this Duonut</label>}
                            {eventNo === 8 && <label>2 credit required for Open Ended Follow-up Conversation Limit</label>}
                            {eventNo === 9 && <label>2 credit required for Number of user views</label>}
                            {eventNo === 10 && <label>2 credit required for the Cover Page</label>}
                            {eventNo === 11 && <label>2 credit required for the Leaderboard Feature</label>}
                            {eventNo === 12 && <label>2 credit required for the Confetti Feature</label>}
                            {eventNo === 13 && <label>2 credit required for the Cover Page Audio</label>}
                            {eventNo === 14 && <label>2 credit required for the CTA Action</label>}
                            {eventNo === 15 && <label>2 credit required for the Coupon Code</label>}
                            {eventNo === 16 && <label>2 credit required for the Redirection URL</label>} */}

                            {eventNo === 8 ? (
                                <label style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src="../../assets/icons/coin-3.svg" style={{ width: "2rem", height: "2rem" }} alt="coin icon" />&nbsp;
                                    {costConversationLimit} coins will be used to perform this operation.
                                </label>
                            ) : eventNo === 9 ? (
                                <label style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src="../../assets/icons/coin-3.svg" style={{ width: "2rem", height: "2rem" }} alt="coin icon" />&nbsp;
                                    {costUserResponse} coins will be used to perform this operation.
                                </label>
                            ) : (
                                <label style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src="../../assets/icons/coin-3.svg" style={{ width: "2rem", height: "2rem" }} alt="coin icon" />&nbsp;
                                    2 coins will be used to perform this operation.
                                </label>
                            )}
                        </div>
                        <br></br>

                        <button className='QRC1Continuebtn' onClick={() => creditDeduct()}>Ok</button>
                        <button className="QRC1Continuebtn" style={{ margin: "2vh", border: "1px solid black" }}
                            onClick={() => creditref1.current.click()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div className='DSP1App'>
            <div className='DSP1template'>
                <div className="row DSP1settings_page ">
                    <div className="col-xs-12 col-md-12 col-lg-12" style={{ textAlign: "left", padding: "3rem", margin: "2px auto", width: "70%" }}>

                        <h2 style={{ textAlign: "center" }}>Customize your userflow
                            <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                onClick={() => { window.open("https://duonut.com/blog/user-guide") }}
                                data-tooltip-content="Read Feature Documentation"
                                data-tooltip-id="settingpage">
                                <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                            </span>
                        </h2>
                        <br></br>
                        <ReactTooltip id="settingpage" place="bottom" effect="solid"> </ReactTooltip>

                        {error && <p className="settings-error"> Enter Activity Name Field </p>}

                        <h3 id="general" onClick={() => onClickTab(1)} style={{ marginBottom: "2rem", cursor: "pointer" }}>{settingTabNum === 1 ? `- ` : `+ `} General</h3>
                        {settingTabNum === 1 &&
                            <>
                                <div className="row">
                                    <div className="col-lg-6 column-left">
                                        <div className="form_fields">
                                            <div className="form_field">
                                                <label>
                                                    {" "}
                                                    <span style={{ color: "red" }}>*</span> Enter Activity Name
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="Shown as your Duonut Name"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span>
                                                </label>
                                                <input
                                                    className="title_input"
                                                    value={title === null ? "" : title}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        if (newValue.length <= maxTitleChar) {
                                                            dispatch(updateTitle(newValue)); // Dispatch the title update without trimming during typing
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        const trimmedValue = e.target.value.trim(); // Trim leading and trailing spaces when input loses focus
                                                        if (trimmedValue !== title && trimmedValue.length <= maxTitleChar) {
                                                            dispatch(updateTitle(trimmedValue)); // Dispatch the trimmed value when the user finishes typing
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Lock this Duonut <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="Lock's the Duonut for the users, unless bought the duonut"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span> &nbsp;
                                                        <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
                                                    </label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="lock" name="lock" checked={lock ? true : false} onChange={() => { lock ? dispatch(updateLock(!lock)) : creditref.current.click(); seteventNo(1); }} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            {!lock && <div className="form_field" style={{ marginTop: "2rem" }}>
                                                <div className="setting-visibility radio_boxes" onClick={() => !isPublic && dispatch(updatePrivacy())} >
                                                    <div className="radio_box">
                                                        {isPublic ? <img src="/assets/icons/circle-selected.svg" /> : <img src="/assets/icons/circle-not-selected.svg" />}
                                                        <input ref={inputRefFirst} type="radio" name="visibility" value="circle-selected" className="visibility_input" defaultChecked />
                                                        <div>
                                                            <div style={{ fontWeight: "600" }}><i className="fa fa-globe" aria-hidden="true"></i>  Public</div>
                                                            <div style={{ fontSize: "0.8rem" }}>Your project will have a URL that appears in search engines.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>
                                    </div>
                                    <div className="col-lg-6 column-right">
                                        <div className="form_field">
                                            <label>
                                                {/* <span style={{ color: "red" }}>* </span> */}
                                                Enter Activity Description
                                                <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                    data-tooltip-content="Shown as your Duonut Description"
                                                    data-tooltip-id="settingGuide">
                                                    <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <textarea
                                                rows="3"
                                                className="description_input"
                                                value={description === null ? "" : description}
                                                onChange={(e) => (e.target.value.length > maxDescChar ? null : dispatch(updateDescription(e.target.value)))}
                                            ></textarea>
                                        </div>

                                        {!lock && <div className="form_field">
                                            <div className="setting-visibility radio_boxes" onClick={() => { !isPrivate && creditref.current.click(); seteventNo(2); }} data-tooltip-id="analyticspage2">
                                                <div className="radio_box">
                                                    {isPrivate ? <img src="/assets/icons/circle-selected.svg" /> : <img src="/assets/icons/circle-not-selected.svg" />}

                                                    <input ref={inputRefSecond} type="radio" name="visibility" value="circle-not-selected" className="visibility_input" />
                                                    <div>
                                                        <div style={{ fontWeight: "600" }}><i className="fa fa-lock" aria-hidden="true"></i>  Private
                                                            {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                                    <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                                </svg>
                                                            </span> */}
                                                            &nbsp;
                                                            <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" />

                                                        </div>
                                                        <div style={{ fontSize: "0.8rem" }}>Your project will be visible to you and those you share the private link with.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="form_field">
                                            <div className="setting-checkbox">
                                                <label>Protect with a password
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="Protect your Duonut with Password"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span>
                                                    {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                            <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                        </svg>
                                                    </span> */}
                                                    &nbsp;
                                                    <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                                </label>
                                                <label className="checkbox-slider">
                                                    <input type="checkbox" id="password" name="password" onChange={() => { passwordCheck ? onPasswordChange() : creditref.current.click(); seteventNo(3); }} checked={passwordCheck ? true : false} />
                                                    <span className="slider-span"></span>
                                                </label>
                                            </div>

                                            {passwordCheck && <input className="password_input" value={password === null ? "" : password} onChange={(e) => (e.target.value.length > maxPassChar ? null : dispatch(updatePassword(e.target.value)))} />}
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 column-left">
                                        <div className="form_fields" style={{ marginTop: "1rem" }}>

                                            <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Viewer Sliding
                                                        <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="Horizontal moving from one page to another in Viewer Side"
                                                            data-tooltip-id="settingGuide">
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                    </label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="viewer_sliding" name="viewer_sliding" checked={viewer_sliding ? true : false} onChange={() => { dispatch(updateViewerSliding()) }} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Show Viewer Vertical
                                                        <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="Vertical moving from one page to another in Viewer Side"
                                                            data-tooltip-id="settingGuide">
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                    </label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="show_viewer_vertical" name="show_viewer_vertical" checked={show_viewer_vertical ? true : false} onChange={() => dispatch(updateShowViewerVertical())} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Show Like Button</label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="show_like_button" name="show_like_button" checked={show_like_button ? true : false} onChange={() => dispatch(updateShowLikeButton())} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Show Share Button</label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="show_share_button" name="show_share_button" checked={show_share_button ? true : false} onChange={() => dispatch(updateShowShareButton())} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Show Recommendation</label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="show_recommendation" name="show_recommendation" checked={show_recommendation ? true : false} onChange={() => dispatch(updateShowRecommendation())} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Show Progressbar
                                                        <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="Show 1 bar in viewer side indicates how much slide left"
                                                            data-tooltip-id="settingGuide">
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                    </label>
                                                    <label className="checkbox-slider" >
                                                        <input type="checkbox" id="show_progressbar" name="show_progressbar" checked={show_progressbar ? true : false} onChange={() => dispatch(updateShowProgressbar())} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            {/* <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Show Location
                                                        <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                                <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="show_location" name="show_location" checked={show_location ? true : false} onChange={() => onLocationChange()} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Show Feedback</label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="show_feedback" name="show_feedback" checked={show_feedback ? true : false} onChange={() => dispatch(updateShowFeedback())} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Show Referral
                                                        <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                                <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="referral" name="referral" checked={referral ? true : false} onChange={() => onRefferalChange()} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div> */}
                                            {/* <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Show Animation</label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="show_animation" name="show_animation" checked={show_animation ? true : false} onChange={() => dispatch(updateShowAnimation())} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form_field" style={{ marginBottom: "10px" }}>
                                                <div className="setting-checkbox">
                                                    <label>Monitor your Duonut
                                                    </label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="show_report" name="show_report" checked={show_report ? true : false} onChange={() => dispatch(updateShowReport())} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div> */}
                                            {/* {show_report && <>
                                            {duonut_id === 0 && <div style={{ color: "red", textAlign: "center" }}>not yet published</div>}
                                            <button className="btn-secondary" style={{ padding: "1rem", cursor: "pointer" }} disabled={duonut_id > 0 ? false : true} onClick={() => window.open(`/duonutreports/${duonut_id}`)}>Show Report</button>
                                        </>} */}
                                            {/* </div> */}
                                            <div className="form_field" style={{ marginBottom: "0px" }}>
                                                <label>
                                                    {/* <span style={{ color: "red" }}>* </span> */}
                                                    Keywords
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="Helps for Duonut search"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span>
                                                </label>
                                                <textarea
                                                    rows="3"
                                                    className="description_input"
                                                    value={keywords}
                                                    onChange={(e) => dispatch(updateKeywords(e.target.value))}
                                                ></textarea>
                                            </div>
                                            <div className="form_field" style={{
                                                paddingTop: '1rem',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                            }}>
                                                <label>Add Background Music
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="Music for your Duonut"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span>
                                                    {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                            <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                        </svg>
                                                    </span> */}
                                                    &nbsp;
                                                    <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />


                                                    {audio && <i style={{ float: "right", cursor: "pointer" }} className="fa fa-trash" aria-hidden="true" onClick={(e) => handleAudioDelete(e)}></i>}
                                                </label>
                                                <input ref={musicRef} type="file" accept="audio/*" name="file" onClick={(e) => handleProSetingClick(e, "audio")} onChange={(e) => handleAudioChange(e)} style={{ display: "none" }} />
                                                <button style={{
                                                    padding: '10px',
                                                    borderRadius: '5px', cursor: "pointer",
                                                }}
                                                    onClick={(e) => { creditref.current.click(); seteventNo(4); }}
                                                >Upload your audio</button>
                                                {audio && <p style={{ padding: "5px 0px", overflow: "hidden" }}>Selected file: {audio}</p>}

                                            </div>

                                            <div className="form_field">
                                                <div className="setting-checkbox">
                                                    <label>Schedule a close date
                                                        <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="Till when the form/contest will be open"
                                                            data-tooltip-id="settingGuide">
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                        {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                                <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                            </svg>
                                                        </span> */}
                                                        &nbsp;
                                                        <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                                    </label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="is_duonut_close_date" name="is_duonut_close_date" checked={is_duonut_close_date ? true : false} onChange={() => { is_duonut_close_date ? handleIsDuonutCloseDate() : creditref.current.click(); seteventNo(6); }} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                                {is_duonut_close_date && <>
                                                    {/* <input type='date' className="password_input" placeholder="Choose close date" value={duonut_close_date === null ? "" : duonut_close_date} onChange={(e) => (dispatch(updateDuonutCloseDate(e.target.value)))} /> */}
                                                    <DatePicker selected={duonut_close_date ? new Date(duonut_close_date) : new Date()}
                                                        // dateFormat="dd/MM/yyyy"
                                                        className="password_input"
                                                        // placeholderText="DD/MM/YYYY"
                                                        onChange={(date) => handleCloseDateChange(date)} />
                                                </>}
                                            </div>
                                            <div className="form_field">
                                                <div className="setting-checkbox">
                                                    <label>Close on submission limit
                                                        <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="Limit the number of user's submissions"
                                                            data-tooltip-id="settingGuide">
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                        {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                                <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                            </svg>
                                                        </span> */}
                                                        &nbsp;
                                                        <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                                    </label>
                                                    <label className="checkbox-slider">
                                                        <input type="checkbox" id="is_duonut_close_limit" name="is_duonut_close_limit" checked={is_duonut_close_limit ? true : false} onChange={() => { is_duonut_close_limit ? handleIsDuonutCloseLimit() : creditref.current.click(); seteventNo(7); }} />
                                                        <span className="slider-span"></span>
                                                    </label>
                                                </div>
                                                {is_duonut_close_limit && <>
                                                    <input type='number' className="password_input" placeholder="Set Close Limit" value={duonut_close_limit === null ? 0 : duonut_close_limit} onChange={(e) => (dispatch(updateDuonutCloseLimit(e.target.value)))} />
                                                </>}
                                            </div>

                                            <div className="form_field">
                                                {/* <div className="setting-checkbox">
                                                    <label>Open Ended Follow-up Conversation Limit
                                                        <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="Open Ended Follow-up Conversation Limit"
                                                            data-tooltip-id="settingGuide">
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                        {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                                <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                            </svg>
                                                        </span> 
                                                        &nbsp;
                                                        <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
                                                    </label>
                                                    {/* <input type='number' className="password_input"
                                                        style={{ width: "4rem", textAlign: "center" }}
                                                        disabled={!userProAccess}
                                                        value={open_ended_conversation_limit === null ? 1 : open_ended_conversation_limit}
                                                        onChange={(e) => (dispatch(updateOpenEndedConversationLimit(e.target.value)))} /> 
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <input
                                                            type="number"
                                                            className="password_input"
                                                            style={{ width: "4rem", textAlign: "center" }}
                                                            disabled={!isEditing || !userProAccess}
                                                            value={conversationLimit}
                                                            onChange={(e) => { setconversationLimit(parseInt(e.target.value)) }}
                                                        />
                                                        {isEditing ? (
                                                            <i
                                                                className="fa fa-floppy-o"
                                                                style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setIsEditing(false);
                                                                    seteventNo(8); creditref.current.click();
                                                                }}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                                                                aria-hidden="true"
                                                                onClick={() => { setIsEditing(true) }}
                                                            ></i>
                                                        )}
                                                    </div>
                                                </div> */}
                                                <div className="setting-checkbox">
                                                    <label>
                                                        Open Ended Follow-up Conversation Limit
                                                        <span
                                                            style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="Open Ended Follow-up Conversation Limit"
                                                            data-tooltip-id="settingGuide"
                                                        >
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                        &nbsp;
                                                        <img
                                                            src="../../assets/icons/coin-3.svg"
                                                            style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }}
                                                            alt="coin icon"
                                                            data-tooltip-id="analyticspage2"
                                                        />
                                                    </label>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <button
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    border: "1px solid #ccc",
                                                                    cursor: "pointer",
                                                                    padding: "0.2rem",
                                                                    fontSize: "1rem",
                                                                }}
                                                                onClick={() => {
                                                                    if (isEditing && userProAccess) {
                                                                        setconversationLimit((prev) => parseInt(prev) + 1);
                                                                    }
                                                                }}
                                                                disabled={!isEditing || !userProAccess}
                                                            >
                                                                ▲
                                                            </button>
                                                            <input
                                                                type="number"
                                                                className="password_input"
                                                                style={{
                                                                    width: "4rem",
                                                                    textAlign: "center",
                                                                    margin: "0 0.5rem",
                                                                }}
                                                                readOnly
                                                                disabled={!isEditing || !userProAccess}
                                                                value={conversationLimit}
                                                                onChange={(e) => {
                                                                    setconversationLimit(parseInt(e.target.value));
                                                                }}
                                                            />
                                                            <button
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    border: "1px solid #ccc",
                                                                    cursor: "pointer",
                                                                    padding: "0.2rem",
                                                                    fontSize: "1rem",
                                                                }}
                                                                onClick={() => {
                                                                    if (isEditing && userProAccess) {
                                                                        setconversationLimit((prev) => Math.max(0, parseInt(prev) - 1));
                                                                    }
                                                                }}
                                                                disabled={!isEditing || !userProAccess}
                                                            >
                                                                ▼
                                                            </button>
                                                        </div>
                                                        {isEditing ? (
                                                            <i
                                                                className="fa fa-floppy-o"
                                                                style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setIsEditing(false);
                                                                    seteventNo(8);
                                                                    creditref.current.click();
                                                                }}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setIsEditing(true);
                                                                }}
                                                            ></i>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form_field">
                                                <div className="setting-checkbox" style={{ textAlign: "left", marginTop: "1rem" }}>
                                                    <label>
                                                        Number of user views
                                                        <span
                                                            style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                            data-tooltip-content="User views per duonut"
                                                            data-tooltip-id="settingGuide"
                                                        >
                                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        </span>
                                                        &nbsp;
                                                        <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                                    </label>
                                                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                                                        <input
                                                            type="number"
                                                            className="password_input"
                                                            style={{ width: "4rem", textAlign: "center" }}
                                                            disabled={!isEditingUserViews || !userProAccess}
                                                            value={totalUserResponse}
                                                            onChange={(e) => { settotalUserResponse(parseInt(e.target.value)) }}
                                                        />
                                                        {isEditingUserViews ? (
                                                            <i
                                                                className="fa fa-floppy-o"
                                                                style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setIsEditingUserViews(false);
                                                                    seteventNo(9); creditref.current.click();
                                                                }}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                                                                aria-hidden="true"
                                                                onClick={() => { setIsEditingUserViews(true) }}
                                                            ></i>
                                                        )}
                                                    </div> */}
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <button
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    border: "1px solid #ccc",
                                                                    cursor: "pointer",
                                                                    padding: "0.2rem",
                                                                    fontSize: "1rem",
                                                                }}
                                                                onClick={() => {
                                                                    if (isEditingUserViews && userProAccess) {
                                                                        settotalUserResponse((prev) => prev + 5);
                                                                    }
                                                                }}
                                                                disabled={!isEditingUserViews || !userProAccess}
                                                            >
                                                                ▲
                                                            </button>
                                                            <input
                                                                type="number"
                                                                className="password_input"
                                                                style={{
                                                                    width: "4rem",
                                                                    textAlign: "center",
                                                                    margin: "0 0.5rem",
                                                                }}
                                                                readOnly
                                                                disabled={!isEditingUserViews || !userProAccess}
                                                                value={totalUserResponse}
                                                                onChange={(e) => {
                                                                    settotalUserResponse(parseInt(e.target.value));
                                                                }}
                                                            />
                                                            <button
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    border: "1px solid #ccc",
                                                                    cursor: "pointer",
                                                                    padding: "0.2rem",
                                                                    fontSize: "1rem",
                                                                }}
                                                                onClick={() => {
                                                                    if (isEditingUserViews && userProAccess) {
                                                                        settotalUserResponse((prev) => Math.max(5, prev - 5));
                                                                    }
                                                                }}
                                                                disabled={!isEditingUserViews || !userProAccess}
                                                            >
                                                                ▼
                                                            </button>
                                                        </div>
                                                        {isEditingUserViews ? (
                                                            <i
                                                                className="fa fa-floppy-o"
                                                                style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setIsEditingUserViews(false);
                                                                    seteventNo(9);
                                                                    creditref.current.click();
                                                                }}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setIsEditingUserViews(true);
                                                                }}
                                                            ></i>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 column-right">
                                        <div className="image_column">
                                            <p className="image_title" style={{ width: "100%" }}>Cover Image
                                                <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                    data-tooltip-content="profile image of duonut"
                                                    data-tooltip-id="settingGuide">
                                                    <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                </span>
                                                {coverPhoto && <i style={{ marginRight: "1rem", float: "right", cursor: "pointer" }} className="fa fa-trash" aria-hidden="true" onClick={(e) => handleCoverPhotoDelete(e)}></i>}
                                            </p>
                                            <div className="cover_img_container">
                                                {loading && <div style={{ display: "flex", alignItems: "center", width: "100%", height: "100%", position: 'absolute' }}>
                                                    <img alt="loader" src={Loader} height={50} width={50} loading="lazy" />
                                                </div>}
                                                <img src={EditDuonutIcon} className="edit-duonut-icon" onClick={() => inputFileRef.current.click()} />
                                                {coverPhoto ? <img src={coverPhoto} onLoad={() => setloading(false)} /> : <img src="/assets/Duonut_default.png" onClick={() => inputFileRef.current.click()} />}
                                            </div>

                                            <input ref={inputFileRef} type="file" onChange={(e) => handleImageChange(e)} style={{ display: "none" }} accept="image/*" />
                                        </div>

                                        <div className="logo_image_column">

                                            <p className="logo_image_title" style={{ width: "100%" }}>Upload Your Logo
                                                <span style={{ cursor: "pointer", paddingLeft: "1rem" }} data-tooltip-content="Logo uploaded here displays on the left top of all the pages." data-tooltip-id="dlogo">
                                                    <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                </span>
                                                {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                        <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                    </svg>
                                                </span> */}
                                                &nbsp;
                                                <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                                {slide_logo && <i style={{ marginRight: "1rem", float: "right", cursor: "pointer" }} className="fa fa-trash" aria-hidden="true" onClick={(e) => handleSlideLogoDelete(e)}></i>}
                                            </p>

                                            <div className="endpage_logo_container">
                                                <img src={EditDuonutIcon} className="edit-duonut-icon" onClick={(e) => { creditref.current.click(); seteventNo(5); }} />
                                                {slide_logo ? <img src={slide_logo} /> : <img src="/assets/no-logo.png" onClick={(e) => { creditref.current.click(); seteventNo(5); }} />}
                                            </div>

                                            <input ref={inputFileLogoRef2} type="file" onClick={(e) => handleProSetingClick(e, "logo")} onChange={(e) => handleSlideLogoChange(e)} style={{ display: "none" }} accept="image/*" />
                                        </div>
                                        <ReactTooltip id="dlogo" place="bottom" effect="solid"> </ReactTooltip>
                                        {slide_logo && <div className="form_field" style={{ marginBottom: "0px" }}>
                                            <div className="setting-checkbox">
                                                <label>Slide Logo Alignment</label>
                                                <select value={slide_logo_alignment} className='text_editor_dropdown' onChange={e => dispatch(updateSlideLogoAlignment(e.target.value))} >
                                                    <option key={"top left"} value={"top left"} > top left </option>
                                                    <option key={"top right"} value={"top right"} > top right </option>
                                                    <option key={"bottom left"} value={"bottom left"} > bottom left </option>
                                                    <option key={"bottom right"} value={"bottom right"} > bottom right </option>
                                                </select>
                                            </div>
                                        </div>}

                                    </div>
                                </div>
                            </>
                        }

                        <h3 id="welcomepage" onClick={() => onClickTab(4)} style={{ margin: "2rem auto", cursor: "pointer" }}>{settingTabNum === 4 ? `- ` : `+ `} Welcome Screen</h3>
                        {settingTabNum === 4 &&
                            <div className="row">
                                <div className="col-lg-6 column-left">
                                    <div className="form_fields">

                                        <div className="form_field">
                                            <div className="setting-checkbox">
                                                <label>Show Welcome Screen
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="Appears as the welcome page of your duonut"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span>
                                                </label>
                                                <label className="checkbox-slider">
                                                    <input type="checkbox" id="show_ai_welcomepage" name="show_ai_welcomepage" checked={show_ai_welcomepage ? true : false} onChange={() => dispatch(updateShowAiWelcomepage())} />
                                                    <span className="slider-span"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form_field">
                                            <label>
                                                Title (for the welcome screen)
                                            </label>
                                            <input className="password_input" disabled={show_ai_welcomepage ? false : true} value={ai_welcomepage_title === null ? "" : ai_welcomepage_title} onChange={(e) => (dispatch(updateAiWelcomepageTitle(e.target.value)))} />
                                        </div>
                                        <div className="form_field">
                                            <label>
                                                Description (for the welcome screen)
                                            </label>
                                            <textarea
                                                rows="3"
                                                disabled={show_ai_welcomepage ? false : true}
                                                className="description_input"
                                                value={ai_welcomepage_description === null ? "" : ai_welcomepage_description}
                                                onChange={(e) => (dispatch(updateAiWelcomepageDescription(e.target.value)))}
                                            ></textarea>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6 column-right">
                                    <div className="form_field">
                                        <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            Background Color (for the welcome screen)
                                            <div style={{
                                                display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px", backgroundColor: "#d7d4d4",
                                                width: "100px", height: "40px", cursor: "pointer", pointerEvents: show_ai_welcomepage ? "auto" : "none", opacity: show_ai_welcomepage ? "1" : "0.4"
                                            }} onClick={() => handleOpenColor(1)}>
                                                <div className="icon mr-3 d-flex">
                                                    <img src="/assets/icons/color-select.svg" />
                                                </div>
                                                <div
                                                    onClick={() => handleOpenColor(1)}
                                                    style={{
                                                        width: "25px", height: "25px", borderRadius: "4px", boxShadow: "0px 0px 2px 0px",
                                                        backgroundColor: ai_welcomepage_bgcolor, position: "relative"
                                                    }}
                                                >
                                                    {isActiveColor === 1 && (
                                                        <div
                                                            ref={colorPickerRef}
                                                            style={width > 700 ? {
                                                                position: "absolute",
                                                                left: "50px",
                                                                top: "-260px",
                                                                zIndex: "1"
                                                            } : {
                                                                position: "absolute",
                                                                right: "50px",
                                                                top: "-260px",
                                                                zIndex: "1"
                                                            }}
                                                        >
                                                            <SketchPicker color={ai_welcomepage_bgcolor} onChangeComplete={(e) => handleColorChange2(e, "bg")} />
                                                        </div>)}
                                                </div>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="form_field">
                                        <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            Font Color (for the welcome screen)
                                            <div style={{
                                                display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px", backgroundColor: "#d7d4d4",
                                                width: "100px", height: "40px", cursor: "pointer", pointerEvents: show_ai_welcomepage ? "auto" : "none", opacity: show_ai_welcomepage ? "1" : "0.4"
                                            }} onClick={() => handleOpenColor(2)}>
                                                <div className="icon mr-3 d-flex">
                                                    <img src="/assets/icons/color-select.svg" />
                                                </div>
                                                <div
                                                    onClick={() => handleOpenColor(2)}
                                                    style={{
                                                        width: "25px", height: "25px", borderRadius: "4px", boxShadow: "0px 0px 2px 0px",
                                                        backgroundColor: ai_welcomepage_fontcolor, position: "relative"
                                                    }}
                                                >
                                                    {isActiveColor === 2 && (
                                                        <div
                                                            ref={colorPickerRef}
                                                            style={width > 700 ? {
                                                                position: "absolute",
                                                                left: "50px",
                                                                top: "-260px",
                                                                zIndex: "1"
                                                            } : {
                                                                position: "absolute",
                                                                right: "50px",
                                                                top: "-260px",
                                                                zIndex: "1"
                                                            }}
                                                        >
                                                            <SketchPicker color={ai_welcomepage_fontcolor} onChangeComplete={(e) => handleColorChange2(e, "font")} />
                                                        </div>)}
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }

                        <h3 id="coverpage" onClick={() => onClickTab(2)} style={{ margin: "2rem auto", cursor: "pointer" }}>{settingTabNum === 2 ? `- ` : `+ `} Cover Page</h3>
                        {settingTabNum === 2 &&
                            <div className="row">
                                <div className="col-lg-6 column-left">
                                    <div className="form_fields">

                                        <div className="form_field">
                                            <div className="setting-checkbox">
                                                <label>Show Cover Page
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="Appears as the first page of your duonut"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span>
                                                    &nbsp;
                                                    <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                                </label>
                                                <label className="checkbox-slider">
                                                    <input type="checkbox" id="show_startpage" name="show_startpage" checked={show_startpage ? true : false} onChange={() => { show_startpage ? dispatch(updateShowStartpage()) : creditref.current.click(); seteventNo(10); }} />
                                                    <span className="slider-span"></span>
                                                </label>
                                            </div>
                                            {show_startpage && <>
                                                <input className="password_input" placeholder="Enter your play name" value={startpage_text === null ? "" : startpage_text} onChange={(e) => (dispatch(updateStartpageText(e.target.value)))} />
                                                <input className="password_input" placeholder="Enter your play name (optional)" value={startpage_text2 === null ? "" : startpage_text2} onChange={(e) => (dispatch(updateStartpageText2(e.target.value)))} />
                                            </>}
                                        </div>
                                        <div className="form_field">
                                            <label>
                                                Title (for the cover page)
                                            </label>
                                            <input className="password_input" disabled={show_startpage ? false : true} value={startpage_title === null ? "" : startpage_title} onChange={(e) => (dispatch(updateStartpageTitle(e.target.value)))} />
                                        </div>
                                        <div className="form_field">
                                            <label>
                                                Description (for the cover page)
                                            </label>
                                            <textarea
                                                rows="3"
                                                disabled={show_startpage ? false : true}
                                                className="description_input"
                                                value={startpage_description === null ? "" : startpage_description}
                                                onChange={(e) => (dispatch(updateStartpageDescription(e.target.value)))}
                                            ></textarea>
                                            {/* <input className="password_input" disabled={show_startpage ? false : true} value={startpage_description === null ? "" : startpage_description} onChange={(e) => (dispatch(updateStartpageDescription(e.target.value)))} /> */}
                                        </div>
                                        <div className="form_field">
                                            <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                Background Color (for the cover page)
                                                <div style={{
                                                    display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px", backgroundColor: "#d7d4d4",
                                                    width: "100px", height: "40px", cursor: "pointer", pointerEvents: show_startpage ? "auto" : "none", opacity: show_startpage ? "1" : "0.4"
                                                }} onClick={() => handleOpenColor(1)}>
                                                    <div className="icon mr-3 d-flex">
                                                        <img src="/assets/icons/color-select.svg" />
                                                    </div>
                                                    <div
                                                        onClick={() => handleOpenColor(1)}
                                                        style={{
                                                            width: "25px", height: "25px", borderRadius: "4px", boxShadow: "0px 0px 2px 0px",
                                                            backgroundColor: startpage_bgcolor, position: "relative"
                                                        }}
                                                    >
                                                        {isActiveColor === 1 && (
                                                            <div
                                                                ref={colorPickerRef}
                                                                style={width > 700 ? {
                                                                    position: "absolute",
                                                                    left: "50px",
                                                                    top: "-260px",
                                                                    zIndex: "1"
                                                                } : {
                                                                    position: "absolute",
                                                                    right: "50px",
                                                                    top: "-260px",
                                                                    zIndex: "1"
                                                                }}
                                                            >
                                                                <SketchPicker color={startpage_bgcolor} onChangeComplete={(e) => handleColorChange1(e, "bg")} />
                                                            </div>)}
                                                    </div>
                                                </div>
                                            </label>
                                            {/* <input className="password_input" value={startpage_bgcolor === null ? "" : startpage_bgcolor} onChange={(e) => (dispatch(updateStartpageBgcolor(e.target.value)))} /> */}
                                        </div>

                                        <div className="form_field">
                                            <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                Font Color (for the cover page)
                                                <div style={{
                                                    display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px", backgroundColor: "#d7d4d4",
                                                    width: "100px", height: "40px", cursor: "pointer", pointerEvents: show_startpage ? "auto" : "none", opacity: show_startpage ? "1" : "0.4"
                                                }} onClick={() => handleOpenColor(2)}>
                                                    <div className="icon mr-3 d-flex">
                                                        <img src="/assets/icons/color-select.svg" />
                                                    </div>
                                                    <div
                                                        onClick={() => handleOpenColor(2)}
                                                        style={{
                                                            width: "25px", height: "25px", borderRadius: "4px", boxShadow: "0px 0px 2px 0px",
                                                            backgroundColor: startpage_fontcolor, position: "relative"
                                                        }}
                                                    >
                                                        {isActiveColor === 2 && (
                                                            <div
                                                                ref={colorPickerRef}
                                                                style={width > 700 ? {
                                                                    position: "absolute",
                                                                    left: "50px",
                                                                    top: "-260px",
                                                                    zIndex: "1"
                                                                } : {
                                                                    position: "absolute",
                                                                    right: "50px",
                                                                    top: "-260px",
                                                                    zIndex: "1"
                                                                }}
                                                            >
                                                                <SketchPicker color={startpage_fontcolor} onChangeComplete={(e) => handleColorChange1(e, "font")} />
                                                            </div>)}
                                                    </div>
                                                </div>
                                            </label>
                                            {/* <input className="password_input" value={startpage_fontcolor === null ? "" : startpage_fontcolor} onChange={(e) => (dispatch(updateStartpageFontcolor(e.target.value)))} /> */}
                                        </div>


                                    </div>
                                </div>
                                <div className="col-lg-6 column-right">
                                    <div className="logo_image_column">
                                        <p className="logo_image_title" style={{ width: "100%" }}>Upload Cover Page Image
                                            <span style={{ cursor: "pointer", paddingLeft: "1rem" }} data-tooltip-id="clogo" data-tooltip-content="Logo uploaded here appears on the cover page only.">
                                                <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                            </span>
                                            {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                    <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                </svg>
                                            </span> */}
                                            {startpage_logo && <i style={{ marginRight: "1rem", float: "right", cursor: "pointer" }} className="fa fa-trash" aria-hidden="true" onClick={(e) => handleStartpageLogoDelete(e)}></i>}
                                        </p>
                                        <div className="endpage_logo_container">
                                            <img src={EditDuonutIcon} className="edit-duonut-icon" onClick={() => inputFileLogoRef1.current.click()} />
                                            {startpage_logo ? <img src={startpage_logo} /> : <img src="/assets/no-logo.png" onClick={() => inputFileLogoRef1.current.click()} />}
                                        </div>

                                        <input ref={inputFileLogoRef1} disabled={show_startpage ? false : true} type="file" onClick={(e) => handleProSetingClick(e, "logo")} onChange={(e) => handleStartpageLogoChange(e)} style={{ display: "none" }} accept="image/*" />
                                    </div>
                                    <ReactTooltip id="clogo" place="bottom" effect="solid"></ReactTooltip>
                                </div>
                            </div>
                        }

                        <h3 id='thankyoupage' onClick={() => onClickTab(3)} style={{ margin: "2rem auto", cursor: "pointer" }}>{settingTabNum === 3 ? `- ` : `+ `} Thank you Page</h3>
                        {settingTabNum === 3 &&
                            <div className="row">
                                <div className="col-lg-6 column-left">
                                    <div className="form_fields">

                                        <div className="form_field" style={{ marginBottom: "0px" }}>
                                            <div className="setting-checkbox">
                                                <label>Show Leaderboard
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="For any mcq contest at the end you can see the results"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span>
                                                    &nbsp;
                                                    <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                                </label>
                                                <label className="checkbox-slider">
                                                    <input type="checkbox" id="show_leaderboard" name="show_leaderboard" checked={show_leaderboard ? true : false} onChange={() => { show_leaderboard ? dispatch(updateShowLeaderboard()) : creditref.current.click(); seteventNo(11); }} />
                                                    <span className="slider-span"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form_field" style={{ marginBottom: "0px" }}>
                                            <div className="setting-checkbox">
                                                <label>Show End Page
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="At last you can show this page as a Thank You"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span>
                                                </label>
                                                <label className="checkbox-slider">
                                                    <input type="checkbox" id="show_endpage" name="show_endpage" checked={show_endpage ? true : false} onChange={() => dispatch(updateShowEndpage())} />
                                                    <span className="slider-span"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form_field" style={{ marginBottom: "0px" }}>
                                            <div className="setting-checkbox">
                                                <label>Show Confetti
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="Vibrant confetti will burst out onto the End Page"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                    </span>
                                                    &nbsp;
                                                    <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                                </label>
                                                <label className="checkbox-slider">
                                                    <input type="checkbox" id="show_confetti" name="show_confetti" checked={show_confetti ? true : false} onChange={() => { show_confetti ? dispatch(updateShowConfetti()) : creditref.current.click(); seteventNo(12); }} />
                                                    <span className="slider-span"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form_field">
                                            <div className="setting-checkbox">
                                                <label>Play Audio
                                                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                        data-tooltip-content="Sound effect in End Page"
                                                        data-tooltip-id="settingGuide">
                                                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                        &nbsp;
                                                        <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                                    </span>
                                                </label>
                                                <label className="checkbox-slider" >
                                                    <input type="checkbox" id="play_audio" name="play_audio" checked={play_audio ? true : false} onChange={() => { play_audio ? dispatch(updatePlayAudio()) : creditref.current.click(); seteventNo(13); }} />
                                                    <span className="slider-span"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form_field">
                                            <label>
                                                Redirect Url on Completion
                                                <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                    data-tooltip-content="Redirect your users to any url as per your need"
                                                    data-tooltip-id="settingGuide">
                                                    <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                </span>
                                                &nbsp;
                                                <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                            </label>
                                            <label className="checkbox-slider">
                                                <input type="checkbox" id="play_audio" name="play_audio" checked={is_redirect_url ? true : false} onChange={() => { is_redirect_url ? dispatch(updateRedirectUrlToggle()) : creditref.current.click(); seteventNo(16); }} />
                                                <span className="slider-span"></span>
                                            </label>
                                            {is_redirect_url &&
                                                <input className="password_input"
                                                    // disabled={(show_endpage || show_leaderboard) ? true : false} 
                                                    value={redirect_url === null ? "" : redirect_url}
                                                    onChange={(e) => (dispatch(updateRedirectUrl(e.target.value)))} />
                                            }
                                        </div>

                                        <div className="form_field" style={{ position: "relative" }}>
                                            <label>
                                                Title (for the end page)
                                            </label>
                                            <textarea
                                                rows="2"
                                                disabled={show_endpage ? false : true}
                                                className="description_input"
                                                value={leaderboard_title === null ? "" : leaderboard_title}
                                                onChange={(e) => (handleChangeEndPageTitle(e))}
                                            ></textarea>
                                            {/* <input className="password_input" disabled={show_endpage ? false : true} value={leaderboard_title === null ? "" : leaderboard_title} onChange={(e) => (handleChangeEndPageTitle(e))} /> */}
                                            {suggestedVar.length > 0 && (
                                                <div ref={suggestionRef} style={{ position: "absolute", backgroundColor: "white", transform: `translate(${popUpPositionX / 2 - 100}px, ${popUpPositionY}px)`, padding: "10px", boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px", zIndex: "999" }}>
                                                    <div>Recall information from...</div>
                                                    {suggestedVar.map((item, i) => {
                                                        return <div key={i}
                                                            style={{ padding: "4px", paddingLeft: "10px", backgroundColor: "#e7e4e4", borderRadius: "4px", margin: "4px", cursor: "pointer" }}
                                                            onClick={() => handleChooseSuggestion(item)}>{item}</div>
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                        <div className="form_field">
                                            <label>
                                                Description (for the end page)
                                            </label>
                                            <textarea
                                                rows="3"
                                                disabled={show_endpage ? false : true}
                                                className="description_input"
                                                value={leaderboard_description === null ? "" : leaderboard_description}
                                                onChange={(e) => (dispatch(updateLeaderboardDescription(e.target.value)))}
                                            ></textarea>
                                            {/* <input className="password_input" disabled={show_endpage ? false : true} value={leaderboard_description === null ? "" : leaderboard_description} onChange={(e) => (dispatch(updateLeaderboardDescription(e.target.value)))} /> */}
                                        </div>
                                        <div className="form_field">
                                            <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                Background Color (for the end page)
                                                <div style={{
                                                    display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px", backgroundColor: "#d7d4d4",
                                                    width: "100px", height: "40px", cursor: "pointer", pointerEvents: show_endpage ? "auto" : "none", opacity: show_endpage ? "1" : "0.4"
                                                }} onClick={() => handleOpenColor(3)}>
                                                    <div className="icon mr-3 d-flex">
                                                        <img src="/assets/icons/color-select.svg" />
                                                    </div>
                                                    <div
                                                        onClick={() => handleOpenColor(3)}
                                                        style={{
                                                            width: "25px", height: "25px", borderRadius: "4px", boxShadow: "0px 0px 2px 0px",
                                                            backgroundColor: leaderboard_bgcolor, position: "relative"
                                                        }}
                                                    >
                                                        {isActiveColor === 3 && (
                                                            <div
                                                                ref={colorPickerRef}
                                                                style={width > 700 ? {
                                                                    position: "absolute",
                                                                    left: "50px",
                                                                    top: "-260px",
                                                                    zIndex: "1"
                                                                } : {
                                                                    position: "absolute",
                                                                    right: "50px",
                                                                    top: "-260px",
                                                                    zIndex: "1"
                                                                }}
                                                            >
                                                                <SketchPicker color={leaderboard_bgcolor} onChangeComplete={(e) => handleColorChange(e, "bg")} />
                                                            </div>)}
                                                    </div>
                                                </div>
                                            </label>
                                            {/* <input className="password_input" value={leaderboard_bgcolor === null ? "" : leaderboard_bgcolor} onChange={(e) => (dispatch(updateLeaderboardBgcolor(e.target.value)))} /> */}
                                        </div>
                                        <div className="form_field">
                                            <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                Font Color (for the end page)
                                                <div style={{
                                                    display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px", backgroundColor: "#d7d4d4",
                                                    width: "100px", height: "40px", cursor: "pointer", pointerEvents: show_endpage ? "auto" : "none", opacity: show_endpage ? "1" : "0.4"
                                                }} onClick={() => handleOpenColor(4)}>
                                                    <div className="icon mr-3 d-flex">
                                                        <img src="/assets/icons/color-select.svg" />
                                                    </div>
                                                    <div
                                                        onClick={() => handleOpenColor(4)}
                                                        style={{
                                                            width: "25px", height: "25px", borderRadius: "4px", boxShadow: "0px 0px 2px 0px",
                                                            backgroundColor: leaderboard_fontcolor, position: "relative"
                                                        }}
                                                    >
                                                        {isActiveColor === 4 && (
                                                            <div
                                                                ref={colorPickerRef}
                                                                style={width > 700 ? {
                                                                    position: "absolute",
                                                                    left: "50px",
                                                                    top: "-260px",
                                                                    zIndex: "1"
                                                                } : {
                                                                    position: "absolute",
                                                                    right: "50px",
                                                                    top: "-260px",
                                                                    zIndex: "1"
                                                                }}
                                                            >
                                                                <SketchPicker color={leaderboard_fontcolor} onChangeComplete={(e) => handleColorChange(e, "font")} />
                                                            </div>)}
                                                    </div>
                                                </div>
                                            </label>
                                            {/* <input className="password_input" value={leaderboard_fontcolor === null ? "" : leaderboard_fontcolor} onChange={(e) => (dispatch(updateLeaderboardFontcolor(e.target.value)))} /> */}
                                        </div>


                                    </div>
                                </div>
                                <div className="col-lg-6 column-right">
                                    <div className="logo_image_column">
                                        <p className="logo_image_title" style={{ width: "100%" }}>Upload End Page Logo
                                            <span style={{ cursor: "pointer", paddingLeft: "1rem" }} data-tooltip-content="Logo uploaded here appears on the Thank you page of the activity only." data-tooltip-id="elogo">
                                                <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                            </span>
                                            {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                    <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                </svg>
                                            </span> */}
                                            {leaderboard_logo && <i style={{ marginRight: "1rem", float: "right", cursor: "pointer" }} className="fa fa-trash" aria-hidden="true" onClick={(e) => handleLeaderboardLogoDelete(e)}></i>}
                                        </p>
                                        <div className="endpage_logo_container">
                                            <img src={EditDuonutIcon} className="edit-duonut-icon" onClick={() => inputFileLogoRef.current.click()} />
                                            {leaderboard_logo ? <img src={leaderboard_logo} /> : <img src="/assets/no-logo.png" onClick={() => inputFileLogoRef.current.click()} />}
                                        </div>

                                        <input ref={inputFileLogoRef} type="file" disabled={show_endpage ? false : true} onClick={(e) => handleProSetingClick(e, "logo")} onChange={(e) => handleLeaderboardLogoChange(e)} style={{ display: "none" }} accept="image/*" />
                                    </div>
                                    <div className="form_field">
                                        <div className="setting-checkbox">
                                            <label>Add CTA [Call-to-Action] Button
                                                <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                    data-tooltip-content="Add your contact link"
                                                    data-tooltip-id="settingGuide">
                                                    <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                </span>
                                                {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                        <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                    </svg>
                                                </span> */}
                                                &nbsp;
                                                <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                            </label>
                                            <label className="checkbox-slider">
                                                <input type="checkbox" id="show_custom_button" disabled={show_endpage ? false : true} name="show_custom_button" checked={show_custom_button ? true : false} onChange={() => { show_custom_button ? handleCustomButton() : creditref.current.click(); seteventNo(14); }} />
                                                <span className="slider-span"></span>
                                            </label>
                                        </div>
                                        {show_custom_button && <>
                                            <input className="password_input" placeholder="CTA Button Text" value={custom_button_message === null ? "" : custom_button_message} onChange={(e) => (dispatch(updateCustomButtoMessage(e.target.value)))} />
                                            <input className="password_input" placeholder="CTA Button Url" value={custom_button_url === null ? "" : custom_button_url} onChange={(e) => (dispatch(updateCustomButtonUrl(e.target.value)))} />
                                        </>}
                                    </div>

                                    <div className="form_field">
                                        <div className="setting-checkbox">
                                            <label>
                                                {/* <span style={{ color: "red" }}>* </span> */}
                                                Coupon Code
                                                <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                                                    data-tooltip-content="Add a coupon code, which your users can claim and use it later"
                                                    data-tooltip-id="settingGuide">
                                                    <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                                </span>
                                                {/* <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                                        <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
                                                    </svg>
                                                </span> */}
                                                &nbsp;
                                                <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />

                                            </label>
                                            <label className="checkbox-slider">
                                                <input type="checkbox" id="show_coupon" disabled={show_endpage ? false : true} name="show_custom_button" checked={showCoupon ? true : false} onChange={() => { showCoupon ? handleCouponButton() : creditref.current.click(); seteventNo(15); }} />
                                                <span className="slider-span"></span>
                                            </label>
                                        </div>
                                        {showCoupon && <>
                                            <label>[Upload a .csv file with coupon codes to display in the last page]</label>
                                            <input type="file" accept=".csv" disabled={show_endpage ? false : true} onChange={(e) => handleCouponCodeFileUpload(e)} />
                                            {coupon_code_file?.name && <p style={{ padding: "5px 0px", overflow: "hidden" }}>Selected file: {coupon_code_file.name}</p>}
                                            <label style={{ margin: "5px 0px" }}>OR</label>
                                            <textarea
                                                rows="5"
                                                className="description_input"
                                                value={coupon_code === null ? "" : coupon_code}
                                                onChange={(e) => dispatch(updateCouponCode(e.target.value))}
                                            ></textarea>
                                        </>}

                                    </div>
                                    <ReactTooltip id="elogo" place="bottom" effect="solid"></ReactTooltip>
                                </div>
                            </div>
                        }

                        <AnimatePresence>
                            {showSuccessMessage && (
                                <motion.div
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    style={{
                                        position: "fixed",
                                        top: "5%",
                                        left: "40%",
                                        transform: "translateX(-50%)",
                                        background: "#4CAF50",
                                        color: "#fff",
                                        padding: "10px 20px",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        zIndex: 1000,
                                    }}
                                >
                                    {message}
                                </motion.div>
                            )}
                            {showErrorMessage && (
                                <motion.div
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    style={{
                                        position: "fixed",
                                        top: "5%",
                                        left: "40%",
                                        transform: "translateX(-50%)",
                                        background: "#DC143C",
                                        color: "#fff",
                                        padding: "10px 20px",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        zIndex: 1000,
                                    }}
                                >
                                    {error_message}
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <ReactTooltip id="settingGuide" place="top" effect="solid"> </ReactTooltip>
                        <ReactTooltip
                            id="analyticspage2"
                            place="bottom"
                            effect="solid"
                            className="custom-tooltip"
                        >
                            <p style={{ display: "flex", alignItems: "center" }}>
                                <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem" }} alt="coin icon" />
                                &nbsp; 2 credit
                            </p>
                        </ReactTooltip>


                    </div>
                </div>
            </div>
        </div >
    </>
    );

}

export default DuonutSettings;