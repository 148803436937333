import React, { useState } from 'react';
import './Pricing.css';
// import background from '../../assets/backgroundImg/Screens.svg';
// import { openModal, showSignUpForm, showSignInForm } from "../../redux/slices/formDetails";
// import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
// import { toggleDemoModalDuonut } from "../../redux/slices/DemoReducer";
// import { saveDemoAsync } from '../../redux/slices/DemoReducer';
import tickmark from '../../assets/Images/tick.svg';
// import crossmark from '../../assets/Images/cross.svg';
import useWindowDimensions from "../../hooks/useWindowDimensions";
// import { toggleCreateFromScratchModalDuonut } from "../../redux/slices/CreateFromScratchReducer";
import Slider from "react-slick";

const Pricing = (props) => {

    const { isLoggedIn } = useSelector((state) => state.user);
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const [selectedBtn, setselectedBtn] = useState(0);
    // const [email, setemail] = useState('');
    // const [message, setmessage] = useState('');
    // const [submitmessage, setsubmitmessage] = useState('');
    const [selected, setSelected] = useState(0);
    const [isMonthly, setisMonthly] = useState(true);

    const onClickTab = (tab) => {
        if (selected !== tab) {
            setSelected(tab);
        } else {
            setSelected(0);
        }
    };

    var sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // const handleClick = () => dispatch(openModal());

    // const handleClickSignUp = () => {
    //     if (!isLoggedIn) {
    //         handleClick();
    //         dispatch(showSignUpForm());
    //     } else {
    //         navigate("/design");
    //     }

    // };

    // const buyBtn = (item) => {
    //     if (!isLoggedIn) {
    //         handleClick();
    //         dispatch(showSignInForm());
    //         setselectedBtn(item);
    //     } else {
    //         if (item === 1) {
    //             navigate(`/payment/pro?monthly=${isMonthly}`);
    //             // window.open("https://rzp.io/l/9uSUqpj");
    //         } else if (item === 2) {
    //             navigate('/payment/plus');
    //             // window.open("https://rzp.io/l/qYlzpZGWFE");
    //         } else if (item === 3) {
    //             navigate('/payment/pro?lifetime=true');
    //             // window.open("https://rzp.io/l/OjUDGY06");
    //         }
    //         // else if (item === 4) {
    //         //     window.open("tel:" + "9353709306");
    //         // }
    //     }
    // }

    useEffect(() => {
        // console.log("after login")
        if (selectedBtn === 1) {
            navigate(`/payment/pro?monthly=${isMonthly}`);
            // window.open("https://rzp.io/l/9uSUqpj");
        } else if (selectedBtn === 2) {
            navigate('/payment/plus');
            // window.open("https://rzp.io/l/qYlzpZGWFE");
        } else if (selectedBtn === 3) {
            navigate('/payment/pro?lifetime=true');
            // window.open("https://rzp.io/l/OjUDGY06");
        }
        // else if (selectedBtn === 4) {
        //     window.open("tel:" + "9353709306");
        // }
    }, [isLoggedIn]);

    // const callBtn = () => {
    //     window.open("tel:" + "9353709306");
    // }

    // const handleClickDemo = () => {
    //     dispatch(toggleDemoModalDuonut(true));
    // };

    // const handleEmailChange = event => {
    //     setemail(event.target.value);
    //     // console.log(event.target.value);
    // };
    // const handleMessageChange = event => {
    //     setmessage(event.target.value);
    //     // console.log(event.target.value);
    // };
    // const submit = (e) => {
    //     e.preventDefault();

    //     var demo_data = {
    //         email: email,
    //         message: message
    //     }
    //     // console.log(demo_data)
    //     // dispatch(saveDemoAsync(demo_data));

    //     setsubmitmessage("Thanks For Contacting!");

    //     setTimeout(() => {
    //         setemail("");
    //         setmessage("");
    //         setsubmitmessage("")
    //     }, 3000); //miliseconds

    // }

    // const handleClickCreateFromScratch = () => {
    //     dispatch(toggleCreateFromScratchModalDuonut(true));
    // };

    return (
        <>
            <div className='PricingMain' style={{ backgroundColor: "#F4F9FC" }}>
                <section id="pricing" className="text-center scrollto clearfix " style={{ paddingTop: "1rem" }}>

                    <div className="my-container row clearfix">

                        <div style={{ backgroundColor: "#f4f9fc", borderRadius: "120px 120px 0px 0px", padding: "2rem 0rem" }}>


                            <h1 style={{ width: "87%", margin: 'auto', lineHeight: "65px", fontSize: "52px", fontWeight: "700" }}>Build
                                <label style={{ color: "#3184c9" }}> forms, surveys </label> and <label style={{ color: "#3184c9" }}> landing pages </label> for free
                            </h1>
                            <div style={{ width: "87%", margin: 'auto', fontSize: "20px", paddingBottom: "3rem" }} >Annoyed by paywalls getting in your way? Duonut offers unlimited forms, submissions,
                                <br></br> and all the tools required to create professional forms — completely free.
                                <br></br><br></br> Effortlessly design surveys with either one question per page or multiple input fields on a single page.</div>

                            <div className='pricingFreeContainerMain' style={{
                                width: "87%", margin: "auto", marginBottom: "2rem", padding: "2rem",
                                backgroundColor: "rgb(14, 44, 69)", color: "white", borderRadius: "20px"
                            }}>
                                <div className='pricingFreeContainer'>
                                    <div className='pricingFreeblock'>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Unlimited forms</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Unlimited submissions</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> File upload</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Custom `Thank You` page</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Redirect on completion</div>
                                    </div>
                                    <div className='pricingFreeblock'>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Leaderboards</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Form logic</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Hidden fields</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Answer piping</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Schedule a close date</div>
                                    </div>
                                    <div className='pricingFreeblock'>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Close on submission limit</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Custom closed message</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Slack Integration</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Hubspot Integration</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> And many more</div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "90%", margin: "auto", paddingBottom: "2rem", display: "flex", justifyContent: "center", flexDirection: width > 800 ? "unset" : "column" }}>

                                <div className="pricing-block pricing-block-li featured col-2 wow fadeInUp" data-wow-delay="0.6s" style={{ width: "60%" }}>
                                    <div className="pricing-block-content">
                                        <div className="pricing" style={{ backgroundColor: "rgb(49, 132, 201)", color: "white" }}>
                                            <h3>Duonut Growth</h3>
                                            <p className="pricing-sub">Only pay for what you need</p>
                                            {/* <div className="price" style={{ color: "rgb(14, 44, 69)", fontSize: "5rem" }}>Free</div>
                                            <div>FOREVER</div>
                                            <div style={{ marginTop: "2.8rem", fontSize: "1rem" }}>All Duonut Form features are freely <br></br> available to every user.</div> */}
                                        </div>
                                        <ul>
                                            <li><img className="tickmark-icon" src={tickmark} />  Unlimited Workspaces</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Analytics</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Custom volume</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Remove Duonut branding</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  No email limits</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Custom subdomain</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Email Notifications</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Partial submissions</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Premium integrations</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  SEO Customization</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  White Glove Support</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Advanced logic & API</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Enterprise SLAs</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Custom Form Services</li>

                                        </ul>
                                        <a onClick={() => window.open("https://duonut.com/duonut/3741201275")} className="createFreeFormButton"
                                            style={{ width: "80%", display: "inline-block", marginBottom: "2rem", fontSize: "16px" }}>
                                            Request pricing</a>
                                    </div>
                                </div>

                            </div>

                            {/* <div style={{
                                width: "87%", margin: "auto", marginBottom: "2rem", padding: "2rem",
                                backgroundColor: "rgb(14, 44, 69)", color: "white", borderRadius: "20px"
                            }}>
                                <div style={{ fontSize: "2rem" }}>
                                    &#128640; Limited time offer
                                </div>
                                <div style={{ fontSize: "80px", fontWeight: "700", color: "#3184c9", margin: "2rem" }}>
                                    $399
                                </div>
                                <div style={{ fontSize: "1rem", margin: "1rem" }}>
                                    LIFETIME PRO ACCESS
                                </div>
                                <div style={{
                                    fontWeight: "600", margin: "8px", fontSize: "1.2rem"
                                }}>
                                    Buy once use forever</div>

                                <a onClick={() => buyBtn(3)} className="createFreeFormButton"
                                    style={{ width: "10rem", display: "inline-block", margin: "2rem", fontSize: "16px" }}>BUY TODAY</a>
                            </div> */}

                            {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "2rem" }}>
                                <div onClick={() => setisMonthly(true)}
                                    style={{
                                        border: "1px solid black", borderRight: 'unset', borderRadius: "10px 0px 0px 10px", cursor: "pointer",
                                        padding: "10px", backgroundColor: isMonthly ? "rgb(49, 132, 201)" : "transparent", color: isMonthly ? "white" : "black"
                                    }}>
                                    Monthly</div>
                                <div onClick={() => setisMonthly(false)}
                                    style={{
                                        border: "1px solid black", borderLeft: 'unset', borderRadius: "0px 10px 10px 0px", cursor: "pointer",
                                        padding: "10px", backgroundColor: isMonthly ? "transparent" : "rgb(49, 132, 201)", color: isMonthly ? "black" : "white"
                                    }}>
                                    Yearly</div>
                            </div> */}

                            {/* <div style={{ width: "90%", margin: "auto", paddingBottom: "2rem", display: "flex", justifyContent: "center", flexDirection: width > 800 ? "unset" : "column" }}>

                                <div className="pricing-block featured col-3 wow fadeInUp" data-wow-delay="0.6s">
                                    <div className="pricing-block-content">
                                        <div className="pricing" style={{ backgroundColor: "rgb(49, 132, 201)", color: "white" }}>
                                            <h3>DUONUT</h3>
                                            <p className="pricing-sub" style={{ marginBottom: "2rem" }}>For Individuals</p>
                                            <div className="price" style={{ color: "rgb(14, 44, 69)", fontSize: "5rem" }}>Free</div>
                                            <div>FOREVER</div>
                                            <div style={{ marginTop: "2.8rem", fontSize: "1rem" }}>All Duonut Form features are freely <br></br> available to every user.</div>
                                        </div>
                                        <ul>
                                            <li><img className="tickmark-icon" src={tickmark} />  Unlimited templates</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Unlimited questions</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Unlimited pages</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Unlimited styles & components</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  1,500 monthly submissions</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Up to 5 live forms</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  100 emails / month</li>

                                      </ul>
                                        <a onClick={() => handleClickCreateFromScratch()} className="createFreeFormButton" style={{ width: "80%", display: "inline-block", marginBottom: "2rem", fontSize: "16px" }}>START FOR FREE</a>
                                    </div>
                                </div>

                                <div className="pricing-block col-3 wow fadeInUp" data-wow-delay="0.4s">
                                    <div className="pricing-block-content">
                                        <div className="pricing" style={{ backgroundColor: "rgb(14, 44, 69)", color: "white" }}>
                                            <h3>DUONUT PRO</h3>
                                            <p className="pricing-sub" style={{ marginBottom: "2rem" }}>For Small Startups</p>
                                            <div className="price" style={{ color: "rgb(49, 132, 201)" }}>{isMonthly ? "$29" : "$348"}</div>
                                            <div>{isMonthly ? "PER MONTH" : "PER YEAR"}</div>
                                            <p style={{ fontWeight: "600", marginBottom: "0.2rem", height: "28px" }}></p>
                                            <div>Create your own branded forms <br></br> and workflows</div>
                                        </div>
                                        <ul>
                                            <li><img className="tickmark-icon" src={tickmark} />  Up to 10 live forms</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Remove Duonut branding</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Custom HTML & CSS</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Custom userflow logic</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Custom Thank you Page</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Redirect on completion</li>
                                            <li><img className="tickmark-icon" src={tickmark} />  Answer piping & more</li>
                                        </ul>
                                        <a onClick={() => buyBtn(1)} className="createFreeFormButton" style={{ width: "80%", display: "inline-block", marginBottom: "2rem", fontSize: "16px" }}>BUY TODAY</a>
                                    </div>
                                </div>

                                <div className="pricing-block col-3 wow fadeInUp" data-wow-delay="0.8s">
                                    <div className="pricing-block-content" style={{ height: "100%" }}>
                                        <div className="pricing" style={{ backgroundColor: "#d1eaff", color: "black", height: "23rem", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                            <h3 style={{ color: "black" }}>DUONUT BUSINESS</h3>
                                            <p className="pricing-sub" style={{ color: "black", marginBottom: "2rem" }}>For Large teams</p>

                                        </div>

                                        <ul>
                                            <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Advanced logic & API</li>
                                            <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Custom subdomain</li>
                                            <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  White Glove Support</li>
                                            <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Enterprise SLAs</li>
                                            <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Workspaces</li>
                                            <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Custom Form Services</li>
                                            <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Unlimited Workspaces</li>
                                        </ul>
                                        <a onClick={() => handleClickDemo()} className="createFreeFormButton" style={{ width: "80%", display: "inline-block", marginBottom: "2rem", fontSize: "16px" }}>Contact Us</a>

                                    </div>
                                </div>
                            </div> */}


                        </div>
                    </div>

                    <div className="home_page_integration_section">
                        <div className="home_page_section_header">Trusted by Startups & Enterprises</div>

                        <div className="home_page_integration_section_images">
                            <a href="https://www.narayanahealth.org/" target="_blank"><img src="/assets/company/Narayana.png" loading="lazy" alt="duonut" /></a>
                            <img src="/assets/company/xe.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/mintorie.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/ourlearn.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/uleash.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/vivahit1.png" loading="lazy" alt="duonut" />
                            <a href="https://shawarmer.com/" target="_blank"><img style={{ width: "145px" }} src="/assets/company/Shawarmer.png" loading="lazy" alt="duonut" /></a>

                        </div>

                    </div>

                    <div style={{ marginBottom: "8rem" }}>
                        <div className="home_page_section_header" style={{ paddingBottom: "2rem" }}>What People Are Saying</div>
                        <Slider {...sliderSettings}>
                            <div className="home_page_customer_review_Container">
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    I absolutely love using Duonut! It's so easy to create customized forms and workflows without needing any technical skills. The variety of templates makes it simple to collect all kinds of information. I highly recommend it to anyone looking for a user-friendly form builder.
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://www.producthunt.com/posts/duonut-2/launch-day/")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/customer_review3.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Nicola Lanzilotto</div>
                                        <div>Senior Frontend Developer @Tiledesk</div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_page_customer_review_Container">
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    Good data and customer feedback as all we need to build quality products. These forms help with that 👍
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://www.producthunt.com/posts/duonut-2/launch-day")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/customer_review4.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Niklas Buchfink</div>
                                        <div>Design Engineer</div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_page_customer_review_Container">
                                <div>
                                    <img style={{ width: "12rem", cursor: "pointer" }} onClick={() => window.open("https://shawarmer.com/")} src="/assets/company/shawarmer_logo.png" loading="lazy" alt="duonut" />
                                </div>
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    As a leading restaurant chain in the Middle East, at Shwarmer we are innovating new ways to engage with our customers effectively. Duonut has been a great partner in this journey. We can quickly create personalized customer workflows and collect quality feedback with a much higher completion rate.
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://shawarmer.com/")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/shawarmer_profile.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Don Sajith</div>
                                        <div>E-commerce Manager (Strategy, Marketing, Loyalty, Delivery, CRM & Transformation)</div>
                                        <div>Shawarmer. Saudi Arabia</div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_page_customer_review_Container">
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    I became a member and reviewed the tools. I didn't expect preparing a form could be this easy. It's great.
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://www.producthunt.com/posts/duonut-2/launch-day")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/customer_review2.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Ayhan Ergezen</div>
                                        <div>Founder at LivelyPencil</div>
                                    </div>
                                </div>
                            </div>
                        </Slider>

                    </div>


                    <div>
                        <h1 style={{ fontSize: "2.2rem", fontWeight: "600" }}>FAQ</h1>
                        <div style={{ textAlign: "left", maxWidth: width > 800 ? "80%" : "100%", margin: "auto", padding: "2rem 0rem" }}>
                            <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(1)} >
                                {selected === 1 ? `- ` : `+ `} How does the 7-day free trial work?</h2>
                            {selected === 1 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                                After you sign up for the trial, you can access all the features of your plan of choice free for 7 days. Once the 7-day fully featured trial expires, your account will be moved to the Free plan.
                            </div>}
                            <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(2)} >
                                {selected === 2 ? `- ` : `+ `} How long will my data be retained if my account is inactive?</h2>
                            {selected === 2 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                                Your account information and data will be retained as per the <a href="/privacypolicy" target='_blank' style={{ cursor: "pointer" }}> Duonut’s privacy policy.</a> </div>}
                            <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(3)} >
                                {selected === 3 ? `- ` : `+ `} Do I need a credit card to sign up?</h2>
                            {selected === 3 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                                No. A credit card is only required once you’ve chosen to upgrade to a paid plan.
                            </div>}
                            <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(4)} >
                                {selected === 4 ? `- ` : `+ `} What types of payment do you accept?</h2>
                            {selected === 4 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                                We accept payment via Visa, Mastercard, American Express, and PayPal. We also accept payment via bank transfer or check transfer for yearly subscriptions. For more details, please contact hello@duonut.com.
                            </div>}
                            <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(5)} >
                                {selected === 5 ? `- ` : `+ `} When will I be billed for a paid plan?</h2>
                            {selected === 5 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                                You will be billed immediately and monthly from that date onwards.
                            </div>}
                            <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(6)} >
                                {selected === 6 ? `- ` : `+ `} Is there any pre-built application template that we can use to get started?</h2>
                            {selected === 6 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                                Yes, we have 50+ app templates which are pre-built on our no-code platform. You can check those <a href="/templates/all" target='_blank' style={{ cursor: "pointer" }}> here.</a>
                            </div>}
                        </div>
                    </div>

                    {/* <div className='PR1flowApp'>
                        <div style={{ fontSize: "2rem", fontWeight: "800" }}>Workflows</div>
                        <div style={{ margin: "1rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        </div>
                        <div style={{ fontSize: "1.5rem", fontWeight: "400" }}>Unlock limitless growth with advanced workflow features and support</div>
                        <div className="PR1flowcontainer">
                            <div className='PR1flowcontainerForm'>
                                <form id="demoForm" onSubmit={submit}>
                                    <div className='demo_form_modal__input_container'>
                                        <div style={{ paddingBottom: '6px' }}>Business Email*</div>
                                        <input placeholder="your business email"
                                            type='email'
                                            value={email}
                                            onChange={e => handleEmailChange(e)}
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required
                                            style={{ backgroundColor: "white" }}
                                        />
                                    </div>
                                    <div className='demo_form_modal__input_container'>
                                        <div style={{ paddingBottom: '6px' }}>Anything you'd like us to know?</div>
                                        <textarea placeholder="Ex: your form or workflow usecase"
                                            value={message}
                                            onChange={e => handleMessageChange(e)}
                                            style={{ backgroundColor: "white" }}
                                        />
                                    </div>
                                    <div>
                                        <button type="submit" className="ScheduleBtn"> Contact sales for pricing</button>
                                        {submitmessage && <p style={{ padding: "10px", textAlign: "center" }}>{submitmessage}</p>}
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div> */}
                </section>
            </div>
            <HomePageFooter />
        </>

    );

}

export default Pricing;