import React from "react";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import { openModal, showSignInForm, showSignUpForm, updateLogoutModalActive } from "../../redux/slices/formDetails";
import { openModal, showSignInForm, updateLogoutModalActive } from "../../redux/slices/formDetails";
// import { toggleModal } from "../../redux/slices/preview";

import PublishButton from "../PublishButton/publishButton";
// import UserProfile from "./UserProfile.js/userProfile";
import { useNavigate } from 'react-router-dom';
import { toggleDemoModalDuonut } from "../../redux/slices/DemoReducer";
import useOutsideAlerter from "../../hooks/useOutsideAlerter"
import { toggleUserSettingModalDuonut } from "../../redux/slices/UserSettingReducer";
import { setUserActivity, selectUserActivity, setHistoryUserActivities, setHistoryUserActivitiesIndex } from "../../redux/slices/userActivity";
// import { toggleModal as toggleSettingModal, updateDescription, updateTitle, updateSelectedTab } from "../../redux/slices/duonutSettings";
import { toggleProMessageModalDuonut, updateProMessageModalMessage } from "../../redux/slices/ProMessageReducer";

export default function NavButtons({ handleClick, handleNavigate, width }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn, userAccountType, userProAccess, credit, total_credit } = useSelector((state) => state.user);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeToolTip, setactiveToolTip] = useState(false);
  const [hover, setHover] = useState(false);
  const { userActivities, selectedUserActivity, defaultUserActivities, historyUserActivities } = useSelector((state) => state.userActivity);
  const [paramsid, setparamsid] = useState("");

  // console.log("paramsid", paramsid);

  useEffect(() => {

    // Get the current pathname from the window location
    const pathname = location.pathname;

    // Split the pathname into an array of words
    const words = pathname.split('/');

    // Get the last word (assuming the URL ends with a word)
    const lastWord = words[words.length - 1];

    // Use a regular expression to match and capture the numeric part of the URL
    const matchResult = pathname.match(/\/design\/(\d+)/);

    // Check if there is a match and retrieve the captured number
    if (matchResult && matchResult[1]) {
      const extractedNumber = parseInt(matchResult[1]);
      // console.log('Extracted Number:', extractedNumber);
      setparamsid(extractedNumber);
    }
    // Log or use the last word as needed
    // console.log('Last word:', lastWord);

  }, []);

  useEffect(() => {
    if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
      setactiveToolTip(false);
    } else if (!isLoggedIn) {
      setactiveToolTip(false);
    } else {
      setactiveToolTip(true);
    }
  }, [location.pathname, isLoggedIn]);

  // to close duonut options menu
  const hamburgermenuRef = useRef(null)
  const handleClose = () => setMenuOpen(false)
  useOutsideAlerter(hamburgermenuRef, handleClose);

  const navigate = useNavigate();

  const handleHamburgerMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const reports = () => {
    navigate('/reports');
    setMenuOpen(false);
  };

  const handleClickDemo = () => {
    window.open("https://app.duonut.com/duonut/8867680518");
    // dispatch(toggleDemoModalDuonut(true));
    setMenuOpen(false);
  };

  const handleClickUserSetting = () => {
    dispatch(toggleUserSettingModalDuonut(true));
    setMenuOpen(false);
  };

  const resetUserActivities = () => {
    localStorage.removeItem("userActivities");
    localStorage.removeItem("duonutSettings");
    navigate('/design')
    // if (!successModal.isEdited) {
    // dispatch(setUserActivity({ activities: [{}], defaultActivities: [{}] }));
    // dispatch(updateTitle(""));
    // dispatch(updateDescription(""));
    // }
  };

  const handleLoginClick = () => dispatch(openModal());

  const handleFlowClick = () => {
    if (location.pathname.substring(0, 7) === "/design" && location.pathname.length >= 8) {
      navigate('/design/1676/flow')
      // dispatch(updateSelectedTab(6));
      //   dispatch(toggleSettingModal());
    } else if (!isLoggedIn) {
      handleLoginClick();
      dispatch(showSignInForm());
    }
  };

  const handleChannelClick = () => {
    if (!userProAccess) {
      dispatch(toggleProMessageModalDuonut(true));
      dispatch(updateProMessageModalMessage('workspace access'));
      return null;
    }
    navigate("/workflow");
  };

  const handleGetProClick = () => {
    dispatch(toggleProMessageModalDuonut(true));
    dispatch(updateProMessageModalMessage('that little bit extra access'));
  };

  const handleUndo = () => {
    if (historyUserActivities.historyActivityIndex > 0) {
      dispatch(setHistoryUserActivitiesIndex(historyUserActivities.historyActivityIndex - 1));
      if ((historyUserActivities.historyActivity[historyUserActivities.historyActivityIndex - 1]).length <= selectedUserActivity) {
        dispatch(selectUserActivity((historyUserActivities.historyActivity[historyUserActivities.historyActivityIndex - 1]).length - 1));
      }
      dispatch(setUserActivity({ activities: historyUserActivities.historyActivity[historyUserActivities.historyActivityIndex - 1] }));
    }
  };

  const handleRedo = () => {
    // console.log("redo", historyUserActivities.historyActivity.length)
    if (historyUserActivities.historyActivityIndex < historyUserActivities.historyActivity.length - 1) {
      dispatch(setHistoryUserActivitiesIndex(historyUserActivities.historyActivityIndex + 1));
      if ((historyUserActivities.historyActivity[historyUserActivities.historyActivityIndex + 1]).length <= selectedUserActivity) {
        dispatch(selectUserActivity((historyUserActivities.historyActivity[historyUserActivities.historyActivityIndex - 1]).length - 1));
      }
      dispatch(setUserActivity({ activities: historyUserActivities.historyActivity[historyUserActivities.historyActivityIndex + 1] }));
    }
  };

  // console.log("history", historyUserActivities, userActivities)

  return (
    <div className="navbar-container__buttons">
      {/* <p style={{ color: "white", fontSize: "13px", marginRight: "10px" }}> {credit} + 100 Credit</p> */}
      {location.pathname.substring(0, 7) === "/design" && <>
        <button className={historyUserActivities.historyActivityIndex > 0 ? "undoButton" : ""}
          style={{ backgroundColor: "transparent", padding: "6px", border: "none" }}
          onClick={() => handleUndo()}>
          <svg width="18" height="18" viewBox="0 0 32 32" stroke={historyUserActivities.historyActivityIndex > 0 ? "white" : "#bdc2ce"} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.6253 9.27527H18.5977C23.8051 9.27527 28 13.432 28 18.5922C28 23.7523 23.8051 27.9091 18.5977 27.9091H4" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.3636 13.3551L10.1477 9.17754L14.3636 4.99994" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        </button>
        <button className={historyUserActivities.historyActivityIndex < historyUserActivities.historyActivity.length - 1 ? "undoButton" : ""}
          style={{ backgroundColor: "transparent", padding: "6px", marginRight: "12px", border: "none" }}
          onClick={() => handleRedo()}>
          <svg width="18" height="18" viewBox="0 0 32 32" stroke={historyUserActivities.historyActivityIndex < historyUserActivities.historyActivity.length - 1 ? "white" : "#bdc2ce"} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3747 9.27527H13.4023C8.19488 9.27527 4 13.432 4 18.5922C4 23.7523 8.19488 27.9091 13.4023 27.9091H28" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17.6364 13.3551L21.8523 9.17754L17.6364 4.99994" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        </button>
      </>}

      {/* !userProAccess &&  previously for procheck to show the button*/}
      {(location.pathname.substring(0, 7) === "/design") && isLoggedIn &&
        <button className="duonutProButton mr-4"
          onClick={() => {
            window.open('/credit');
            //  handleGetProClick()
          }}>
          {/* <span style={{ paddingRight: "5px", verticalAlign: "middle" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
            <path fill="#fec208" d="M7.51 4.87C7.01 6.27 6.45 6.95 5.94 7c-.57.07-1.07-.18-1.54-.8a.54.54 0 0 0-.1-.1 1 1 0 1 0-.8.4l.01.12.82 3.24A1.5 1.5 0 0 0 5.78 11h4.44a1.5 1.5 0 0 0 1.45-1.14l.82-3.24a.54.54 0 0 0 .01-.12 1 1 0 1 0-.8-.4.54.54 0 0 0-.1.09c-.49.62-1 .87-1.54.81-.5-.05-1.04-.74-1.57-2.13a1 1 0 1 0-.98 0zM11 11.75a.5.5 0 1 1 0 1H5a.5.5 0 1 1 0-1h6z"></path>
          </svg>
        </span>
        Get Duonut Pro */}
          <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" />
          {(total_credit - 100 !== 0) ? <>{total_credit - credit} / 100 + {total_credit - 100}</> : <>{total_credit - credit} / 100</>}
        </button>}

      {(location.pathname.includes("/myduonuts") || location.pathname.includes("/credit") || location.pathname.includes("/paneldata")) && isLoggedIn && (
        <button
          className="duonutProButton mr-4"
          style={{ color: "black" }}
          onClick={() => {
            window.open('/credit');
          }}
        >
          <img
            src="../../assets/icons/coin-3.svg"
            style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }}
            alt="coin icon"
          />{" "}
          {(total_credit - 100 !== 0) ? <>{total_credit - credit} / 100 + {total_credit - 100}</> : <>{total_credit - credit} / 100</>}
        </button>
      )}


      {/* {location.pathname.substring(0, 7) !== "/design" && <a style={{ marginRight: "1rem" }}
        href="https://www.producthunt.com/posts/duonut-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-duonut&#0045;2" target="_blank">
        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=436792&theme=dark" alt="Duonut - The&#0032;most&#0032;powerful&#0032;no&#0045;code&#0032;forms&#0032;&#0038;&#0032;workflows | Product Hunt"
          style={{ width: "200px", height: "40px" }} width="250" height="54" />
      </a>} */}

      {(!isLoggedIn
        // && location.pathname.substring(0, 14) !== "/viewworkflows"
        // && location.pathname.substring(0, 15) !== "/viewworkflow/@"
      ) ? (
        <>
          <button
            onClick={() => {
              handleClick();
              dispatch(showSignInForm());
            }}
            style={{ position: "relative", color: location.pathname.substring(0, 7) === "/design" ? "white" : "black" }}
            className="btn-secondary sign-in-btn"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            Sign In
            {hover && <div className="navbar-selected-line"></div>}
          </button>
          {location.pathname.substring(0, 7) !== "/design" && <button className="demoBtnNav" style={{ marginRight: "16px", marginLeft: "0px" }}
            onClick={() => handleClickDemo()}> BOOK A DEMO
          </button>}
        </>
      ) : (
        <>
          {/* {location.pathname.substring(0, 7) !== "/design" && location.pathname.substring(0, 14) !== "/viewworkflows" && location.pathname.substring(0, 15) !== "/viewworkflow/@" &&
            <button onClick={() => handleChannelClick()} className="btn-secondary sign-in-btn "
              style={location.pathname === "/workflow" ? { position: "relative", fontWeight: "700" } : { position: "relative" }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}>
              My Workflow
              {(hover || location.pathname === "/workflow") && <div className="navbar-selected-line"></div>}
            </button>} */}
          {/* <button
              onClick={() => {
                dispatch(updateLogoutModalActive(true));
              }}
              className="btn-secondary sign-in-btn "
              style={{ marginRight: isLoggedIn ? "0.5rem" : "1rem" }}
            >
              Sign out
            </button> */}
        </>
      )}

      {location.pathname.substring(0, 7) === "/design" ? (
        <>

          <PublishButton />
          <div ref={hamburgermenuRef}>
            {!isLoggedIn ? <></>
              // <button type="button" className="designpagehamburgermenu" onClick={() => handleHamburgerMenu()}>
              //   <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M15,7a.94.94,0,0,1,1,1,.94.94,0,0,1-1,1H1A.94.94,0,0,1,0,8,.94.94,0,0,1,1,7Zm0-6a.94.94,0,0,1,1,1,.94.94,0,0,1-1,1H1A.94.94,0,0,1,0,2,.94.94,0,0,1,1,1Zm0,12a1,1,0,0,1,0,2H1a1,1,0,0,1,0-2Z"></path></g></svg>
              // </button>
              : <div style={{ cursor: "pointer", marginLeft: "1rem" }} onClick={() => handleHamburgerMenu()}>
                <i className="fa fa-user-circle design-hamburgermenuProfile" aria-hidden="true"></i>
              </div>}

            <div className={menuOpen ? "hamburgermenu hamburgeropen" : "hamburgermenu"}>
              <div className="hamburgermenuinside">
                {/* <div className="hamburgermenuitem" onClick={() => { resetUserActivities(); setMenuOpen(false); window.location.reload(); }}> &nbsp;  Create New</div> */}
                {isLoggedIn && <a href="/myduonuts" className="hamburgermenuitem" onClick={() => { navigate("/myduonuts"); setMenuOpen(false); }}> &nbsp; My Duonuts</a>}
                {/*{isLoggedIn && <a href="/reports" className="hamburgermenuitem" onClick={() => reports()} >  &nbsp; Reports</a>}*/}
                {/* {isLoggedIn && <a href={`/pdf/${paramsid}`} className="hamburgermenuitem" onClick={() => { handleNavigate(`/pdf/${paramsid}`); setMenuOpen(false); }}>  &nbsp; Download</a>} */}
                {/* {isLoggedIn && <a className="hamburgermenuitem" onClick={() => { handleChannelClick(); setMenuOpen(false); }}> &nbsp; Workspace</a>} */}
                {isLoggedIn && <div className="hamburgermenuitem" onClick={() => handleClickUserSetting()}> &nbsp; Duonut Mapping</div>}
                {isLoggedIn && <div className="hamburgermenuitem" onClick={() => { navigate("/profile"); setMenuOpen(false); }}> &nbsp; Account Setting</div>}
                {/* <div className="hamburgermenuitem" onClick={() => { handleClickDemo() }}> &nbsp;  Book a Demo</div> */}
                {/* <div className="hamburgermenuitem" onClick={() => { window.open("https://app.getsmartcue.com/demos/IS9SKZA6/embed"); setMenuOpen(false); }}> &nbsp; How does it work</div> */}
                {isLoggedIn && <div className="hamburgermenuitem" onClick={() => dispatch(updateLogoutModalActive(true))}> &nbsp; Sign out</div>}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* {!isLoggedIn && (
            <button
              onClick={() => {
                handleClick();
                dispatch(showSignUpForm());
              }}
              className="btn-secondary"
            >
              START FOR FREE
            </button>
          )} */}
        </>
      )}
      {
        location.pathname.substring(0, 7) !== "/design" && location.pathname.substring(0, 14) !== "/viewworkflows" && location.pathname.substring(0, 15) !== "/viewworkflow/@" && (
          <div ref={hamburgermenuRef}>
            {!isLoggedIn ? <></>
              // <button type="button" className="designpagehamburgermenu" onClick={() => handleHamburgerMenu()}>
              //   <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M15,7a.94.94,0,0,1,1,1,.94.94,0,0,1-1,1H1A.94.94,0,0,1,0,8,.94.94,0,0,1,1,7Zm0-6a.94.94,0,0,1,1,1,.94.94,0,0,1-1,1H1A.94.94,0,0,1,0,2,.94.94,0,0,1,1,1Zm0,12a1,1,0,0,1,0,2H1a1,1,0,0,1,0-2Z"></path></g></svg>
              // </button>
              : <div style={{ cursor: "pointer", marginLeft: "1rem" }} onClick={() => handleHamburgerMenu()}>
                <i className="fa fa-user-circle hamburgermenuProfile" aria-hidden="true"></i>
              </div>}

            <div className={menuOpen ? "hamburgermenu hamburgeropen" : "hamburgermenu"}>
              <div className="hamburgermenuinside">
                {isLoggedIn && <a href="/myduonuts" className="hamburgermenuitem" onClick={() => { navigate("/myduonuts"); setMenuOpen(false); }}>  &nbsp; My Duonuts</a>}
                {/* {isLoggedIn && <a href="/reports" className="hamburgermenuitem" onClick={() => reports()} > &nbsp; Reports</a>}*/}
                {isLoggedIn && <div className="hamburgermenuitem" onClick={() => handleClickUserSetting()}> &nbsp; Duonut Mapping</div>}
                {/* {isLoggedIn && <a className="hamburgermenuitem" onClick={() => { handleChannelClick(); setMenuOpen(false); }}> &nbsp; Workspace</a>} */}
                {isLoggedIn && <div className="hamburgermenuitem" onClick={() => { navigate("/profile"); setMenuOpen(false); }}> &nbsp; Account Setting</div>}
                {/* <div className="hamburgermenuitem" onClick={() => { handleClickDemo() }}> &nbsp;  Book a Demo</div> */}
                {/* <a href="https://app.getsmartcue.com/demos/IS9SKZA6/embed" className="hamburgermenuitem" onClick={() => { window.open("https://app.getsmartcue.com/demos/IS9SKZA6/embed"); setMenuOpen(false); }}> &nbsp; How does it work</a> */}
                {isLoggedIn && <div className="hamburgermenuitem" onClick={() => dispatch(updateLogoutModalActive(true))}> &nbsp; Sign out</div>}
              </div>
            </div>
          </div>)
      }
    </div>
  );
}
