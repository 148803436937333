import React, {  useState, } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./DemoForm.css";
import { motion, AnimatePresence } from "framer-motion";
import { toggleDemoModalDuonut } from "../../../redux/slices/DemoReducer";
import { saveDemoAsync } from '../../../redux/slices/DemoReducer';
import { useLocation } from "react-router-dom";

export default function DemoForm() {
    const dispatch = useDispatch();
    const modalActive = useSelector((state) => state.demo.modalActive);
    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [company, setcompany] = useState('');
    const [source, setsource] = useState('');
    const [message, setmessage] = useState('');
    const [submitmessage, setsubmitmessage] = useState('');
    const location = useLocation();

    const handleClose = () => {
        dispatch(toggleDemoModalDuonut(false));
    };

    const handleNameChange = event => {
        setname(event.target.value);
        // console.log(event.target.value);
    };
    const handleEmailChange = event => {
        setemail(event.target.value);
        // console.log(event.target.value);
    };
    const handlePhoneChange = event => {
        setphone(event.target.value);
        // console.log(event.target.value);
    };
    const handleCompanyChange = event => {
        setcompany(event.target.value);
        // console.log(event.target.value);
    };
    const handleSourceChange = event => {
        setsource(event.target.value);
        // console.log(event.target.value);
    };
    const handleMessageChange = event => {
        setmessage(event.target.value);
        // console.log(event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();

        var demo_data = {
            name: name,
            email: email,
            phone_number: phone,
            company_name: company,
            source: source,
            message: message
        }
        // console.log(demo_data)
        dispatch(saveDemoAsync(demo_data));

        setsubmitmessage("Thanks For Contacting!");

        setTimeout(() => {
            setname("");
            setemail("");
            setphone("");
            setcompany("");
            setsource("");
            setmessage("");
            setsubmitmessage("")
            handleClose();
        }, 3000); //miliseconds

    }

    return (
        <AnimatePresence>
            {modalActive && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.7, ease: "backOut" }} className="modal_container">
                    <motion.div initial={{ y: -20 }} animate={{ y: 0 }} exit={{ y: -30 }} transition={{ duration: 0.7, ease: "backOut" }} className="form_modal row">
                        <div className="col-lg-6 img-column">
                            <div className="form-img-container">
                                <img src="/assets/form.png" className="form-img" />
                            </div>
                        </div>
                        <div className="col-lg-6 form-column">
                            <div className="demo_form_modal__form">
                                <div className="demo_modal_back_btn icon-button" onClick={handleClose}>
                                    {`<`}
                                </div>
                                <h4 className="demo_form_modal__header">{location.pathname !== "/ai" ? "Schedule a DEMO" : "Join Waitlist"}</h4>
                                <form id="demoForm" onSubmit={submit}>
                                    <div className='demo_form_modal__input_container'>
                                        <input placeholder="Full Name*"
                                            value={name}
                                            onChange={e => handleNameChange(e)}
                                            required
                                        />
                                    </div>
                                    <div className='demo_form_modal__input_container'>
                                        <input placeholder="Work Email*"
                                            value={email}
                                            onChange={e => handleEmailChange(e)}
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required
                                        />
                                    </div>
                                    <div className='demo_form_modal__input_container'>
                                        <input placeholder="Phone"
                                            value={phone}
                                            onChange={e => handlePhoneChange(e)}
                                            pattern=".{10,}" minLength="10"
                                        />
                                    </div>
                                    <div className='demo_form_modal__input_container'>
                                        <input placeholder="Website/Company Name"
                                            value={company}
                                            onChange={e => handleCompanyChange(e)}
                                        />
                                    </div>
                                    <div className='demo_form_modal__input_container'>
                                        <select id="mySelect" value={source} onChange={e => handleSourceChange(e)}>
                                            <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>How did you hear about us?</option>
                                            <option value="LinkedIn">LinkedIn</option>
                                            <option value="Google">Google</option>
                                            <option value="Referral">Referral</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div className='demo_form_modal__input_container'>
                                        <textarea placeholder="How are you planning to use Duonut Activities?*"
                                            value={message}
                                            onChange={e => handleMessageChange(e)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <button type="submit" className="ScheduleBtn">{location.pathname !== "/ai" ? "Schedule" : "Submit"}</button>
                                        {submitmessage && <p style={{ padding: "10px", textAlign: "center" }}>{submitmessage}</p>}
                                    </div>
                                </form>
                            </div>

                            <div className="close-icon icon-button" onClick={handleClose}>
                                <img src="/assets/icons/close.svg" />
                            </div>
                        </div>

                    </motion.div>
                    <div className="modal-overlay" onClick={handleClose}></div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
