import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getFileSizeFromBase64 } from "../../../utils";
import { updateUserActivityElements } from '../../../redux/slices/userActivity';
import { domain } from "../../../services/constants";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function AudioUpload() {
  const inputRef = useRef(null);
  const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);
  const dispatch = useDispatch();
  const location = useLocation();
  const [paramsid, setparamsid] = useState("");
  const [errorMess, seterrorMess] = useState("");

  useEffect(() => {

    // Get the current pathname from the window location
    const pathname = location.pathname;

    // Split the pathname into an array of words
    const words = pathname.split('/');

    // Get the last word (assuming the URL ends with a word)
    const lastWord = words[words.length - 1];

    // Use a regular expression to match and capture the numeric part of the URL
    const matchResult = pathname.match(/\/design\/(\d+)/);

    // Check if there is a match and retrieve the captured number
    if (matchResult && matchResult[1]) {
      const extractedNumber = parseInt(matchResult[1]);
      // console.log('Extracted Number:', extractedNumber);
      setparamsid(extractedNumber);
    }
    // Log or use the last word as needed
    // console.log('Last word:', lastWord);

  }, [location.pathname]);

  const handleClick = () => {
    inputRef.current.value = null; // reset the value of the file input
    inputRef.current.click();
  };

  const updateActivityAudio = async (e) => {
    const file = e.target.files[0];
    // console.log(file);

    if (file.size > 5 * 1024 * 1024) {
      // alert("File size exceeds 5MB limit.");
      seterrorMess("upload less than 5 MB Audio file.");

      setTimeout(() => {
        seterrorMess("");
      }, 3000); //miliseconds
      return;
    }

    if (selectedComponent.name === "Audio") {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;

      var reader = new FileReader();
      reader.onloadend = function () {
        // adds image_data in question_id
        // console.log(e.target.files[0],reader.result);

        const audio_data = {
          duonut_id: paramsid,
          name: e.target.files[0].name,
          uri: reader.result,
        }
        axios({
          method: "POST",
          url: `${domain}/duonut/upload_files`,
          headers: {
            "Content-Type": "application/json"
          },
          data: JSON.stringify(audio_data),
        }).then((res) => {
          //console.log(res.data.file_url);
          tempElement = {
            ...tempElement,
            elementAttributes: {
              ...elementAttrib,
              url_data: {},
              url: res.data.file_url,
            },
          };
          dispatch(
            updateUserActivityElements({
              selectedActivityIdx: selectedUserActivity,
              selectedElementIdx: selectedComponent.target_id,
              element: tempElement,
            })
          );
        }).catch((e) => {
          console.log(e.message, e);
        });

        getFileSizeFromBase64(reader.result)
      };
      reader.readAsDataURL(e.target.files[0]);
    }

  };

  return (
    <div className="EditorRowContainer" style={{ margin: "6px 1rem", justifyContent: "center", flexDirection: "column" }}>
      <div className="EditorRowButton" onClick={handleClick}>
        <img src="/assets/icons/upload-image.svg" style={{ marginRight: "5px" }} />
        Upload
        <input type="file" ref={inputRef}
          onChange={(e) => updateActivityAudio(e)}
          accept={"audio/*"}
          onClick={(e) => { e.target.value = null }} name="postImages" style={{ display: "none" }} />
      </div>
      {errorMess && <div style={{ color: 'red', margin: "10px" }}>{errorMess}</div>}
    </div>
  );
}