import React from 'react';
import './ImproveHealthcareWorkflows.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
// import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";

const ImproveHealthcareWorkflows = () => {
    // const { width, height } = useWindowDimensions();
    // const [selected, setSelected] = useState(0);
    const navigate = useNavigate();

    return (
        <div className='BCPO1App'>
            <div className='BCPO1template'>
                <h1 style={{ fontSize: "2.5rem", padding: "2rem 0rem" }}>
                    Improve Healthcare Workflows and Save more than $35,000
                </h1>

                <br></br>
                <div>
                    Healthcare services requires the employers to collect a <a href='https://duonut.com/templates/health_&_life_style/new-patient-health-history-form' target="_blank"> lot of patient data</a>, which focuses on building better systems that provide excellent patient experiences.
                </div>
                <br></br>
                <div>
                    Here team requires much more than a tool to just store and manage all the documents.
                </div>
                <br></br>

                <div className='BCPO1section-1'>
                    <img className='BCPO1section-1-image' src="/assets/blogs/bihw1.png" alt="" />
                </div>

                <br></br>
                <div>
                    During many large events and meetings, often healthcare organization with large, national & international employers with a variety of healthcare services, from worksite clinics to on-site healthcare, requires to collect and process a lot of protected health information (PHI). As and when they expanded their services and offerings, they realize that typical electronic medical records were too cumbersome and complicated, as it doesn’t fit the needs. Team wanted easy-to-use software that could securely collect patient data and provide exceptional patient experiences.
                </div>
                <br></br>

                <div className='BCPO1section-1'>
                    <img className='BCPO1section-1-image' src="/assets/blogs/bihw2.png" alt="" />
                </div>
                <br></br>
                <div>
                    They started using <a href='https://duonut.com/' target="_blank"> Duonut's</a> rapid COVID-19 testing and healthcare screenings to patient surveys and data collection, the combination of various types of Duonut’s <a href='https://duonut.com/templates/health_&_life_style' target="_blank"> Forms</a> , Surveys, stories provided in house Physicians a single tool that could improve all the healthcare workflows they require to build.This helped them in saving operations team efforts by more than $35,000!
                </div>

                <br></br>
                <br></br>

                <h2 id='BlogFeedback3'>Related Templates</h2>
                <br></br>

                <div className='BCPO1MainContainer'>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo3.png" alt="" />
                        </div>
                        <div>
                            Patient satisfaction form
                        </div>
                        <a href='https://duonut.com/templates/health_&_life_style/patient-satisfaction-survey' target="_blank">Get started with this Template </a>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo3.png" alt="" />
                        </div>
                        <div>
                            Covid 19 patient intake form
                        </div>
                        <a href='https://www.duonut.com/duonut/4801779108' target="_blank">Get started with this Template </a>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo3.png" alt="" />
                        </div>
                        <div>
                            Protocols for Infection Control
                        </div>
                        <a href='https://www.duonut.com/duonut/2936784151' target="_blank">Get started with this Template </a>
                    </div>
                    {/* <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo3.png" alt="" />
                        </div>
                        <div>
                            Patient satisfaction form
                        </div>
                        <a href='https://duonut.com/templates/health_&_life_style/patient-satisfaction-survey' target="_blank">Get started with this Template </a>
                    </div> */}
                </div>
                <br></br>
                <br></br>
                <h2 id='BlogFeedback3'>Results:</h2>
                <br></br>

                <div className='BCPO1MainContainer'>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo4.png" alt="" />
                        </div>
                        <div>
                            Simplified workflows
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "400" }}>
                            Created seamless healthcare processes with digital forms, surveys and documents.
                        </div>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo4.png" alt="" />
                        </div>
                        <div>
                            Saved money
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "400" }}>
                            Saved the operations team over $35,000
                        </div>
                    </div>
                    <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo4.png" alt="" />
                        </div>
                        <div>
                            Enhanced the patient experience
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "400" }}>
                            Provided patients with an easier way to complete surveys and get test results
                        </div>
                    </div>
                    {/* <div className='BCPO1Container'>
                        <div className='BCPO1section-2'>
                            <img className='BCPO1section-2-image' src="/assets/blogs/bcpo4.png" alt="" />
                        </div>
                        <div>
                            Saved time
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "400" }}>
                            Reduced patient onboarding time by 25 minutes
                        </div>
                    </div> */}
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <h2>Related Blogs</h2>
                <br></br>

                <div className='BLG1blogs'>

                    <div className='BLG1blog' onClick={() => navigate('/blog/story-telling')}>
                        <div className='BLG1blogImgContainer'>
                            <img className='BLG1blogimage' src="/assets/blogs/bst1.png" alt="" />
                        </div>
                        <h2 style={{ padding: "10px 0px" }}>What is storytelling? How to tell great stories</h2>
                        <div>
                            Storytelling, the timeless craft of narrating tales, is deeply ingrained in human history...
                        </div>
                    </div>

                    <div className='BLG1blog' onClick={() => navigate('/blog/to-do-in-2024')}>
                        <div className='BLG1blogImgContainer'>
                            <img className='BLG1blogimage' src="/assets/blogs/td1.png" alt="" />
                        </div>
                        <h2 style={{ padding: "10px 0px" }}>10 things to do in 2024 that will change your life forever!</h2>
                        <div>
                            It is that time of the year where you make new year resolutions that can bring more inner peace and overall well-being...
                        </div>
                    </div>

                    <div className='BLG1blog' onClick={() => navigate('/blog/cut-patient-onboarding')}>
                        <div className='BLG1blogImgContainer'>
                            <img className='BLG1blogimage' src="/assets/blogs/bcpo2.png" alt="" />
                        </div>
                        <h2 style={{ padding: "10px 0px" }}>Cut Patient onboarding time by 25 minutes</h2>
                        <div>
                            To make patient paperwork more convenient.
                        </div>
                    </div>

                </div>
                <br></br>

                <br></br>
                <br></br>
            </div>
            <HomePageFooter />
        </div>
    );

}

export default ImproveHealthcareWorkflows;