import React, {  useState } from "react";
// import ProlificData from './ProlificData';
import RespondentData from './RespondenData';
// import { updateLogoutModalActive } from "../../redux/slices/formDetails";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import useOutsideAlerter from "../../hooks/useOutsideAlerter";
// import { useDispatch } from "react-redux";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import MenuLeftBar from "../../Components/MenuLeftBar/MenuLeftBar";

const Panel = () => {
    // const dispatch = useDispatch();
    const [selectedPanelTab, setselectedPanelTab] = useState(2);
    // const navigate = useNavigate();
    // const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);

    return (
        <div className='duonutContainer' style={{ display: "flex" }}>

            <MenuLeftBar />
            <div style={{
                display: "flex", flexDirection: "column", minWidth: "95%", paddingTop: "10vh", overflowY: "scroll"
            }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <div className="title" style={{ position: "relative", padding: '0px 10px', cursor: "pointer", fontWeight: selectedPanelTab === 1 ? "700" : "400" }}
                        onClick={() => setselectedPanelTab(1)}>
                        {selectedPanelTab === 1 && <div className="navbar-selected-line"></div>}
                        Prolific </div> */}
                    <div className="title" style={{ position: "relative", padding: '0px 10px', marginLeft: "1rem", cursor: "pointer", fontWeight: selectedPanelTab === 2 ? "700" : "400" }}
                        onClick={() => setselectedPanelTab(2)}>
                        {selectedPanelTab === 2 && <div className="navbar-selected-line"></div>}
                        Respondent </div>
                </div>
                {/* {selectedPanelTab === 1 && <>
                    <ProlificData />
                </>} */}
                {selectedPanelTab === 2 && <>
                    <RespondentData />
                </>}
            </div>
        </div>
    );
}
export default Panel;