import React, { useContext, useEffect, useRef, useState } from "react";
import '../AiSettingNew/AiSettingNew.css';
import axios from "axios";
import { domain } from "../../../services/constants";

import { getUserDetails } from "../../../services/auth";
import { updateCredit, updateTotalCredit } from "../../../redux/slices/user";
import { useDispatch, useSelector } from "react-redux";

import Modal from '../Modal';
// import ReactSlider from "react-slider";

const RespondentPanel = ({ link, projectData }) => {
    // const { isLoggedIn, userAccountType, userProAccess, credit } = useSelector((state) => state.user);
    const {  credit } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [quantity, setQuantity] = useState(10);
    const [incentive, setIncentive] = useState(10);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedAgeGroup, setSelectedAgeGroup] = useState([]);
    const [selectedGender, setSelectedGender] = useState("");
    const [selectedMethodology, setSelectedMethodology] = useState("");
    const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [selectedIncome, setSelectedIncome] = useState([]);
    const [selectedCompanySize, setSelectedCompanySize] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [selectedEthnicity, setSelectedEthnicity] = useState([]);
    const [selectedSeniority, setSelectedSeniority] = useState([]);

    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [marketType, setmarketType] = useState("b2b");
    const [ageRange, setAgeRange] = useState([18, 100]);
    const [timeRequired, settimeRequired] = useState(10);

    const [selectedCountrtKeys, setSelectedCountrtKeys] = useState([]);
    const [selectedAgeGroupKeys, setSelectedAgeGroupKeys] = useState([]);
    const [selectedGenderKeys, setSelectedGenderKeys] = useState("");
    const [selectedMethodologyKeys, setSelectedMethodologyKeys] = useState("");
    const [selectedIndustryKeys, setSelectedIndustryKeys] = useState([]);
    const [selectedIncomeKeys, setSelectedIncomeKeys] = useState([]);
    const [selectedCompanySizeKeys, setSelectedCompanySizeKeys] = useState([]);
    const [selectedEducationKeys, setSelectedEducationKeys] = useState([]);
    const [selectedSkillsKeys, setSelectedSkillsKeys] = useState([]);
    const [selectedJobKeys, setSelectedJobKeys] = useState([]);
    const [selectedTopicsKeys, setSelectedTopicsKeys] = useState([]);
    const [selectedEthnicityKeys, setSelectedEthnicityKeys] = useState([]);
    const [selectedSeniorityKeys, setSelectedSeniorityKeys] = useState([]);
    const [respondentFilter, setrespondentFilter] = useState([]);
    const [respondentSkills, setrespondentSkills] = useState([]);
    const [selectedJobFunctionsKeys, setSelectedJobFunctionsKeys] = useState([]);

    const [activeDropdown, setActiveDropdown] = useState(null);

    const [questions, setQuestions] = useState([]);

    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', message: '' });
    const [buttonText, setButtonText] = useState('Create');

    const handleCloseModal = () => setModalOpen(false);

    const calculateCost = (quantity, incentive, marketType) => {
        const recruitingCost = marketType === "b2b" ? 47 : 23;
        return quantity * (parseFloat(incentive) + recruitingCost);
    };
    const [totalCost, setTotalCost] = useState(() =>
        calculateCost(10, 10, "b2c")
    );
    useEffect(() => {
        setTotalCost(calculateCost(quantity, incentive, marketType));
    }, [quantity, incentive, marketType]);

    useEffect(() => {
        const storedEmail = localStorage.getItem("userEmail");
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []);


    const formRef = useRef(null);

    const resetBtn = () => {
        formRef.current.reset();
        setTitle("");
        setSelectedCountries([]);
        setQuantity(10);
        setSelectedAgeGroup([]);
        setSelectedGender("");
        setSelectedMethodology("");
        setSelectedJobFunctions([]);
        setSelectedIndustry([]);
        // setAgeRange([18, 100]);
        setSelectedCompanySize([]);
        setSelectedEducation([]);
        setSelectedJob([]);
        setSelectedSkills([]);
        setSelectedIncome([]);
        setSelectedTopics([]);
        setSelectedEthnicity([]);
        setSelectedSeniority([]);
        setIncentive(10);
    };

    // useEffect(() => {
    //     console.log("projectData", projectData);
    //     if (projectData !== "undefined" && Object.keys(respondentFilter).length > 0) {
    //         console.log("projectData1");
    //         setTitle(projectData?.publicTitle);
    //         setQuantity(projectData?.targetNumberOfParticipants);
    //         setIncentive(projectData?.incentiveAmount);
    //         setmarketType(projectData?.targetMarketType);
    //         settimeRequired(projectData?.participantTimeRequiredMinutes);
    //         // setTotalCost(projectData?.publicTitle);
    //         setSelectedCountrtKeys(projectData?.targetCountries);
    //         const country = respondentFilter?.country?.filter((c) => projectData?.targetCountries.includes(c.code.toUpperCase()))
    //             .map((c) => c.value);
    //         console.log(country)
    //         setSelectedCountries(country);

    //         // setTitle(projectData?.publicTitle);
    //         // setTitle(projectData?.publicTitle);
    //     }
    // }, [projectData, respondentFilter]);

    useEffect(() => {
        axios({
            method: "GET",
            url: `${domain}/duonut/respondent_filter`,
            headers: {
                "Content-Type": "application/json"
            },

        }).then(data => {
            // console.log('Full API Data:', data);
            setrespondentFilter(data?.data?.respondent_response_filter);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });

    }, []);

    const getSkillFilter = (query) => {
        // console.log(query, query.length)
        if (query.length < 3) {
            setrespondentSkills([]);
            return null;
        }
        axios({
            method: "POST",
            url: `${domain}/duonut/respondent_skills`,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                // Add your payload data here
                query: query,
            },
        }).then(data => {
            // console.log('Full API Data:', data);
            setrespondentSkills(data?.data?.respondent_response_filter?.results);

        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    };

    const handleJobCheckboxChange = (size, key) => {

        setSelectedJob((prev) =>
            prev.includes(size)
                ? prev.filter((item) => item !== size) // Remove if already selected
                : [...prev, size] // Add if not selected
        );
        setSelectedJobKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedJobKeys => selectedJobKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleEducationCheckboxChange = (size, key) => {

        setSelectedEducation((prev) =>
            prev.includes(size)
                ? prev.filter((item) => item !== size) // Remove if already selected
                : [...prev, size] // Add if not selected
        );
        setSelectedEducationKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedEducationKeys => selectedEducationKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleSkillsCheckboxChange = (size, key) => {

        setSelectedSkills((prev) =>
            prev.includes(size)
                ? prev.filter((item) => item !== size) // Remove if already selected
                : [...prev, size] // Add if not selected
        );
        setSelectedSkillsKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedSkillsKeys => selectedSkillsKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    // Function to toggle selection
    const handleComapnySizeSelect = (size, key) => {

        setSelectedCompanySize((prev) =>
            prev.includes(size)
                ? prev.filter((item) => item !== size) // Remove if already selected
                : [...prev, size] // Add if not selected
        );
        setSelectedCompanySizeKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedCompanySizeKeys => selectedCompanySizeKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleTopicsSelect = (topic, key) => {

        setSelectedTopics((prev) =>
            prev.includes(topic)
                ? prev.filter((item) => item !== topic) // Remove if already selected
                : [...prev, topic] // Add if not selected
        );
        setSelectedTopicsKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedTopicsKeys => selectedTopicsKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleEthnicitySelect = (ethnicity, key) => {
        setSelectedEthnicity((prev) =>
            prev.includes(ethnicity)
                ? prev.filter((item) => item !== ethnicity) // Remove if already selected
                : [...prev, ethnicity] // Add if not selected
        );
        setSelectedEthnicityKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedEthnicityKeys => selectedEthnicityKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleSenioritySelect = (seniority, key) => {
        setSelectedSeniority((prev) =>
            prev.includes(seniority)
                ? prev.filter((item) => item !== seniority) // Remove if already selected
                : [...prev, seniority] // Add if not selected
        );
        setSelectedSeniorityKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedSeniorityKeys => selectedSeniorityKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    // Function to toggle selection
    const handleIncomeCheckboxChange = (income, key) => {
        setSelectedIncome((prev) =>
            prev.includes(income)
                ? prev.filter((item) => item !== income) // Remove if already selected
                : [...prev, income] // Add if not selected
        );
        setSelectedIncomeKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedIncomeKeys => selectedIncomeKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    // Function to toggle selection
    const handleAgeGroupCheckboxChange = (age, key) => {
        setSelectedAgeGroup((prev) =>
            prev.includes(age)
                ? prev.filter((item) => item !== age) // Remove if already selected
                : [...prev, age] // Add if not selected
        );
        setSelectedAgeGroupKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedAgeGroupKeys => selectedAgeGroupKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    // Function to toggle selection
    const handleCheckboxChange = (country, key) => {
        setSelectedCountries((prev) =>
            prev.includes(country)
                ? prev.filter((item) => item !== country)
                : [...prev, country]
        );
        // setSelectedCountrtKeys(prev => {
        //     if (prev.includes(key)) {
        //         return prev.filter(selectedCountryKeys => selectedCountryKeys !== key);
        //     } else {
        //         return [...prev, key];
        //     }
        // });
        setSelectedCountrtKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedCountryKeys => selectedCountryKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleGenderSelect = (gender, key) => {
        setSelectedGender(gender);
        setSelectedGenderKeys(key);
    };
    const handleMethodologySelect = (methodology, key) => {
        setSelectedMethodology(methodology);
        setSelectedMethodologyKeys(key);
    };
    const handleJobFunctionsSelect = (jobFunctions, key) => {
        setSelectedJobFunctions((prev) => {
            if (prev.includes(jobFunctions)) {
                return prev.filter((item) => item !== jobFunctions);
            } else {
                return [...prev, jobFunctions];
            }
        });
        setSelectedJobFunctionsKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedJobFunctionsKeys => selectedJobFunctionsKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleAgeChange = (values) => {
        setAgeRange(values);
    };


    const handleIndustrySelect = (industry, key) => {
        setSelectedIndustry((prev) => {
            if (prev.includes(industry)) {
                return prev.filter((item) => item !== industry);
            } else {
                return [...prev, industry];
            }
        });
        setSelectedIndustryKeys(prevState => {
            const keyAsString = String(key);
            if (prevState.includes(keyAsString)) {
                return prevState.filter(selectedIndustryKeys => selectedIndustryKeys !== keyAsString);
            } else {
                return [...prevState, keyAsString];
            }
        });
    };

    const handleIncreaseResponse = () => {
        if (quantity < 100) {
            setQuantity(quantity + 1);
        }
    };

    const handleDecreaseResponse = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };
    const handleIncreaseIncentive = () => {
        if (incentive < 100) {
            setIncentive(incentive + 1);
        }
    };

    const handleDecreaseIncentive = () => {
        if (incentive >= 5) {
            setIncentive(incentive - 1);
        }
    };

    const handleIncreaseTime = () => {
        if (timeRequired < 150) {
            settimeRequired(timeRequired + 5);
        }
    };

    const handleDecreaseTime = () => {
        if (timeRequired > 0) {
            settimeRequired(timeRequired - 5);
        }
    };

    const inputRef = useRef(null);

    const continuePanelBtn = async () => {
        if (!title) {
            setTitleError(true);
            inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            setTimeout(() => {
                setModalContent({
                    title: 'Title Required',
                    message: 'Please enter the title of your study!',
                    button_message: 'OK',
                });
                setModalOpen(true);
                setButtonText('Create');
            }, 1000);// Show error for missing title
            return;
        }

        setTitleError(false); // Clear the error if the title is valid

        setButtonText('Creating...');

        const data_to_respondent = {
            title: title,
            response: quantity,
            survey_link: link,
            incentive: incentive,
            total_cost: totalCost,
            time_required: timeRequired,
            job_functions: selectedJobFunctionsKeys.length > 0 ? selectedJobFunctionsKeys : [],
            country: selectedCountrtKeys.length > 0 ? selectedCountrtKeys : [],
            gender: selectedGenderKeys ? selectedGenderKeys : "",
            methodology: selectedMethodologyKeys ? selectedMethodologyKeys : "",
            age_group: selectedAgeGroupKeys.length > 0 ? selectedAgeGroupKeys : [],
            education: selectedEducationKeys.length > 0 ? selectedEducationKeys : [],
            skills: selectedSkillsKeys.length > 0 ? selectedSkillsKeys : [],
            income: selectedIncomeKeys.length > 0 ? selectedIncomeKeys : [],
            industry: selectedIndustryKeys.length > 0 ? selectedIndustryKeys : [],
            size: selectedCompanySizeKeys.length > 0 ? selectedCompanySizeKeys : [],
            job: selectedJobKeys.length > 0 ? selectedJobKeys : [],
            topics: selectedTopicsKeys.length > 0 ? selectedTopicsKeys : [],
            ethnicity: selectedEthnicityKeys.length > 0 ? selectedEthnicityKeys : [],
            seniority: selectedSeniorityKeys.length > 0 ? selectedSeniorityKeys : [],
            marketType: marketType,
            screener_questions: questions,
        };

        const postToSecondAPI = async () => {
            try {
                const response = await fetch(`${domain}/duonut/create_respondent`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data_to_respondent),
                });

                const responseData = await response.json();

                if (response.status === 200) {
                    // console.log("Data submitted to the second API successfully!", responseData);
                    return { success: true, data: responseData };
                } else {
                    console.error("Error submitting to the second API:", responseData);
                    return { success: false, data: responseData };
                }
            } catch (error) {
                console.error("Error in second API:", error);
                return { success: false, data: error };
            }
        };

        // First API call
        const postToFirstAPI = async (data_toDatabase) => {
            try {
                const token = localStorage.getItem("access");
                const response = await fetch(`${domain}/duonut/duonut_respondent_data`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(data_toDatabase),
                });

                const responseData = await response.json(); // Get the response body
                // console.log("Response Data:", responseData);

                if (response.ok) {  // Checks if status is 200 OK
                    if (responseData.status === 201) {  // Check the backend response content
                        // console.log("Data submitted successfully!");
                        getUserDetails((err, userDetails) => {
                            if (err) return;
                             dispatch(updateTotalCredit(userDetails.data.total_credit));
                                                    dispatch(updateCredit(userDetails.data.credit));
                                                   
                        });
                        return true;
                    } else {
                        console.log(`Error: ${responseData.status || "Submission failed"}`);
                        return false;
                    }
                } else {
                    console.log(`Error: ${responseData.error || "Submission failed"}`);
                    return false;
                }
            } catch (error) {
                console.error("Error in first API:", error);
                return false;
            }
        };

        // Execute the second API first
        try {
            // if (userAccountType === 'free') {
            //     setTimeout(() => {
            //         setModalContent({
            //             title: 'Upgrade Required',
            //             message: 'Please upgrade your account to use the credit for your study!',
            //             button_message: "OK"
            //         });
            //         setModalOpen(true);
            //         setButtonText('Create');
            //     }, 1000);
            // } else 
            if (credit > totalCost) {
                // First, check if the second API is successful
                const response = await postToSecondAPI();
                // console.log(response);

                if (response.success) {
                    const data_toDatabase = {
                        title: response.data.respondent_response.publicTitle,
                        response: response.data.respondent_response.targetNumberOfParticipants,
                        market_type: response.data.respondent_response.targetMarketType,
                        incentive: response.data.respondent_response.targetNumberOfParticipants,
                        cost: totalCost,
                        survey_link: link,
                        invite_link: response.data.respondent_response.inviteSurveyLink,
                        time_required: response.data.respondent_response.participantTimeRequiredMinutes,
                        project_id: response.data.respondent_response.id,
                        project_status: response.data.respondent_response.projectStatus,
                        methodology: response.data.respondent_response.targetResearchMethodology,
                        country: response.data.respondent_response.targetCountries,
                        gender: response.data.respondent_response.targetGenders,
                        topics: response.data.respondent_response.targetProjectTopics,
                        education: response.data.respondent_response.targetEducation,
                        skills: response.data.respondent_response.targetAudienceSkills,
                        job_title: response.data.respondent_response.targetJobTitles,
                        income: response.data.respondent_response.targetHouseholdIncome,
                        industry: response.data.respondent_response.targetProfessionalIndustries,
                        ethnicities: response.data.respondent_response.targetEthnicities,
                        job_functions: response.data.respondent_response.targetJobFunctions,
                        company_size: response.data.respondent_response.targetCompanySize,
                        age_group: response.data.respondent_response.targetAgeGroups,
                        seniority: response.data.respondent_response.seniority,
                        screener_questions: questions,
                    };

                    // If the second API is successful, then call the first API
                    const firstAPISuccess = await postToFirstAPI(data_toDatabase);

                    if (firstAPISuccess) {
                        setTimeout(() => {
                            setModalContent({
                                title: 'Success',
                                message: 'Project has been created successfully',
                                link: '/paneldata',
                                button_message: "View Project"
                            });
                            setModalOpen(true);
                            setButtonText('Create');
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            setModalContent({
                                title: 'Failed',
                                message: 'Failed to create your study. Please try again.',
                                button_message: "OK"
                            });
                            setModalOpen(true);
                            setButtonText('Create');
                        }, 1000);
                    }
                } else {
                    setTimeout(() => {
                        setModalContent({
                            title: 'Failed',
                            message: 'Please select all the fields to create your study.',
                            button_message: "OK"
                        });
                        setModalOpen(true);
                        setButtonText('Create');
                    }, 1000);
                }
                setModalOpen(true);
            } else {
                setTimeout(() => {
                    setModalContent({
                        title: 'Insufficient Credits',
                        message: `You don't have enough credits to create a study. Please purchase ${totalCost} credits.`,
                        link: '/credit',
                        button_message: "Purcharse Credits"
                    });
                    setModalOpen(true);
                    setButtonText('Create');
                }, 1000);
            }
        } catch (error) {
            setTimeout(() => {
                setModalContent({
                    title: 'Error',
                    message: 'An unexpected error has occurred. Please try again.',
                    button_message: "OK"
                });
                setModalOpen(true);
                setButtonText('Create');
            }, 1000);
            setModalOpen(true);
            console.error("Error executing API calls:", error);
        }
    };


    const addOption = (questionId) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === questionId
                    ? { ...q, options: [...q.options, ""] }
                    : q
            )
        );
    };

    const updateOption = (questionId, optionIndex, value) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === questionId
                    ? {
                        ...q,
                        options: q.options.map((opt, i) =>
                            i === optionIndex ? value : opt
                        ),
                    }
                    : q
            )
        );
    };

    const updateQuestionType = (questionId, type) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === questionId ? {
                    ...q,
                    questionType: type,
                    options: type === "radio" || type === "checkbox" ? [""] : [], // Add options for radio/checkbox
                    minValue: type === "sliderScale" ? 0 : undefined, // Add slider fields
                    maxValue: type === "sliderScale" ? 100 : undefined,
                    step: type === "sliderScale" ? 10 : undefined,
                } : q
            )
        );
    };

    const updateQuestionText = (questionId, text) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === questionId ? { ...q, question: text } : q
            )
        );
    };

    const toggleRequired = (questionId) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === questionId
                    ? { ...q, isRequired: !q.isRequired }
                    : q
            )
        );
    };

    const addQuestion = () => {
        setQuestions((prevQuestions) => [
            ...prevQuestions,
            {
                id: prevQuestions.length + 1,
                question: "",
                questionType: "radio",
                options: [""],
                isRequired: false,
            },
        ]);
    };

    const deleteOption = (questionId, optionIndex) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === questionId
                    ? {
                        ...q,
                        options: q.options.filter((_, i) => i !== optionIndex),
                    }
                    : q
            )
        );
    };

    // Function to remove a question
    const removeQuestion = (questionId) => {
        setQuestions((prevQuestions) =>
            prevQuestions.filter((q) => q.id !== questionId)
        );
    };

    const handleDropdownToggle = (dropdownName, e) => {
        e.stopPropagation(); // Prevent click from propagating to the document listener
        setActiveDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const handleOutsideClick = () => {
        setActiveDropdown(null);
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);
    return (
        <>
            <div
                // className="col-lg-6 col-md-6 col-sm-12"
                style={{
                    maxHeight: "700px",
                    overflowY: "auto",
                    // borderBottom: "1px solid #ccc"
                }}>
                <form ref={formRef}>
                    <div
                        style={{ textAlign: "left", width: "98%" }}>
                        <div className="input-title" style={{ width: "90%", boder: "1px solid #ccc", background: "#cccccc42", padding: "15px", marginTop: "10px", borderRadius: "5px" }}>
                            <input
                                ref={inputRef}
                                placeholder="Enter the title of your study"
                                style={{ background: "transparent", fontSize: "15px", width: "100%" }}
                                value={title}
                                required
                                onChange={(e) => setTitle(e.target.value)}
                                onBlur={() => setTitleError(!title)}
                            />
                            {titleError && (
                                <span
                                    style={{
                                        color: "red",
                                        fontSize: "12px",
                                        marginTop: "5px",
                                        display: "block",
                                    }}
                                >
                                    Title is required.
                                </span>
                            )}
                        </div>
                        <div className="responses">
                            <h3 style={{ textAlign: "left", }}>Responses</h3>
                            <div className=""
                                style={{ display: "flex", flexDirection: "column", marginLeft: "30px", alignItems: "flex-end" }}>
                                <div
                                    style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px", display: "flex", justifyContent: "space-between", width: "80px", height: "30px" }}>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        min="1"
                                        max="300"
                                        value={quantity}
                                        style={{ width: '40px', textAlign: 'center' }}
                                        readOnly
                                    />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <button
                                            type="button"
                                            onClick={handleIncreaseResponse}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25B2;
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleDecreaseResponse}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25BC;
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="type-div">
                            <div className="type-selector-container" style={{ marginTop: "20px", width: "90%" }}>
                                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>Type</h3><br />
                                <div className="type-options">
                                    <div>
                                        <input
                                            type="radio"
                                            id="b2b"
                                            name="type"
                                            value="b2b"
                                            checked={marketType === "b2b"}
                                            onChange={() => setmarketType("b2b")}
                                            style={{ border: '2px solid #007BFF', }}
                                        />
                                        &nbsp; <label htmlFor="b2b">Industry Professionals (B2B)</label><br />
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="b2c"
                                            name="type"
                                            value="b2c"
                                            checked={marketType === "b2c"}
                                            onChange={() => setmarketType("b2c")}
                                        />
                                        &nbsp; <label htmlFor="b2c">General Population (B2C)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cost"
                            style={{ textAlign: "left", width: "90%" }}>
                            <div>
                                <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Incentive</label>
                                <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px", display: "flex", justifyContent: "space-between", width: "80px", height: "30px" }}>
                                    <input
                                        type="number"
                                        id="incentive"
                                        name="incentive"
                                        min="5"
                                        max="150"
                                        value={incentive}
                                        style={{ width: '40px', textAlign: 'center' }}
                                        readOnly
                                    />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <button
                                            type="button"
                                            onClick={handleIncreaseIncentive}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25B2;
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleDecreaseIncentive}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25BC;
                                        </button>
                                    </div>
                                </div>
                                <p style={{ color: "lightgret", fontSize: "13px", marginTop: "5px", textAlign: "left" }}>
                                    {/* {marketType === "b2b" ? "We recommend $30+($3 per min for B2B)" : "We recommend $10+($1 per min for B2C)"} */}
                                </p>
                            </div>
                            <div>
                                <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Time Required</label>
                                <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "5px", display: "flex", justifyContent: "space-between", width: "80px", height: "30px" }}>
                                    <input
                                        type="number"
                                        id="time"
                                        name="time"
                                        value={timeRequired}
                                        style={{ width: '40px', textAlign: 'center' }}
                                    />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <button
                                            type="button"
                                            onClick={handleIncreaseTime}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25B2;
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleDecreaseTime}
                                            style={{ background: "transparent" }}
                                        >
                                            &#x25BC;
                                        </button>
                                    </div>
                                </div>
                                <p style={{ color: "lightgret", fontSize: "13px", marginTop: "5px", textAlign: "left" }}>
                                    Time Required in Minutes
                                </p>
                            </div>
                            <div>
                                <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Cost : ${totalCost.toFixed(2)}</label>
                                <p style={{ color: "lightgret", fontSize: "13px", marginTop: "5px", textAlign: "left" }}>
                                    ({quantity} completes) x (${incentive} incentive + $
                                    {marketType === "b2b" ? "47" : "23"} {marketType === "b2b" ? "B2B" : "B2C"} recruiting cost)
                                </p>
                            </div>
                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", margin: "10px", width: "100%", paddingTop: "10px", borderTop: "1px solid #dbd9e1" }}>
                            <div className="age-selector-container" style={{ marginTop: '0', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                <div>
                                    <label style={{ fontWeight: "bold" }}>Age Range</label>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px", width: "80%" }}>
                                    <span style={{ fontSize: "14px", color: "grey", marginRight: "10px" }}>{ageRange[0]}</span>
                                    <ReactSlider
                                        className="horizontal-slider"
                                        thumbClassName="thumb"
                                        trackClassName="track"
                                        min={18}
                                        max={100}
                                        step={1}
                                        value={ageRange}
                                        onChange={handleAgeChange}
                                        renderTrack={(props, state) => {
                                            const { index } = state; // Track index (0 = left, 1 = middle, 2 = right)
                                            const isSelectedRange = index === 1;
                                            return (
                                                <div
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        backgroundColor: isSelectedRange ? "#007BFF" : "#B0D4FF",
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                    <span style={{ fontSize: "14px", color: "grey", marginLeft: "10px" }}>{ageRange[1]}</span>
                                </div>
                            </div>
                        </div> */}
                        <div className="country-selector-container" style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => handleDropdownToggle("country", e)}>
                            <label style={{ fontWeight: "bold" }}>Country</label>

                            {/* Display selected countries */}
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"

                            >
                                <span>
                                    {selectedCountries.length > 0
                                        ? selectedCountries.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "country" ? (
                                    <i
                                        className="fa fa-angle-up"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-angle-down"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                )}
                            </div>

                            {/* Dropdown for checkboxes */}
                            {activeDropdown === "country" && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.country.map((item, i) => (
                                        <label key={i} className="dropdown-item">
                                            <input
                                                type="checkbox"
                                                checked={selectedCountries.includes(item.value)}
                                                onChange={() => handleCheckboxChange(item.value, item.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {item.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="gender-selector-container" style={{ marginTop: "0", width: "90%", }}
                            onClick={(e) => handleDropdownToggle("gender", e)}>
                            <label style={{ fontWeight: "bold" }}>Gender <label style={{ color: "red", fontWeight: "400", fontSize: "12px" }}>required</label></label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field" >
                                <span>
                                    {selectedGender ? selectedGender : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "gender" ? (
                                    <i
                                        className="fa fa-angle-up"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-angle-down"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                )}
                            </div>
                            {activeDropdown === "gender" && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.gender.map((gender, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedGender === gender.value}
                                                onChange={() => handleGenderSelect(gender.value, gender.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {gender.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="gender-selector-container" style={{ marginTop: "0", width: "90%", }}
                            onClick={(e) => handleDropdownToggle("methodology", e)}>
                            <label style={{ fontWeight: "bold" }}>Methodology  <label style={{ color: "red", fontWeight: "400", fontSize: "12px" }}>required</label></label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedMethodology ? selectedMethodology : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "methodology" ? (
                                    <i
                                        className="fa fa-angle-up"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-angle-down"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                )}
                            </div>
                            {activeDropdown === "methodology" && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {[
                                        { value: 'One on One', code: 'oneOnOne' },
                                        { value: 'Focus Group', code: 'focusGroup' },
                                        { value: 'Survey', code: 'survey' },
                                        { value: 'Diary Study', code: 'diaryStudy' },
                                    ].map((methodology, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedMethodology === methodology.value}
                                                onChange={() => handleMethodologySelect(methodology.value, methodology.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {methodology.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="language-selector-container" style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => handleDropdownToggle("age", e)}>
                            <label style={{ fontWeight: "bold" }}>Age Group</label>

                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedAgeGroup.length > 0
                                        ? selectedAgeGroup.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "age" ? (
                                    <i
                                        className="fa fa-angle-up"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-angle-down"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                )}
                            </div>
                            {activeDropdown === "age" && (
                                <div
                                    className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.ageGroup.map((age, i) => (
                                        <label
                                            key={i}
                                            className="dropdown-item"
                                            style={{
                                                display: 'block',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                color: '#374151',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedAgeGroup.includes(age.value)}
                                                onChange={() => handleAgeGroupCheckboxChange(age.value, age.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {age.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="education-selector-container" style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => handleDropdownToggle("education", e)}>
                            <label style={{ fontWeight: "bold" }}>Education</label>

                            {/* Display selected languages */}
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedEducation.length > 0
                                        ? selectedEducation.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "education" ? (
                                    <i
                                        className="fa fa-angle-up"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-angle-down"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                )}
                            </div>
                            {activeDropdown === "education" && (
                                <div
                                    className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.educationLevel.map((education, i) => (
                                        <label
                                            key={i}
                                            className="dropdown-item"
                                            style={{
                                                display: 'block',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                color: '#374151',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedEducation.includes(education.value)}
                                                onChange={() => handleEducationCheckboxChange(education.value, education.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {education.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        {marketType === "b2b" &&
                            <div className="skills-selector-container" style={{ marginTop: '0', width: "90%", }}
                                onClick={(e) => handleDropdownToggle("skills", e)}>
                                <label style={{ fontWeight: "bold" }}>Skills <label style={{ color: "red", fontWeight: "400", fontSize: "12px" }}>required</label></label>

                                {/* Display selected languages */}
                                <div style={{ marginTop: "10px", fontSize: "16px" }}
                                    className="dropdown-field"
                                >
                                    <span>
                                        {selectedSkills.length > 0
                                            ? selectedSkills.join(', ')
                                            : 'Any'}
                                    </span>
                                    &nbsp;
                                    {activeDropdown === "skills" ? (
                                        <i
                                            className="fa fa-angle-up"
                                            aria-hidden="true"
                                            style={{ fontSize: "20px" }}
                                        ></i>
                                    ) : (
                                        <i
                                            className="fa fa-angle-down"
                                            aria-hidden="true"
                                            style={{ fontSize: "20px" }}
                                        ></i>
                                    )}
                                </div>
                                {activeDropdown === "skills" && (<>
                                    <div
                                        className="dropdown-menu" style={{ fontSize: "16px" }}
                                        onClick={(e) => e.stopPropagation()}>
                                        <input type="text"
                                            style={{
                                                width: "95%",
                                                padding: "10px",
                                                border: "1px solid gray",
                                                borderRadius: "5px",
                                                margin: "auto",
                                                display: "block"
                                            }}
                                            placeholder="Search... (type min 3 letter)"
                                            onChange={(e) => getSkillFilter(e.target.value)}
                                        />
                                        {respondentSkills?.map((skills, i) => (
                                            <label
                                                key={i}
                                                className="dropdown-item"
                                                style={{
                                                    display: 'block',
                                                    marginBottom: '5px',
                                                    cursor: 'pointer',
                                                    fontSize: '14px',
                                                    color: '#374151',
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedSkills.includes(skills.name)}
                                                    onChange={() => handleSkillsCheckboxChange(skills.name, skills.id)}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                {skills.name}
                                            </label>
                                        ))}
                                    </div>
                                </>
                                )}
                            </div>}
                        {marketType === "b2b" &&
                            <div className="job-selector-container" style={{ marginTop: '0', width: "90%", }}
                                onClick={(e) => handleDropdownToggle("job", e)}
                            >
                                <label style={{ fontWeight: "bold" }}>Job Title <label style={{ color: "red", fontWeight: "400", fontSize: "12px" }}>required</label></label>

                                {/* Display selected languages */}
                                <div style={{ marginTop: "10px", fontSize: "16px" }}
                                    className="dropdown-field"
                                >
                                    <span>
                                        {selectedJob.length > 0
                                            ? selectedJob.join(', ')
                                            : 'Any'}
                                    </span>
                                    &nbsp;
                                    {activeDropdown === "job" ? (
                                        <i
                                            className="fa fa-angle-up"
                                            aria-hidden="true"
                                            style={{ fontSize: "20px" }}
                                        ></i>
                                    ) : (
                                        <i
                                            className="fa fa-angle-down"
                                            aria-hidden="true"
                                            style={{ fontSize: "20px" }}
                                        ></i>
                                    )}
                                </div>
                                {activeDropdown === "job" && (
                                    <div
                                        className="dropdown-menu" style={{ fontSize: "16px" }}
                                        onClick={(e) => e.stopPropagation()}>
                                        {respondentFilter.jobTitles.map((job, i) => (
                                            <label
                                                key={i}
                                                className="dropdown-item"
                                                style={{
                                                    display: 'block',
                                                    marginBottom: '5px',
                                                    cursor: 'pointer',
                                                    fontSize: '14px',
                                                    color: '#374151',
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedJob.includes(job.value)}
                                                    onChange={() => handleJobCheckboxChange(job.value, job.code)}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                {job.value}
                                            </label>
                                        ))}
                                    </div>
                                )}
                            </div>}
                        <div className="income-selector-container" style={{ marginTop: '0', width: "90%", }}
                            onClick={(e) => handleDropdownToggle("income", e)}>
                            <label style={{ fontWeight: "bold" }}>Income</label>

                            {/* Display selected languages */}
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedIncome.length > 0
                                        ? selectedIncome.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "income" ? (
                                    <i
                                        className="fa fa-angle-up"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-angle-down"
                                        aria-hidden="true"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                )}
                            </div>
                            {activeDropdown === "income" && (
                                <div
                                    className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.householdIncome.map((income, i) => (
                                        <label
                                            key={i}
                                            className="dropdown-item"
                                            style={{
                                                display: 'block',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                color: '#374151',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedIncome.includes(income.value)}
                                                onChange={() => handleIncomeCheckboxChange(income.value, income.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {income.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        {marketType === "b2b" && <div className="industry-selector-container" style={{ marginTop: "0", width: "90%", }}
                            onClick={(e) => handleDropdownToggle("industries", e)}>
                            <label style={{ fontWeight: "bold" }}>Industries <label style={{ color: "red", fontWeight: "400", fontSize: "12px" }}>required</label></label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedIndustry.length > 0
                                        ? selectedIndustry.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "industries" ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {activeDropdown === "industries" && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }} onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.industries.map((industry, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedIndustry.includes(industry.value)}
                                                onChange={() => handleIndustrySelect(industry.value, industry.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {industry.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>}
                        {marketType === "b2b" && <div className="devices-selector-container" style={{ marginTop: "0", width: "90%", }}
                            onClick={(e) => handleDropdownToggle("job-function", e)}>
                            <label style={{ fontWeight: "bold" }}>Job Functions <label style={{ color: "red", fontWeight: "400", fontSize: "12px" }}>required</label></label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedJobFunctions.length > 0
                                        ? selectedJobFunctions.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "job-function" ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {activeDropdown === "job-function" && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.jobFunction.map((job, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedJobFunctions.includes(job.value)}
                                                onChange={() => handleJobFunctionsSelect(job.value, job.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {job.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>}
                        {marketType === "b2b" && <div className="companySize-selector-container" style={{ marginTop: "0", width: "90%", }} onClick={(e) => handleDropdownToggle("company-size", e)}>
                            <label style={{ fontWeight: "bold" }}>Company Size  <label style={{ color: "red", fontWeight: "400", fontSize: "12px" }}>required</label></label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedCompanySize.length > 0
                                        ? selectedCompanySize.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "company-size" ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {activeDropdown === "company-size" && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }} onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.companySize.map((size, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedCompanySize.includes(size.value)}
                                                onChange={() => handleComapnySizeSelect(size.value, size.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {size.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>}
                        {marketType === "b2c" && <div className="companySize-selector-container" style={{ marginTop: "0", width: "90%", }}
                            onClick={(e) => handleDropdownToggle("topic", e)}>
                            <label style={{ fontWeight: "bold" }}>Topics <label style={{ color: "red", fontWeight: "400", fontSize: "12px" }}>required</label></label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedTopics.length > 0
                                        ? selectedTopics.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "topic" ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {activeDropdown === "topic" && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.topics.map((topic, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedTopics.includes(topic.value)}
                                                onChange={() => handleTopicsSelect(topic.value, topic.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {topic.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>}

                        <div className="companySize-selector-container" style={{ marginTop: "0", width: "90%", }}
                            onClick={(e) => handleDropdownToggle("ethnicity", e)}>
                            <label style={{ fontWeight: "bold" }}>Ethnicity</label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedEthnicity.length > 0
                                        ? selectedEthnicity.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "ethnicity" ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {activeDropdown === "ethnicity" && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.ethnicity.map((ethnicity, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedEthnicity.includes(ethnicity.value)}
                                                onChange={() => handleEthnicitySelect(ethnicity.value, ethnicity.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {ethnicity.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        {marketType === "b2b" && <div className="companySize-selector-container" style={{ marginTop: "0", width: "90%", }}
                            onClick={(e) => handleDropdownToggle("seniority", e)}>
                            <label style={{ fontWeight: "bold" }}>Seniority</label>
                            <div style={{ marginTop: "10px", fontSize: "16px" }}
                                className="dropdown-field"
                            >
                                <span>
                                    {selectedSeniority.length > 0
                                        ? selectedSeniority.join(', ')
                                        : 'Any'}
                                </span>
                                &nbsp;
                                {activeDropdown === "seniority" ?
                                    <i class="fa fa-angle-up" aria-hidden="true" style={{ fontSize: "20px" }}></i> :
                                    <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: "20px" }}></i>}
                            </div>

                            {/* Dropdown for languages */}
                            {activeDropdown === "seniority" && (
                                <div className="dropdown-menu" style={{ fontSize: "16px" }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {respondentFilter.seniorityLevel.map((seniority, i) => (
                                        <label key={i} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedSeniority.includes(seniority.value)}
                                                onChange={() => handleSenioritySelect(seniority.value, seniority.code)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            {seniority.value}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>}

                        <div style={{ margin: "30px 0px 0px 40px" }}>
                            <h3>Screener Questions</h3>
                            <p>Filter respondents based on your custom screening criteria.</p>
                            <p style={{ color: "red", fontWeight: "400", fontSize: "12px", padding: "6px 0px" }}>minimun 2 Screener questions required</p>

                            {questions.map((question, questionIndex) => (
                                <div
                                    key={question.id}
                                    style={{
                                        border: "1px solid #ccc",
                                        padding: "15px",
                                        margin: "10px 0px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <div style={{ marginBottom: "10px" }}>
                                        <input
                                            type="text"
                                            placeholder="Enter your question"
                                            value={question.question}
                                            onChange={(e) =>
                                                updateQuestionText(question.id, e.target.value)
                                            }
                                            style={{ width: "100%", padding: "6px", marginBottom: "10px" }}
                                        />
                                    </div>
                                    <div style={{ marginBottom: "10px" }}>
                                        <select
                                            value={question.questionType}
                                            onChange={(e) =>
                                                updateQuestionType(question.id, e.target.value)
                                            }
                                            style={{ padding: "8px", width: "100%" }}
                                        >
                                            <option value="singleLineTextbox">Single line text box</option>
                                            <option value="radio">Radio (Single select)</option>
                                            <option value="checkbox">Checkbox (Multi select)</option>
                                            <option value="sliderScale">Slider number selector</option>
                                        </select>
                                    </div>
                                    {(question.questionType === "radio" ||
                                        question.questionType === "checkbox") && (
                                            <>
                                                {question.options.map((option, index) => (
                                                    <div key={index} style={{ display: "flex", marginBottom: "10px" }}>
                                                        <input
                                                            type="text"
                                                            placeholder="Option text"
                                                            value={option}
                                                            onChange={(e) =>
                                                                updateOption(question.id, index, e.target.value)
                                                            }
                                                            style={{ flex: 1, padding: "8px" }}
                                                        />
                                                        <button type="button"
                                                            onClick={() => deleteOption(question.id, index)}
                                                            style={{
                                                                marginLeft: "10px",
                                                                padding: "8px",
                                                                background: "transparent",
                                                                border: "none",
                                                                borderRadius: "4px",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <i className="fa fa-times" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                ))}
                                                <button type="button"
                                                    onClick={() => addOption(question.id)}
                                                    style={{
                                                        padding: "8px",
                                                        background: "#007bff",
                                                        color: "#fff",
                                                        border: "none",
                                                        borderRadius: "4px",
                                                        cursor: "pointer",
                                                        marginBottom: "10px",
                                                        display: "block"
                                                    }}
                                                >
                                                    Add Option
                                                </button>
                                            </>
                                        )}
                                    {question.questionType === "sliderScale" && (
                                        <div style={{ marginBottom: "10px", display: "flex", justifyContent: "space-between" }}>
                                            <label>
                                                Min Value:
                                                <input
                                                    type="number"
                                                    value={question.minValue}
                                                    onChange={(e) =>
                                                        setQuestions((prevQuestions) =>
                                                            prevQuestions.map((q) =>
                                                                q.id === question.id
                                                                    ? { ...q, minValue: Number(e.target.value) }
                                                                    : q
                                                            )
                                                        )
                                                    }
                                                    style={{ marginLeft: "10px", padding: "8px", width: "3rem" }}
                                                />
                                            </label>
                                            <label style={{ marginLeft: "10px" }}>
                                                Max Value:
                                                <input
                                                    type="number"
                                                    value={question.maxValue}
                                                    onChange={(e) =>
                                                        setQuestions((prevQuestions) =>
                                                            prevQuestions.map((q) =>
                                                                q.id === question.id
                                                                    ? { ...q, maxValue: Number(e.target.value) }
                                                                    : q
                                                            )
                                                        )
                                                    }
                                                    style={{ marginLeft: "10px", padding: "8px", width: "3rem" }}
                                                />
                                            </label>
                                            <label style={{ marginLeft: "10px" }}>
                                                Step:
                                                <input
                                                    type="number"
                                                    value={question.step}
                                                    onChange={(e) =>
                                                        setQuestions((prevQuestions) =>
                                                            prevQuestions.map((q) =>
                                                                q.id === question.id
                                                                    ? { ...q, step: Number(e.target.value) }
                                                                    : q
                                                            )
                                                        )
                                                    }
                                                    style={{ marginLeft: "10px", padding: "8px", width: "3rem" }}
                                                />
                                            </label>
                                        </div>
                                    )}
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={question.isRequired}
                                            onChange={() => toggleRequired(question.id)}
                                        />{" "}
                                        Required
                                    </label>
                                    <button type="button"
                                        onClick={() => removeQuestion(question.id)}
                                        style={{
                                            padding: "8px",
                                            background: "transparent",
                                            border: "none",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            float: "right"
                                        }}
                                    >
                                        <i className="fa fa-trash" style={{ fontSize: "1.2rem" }} aria-hidden="true"></i>
                                    </button>
                                </div>
                            ))}

                            <button type="button" style={{ padding: "1rem", display: "block", width: "90%", margin: "1rem auto", cursor: "pointer" }}
                                onClick={() => addQuestion()}>Add Question</button>
                        </div>


                    </div>
                </form>
            </div>

            <div>

                <button className="QRC1Continuebtn" style={{ margin: "2vh", border: "1px solid black" }}
                    onClick={() => continuePanelBtn()}> {buttonText}</button>
                <button className='QRC1Continuebtn' onClick={() => resetBtn()}>Reset</button>
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    title={modalContent.title}
                    link={modalContent.link}
                    button_message={modalContent.button_message}
                />
            </div>
        </>
    );
}
export default RespondentPanel;