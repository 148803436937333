import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements,    updateElementInputFocus } from '../../redux/slices/userActivity';
import {  useLocation, } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import { showduonutData } from '../../redux/slices/DuonutReducer';
import axios from "axios";
import { domain } from "../../services/constants";

const FileUpload = ({
  id,
  elementAttr: {
    color, fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign,
    lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, required,
    fieldPosition, borderBottom, gap, height,
  },
  conditions,
  language,
  slideId,
}) => {
  const dispatch = useDispatch();
  const { userActivities, selectedUserActivity,  selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
  const location = useLocation();
  const save_ans_response = useSelector(showDuonutresponse);
  const { viewer_display_id, viewer_nick_name, save_form_data, viewer_url_params } = useSelector((state) => state.duonut);
  const { viewType } = useSelector((state) => state.duonutSettings);
  const [inputText, setInputText] = useState("");
  const [errMsg, seterrMsg] = useState("");

  const duonutDataMain = useSelector(showduonutData);
  // const duonutData = duonutDataMain[0];

  var gmail_integration;
  if (duonutDataMain[0]) {
    gmail_integration = duonutDataMain[0].gmail_integration;

  }
  const refreshAccessToken = async (refreshToken) => {
    try {
      const response = await axios.post(
        'https://oauth2.googleapis.com/token',
        new URLSearchParams({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
          client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET, // Replace with your client secret
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
        })
      );
      // console.log(response.data)
      return response.data.access_token;
    } catch (error) {
      console.error('Error refreshing access token', error);
      throw error;
    }
  };

  const sendEmail = async (accessToken, refreshToken, email_id, subject, message) => {
    // console.log("data", accessToken, email_id, subject, message);

    const email = [
      `To: ${email_id}`,
      `Subject: ${subject}`,
      '',
      `${message}`,
    ].join('\n');

    const base64EncodedEmail = btoa(unescape(encodeURIComponent(email)));

    try {
      await axios.post(
        'https://www.googleapis.com/gmail/v1/users/me/messages/send',
        {
          raw: base64EncodedEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log('Email sent successfully');
    } catch (error) {
      console.error('Error sending email', error);
      if (error.response && error.response.status === 401) { // Access token expired
        try {
          const newAccessToken = await refreshAccessToken(refreshToken);
          await sendEmail(newAccessToken, refreshToken, email_id, subject, message);
        } catch (refreshError) {
          console.error('Error refreshing access token', refreshError);
        }
      } else {
        console.error('Error sending email', error);
      }
    }
  };



  useEffect(() => {
    if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
      fetchText(text);
    } else {
      setInputText(text);
    }
  }, [text]);

  const fetchText = async (texts) => {
    const t_text = await translateText(texts, language);
    setInputText(t_text);
  };
  const translateText = async (texts, targetLanguage) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${domain}/duonut/language_translator`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          source: "en",
          target: targetLanguage,
          query: texts,
        },
      });

      if (response.status === 200) {
        // console.log("Translation:", response);
        return response?.data.translation; // return the translated text
      } else {
        // Handle unsuccessful status codes
        return "Error: Translation failed. Please try again.";
      }
    } catch (error) {
      console.error('Error in translation API:', error);
      return "Error translating text. Please try again."; // Handle API errors gracefully
    }
  };

  const handleFileUploadFieldTextChange = (e) => {
    setInputText(e.target.value);
    // console.log(e.target.value);

    if (userActivities[selectedUserActivity].elements.length >= 1) {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;
      tempElement = {
        ...tempElement,
        elementAttributes: {
          ...elementAttrib,
          text: e.target.value,
        },
      };
      // console.log("tempElement", tempElement)
      dispatch(
        updateUserActivityElements({
          selectedActivityIdx: selectedUserActivity,
          selectedElementIdx: selectedComponent.target_id,
          element: tempElement,
        })
      );
    }
  }

  const handleFileUploadFieldInputChange = (e) => {
    if (location.pathname.includes("/duonut/")) {

      const file = e.target.files[0];

      if (file) {
        const fileSize = file.size; // size in bytes
        const fileSizeInKB = fileSize / 1024; // size in KB

        if (fileSizeInKB > 2048) {
          seterrMsg("Please upload less than 2 MB.");
          setTimeout(() => {
            seterrMsg("");
          }, 3000); //miliseconds
          return null;
        }
      }

      var save_ans = {
        display_id: viewer_display_id,
        watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
        result: file?.name ? 1 : 0,
        question: text,
        answered_text: file?.name,
        // end: lastQue ? "1" : "0",
        nickname: viewer_nick_name,
        step: selectedSlide + 1,
        type: "FileUpload",
        // country: location[0] ? location[0].address?.country : "",
        // city: location[0] ? location[0].address?.state_district : "",
        // location: location[0] ? location[0].display_name : "",
        url_params: viewer_url_params,
      }
      // console.log(save_ans);

      const formData = new FormData();
      formData.append("analytic_file", file);
      Object.keys(save_ans).forEach(key => {
        const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
        formData.append(key, value);
      });

      dispatch(saveQuizAnsAsync(formData));

      var save_response = {
        id: id,
        type: "FileUpload",
        step: selectedSlide + 1,
        question: text,
        answered_text: file?.name,
      }
      dispatch(saveQuizResponse(save_response));


      if (gmail_integration?.access_key) {

        if (gmail_integration?.trigger !== "File Submitted") return;

        var email_id = gmail_integration?.to?.replace(/{{(.*?)}}/g, (match, captured) => {
          const lowerCaptured = captured.toLowerCase();
          // Convert keys in save_form_data to lowercase for case-insensitive check
          const lowerSaveFormData = Object.fromEntries(
            Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
          );
          if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
            return lowerSaveFormData[lowerCaptured];
          } else {
            return match; // if no replacement found, keep the original placeholder
          }
        });
        var subject = gmail_integration?.subject?.replace(/{{(.*?)}}/g, (match, captured) => {
          const lowerCaptured = captured.toLowerCase();
          // Convert keys in save_form_data to lowercase for case-insensitive check
          const lowerSaveFormData = Object.fromEntries(
            Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
          );
          if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
            return lowerSaveFormData[lowerCaptured];
          } else {
            return match; // if no replacement found, keep the original placeholder
          }
        });
        var message = gmail_integration?.message?.replace(/{{(.*?)}}/g, (match, captured) => {
          const lowerCaptured = captured.toLowerCase();
          // Convert keys in save_form_data to lowercase for case-insensitive check
          const lowerSaveFormData = Object.fromEntries(
            Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
          );
          if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
            return lowerSaveFormData[lowerCaptured];
          } else {
            return match; // if no replacement found, keep the original placeholder
          }
        });
        // console.log("data", email_id, subject, message);

        sendEmail(gmail_integration?.access_key, gmail_integration?.refresh_key, email_id, subject, message);

      }


    }
  }


  const handleInputFocus = () => {
    dispatch(updateElementInputFocus(true));
  }
  const handleInputBlur = () => {
    dispatch(updateElementInputFocus(false));
  }

  return (<>
    <div style={{ margin, display: "flex", justifyContent: 'space-between', flexDirection: fieldPosition ? fieldPosition : "column", gap: gap ? gap : fieldPosition === 'row' ? '20px' : '0' }}>
      <EditText
        id={`file-uplaod-input-${id}`}
        value={required && location.pathname.includes("/duonut/") ? inputText + " *" : inputText}
        className='edit-input-text'
        style={{
          backgroundColor: "transparent",
          border: "0",
          outline: "none",
          // display: "flex",
          // alignItems: "center",
          width: fieldPosition === 'row' ? "max-content" : "100%",
          color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
          textAlign, lineHeight, letterSpacing, padding
        }}
        onEditMode={() => {
          handleInputFocus();
          // setTimeout(() => document.querySelector(`#file-uplaod-input-${id}`).select(), 100); 
        }}
        onChange={(e) => handleFileUploadFieldTextChange(e)}
        onBlur={handleInputBlur}
        readonly={(location.pathname).includes("/duonut/") || viewType === 3 ? true : false}
      />
      <input type='file' key={borderBottom} style={{
        width: "100%", height: height, pointerEvents: location.pathname.includes("/duonut/") ? 'auto' : 'none',
        color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
        textAlign, lineHeight, letterSpacing, padding: "4px 10px", boxSizing: "border-box",
        backgroundColor, border: borderBottom ? "0" : border, margin, borderRadius, borderBottom: border
      }}
        onChange={(e) => handleFileUploadFieldInputChange(e)}
        required={required}
      />
    </div>
    {errMsg && <div style={{ color: 'red', padding: '10px' }}>{errMsg}</div>}
  </>
  );
}

export default FileUpload;
