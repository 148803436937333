import React, { useState, useEffect, useRef } from 'react';
import './AiDuonutPageNew.css';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
    getAiDuonutNewAsync, showAiDuonutNewData, saveAiAnsNewAsync, getRatingFeedbackAsync,
    getAiConversationAsync, getAiAnsNewAsync
} from '../../redux/slices/AiDuonutNewReducer';

const AiDuonutPageNew = () => {
    const paramsid = useParams();
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(-1);
    const [isLoading, setisLoading] = useState(false);
    const [clickedNum, setClickedNum] = useState(-1);
    const [aiConversation, setaiConversation] = useState([]);
    const [userQuestion, setuserQuestion] = useState("");
    const [pageAcess, setpageAcess] = useState(1);
    const get_watch_id = localStorage.getItem(paramsid.id);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const rating = queryParams.get('rating');
    // console.log(rating)
    const inputRef = useRef(null);

    const [isInIframe, setIsInIframe] = useState(false);
    const [queryParamsObject, setqueryParamsObject] = useState("");
    const [errMsg, seterrMsg] = useState("");


    useEffect(() => {
        // Get the current URL
        const currentUrl = window.location.href;

        // Create a URL object
        const url = new URL(currentUrl);

        // Get the search parameters
        const searchParamsValue = url.searchParams;

        // Alternatively, you can convert the searchParams to an object
        const searchParamsObject = {};
        searchParamsValue.forEach((value, key) => {
            searchParamsObject[key] = value;
        });

        // console.log(searchParamsObject);
        setqueryParamsObject(searchParamsObject);

    }, []);

    useEffect(() => {
        setIsInIframe(window.self !== window.top);
    }, []);

    useEffect(() => {
        if (paramsid && paramsid.id && paramsid.id !== "0" && paramsid.id !== "undefined") {
            dispatch(getAiDuonutNewAsync(paramsid)).then((response) => {
                // console.log(response);

                var userIsPro = false;
                if (response?.created_by.pro_user) {
                    userIsPro = true;
                } else if (response?.created_by.expire_date) {
                    const currentDate = new Date();
                    const dateToCompare = new Date(response?.created_by.expire_date);
                    const comparisonResult = currentDate < dateToCompare;
                    if (comparisonResult) {
                        userIsPro = true;
                    } else {
                        userIsPro = false;
                    }
                }


                if (userIsPro && response?.created_by.user_type === "pro" && response.totalInsights >= response.total_user_response) {
                    setpageAcess(2);
                    return null;
                } else if (userIsPro && response?.created_by.user_type === "plus" && response.totalInsights >= response.total_user_response) {
                    setpageAcess(2);
                    return null;
                } else if (!userIsPro && response.totalInsights >= response.total_user_response) {
                    setpageAcess(2);
                    return null;
                }

                const embeddedLocation = window.top.location.href;
                const pathName = window.top.location.pathname;
                // console.log("This iframe is embedded in:", embeddedLocation, pathName);

                if (get_watch_id && !pathName.includes('/design')) {
                    const formData = new FormData();
                    formData.append('watch_id', get_watch_id);
                    dispatch(getAiAnsNewAsync(formData)).then((response1) => {
                        // console.log(response1);
                        setClickedNum(parseInt(response1.answer));
                        setaiConversation(response1.ai_conversation);
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    if (rating > 0 && rating <= 5) {
                        setClickedNum(parseInt(rating));

                        var get_ans = {
                            question: response?.question,
                            rating: rating,
                        }

                        dispatch(getRatingFeedbackAsync(get_ans)).then((res) => {
                            // console.log(res);
                            if (res?.answer) {
                                setaiConversation([
                                    {
                                        "sender": "bot",
                                        "message": `${res?.answer}`
                                    }
                                ])

                                var save_ans = {
                                    display_id: paramsid.id,
                                    watch_id: "",
                                    answer: rating,
                                    name: "",
                                    url_params: queryParamsObject,
                                    ai_conversation: [
                                        {
                                            "sender": "bot",
                                            "message": `${res?.answer}`
                                        }
                                    ],
                                }
                                console.log(save_ans);

                                dispatch(saveAiAnsNewAsync(save_ans)).then((res) => {
                                    // console.log(res);
                                    localStorage.setItem(paramsid.id, res?.watch_id);
                                }).catch((e) => {
                                    console.log(e.message, e);
                                });
                            }
                        }).catch((e) => {
                            console.log(e.message, e);
                        });


                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }

        // eslint - disable - next - line
    }, []);

    const duonutDataMain = useSelector(showAiDuonutNewData);
    const duonutData = duonutDataMain[0];
    // console.log(duonutData)

    const handleButtonClick = (num) => {
        // console.log(duonutData)
        if (clickedNum !== -1) {
            return null;
        }

        setClickedNum(num);

        var get_ans = {
            question: duonutData?.question,
            rating: num,
        }

        dispatch(getRatingFeedbackAsync(get_ans)).then((res) => {
            // console.log(res);
            if (res?.answer) {
                // console.log(res?.answer);

                setaiConversation([
                    {
                        "sender": "bot",
                        "message": `${res?.answer}`
                    }
                ])

                var save_ans = {
                    display_id: paramsid.id,
                    watch_id: "",
                    answer: num,
                    name: "",
                    url_params: queryParamsObject,
                    ai_conversation: [
                        {
                            "sender": "bot",
                            "message": `${res?.answer}`
                        }
                    ],
                }
                // console.log(save_ans);
                window.scrollBy({ top: 300, behavior: 'smooth' });


                dispatch(saveAiAnsNewAsync(save_ans)).then((res) => {
                    // console.log(res);
                    localStorage.setItem(paramsid.id, res?.watch_id);
                }).catch((e) => {
                    console.log(e.message, e);
                });
            }
        }).catch((e) => {
            console.log(e.message, e);
        });


    }

    const handleUserQuestion = (event) => {
        setuserQuestion(event.target.value);
        // console.log(event.target.value);
        if (event.keyCode === 13) {
            document.getElementById("questionBtn").click();
        }
    };

    // console.log("aiConversation", aiConversation)
    const handleUserQuestionSubmit = () => {
        // console.log("aiConversation", aiConversation, duonutData)
        if (aiConversation.length >= (parseInt(duonutData?.conversation_depth) * 2) + 1) {
            seterrMsg("AI Conversation Limit is over.")
            setTimeout(() => seterrMsg(""), 3000);
            return null;
        }
        setisLoading(true);

        var ai_conver = {
            display_id: paramsid.id,
            watch_id: get_watch_id,
            aidata_id: duonutData?.aiData?.id,
            user_question: userQuestion,
        }

        dispatch(getAiConversationAsync(ai_conver)).then((res) => {
            // console.log(res);
            var userQue = {
                "sender": "user",
                "message": `${userQuestion}`
            }
            var botAns = {
                "sender": "bot",
                "message": `${res.answer}`
            }
            var save_ans = {
                display_id: paramsid.id,
                watch_id: get_watch_id,
                answer: clickedNum,
                name: "",
                url_params: queryParamsObject,
                ai_conversation: [...aiConversation, userQue, botAns],
            }
            // console.log(save_ans);

            dispatch(saveAiAnsNewAsync(save_ans)).then((res) => {
                // console.log(res);
                setaiConversation([...aiConversation, userQue, botAns]);
                setuserQuestion("");
                setisLoading(false);
                document.getElementById("user-input_box").value = "";
                document.getElementById("user-input_box").focus();
                if (inputRef.current) {
                    inputRef.current.scrollIntoView({ behavior: 'smooth' });
                    inputRef.current.focus();
                }

            }).catch((e) => {
                console.log(e.message, e);
            });

        }).catch((e) => {
            console.log(e.message, e);
        });


    }

    return (
        <div className='AIDVP1App'>
            {pageAcess === 1 && duonutData && <div className='AIDVP1template'>
                <div style={{}}>
                    <img style={{ width: "3rem", height: "3rem", borderRadius: '15px' }}
                        src={"/assets/duonut_icon.png"} alt="duonut" loading="lazy" /><br /><br />
                    <h2>You are chatting with Duonut AI</h2>
                    {/* <h5 style={{ color: "#3498db" }}>Powered by Duonut AI</h5> */}
                    <div style={{
                        margin: "0.5rem",
                        lineHeight: "1.2rem",
                        width: "auto",
                        maxWidth: "30rem",
                        fontSize: "15px",
                        fontWeight: "300"
                    }}>
                        Like all AI systems, Duonut cannot guarantee complete accuracy in every response.
                    </div>
                </div>

                <div className="AIDVP1container">
                    <h3>{duonutData?.question}</h3>
                    <div style={{ width: "fit-content", margin: "2rem auto 1rem auto" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {Array.from({ length: 5 }, (_, i) => {
                                return <div key={i} id={`option-id${i + 1}`}
                                    style={{
                                        display: "flex", justifyContent: "center", alignItems: "center",
                                        border: "1px solid #e5e7eb",
                                        margin: "8px",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        width: `30px`,
                                        height: `30px`,
                                        backgroundColor: clickedNum === (i + 1) ? "#7ED957" : isHovered === (i + 1) ? "#e5e7eb" : "white",
                                        cursor: "pointer"
                                    }}
                                    onMouseEnter={() => setIsHovered(i + 1)}
                                    onMouseLeave={() => setIsHovered(-1)}
                                    onClick={() => handleButtonClick(i + 1)}>{i + 1}
                                </div>
                            })}
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: "0 10px", fontSize: "0.8rem" }}>
                            <div>NEGATIVE</div>
                            <div>POSITIVE</div>
                        </div>
                    </div>

                </div>
                {clickedNum >= 0 && <>
                    <div style={{
                        backgroundColor: "#f5f5f5",
                        padding: "1rem",
                        borderRadius: "10px",
                        marginTop: "2rem"
                    }}>Duonut AI is trained on content from {duonutData?.aiData?.websiteData?.company_name ? duonutData?.aiData?.websiteData?.company_name : duonutData?.aiData?.title}. Feel free to ask any questions you have.</div>
                    <div className='AIDVP1Aicontainer'>
                        {/* <h2>Duonut Ai</h2>
                        <br></br> */}
                        <div className='AIDVP1chat-container'>
                            {aiConversation.map((item, index) => (
                                <div key={index} className={`AIDVP1message-row ${item.sender}`}>
                                    <img
                                        src={item.sender === "bot" ? "/assets/duonut_icon.png" : "/assets/user.png"}
                                        alt={`${item.sender}-avatar`}
                                        className="AIDVP1avatar"
                                    />
                                    <div className="AIDVP1message">
                                        {item.message.split('\n').map((str, idx, arr) => {
                                            // Regex to capture [text](url), plain URLs, and **bold text** excluding links within **
                                            const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)|(https?:\/\/[^\s]+)|(\*\*(?!\[.*\]\(.*\))([^*]+)\*\*)/g;
                                            const parts = [];
                                            let lastIndex = 0;
                                            let match;

                                            while ((match = linkRegex.exec(str)) !== null) {
                                                // Add text before the link
                                                if (match.index > lastIndex) {
                                                    parts.push(str.substring(lastIndex, match.index));
                                                }

                                                if (match[1] && match[2]) {
                                                    // Markdown style link [text](url)
                                                    parts.push(
                                                        <a key={match.index} href={match[2]} target="_blank" rel="noopener noreferrer">
                                                            {match[1]}
                                                        </a>
                                                    );
                                                } else if (match[3]) {
                                                    // Plain URL
                                                    parts.push(
                                                        <a key={match.index} href={match[3]} target="_blank" rel="noopener noreferrer">
                                                            {match[3]}
                                                        </a>
                                                    );
                                                } else if (match[4]) {
                                                    // Bold text **text** (only when it's not around a link)
                                                    parts.push(<strong key={match.index}>{match[4]}</strong>);
                                                }
                                                lastIndex = match.index + match[0].length;
                                            }

                                            // Add any remaining text after the last link
                                            if (lastIndex < str.length) {
                                                parts.push(str.substring(lastIndex));
                                            }

                                            // Check if the parts array is empty (i.e., no text or links), skip rendering <p> if true
                                            if (parts.length === 0) return null;

                                            // return <p key={idx}>{parts}</p>;
                                            return (
                                                <React.Fragment key={idx}>
                                                    <p>{parts}</p>
                                                    {idx < arr.length - 1 && <br />}
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <br></br>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                            <input className='AIDVP1Ai-input_box'
                                style={{ padding: "10px" }}
                                id="user-input_box"
                                type="text"
                                placeholder="Ask me something..."
                                autoFocus={isInIframe ? false : true}
                                onKeyUp={e => handleUserQuestion(e)}
                                ref={inputRef}
                            />
                            <button type='submit' id="questionBtn" className='GPT-submitBtn' disabled={isLoading}
                                onClick={() => handleUserQuestionSubmit()}>{!isLoading ? "Submit" : "Submitting..."}</button>
                        </div>
                        {errMsg && <div style={{ color: "red", padding: "10px 0px" }}>{errMsg}</div>}
                    </div>
                </>}

                <div style={{ margin: "1rem 1rem 0.4rem 1rem" }}>
                    {clickedNum >= 0 && queryParamsObject.respondent_profile_id && <button type='button' className='GPT-submitBtn' style={{ marginBottom: "1rem" }}
                        onClick={() => setpageAcess(3)}>Complete Survey</button>}

                    <p style={{ fontSize: "0.85rem", lineHeight: "1rem", margin: "5px", textAlign: "center", color: "rgb(156,163,175)" }}>Powered by</p>
                    <img style={{ width: "auto", height: "2rem" }}
                        src="https://app.duonut.com/assets/duonut_logo.png" alt="duonut" loading="lazy" /><br />

                    <p style={{ fontSize: "0.85rem", lineHeight: "1rem", margin: "10px", textAlign: "center", color: "rgb(156,163,175)" }}>
                        Sign up <a href="https://duonut.com" style={{ color: "inherit", textDecoration: "none", userSelect: "none", textDecorationLine: "underline" }} target="_blank">here</a> to send your first survey.</p>
                </div>
            </div>}
            {pageAcess === 2 && <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "40rem", width: "auto", height: "100vh", margin: 'auto' }}>
                Oops! Looks like you can not view this Duonut. Please contact the creator of this Duonut for more information.
            </h2>}
            {pageAcess === 3 && <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "40rem", width: "auto", height: "100vh", margin: 'auto' }}>
                Thank you for Completing Survey...
            </h1>}
        </div>
    );

}

export default AiDuonutPageNew;