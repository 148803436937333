import React, { useEffect, useState } from "react";
import "./GoogleSheetsIntegration.css";
import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";
import { updateGoogleSheetIntegration } from "../../redux/slices/duonutSettings";
import { useGoogleLogin } from "@react-oauth/google";
// import { domain } from "../../services/constants";

const GoogleSheetsIntegration = ({ setSelected }) => {
    const dispatch = useDispatch();
    const { title, google_sheet_integration } = useSelector(state => state.duonutSettings);
    const [clickAuthButton, setclickAuthButton] = useState(0);
    const [accessKey, setaccessKey] = useState("");
    const [refreshKey, setrefreshKey] = useState("");

    useEffect(() => {
        // console.log(google_sheet_integration)
        if (google_sheet_integration?.access_key) {
            setclickAuthButton(2);
            setaccessKey(google_sheet_integration.access_key);
            setrefreshKey(google_sheet_integration.refresh_key);
        }
    }, [google_sheet_integration]);

    const createSheet = async (tokenResponse) => {
        try {
            const response = await fetch('https://sheets.googleapis.com/v4/spreadsheets', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${tokenResponse.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    properties: {
                        title: 'My New 8 Spreadsheet',
                    },
                }),
            });

            if (response.ok) {
                const data = await response.json();
                //   setSpreadsheetId(data.spreadsheetId);
                // console.log('Spreadsheet created:', data);
            } else {
                console.error('Error creating spreadsheet:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }

    const exchangeCodeForTokens = async (authorizationCode) => {

        const response = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
                grant_type: 'authorization_code',
                redirect_uri: 'https://app.duonut.com', // Redirect URI configured in Google Cloud Platform
                code: authorizationCode,
            }),
        });

        // console.log(response)

        if (!response.ok) {
            throw new Error('Failed to exchange code for tokens');
        }

        const data = await response.json();
        // console.log("qqq", data)
        return data;
    };

    const handleAuthentication = () => {
        if (clickAuthButton === 2) {
            setaccessKey("");
            setrefreshKey("");
            setclickAuthButton(0);
            const sheetData = {}
            dispatch(updateGoogleSheetIntegration(sheetData));

            return null;
        }

        setclickAuthButton(1);
        googleLogin();

    }

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            // console.log(tokenResponse);
            try {
                const tokenData = await exchangeCodeForTokens(tokenResponse.code);
                // console.log(tokenData);
                // Access token in tokenData.access_token
                // Refresh token (if available) in tokenData.refresh_token
                const accessToken = tokenData.access_token;
                const refreshToken = tokenData.refresh_token;
                setaccessKey(accessToken);
                setrefreshKey(refreshToken);

                // createSheet(tokenData);
                const sheetData = {
                    access_key: tokenData.access_token,
                    refresh_key: tokenData.refresh_token,
                }
                dispatch(updateGoogleSheetIntegration(sheetData));
                setclickAuthButton(2);


            } catch (error) {
                console.error('Failed to exchange code for tokens:', error);
            }

        },
        onFailure: error => {
            console.error('Login failed', error);
        },
        scope: 'https://www.googleapis.com/auth/spreadsheets',
        flow: 'authorization_code',
        codeExchange: exchangeCodeForTokens,
    });

    const handleDisconnect = () => {
        setaccessKey("");
        setrefreshKey("");
        setclickAuthButton(0);
        const sheetData = {}
        dispatch(updateGoogleSheetIntegration(sheetData));
    }

    return (
        <div>
            <div>
                <svg onClick={() => setSelected(0)} style={{ display: "flex", cursor: "pointer" }} width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="black" fill="none"><path d="M12.7715 24.8389L4.00001 16.0517L12.7715 7.2644" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path><path d="M28 16.0517L4.0001 16.0517" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "2rem" }}>
                <label style={{ display: "flex", alignItems: "center" }}>

                    <svg style={{ paddingRight: "1rem" }} height="46" width="40" preserveAspectRatio="xMinYMin meet" role="img" viewBox="0 0 212 283">
                        <g fill="none" fill-rule="evenodd"><path d="M17 0h124l71 71v195c0 9.389-7.611 17-17 17H17c-9.389 0-17-7.611-17-17V17C0 7.611 7.611 0 17 0z" fill="#13A562"></path><g fill="#F1F1F1"><path d="M55 158h103v12H55z"></path>
                            <path d="M101 138h12v83h-12z"></path><path d="M55 188h103v12H55z"></path><path d="M60 142v75h93v-75H60zm-12-12h117v99H48v-99z" fill-rule="nonzero"></path></g>
                            <path d="M146 66l66 67V70.992z" fill="#049052"></path><path d="M141 0l71 71h-54c-9.389 0-17-7.611-17-17V0z" fill="#8BD1AF"></path></g>
                    </svg>
                    Integrate with Google Sheets </label>
                <button type="submit" key={clickAuthButton} className="SlackIntegrationButton" disabled={clickAuthButton === 1}
                    onClick={() => { handleAuthentication() }}>{clickAuthButton === 1 ? "Authenticating" : clickAuthButton === 2 ? "Revoke" : "Authenticate"}</button>
            </div>
            {clickAuthButton === 2 && <div style={{
                display: "flex", justifyContent: "space-between",
                alignItems: "center", padding: "2rem 0rem", fontWeight: "600"
            }}>
                you have connected Google Sheet successfully. Now you can able to Download Duonut Responses in Google sheet.
            </div>}

            {clickAuthButton === 2 && accessKey && <button type="submit" className="SlackIntegrationButton"
                style={{ float: "right", color: "#e5251e", background: "transparent", border: "1px solid #DB615C" }}
                onClick={() => handleDisconnect()}>
                <i className="fa fa-trash-o" aria-hidden="true" style={{ marginRight: "10px" }}></i>
                Disconnect</button>}
        </div>
    );
}

export default GoogleSheetsIntegration;