import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements } from '../../../redux/slices/userActivity';
import { EditText } from "react-edit-text";

export default function MaxLength() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [maxLength, setMaxLength] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const maxLength = tempElement?.elementAttributes?.maxLength;

            if (maxLength) {
                setMaxLength(maxLength);
            } else {
                setMaxLength(null);
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleMaxLengthChange = (e) => {
        console.log(e)
        const value = parseInt(e.target.value)
        setMaxLength(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    maxLength: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    return (
        <div className="EditorRowContainer">
            <div style={{ marginRight: "1rem" }}>{(selectedComponent.type === "CheckboxGroup" || selectedComponent.type === "CheckboxImage") ? "Max Selectable Options" : "Rating Scale"}
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {(selectedComponent.type !== "CheckboxGroup" || selectedComponent.type !== "CheckboxImage") && <p style={{ color: "#5d5959", width: "7rem" }}>Starts from 1 - </p>}
                <EditText
                    id="maxLenght-input-field"
                    value={maxLength}
                    type="number"
                    style={{ fontSize: "14px", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", alignItems: "center", width: "5rem", height: "28px", paddingLeft: "5px" }}
                    // onChange={(e) => (e.target.value.length > 3 ? null : handleMaxLengthChange(e))}
                    onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        if (value >= 1 && e.target.value.length <= 3) {
                            handleMaxLengthChange(e);
                        }
                    }}
                    placeholder={(selectedComponent.type === "CheckboxGroup" || selectedComponent.type === "CheckboxImage") ? 'Unlimited' : ""}
                />
            </div>
        </div>
    )
}

