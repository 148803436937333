import React, { useRef, useEffect, useState } from 'react';
import { frontendUrl, backendUrl } from "../../../services/constants";
import './ShareModal.css';
import "../../../assets/bootstrap.min.css";
import { useNavigate } from 'react-router-dom';
import ProlificPanel from '../../../Pages/AiDataNew/Panel/ProlificPanel';
import RespondentPanel from '../../../Pages/AiDataNew/Panel/RespondentPanel';
import EmbedModal from './EmbedModal';
import QrCodeModal from './QrCodeModal';
import './QrCodeModal.css';
import "../../../assets/bootstrap.min.css";
import * as FileSaver from 'file-saver';


var shareData = {};
const ShareModal = (props) => {
    // console.log(props);
    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }
    const duonutName = props.shareData.duonut_name;
    const displayId = props.shareData.display_id;
    const password = props.shareData.password;
    const message = props.shareData.message;
    const ref = useRef(null);
    const ref1 = useRef(null);
    const [iscopy, setiscopy] = useState(false);
    const [iscopylink, setiscopylink] = useState(false);
    const [show, setShow] = useState(false);
    const [socials, setShowSocials] = useState(false);
    const [link, setShowLink] = useState(true);
    const navigate = useNavigate();
    const [selecteShare, setselecteShare] = useState(show);
    const [isPanelVisible, setIsPanelVisible] = useState(false);
    const [selectedPanelTab, setselectedPanelTab] = useState(2);
    const [showEmbedModal, setshowEmbedModal] = useState(false)
    const [showQrCode, setshowQrCode] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            ref.current.click();
        }, 100); //miliseconds

        setTimeout(() => {
            ref1.current.click();
        }, 300000); //miliseconds
    }, []);


    const continuebtn = () => {
        ref1.current.click();
        setiscopy(false);
    }
    const viewbtn = () => {
        ref1.current.click();
        window.open(`/duonut/${displayId}`);
        // window.location.reload();
    }

    const onclickembedcode = () => {
        setShow(true);
        setShowSocials(false);
        setiscopylink(false);
        setShowLink(false);
        setiscopylink(false);
        setiscopy(false);
        setIsPanelVisible(false);
        setshowEmbedModal(false);
        setshowQrCode(false);
    }
    const onclicksocials = () => {
        setShowSocials(true);
        setShow(false);
        setiscopylink(false);
        setShowLink(false);
        setiscopylink(false);
        setiscopy(false);
        setIsPanelVisible(false);
        setshowEmbedModal(false);
        setshowQrCode(false);
    }
    const onclickcopylink = () => {
        setShowLink(true);
        setShow(false);
        setShowSocials(false);
        setiscopylink(false);
        setiscopy(false);
        setIsPanelVisible(false);
        setshowEmbedModal(false);
        setshowQrCode(false);
    }
    const onclickpanel = () => {
        setIsPanelVisible(true);
        setShowLink(false);
        setShow(false);
        setShowSocials(false);
        setiscopylink(false);
        setiscopy(false);
        setshowEmbedModal(false);
        setshowQrCode(false);
    }
    const onClickEmbed = () => {
        setshowEmbedModal(true);
        setIsPanelVisible(false);
        setShowLink(false);
        setShow(false);
        setShowSocials(false);
        setiscopylink(false);
        setiscopy(false);
        setshowQrCode(false);
    }
    const onClickQrCode = () => {
        setshowEmbedModal(false);
        setIsPanelVisible(false);
        setShowLink(false);
        setShow(false);
        setShowSocials(false);
        setiscopylink(false);
        setiscopy(false);
        setshowQrCode(true);
    }
    const copylink = () => {
        var copyText = `${frontend_url}/duonut/${displayId}`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopylink(true);
    }
    const copycode = () => {
        var copyText = document.getElementById("iframearea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setiscopy(true);
    }

    var shareLink;
    if (password) {
        shareLink = `I liked ${duonutName} duonut and I think you will like it too.
        %0aSee here - ${frontend_url}/duonut/${displayId}  
        %0aPassword - ${password}
        %0a\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538} 
        %0a%23itsduonut`;
    } else {
        shareLink = `${frontend_url}/duonut/${displayId}`;
    }

    const iframe = `<iframe src="${frontend_url}/duonut/${displayId} " style="width: 100%; min-height: 80vh;" title="Duonut Iframe"></iframe>`;
    const copyLink = `${frontend_url}/duonut/${displayId}`;


    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }
    // const duonutName = props.shareData.duonut_name;
    // const displayId = props.shareData.display_id;
    // const password = props.shareData.password;
    // const message = props.shareData.message;
    // const ref = useRef(null);
    // const ref1 = useRef(null);
    // const { width } = useWindowDimensions();
    // const [iscopy, setiscopy] = useState(false);
    // const [show, setShow] = useState(false);
    // const navigate = useNavigate();
    const [embedType, setembedType] = useState('Standard');
    const [popupSize, setpopupSize] = useState('Large');
    const [iframeCode, setiframeCode] = useState(`<iframe src="${frontend_url}/duonut/${displayId}" style="width: 80%; min-height: 80vh; border: none;" title="Duonut Iframe"></iframe>`);

    const handlePopupSizeChange = event => {
        setpopupSize(event.target.value);
        setiscopy(false);
        // console.log(event.target.value);
    };

    const selectEmbedType = (type) => {
        setembedType(type);
        setiscopy(false);
        // console.log(type);
    };


    useEffect(() => {
        if (embedType === "Standard") {
            setiframeCode(`<iframe src="${frontend_url}/duonut/${displayId}" style="width: 80%; min-height: 80vh; border: none;" title="Duonut Iframe"></iframe>`);
        } else if (embedType === "Fullpage") {
            setiframeCode(`<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>`);
        } else if (embedType === "Mobile") {
            setiframeCode(`<div style="display: flex; justify-content: center;"><iframe src="${frontend_url}/duonut/${displayId}" style="width: 350px; min-height: 80vh; border: none;" title="Duonut Iframe"></iframe><div>`);
        } else if (embedType === "Popup" && popupSize === "Large") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,.75); display: flex; align-items: center; justify-content: center; z-index: 99;">
                \n<div style="width: 90%; height: 90%; position: relative;">
                \n<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
                \n<button style="color: white; background: none; border: none; cursor: pointer; position: absolute; right: -5px; top: -35px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>
                \n</div>
                \n</div>`);
        } else if (embedType === "Popup" && popupSize === "Medium") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,.75); display: flex; align-items: center; justify-content: center; z-index: 99;">
                \n<div style="width: 70%; height: 70%; position: relative;">
                \n<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
                \n<button style="color: white; background: none; border: none; cursor: pointer; position: absolute; right: -5px; top: -35px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>
                \n</div>
                \n</div>`);
        } else if (embedType === "Popup" && popupSize === "Small") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,.75); display: flex; align-items: center; justify-content: center; z-index: 99;">
                \n<div style="width: 50%; height: 60%; position: relative;">
                \n<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
                \n<button style="color: white; background: none; border: none; cursor: pointer; position: absolute; right: -5px; top: -35px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>
                \n</div>
                \n</div>`);
        } else if (embedType === "Popover") {
            setiframeCode(`<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
                <div style="position: fixed; right: 0px; bottom: 80px; min-width: 350px; height: 70vh; z-index: 99;">
                
                <div style="width: 100%; height: 100%; position: relative;">
                
                <iframe id="duonutPopUp" src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none; box-shadow: rgba(0,0,0,.08) 0 2px 4px, rgba(0,0,0,.06) 0 2px 12px;" title="Duonut Iframe"></iframe>
                
                <button style="width: 40px; height: 40px; background: white; border: none;  border-radius: 50%; cursor: pointer; position: absolute; right: 15px; bottom: -60px;" onclick="show()">
                <i class="fa fa-lightbulb-o" aria-hidden="true" style="font-size:30px"></i>
                </button>
                
                </div>            
                </div>
                <script>
                  function show() {
                    let divContainer = document.getElementById("duonutPopUp");
                    if(divContainer.style.opacity==='0'){
                      divContainer.style.opacity='1';
                    } else{
                      divContainer.style.opacity='0';
                    }
                  }
                </script>`);
        } else if (embedType === "Sidetab1") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; right: 0px; width: 350px; height: 70vh; z-index: 99;">
                <div style="width: 100%; height: 100%; position: relative;">            
                <iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
                <button style="color: black; background: none; border: none; cursor: pointer; position: absolute; right: 2px; top: -10px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>   
                </div>
                </div>`);
        } else if (embedType === "Sidetab2") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; width: 350px; height: 70vh; z-index: 99;">
                <div style="width: 100%; height: 100%; position: relative;">            
                <iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
                <button style="color: black; background: none; border: none; cursor: pointer; position: absolute; right: 2px; top: -10px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>   
                </div>
                </div>`);
        }
    }, [embedType, popupSize]);

    useEffect(() => {
        setTimeout(() => {
            ref.current.click();
        }, 100); //miliseconds

        setTimeout(() => {
            ref1.current.click();
        }, 3000000); //miliseconds
    }, []);


    // const continuebtn = () => {
    //     ref1.current.click();
    //     setiscopy(false);
    // }
    // const viewEmbedbtn = () => {
    //     ref1.current.click();
    //     window.open('/duonut/' + displayId);
    //     // window.location.reload();
    // }

    // const onclickembedcode = () => {
    //     setShow(!show);
    // }
    const copyEmbedcode = () => {
        var copyText = document.getElementById("embediframearea");
        // console.log(copyText);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setiscopy(true);
    }
    const qrcode = props.shareData.qrcode;
    const qrcodeUrl = `${backendUrl}${qrcode}`;
    const qrref = useRef(null);
    const qrref1 = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            qrref.current.click();
        }, 100); //miliseconds

        setTimeout(() => {
            qrref1.current.click();
        }, 300000); //miliseconds
    }, []);


    // const continuebtn = () => {
    //     qrref1.current.click();
    //     setiscopy(false);
    // }
    const downloadbtn = () => {
        // const img = new Image();
        // // img.crossOrigin = 'Anonymous';
        // img.onload = function () {
        //     const canvas = document.createElement('canvas');
        //     canvas.width = img.width;
        //     canvas.height = img.height;
        //     const ctx = canvas.getContext('2d');
        //     ctx.drawImage(img, 0, 0);
        //     const link = document.createElement('a');
        //     link.download = `qrcode-${displayId}.png`;
        //     link.href = canvas.toDataURL('image/png');
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // };
        // img.src = qrcode;

        let url;
        // url = qrcodeUrl;
        if (qrcodeUrl?.toString().includes("https")) {
            url = qrcodeUrl;

        } else {
            url = qrcodeUrl.toString().replace("http", "https");
        }
        // console.log("url-", url);
        fetch(url)
            .then(res => res.blob())
            .then(blob => {
                FileSaver.saveAs(blob, `qrcode-${displayId}.png`);
            })
            .catch(error => console.log(error));
    }

    return (
        <><button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#shareModal${displayId}`}>
        </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`shareModal${displayId}`} tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "50vw" }}>
                    <div className="modal-content SM1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px", overflow: "scroll" }}>
                        <div className="modal-body" style={{ maxHeight: "90vh" }}>
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className="close-icon icon-button publishCloseIcon" onClick={() => ref1.current.click()}>
                                <img src="/assets/icons/close.svg" />
                            </div>
                            <div>{duonutName}{message}</div>

                            <div className='SM1shareTitle'> Share your Duonut</div>
                            {/* <div className='PM1sharemsg'>YOU CAN SHARE THIS QUIZ TO YOUR FAMILY OR FRIENDS.</div> */}
                            <br></br>
                            <div className="">
                                <div className='' style={{ display: "flex", justifyContent: "space-around", gap: "15px", padding: "0px 10px" }}>
                                    <p
                                        className='SM1modalShare-btn'
                                        onClick={() => onclickcopylink()}
                                        style={{
                                            display: "flex",
                                            alignItems: "center", justifyContent: "center",
                                            ...(link ? { color: "white", backgroundColor: "#0E2C45" } : {}),
                                        }}
                                    >
                                        Link
                                    </p>
                                    <p
                                        className='SM1modalShare-btn'
                                        onClick={() => onclickpanel()}
                                        style={{
                                            display: "flex",
                                            alignItems: "center", justifyContent: 'center',
                                            ...(isPanelVisible ? { color: "white", backgroundColor: "#0E2C45" } : {}),
                                        }}
                                    >
                                        Share with other panel participants
                                    </p>
                                    <p
                                        className='SM1modalShare-btn'
                                        onClick={() => onclickembedcode()}
                                        style={{
                                            display: "flex",
                                            alignItems: "center", justifyContent: 'center',
                                            ...(show ? { color: "white", backgroundColor: "#0E2C45" } : {}),
                                        }}
                                    >
                                        HTML Embed code
                                    </p>
                                    <p
                                        className='SM1modalShare-btn'
                                        onClick={() => onclicksocials()}
                                        style={{
                                            display: "flex",
                                            alignItems: "center", justifyContent: 'center',
                                            ...(socials ? { color: "white", backgroundColor: "#0E2C45" } : {}),
                                        }}
                                    >
                                        Share in social media
                                    </p>
                                    <p
                                        className='SM1modalShare-btn'
                                        onClick={() => onClickEmbed()}
                                        style={{
                                            display: "flex",
                                            alignItems: "center", justifyContent: 'center',
                                            ...(showEmbedModal ? { color: "white", backgroundColor: "#0E2C45" } : {}),
                                        }}
                                    >
                                        Get Embed Code
                                    </p>
                                    <p
                                        className='SM1modalShare-btn'
                                        onClick={() => onClickQrCode()}
                                        style={{
                                            display: "flex",
                                            alignItems: "center", justifyContent: 'center',
                                            ...(showQrCode ? { color: "white", backgroundColor: "#0E2C45" } : {}),
                                        }}
                                    >
                                        QR Code
                                    </p>
                                </div>
                                <hr />

                                {showEmbedModal &&

                                    <>
                                        <div className='SM1shareTitle'> Embed this code in your website</div>
                                        <br></br>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div className='EM1embedType' style={embedType === "Standard" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Standard")}>
                                                <svg width="40" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-standard" style={{ borderRadius: "4px" }}>
                                                    <path d="M0.333496 4C0.333496 1.79086 2.12436 0 4.3335 0H36.3335C38.5426 0 40.3335 1.79086 40.3335 4V28C40.3335 30.2091 38.5426 32 36.3335 32H4.3335C2.12436 32 0.333496 30.2091 0.333496 28V4Z" fill="white"></path>
                                                    <path d="M6.3335 11H34.3335V19C34.3335 20.1046 33.4381 21 32.3335 21H6.3335V11Z" fill="#3184C9"></path>
                                                    <path d="M6.3335 6H26.3335C26.3335 7.10457 25.4381 8 24.3335 8H6.3335V6Z" fill="#737373"></path>
                                                    <path d="M6.3335 24H18.3335C18.3335 25.1046 17.4381 26 16.3335 26H6.3335V24Z" fill="#737373"></path>
                                                    <path d="M4.3335 1H36.3335V-1H4.3335V1ZM39.3335 4V28H41.3335V4H39.3335ZM36.3335 31H4.3335V33H36.3335V31ZM1.3335 28V4H-0.666504V28H1.3335ZM4.3335 31C2.67664 31 1.3335 29.6569 1.3335 28H-0.666504C-0.666504 30.7614 1.57207 33 4.3335 33V31ZM39.3335 28C39.3335 29.6569 37.9903 31 36.3335 31V33C39.0949 33 41.3335 30.7614 41.3335 28H39.3335ZM36.3335 1C37.9903 1 39.3335 2.34315 39.3335 4H41.3335C41.3335 1.23858 39.0949 -1 36.3335 -1V1ZM4.3335 -1C1.57207 -1 -0.666504 1.23858 -0.666504 4H1.3335C1.3335 2.34315 2.67664 1 4.3335 1V-1Z" fill="#737373"></path>
                                                </svg>
                                                <span style={{ fontSize: "13px" }}>Standard</span>
                                            </div>
                                            <div className='EM1embedType' style={embedType === "Fullpage" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Fullpage")}>
                                                <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-fullpage" style={{ borderRadius: "4px" }}>
                                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V28C40 30.2091 38.2091 32 36 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="white"></path>
                                                    <rect x="3" y="3" width="34" height="26" rx="2" fill="#3184C9"></rect>
                                                    <path d="M4 1H36V-1H4V1ZM39 4V28H41V4H39ZM36 31H4V33H36V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM39 28C39 29.6569 37.6569 31 36 31V33C38.7614 33 41 30.7614 41 28H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#737373"></path>
                                                </svg>
                                                <span style={{ fontSize: "13px" }}>Full-Page</span>
                                            </div>
                                            <div className='EM1embedType' style={embedType === "Popover" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Popover")}>
                                                <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-popover" style={{ borderRadius: "4px" }}>
                                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V28C40 30.2091 38.2091 32 36 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="white"></path>
                                                    <path d="M26 7H36V23H28C26.8954 23 26 22.1046 26 21V7Z" fill="#3184C9"></path>
                                                    <circle cx="34" cy="26" r="2" fill="#3184C9"></circle>
                                                    <path d="M4 1H36V-1H4V1ZM39 4V28H41V4H39ZM36 31H4V33H36V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM39 28C39 29.6569 37.6569 31 36 31V33C38.7614 33 41 30.7614 41 28H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#737373"></path>
                                                </svg>
                                                <span style={{ fontSize: "13px" }}>Popover</span>
                                            </div>
                                            <div className='EM1embedType' style={embedType === "Popup" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Popup")}>
                                                <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-popup" style={{ borderRadius: "4px" }}>
                                                    <path d="M0.666992 4C0.666992 1.79086 2.45785 0 4.66699 0H36.667C38.8761 0 40.667 1.79086 40.667 4V28C40.667 30.2091 38.8761 32 36.667 32H4.66699C2.45785 32 0.666992 30.2091 0.666992 28V4Z" fill="white"></path>
                                                    <path d="M6.66699 6H34.667V24C34.667 25.1046 33.7716 26 32.667 26H6.66699V6Z" fill="#3184C9"></path>
                                                    <path d="M4.66699 1H36.667V-1H4.66699V1ZM39.667 4V28H41.667V4H39.667ZM36.667 31H4.66699V33H36.667V31ZM1.66699 28V4H-0.333008V28H1.66699ZM4.66699 31C3.01014 31 1.66699 29.6569 1.66699 28H-0.333008C-0.333008 30.7614 1.90557 33 4.66699 33V31ZM39.667 28C39.667 29.6569 38.3238 31 36.667 31V33C39.4284 33 41.667 30.7614 41.667 28H39.667ZM36.667 1C38.3238 1 39.667 2.34315 39.667 4H41.667C41.667 1.23858 39.4284 -1 36.667 -1V1ZM4.66699 -1C1.90557 -1 -0.333008 1.23858 -0.333008 4H1.66699C1.66699 2.34315 3.01014 1 4.66699 1V-1Z" fill="#737373"></path>
                                                </svg>
                                                <span style={{ fontSize: "13px" }}>Popup</span>
                                            </div>
                                            <div className='EM1embedType' style={embedType === "Sidetab1" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Sidetab1")}>
                                                <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-popover" style={{ borderRadius: "4px" }}>
                                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V28C40 30.2091 38.2091 32 36 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="white"></path>
                                                    <path d="M26 7H36V23H28C26.8954 23 26 22.1046 26 21V7Z" fill="#3184C9"></path>
                                                    {/* <circle cx="34" cy="26" r="2" fill="#3184C9"></circle> */}
                                                    <path d="M4 1H36V-1H4V1ZM39 4V28H41V4H39ZM36 31H4V33H36V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM39 28C39 29.6569 37.6569 31 36 31V33C38.7614 33 41 30.7614 41 28H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#737373"></path>
                                                </svg>
                                                <span style={{ fontSize: "13px" }}>Right Side Tab</span>
                                            </div>
                                            <div className='EM1embedType' style={embedType === "Sidetab2" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Sidetab2")}>
                                                <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-popover" style={{ borderRadius: "4px", transform: "rotate(180deg)" }}>
                                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V28C40 30.2091 38.2091 32 36 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="white"></path>
                                                    <path d="M26 7H36V23H28C26.8954 23 26 22.1046 26 21V7Z" fill="#3184C9"></path>
                                                    {/* <circle cx="34" cy="26" r="2" fill="#3184C9"></circle> */}
                                                    <path d="M4 1H36V-1H4V1ZM39 4V28H41V4H39ZM36 31H4V33H36V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM39 28C39 29.6569 37.6569 31 36 31V33C38.7614 33 41 30.7614 41 28H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#737373"></path>
                                                </svg>
                                                <span style={{ fontSize: "13px" }}>Left Side Tab</span>
                                            </div>
                                        </div>
                                        {embedType === "Popup" && <><br></br>
                                            <div>Popup Size
                                                <select id="mySelect" style={{ padding: "5px", marginLeft: "1rem" }} value={popupSize}
                                                    onChange={e => handlePopupSizeChange(e)}>
                                                    <option selected value="Large">Large</option>
                                                    <option value="Medium">Medium</option>
                                                    <option value="Small">Small</option>
                                                </select>
                                            </div></>}
                                        <br></br>
                                        <textarea id='embediframearea' rows="4" key={iframeCode}
                                            style={{ width: "90%", padding: "10px", margin: "1vh auto", fontSize: "16px", height: "132px", display: "block", borderRadius: "5px", color: "white", background: "#0E2C45" }}>
                                            {iframeCode}
                                        </textarea>
                                        <button className='SM1Continuebtn' onClick={() => copyEmbedcode()} style={{ background: iscopy ? "#4fec4f" : "transparent", color: iscopy ? "white" : "#0E2C45" }}>
                                            {iscopy ? (
                                                <> <>
                                                    <i class="fa fa-check" aria-hidden="true"></i>&nbsp; Copied
                                                </></>
                                            ) : (
                                                <>
                                                    <i className="fa fa-files-o" aria-hidden="true"></i>&nbsp; Copy
                                                </>
                                            )}</button>

                                    </>
                                }
                                {showQrCode &&
                                    <>
                                        <div className='PM1shareTitle' style={{ fontSize: "20px", fontWeight: "bold", padding: "10px" }}> Scan QR Code and view this Duonut</div>
                                        {/* <br></br> */}
                                        <img className='qrcodeImage' src={qrcodeUrl} alt="" style={{ heigth: "25vh", }} />
                                        <button className='QRC1Continuebtn' onClick={() => downloadbtn()}>Download</button>
                                        {/* <button className='QRC1Continuebtn' onClick={() => continuebtn()}>Continue</button> */}
                                    </>}

                                {socials && <div className='SM1modalShare-icons'>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${frontend_url}/duonut/${displayId} `)}>
                                        <i className="fa fa-facebook" aria-hidden="false"></i>
                                    </label>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${frontend_url}/duonut/${displayId} ?page=ld`)}>
                                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                                    </label>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://twitter.com/share?text=${shareLink}`)} >
                                        <img style={{ marginTop: "6px" }} src="/assets/icons/twitter2.svg" />
                                    </label>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://api.whatsapp.com/send/?text=${shareLink}`)}>
                                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                    </label>
                                    <label className='SM1iconCircle' onClick={() => window.open(`https://classroom.google.com/share?url=${frontend_url}/duonut/${displayId}`)}
                                        style={{ display: "block", cursor: "pointer", }}>
                                        <img src='/assets/classroom.png' />
                                    </label>
                                </div>}
                                {/* <div className='link-share'>
                                    <label className='SM1iconCircle' onClick={() => onclickembedcode()}>
                                        <i className="fa fa-link" aria-hidden="true"></i>
                                    </label>
                                </div> */}

                            </div>
                            <br></br>
                            {show && <>
                                <textarea id='iframearea' rows="4" key={iframe} readOnly
                                    style={{ width: "80%", padding: "15px", margin: "1vh auto", fontSize: "15px", height: "40%", display: "block", borderRadius: "5px", color: "white", background: "#0E2C45", cursor: "pointer" }}>
                                    {iframe}
                                </textarea>
                                <button className='SM1Continuebtn' onClick={() => copycode()} style={{ background: iscopy ? "#4fec4f" : "transparent", color: iscopy ? "white" : "#0E2C45" }}>
                                    {iscopy ? (
                                        <> <>
                                            <i class="fa fa-check" aria-hidden="true"></i>&nbsp; Copied
                                        </></>
                                    ) : (
                                        <>
                                            <i className="fa fa-files-o" aria-hidden="true"></i>&nbsp; Copy
                                        </>
                                    )}</button>
                            </>
                            }

                            {link &&
                                <><textarea id='iframearea' rows="4" key={copyLink} readOnly
                                    style={{ width: "80%", padding: "15px", margin: "1vh auto", fontSize: "15px", height: "40%", display: "block", borderRadius: "5px", color: "white", background: "#0E2C45", cursor: "pointer" }}>
                                    {copyLink}
                                </textarea>
                                    <button className='SM1Continuebtn' onClick={() => copylink()} style={{ background: iscopylink ? "#4fec4f" : "transparent", color: iscopylink ? "white" : "#0E2C45" }}>
                                        {iscopylink ? (
                                            <>
                                                <i class="fa fa-check" aria-hidden="true"></i>&nbsp; Copied
                                            </>
                                        ) : (
                                            <>
                                                <i className="fa fa-files-o" aria-hidden="true"></i>&nbsp; Copy
                                            </>
                                        )}
                                    </button>

                                </>
                            }
                            {isPanelVisible &&
                                <div >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                                        {/* <div className="title" style={{ position: "relative", padding: '0px 10px', cursor: "pointer", fontWeight: selectedPanelTab === 1 ? "700" : "400" }}
                                            onClick={() => setselectedPanelTab(1)}>
                                            {selectedPanelTab === 1 && <div className="navbar-selected-line"></div>}
                                            Prolific </div> */}
                                        <div className="title" style={{ position: "relative", padding: '0px 10px', marginLeft: "1rem", cursor: "pointer", fontWeight: selectedPanelTab === 2 ? "700" : "400" }}
                                            onClick={() => setselectedPanelTab(2)}>
                                            {selectedPanelTab === 2 && <div className="navbar-selected-line"></div>}
                                            {/* Respondent */}
                                        </div>
                                    </div>
                                    {/* {selectedPanelTab === 1 &&
                                        <ProlificPanel link={`${frontend_url}/duonut/${displayId}`} />} */}
                                    {selectedPanelTab === 2 &&
                                        <RespondentPanel link={`${frontend_url}/duonut/${displayId}`} />}
                                </div>
                            }
                            {!isPanelVisible &&
                                <div>
                                    <hr />
                                    <button className='SM1Continuebtn' onClick={() => viewbtn()}>View</button>
                                    <button className='SM1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </>
    );

}

export default ShareModal;