import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Input from "../../../Input/input";
import { showSignInForm } from "../../../../redux/slices/formDetails";
import { validateEmail } from "../utils";
import { verifyEmail, checkEmailValid, requestPasswordResetEmail } from "../../../../services/auth";
import { useLocation } from "react-router-dom";

export default function Signup({ handleClose, onVerifyEmail, setSignInError }) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") return setError("Please enter your email address");
    const result = validateEmail(email.trim());
    if (result === null) {
      setError("Please enter your email address in format : xyz@gmail.com");
      return;
    }
    verifyEmail(email, (err, res) => {
      if (err) {
        // console.log(err.response);
        if (err.response.data.detail === "Email is already registered!") {
          dispatch(showSignInForm())
          setSignInError('Email already registered')
          return
        }
        if (err.response.data.detail === "Email is already verified!") return onVerifyEmail(email, true);
      }
      onVerifyEmail(email, false);
    });
  };

  return (
    <div className="form_modal__form signup-form">
      <div className="logo" >
        <img style={{ width: "auto", height: "2rem", cursor: "pointer" }}
          src="/assets/icons/duonut_icon.png" />
        {/* <img style={{ width: "auto", height: "1.5rem", cursor: "pointer", paddingRight: "1.5rem" }}
                src="../../assets/icons/duonut_logo.png" /> */}
      </div>
      <div className="modal_back_btn icon-button" style={location.pathname === "/login" ? { display: "none" } : {}} onClick={handleClose}>
        {`< Back`}
      </div>
      <div className={location.pathname === "/login" ? "form_modal__header1 form_modal__signup-header" : "form_modal__header form_modal__signup-header"}>
        <label style={{ fontSize: "24px", fontWeight: "600" }}>Create your Duonut Account</label>
      </div>

      {/* <button className='form_modal__btn-secondary btn-secondary'>
                <img src='/assets/icons/google.svg' className='google-icon' />
                Sign up with Google
            </button> */}

      {/* <GoogleButton text={"Sign up with Google"} />
      <MicrosoftButton text={"Sign up with Microsoft"} />

      <FormDivider /> */}
      <div style={{ paddingBottom: "3rem", textAlign: "center", fontSize: "1rem" }}>No credit card required.<br />
        <span> <img src="../../assets/icons/coin-3.svg" style={{ width: "1.2rem", height: "1.2rem", cursor: "pointer" }} alt="coin icon" data-tooltip-id="analyticspage2" />
          100 free credit will be added on registering.</span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form_modal__form_fields" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <label className="form-label">Email</label>
          <Input placeholder="Email" value={email} setValue={setEmail} errorMsg={error} />
        </div>

        <button className="form_modal__submit_btn">Sign Up with Email</button>
        {/* <div style={{ display: "inline-block", padding: "0rem 2rem", cursor: "pointer" }}
          onClick={() => {
            dispatch(showSignInForm());
          }}>Sign In</div> */}
      </form>
      <div className="form_modal__form_footer_container" style={{ paddingTop: "1rem" }}>
        <div className="form-footer">
          By clicking 'Sign up', you agree to our  <br></br>
          <span className="form-link" onClick={() => window.open("/termsandconditions")}>
            Terms of use <span className="text_underline"></span>
          </span> &nbsp; & &nbsp;
          <span className="form-link" onClick={() => window.open("/privacypolicy")} >
            Privacy Policy
            <span className="text_underline"></span>
          </span>
        </div>

        <div className="form-footer"
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1.5rem", gap: "70px" }}>
          <div onClick={() => window.open("https://duonut.com")}
            style={{ cursor: "pointer", position: "relative", color: "#3498db" }}>
            {/* <span className="text_underline"> </span> */}
            <i className="fa fa-long-arrow-left" aria-hidden="true"></i> &nbsp;duonut.com
          </div>
          <div>
            Have an account? &nbsp;<label style={{ color: "#3498db" }} className="form-link"
              onClick={() => {
                dispatch(showSignInForm());
              }}>Sign In&nbsp;<i className="fa fa-long-arrow-right" aria-hidden="true"></i></label></div>
        </div>
      </div>
    </div>
  );
}
