import React, { useEffect, useState } from "react";
import './DuonutReports.css';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import { groupBy, meanBy } from 'lodash';

// Extend dayjs with necessary plugins
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

const LineChart0 = ({ transformedData, columns1, selectedColumn }) => {
    // const [selectedColumn, setselectedColumn] = useState("Actual Production");
    const [selectedMonth, setSelectedMonth] = useState('2024-07'); // For weekly data
    const [selectedYear, setSelectedYear] = useState('2024'); // For monthly data
    const [timeUnit, setTimeUnit] = useState('week');

    const getWeekOfMonth = (date) => {
        const firstDayOfMonth = dayjs(date).startOf('month');
        return dayjs(date).diff(firstDayOfMonth, 'week') + 1;
    };

    const ensureFullWeek = (weekData) => {
        const fullWeek = Array.from({ length: 7 }, (_, i) => i + 1); // Days 1 to 7 (Sunday to Saturday)
        const weekDays = weekData.map(data => data.x);

        fullWeek.forEach(day => {
            if (!weekDays.includes(day)) {
                weekData.push({ x: day, y: 0 });
            }
        });

        return weekData.sort((a, b) => a.x - b.x); // Sort by day
    };

    const aggregateDataByDate = (data, month) => {
        const filteredData = data.filter(item => dayjs(item.Date).format('YYYY-MM') === month);

        const groupedByDate = groupBy(filteredData, 'Date');

        return Object.keys(groupedByDate).map(date => {
            const averageProduction = meanBy(groupedByDate[date], (item) => parseInt(item[selectedColumn], 10) || 0);

            return {
                date: dayjs(date),
                averageProduction: averageProduction,
            };
        });
    };

    const aggregateDataByWeeks = (data, month) => {
        const dateAveragedData = aggregateDataByDate(data, month);

        const groupedData = groupBy(dateAveragedData, (item) => getWeekOfMonth(item.date));

        return Object.keys(groupedData).map((week) => {
            const weekData = groupedData[week].map((entry) => ({
                x: entry.date.weekday() + 1, // +1 to make Sunday = 1, Monday = 2, ..., Saturday = 7
                y: entry.averageProduction,
            }));

            return {
                label: `Week ${week}`,
                data: ensureFullWeek(weekData),
            };
        });
    };

    const aggregateDataByMonths = (data, year) => {
        const filteredData = data.filter(item => dayjs(item.Date).format('YYYY') === year);

        const groupedByMonth = groupBy(filteredData, (item) => dayjs(item.Date).format('YYYY-MM'));

        return Object.keys(groupedByMonth).map(month => {
            const daysInMonth = dayjs(month).daysInMonth();
            const dailyAverages = groupBy(groupedByMonth[month], (item) => dayjs(item.Date).date());

            const monthData = Array.from({ length: daysInMonth }, (_, i) => {
                const day = i + 1;
                const dailyData = dailyAverages[day] || [];

                const averageProduction = meanBy(dailyData, (item) => parseInt(item[selectedColumn], 10) || 0);

                return {
                    x: day,
                    y: dailyData.length > 0 ? averageProduction : 0,
                };
            });

            return {
                label: dayjs(month).format('MMM YYYY'),
                data: monthData,
            };
        });
    };

    const weeklyData = aggregateDataByWeeks(transformedData, selectedMonth);
    const monthlyData = aggregateDataByMonths(transformedData, selectedYear);

    const weeklySeries = weeklyData.map(item => ({
        name: item.label,
        data: item.data.map(d => d.y),
    }));

    const monthlySeries = monthlyData.map(item => ({
        name: item.label,
        data: item.data.map(d => d.y),
    }));

    const weeklyOptions = {
        chart: {
            type: 'line',
            height: 350,
        },
        xaxis: {
            title: { text: 'Day of the Week' },
            categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            tickAmount: 7,
        },
        yaxis: {
            title: { text: selectedColumn },
            min: 0,
        },
        title: {
            text: `Weekly Comparison for ${selectedMonth}`,
            align: 'center',
        },
        colors: ['#008FFB', '#FF4560', '#00E396', '#775DD0', '#FEB019'],
    };

    const monthlyOptions = {
        chart: {
            type: 'line',
            height: 350,
        },
        xaxis: {
            title: { text: 'Day of the Month' },
            tickAmount: 31,
        },
        yaxis: {
            title: { text: selectedColumn },
            min: 0,
        },
        title: {
            text: `Monthly Comparison for ${selectedYear}`,
            align: 'center',
        },
        colors: ['#008FFB', '#FF4560', '#00E396', '#775DD0', '#FEB019'],
    };

    return (
        <div className="DR1linechart">
            <h2 style={{ paddingBottom: "1rem" }}>{`${selectedColumn} Chart`}</h2>
            <br></br>
            {/* <div className="DR1linecharfilter">
                <div>Select Field to show in Chart</div>
                <select value={selectedColumn} className='text_editor_dropdown'
                    onChange={e => setselectedColumn(e.target.value)} >
                    <option key={"select option"} value="" style={{ display: "none", color: "#44474d" }} selected disabled>
                        Select Field</option>
                    {columns1.map((column, idx) => {
                        return <option key={idx + 1} value={column.name} > {column.name} </option>
                    }
                    )}
                </select>
            </div>
            <br></br> */}

            <div className="DR1linecharfilter">
                <div>Show Graph of Weekly / Monthly: </div>
                <select value={timeUnit} className='text_editor_dropdown'
                    onChange={e => setTimeUnit(e.target.value)} >
                    <option value="week">Weekly</option>
                    <option value="month">Monthly</option>
                </select>
            </div>
            <br></br>

            {timeUnit === 'week' && <div className="DR1linecharfilter">
                <label>Select Month and Year: </label>
                <input className='text_editor_dropdown'
                    type="month"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                />
            </div>}
            {timeUnit === 'month' && <div className="DR1linecharfilter">
                <label>Select Year: </label>
                <input className='text_editor_dropdown'
                    type="number"
                    value={selectedYear}
                    min="2000"
                    max={dayjs().year()}
                    onChange={(e) => setSelectedYear(e.target.value)}
                />
            </div>}

            <br></br>
            <br></br>

            {timeUnit === 'week' && (
                <Chart options={weeklyOptions} series={weeklySeries} type="line" width={800} height={400} />
            )}
            {timeUnit === 'month' && (
                <Chart options={monthlyOptions} series={monthlySeries} type="line" width={800} height={400} />
            )}
        </div>
    );
}

export default LineChart0;