import React, { useState } from 'react';
import './HubspotPage.css';
// import { useSelector, useDispatch } from "react-redux";
// import { useParams, useNavigate } from 'react-router-dom';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
// import useWindowDimensions from "../../hooks/useWindowDimensions";
import YouTube from "react-youtube";

const HubspotPage = () => {
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const [channelName, setchannelName] = useState("");
    // const { isLoggedIn } = useSelector((state) => state.user);
    // const { width } = useWindowDimensions();
    const [settingTabNum, setsettingTabNum] = useState(1);

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            playsinline: 1
        }
    };

    return (
        <div className='SPI1App'>
            <div className='SPI1Template'>
                <div className="row SPI1Container1 ">
                    <div className="col-xs-12 col-md-12 col-lg-3" style={{ padding: "2rem" }}>
                        <div>
                            <img style={{ width: "6rem" }} src="/assets/icons/hubspot.svg" alt="create a quiz" loading="lazy" />
                        </div>
                        <button style={{ padding: "1rem", marginTop: "2rem" }}
                            onClick={() => window.open("/login")}
                            className='btn-secondary'>Use this Integration</button>
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-9" style={{ textAlign: "left", padding: "1rem" }}>
                        <h1 style={{ fontSize: "2.5rem" }}>Create or update contacts in HubSpot                        </h1>
                        {/* <div style={{ padding: "0.5rem 0rem", fontSize: "1.5rem" }}>App Integration</div> */}

                        <div style={{ margin: "1rem 0rem", fontSize: "1.4rem", fontWeight: "600" }}>
                            <label className="setting-header-label" style={{ color: settingTabNum === 1 ? "#3184C9" : "" }} onClick={() => setsettingTabNum(1)}>
                                About
                                {settingTabNum === 1 && <div className="setting-selected-line"></div>}
                            </label>
                            <label className="setting-header-label" style={{ color: settingTabNum === 2 ? "#3184C9" : "", marginLeft: "1rem" }} onClick={() => setsettingTabNum(2)}>
                                How to setup
                                {settingTabNum === 2 && <div className="setting-selected-line"></div>}
                            </label>
                        </div>

                        {settingTabNum === 1 && <>
                            <h3 style={{ paddingTop: "1rem" }}>Create or update contacts in HubSpot.</h3>

                            <YouTube
                                videoId="C0oEJSe60as"
                                id='player'
                                containerClassName="embed embed-youtube"
                                opts={opts}
                                className='HSK1section-1'
                                style={{ height: "20rem" }}
                            />

                            <div style={{ padding: "0.5rem 0rem" }}>
                                The Duonut integration for HubSpot opens up some exciting possibilities for your business. Here are just a few things you can do:
                            </div>

                            <ul style={{ padding: "0.5rem 0rem", listStyle: "decimal", paddingLeft: "2rem" }}>
                                <li>Use a lead gen type of form from Duonut to capture new leads and automatically send the info directly into HubSpot to segment your audience.</li>
                                <br></br>
                                <li>Find out where your Duonut is performing best with tracking, then double down your investment on this touchpoint. </li>
                                <br></br>
                                <li>You can collect email addresses with a contact form form Duonut, then personalize your emails using details from your HubSpot contacts list.</li>
                            </ul>

                            <br></br>
                            <div style={{ padding: "0.5rem 0rem" }}>
                                <h3>What you need:</h3>
                                <ul style={{ padding: "0.5rem 0rem", listStyle: "unset", paddingLeft: "2rem" }}>
                                    <li>A free Duonut account with at least one active Duonut activity in it.</li>
                                    <li>A free HubSpot CRM account.</li>
                                </ul>
                            </div>


                        </>}
                        {settingTabNum === 2 && <div>
                            <div style={{ paddingTop: '1rem' }}>
                                Click the Use this integration button on the left of this text to get started.
                            </div>
                            <br></br>
                            <div>
                                If you already have a Duonut account, you’ll be asked to log in, and then to either choose an existing Duonut, or create a new one to connect with HubSpot.
                            </div>
                            <br></br>
                            <div>
                                If you don’t yet have a Duonut account, we’ll get you signed up, and the guide linked below will take you through creating a Duonut to connect with HubSpot.
                            </div>
                            <br></br>
                            <div>Checkout the  <a href='https://duonut.com/help/how-to-integrate-duonut-with-hubspot' target="_blank">setup guide. </a></div>

                            <br></br>
                        </div>}
                    </div>
                </div>

            </div>
            <HomePageFooter />
        </div>

    );

}

export default HubspotPage;