import React, { useEffect, useState, useRef } from "react";
import './Dashboard.scss';
import './Viewer.css';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getDuonutAsync, showduonutData, postquizAsync } from '../../redux/slices/DuonutReducer';
import loader from '../../assets/Images/loader.gif';
import { Navigation, Pagination, Scrollbar, Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { selectSlide, updateGoToSlide } from '../../redux/slices/userActivity';
// import { useAudio } from '../../viewerComponents/BGAudio/useAudio';
import { backendUrl, frontendUrl } from "../../services/constants";
import background from '../../assets/Images/background-img.svg';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { viewerDisplayId, viewerNickName, viewerUrlParams } from '../../redux/slices/DuonutReducer';
import { showDuonutresponse, saveAnalyticsAsync } from '../../redux/slices/DuonutReducer';
import ThankyouPage from "./LeaderBoard/ThankyouPage";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import Element from "../../Components/Elements/Element";
import axios from "axios";
import CorrectModel from '../../Components/ViewerModals/CorrectModel';
import WrongModel from '../../Components/ViewerModals/WrongModel';
import TimesUpModel from '../../Components/ViewerModals/TimesUpModel';
import ConfettiExplosion from 'react-confetti-explosion';
import { getDuonutUserAccessAsync } from "../../redux/slices/UserMappingReducer";
import { domain } from "../../services/constants";

let swiperInstance;

const Viewer = () => {
    const dispatch = useDispatch();
    const paramsid = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const step = queryParams.get('step');
    const p_type = queryParams.get('type');

    const { selectedSlide, goToSlide, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const { isLoggedIn } = useSelector((state) => state.user);
    const [duonutPage, setduonutPage] = useState(0);
    const swiperRef = useRef(null);
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const [isInIframe, setIsInIframe] = useState(false);
    const [nickname, setnickname] = useState('');
    const [nickname2, setnickname2] = useState('');
    const [passwordChar, setpasswordChar] = useState('');
    const [nameMsg, setnameMsg] = useState('');
    const [customStartPage, setcustomStartPage] = useState(false);
    const save_analytics_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name, save_form_data, viewer_url_params } = useSelector((state) => state.duonut);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const { width } = useWindowDimensions();
    const [animationLoaded, setanimationLoaded] = useState(false);
    const [isUserPro, setisUserPro] = useState(false);
    const [seconds, setSeconds] = useState(null);
    const [timesup, settimesup] = useState(true);
    const [playing, setplaying] = useState(false);
    const audioRef = useRef(null);
    const [showModel, setShowModel] = useState(0);
    const [correctAnswer, setCorrectAnswer] = useState("");
    const timerRef = useRef(null);
    const [queryParamsObject, setqueryParamsObject] = useState("");
    const [language, setLanguage] = useState("en");
    const [transaltedText, settransaltedText] = useState({});

    useEffect(() => {
        // Get the current URL
        const currentUrl = window.location.href;

        // Create a URL object
        const url = new URL(currentUrl);

        // Get the search parameters
        const searchParamsValue = url.searchParams;

        // Alternatively, you can convert the searchParams to an object
        const searchParamsObject = {};
        searchParamsValue.forEach((value, key) => {
            searchParamsObject[key] = value;
        });

        // console.log(searchParamsObject);
        setqueryParamsObject(searchParamsObject);

    }, []);

    useEffect(() => {
        if (paramsid && paramsid.id && paramsid.id !== "0" && paramsid.id !== "undefined") {
            // console.log("qqqgetqqquizAsync", paramsid)
            dispatch(getDuonutAsync(paramsid));
        }
        // dispatch(getgeoLocationAsync());
        // eslint-disable-next-line
    }, []);

    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];

    // console.log(duonutData)

    var viewer_sliding;
    var show_viewer_vertical;
    var published;
    var package_name;
    var created_by;
    var show_joinNowPage;
    var isAudio;
    var show_recommendation;
    var catId;
    var password;
    var show_home_footer_button;
    var startpage_text;
    var startpage_text2;
    var referral;
    var duonutId;
    var displayId;
    var show_location;
    var startpage_title;
    var startpage_description;
    var startpage_fontcolor;
    var startpage_bgcolor;
    var startpage_logo;
    var total_views;
    var is_duonut_close_date;
    var duonut_close_date;
    var is_duonut_close_limit;
    var duonut_close_limit;
    var base_bgimage;
    var base_color;
    var duonut_active_status;
    var show_progressbar;
    var slide_logo;
    var slide_logo_alignment;
    var gmail_integration;
    var show_leaderboard;
    var lock;
    var show_ai_welcomepage;
    var ai_welcomepage_title;
    var ai_welcomepage_description;
    var ai_welcomepage_fontcolor;
    var ai_welcomepage_bgcolor;
    var no_of_user_views;

    if (duonutDataMain[0]) {
        viewer_sliding = duonutDataMain[0].setting.viewer_sliding;
        show_viewer_vertical = duonutDataMain[0].setting.show_viewer_vertical;
        published = duonutDataMain[0].published;
        package_name = duonutDataMain[0].name;
        created_by = duonutDataMain[0].created_by;
        show_joinNowPage = duonutDataMain[0].setting.show_startpage;
        isAudio = duonutDataMain[0].setting.audio;
        show_recommendation = duonutDataMain[0].setting.show_recommendation;
        catId = duonutDataMain[0].duonut_category_id.id;
        password = duonutDataMain[0].password;
        show_home_footer_button = duonutDataMain[0].setting.show_home_footer_button;
        startpage_text = duonutDataMain[0].setting.startpage_text;
        startpage_text2 = duonutDataMain[0].setting.startpage_text2;
        referral = duonutDataMain[0].setting.referral;
        duonutId = duonutDataMain[0].id;
        displayId = duonutDataMain[0].display_id;
        show_location = duonutDataMain[0].setting.show_location;
        startpage_title = duonutDataMain[0].setting.startpage_title;
        startpage_description = duonutDataMain[0].setting.startpage_description;
        startpage_fontcolor = duonutDataMain[0].setting.startpage_fontcolor;
        startpage_bgcolor = duonutDataMain[0].setting.startpage_bgcolor;
        startpage_logo = duonutDataMain[0].setting.startpage_logo;
        total_views = duonutDataMain[0].total_views;
        is_duonut_close_date = duonutDataMain[0].setting.is_duonut_close_date;
        duonut_close_date = duonutDataMain[0].setting.duonut_close_date;
        is_duonut_close_limit = duonutDataMain[0].setting.is_duonut_close_limit;
        duonut_close_limit = duonutDataMain[0].setting.duonut_close_limit;
        base_bgimage = duonutDataMain[0].setting.base_bgimage;
        base_color = duonutDataMain[0].setting.base_color;
        duonut_active_status = duonutDataMain[0].active;
        show_progressbar = duonutDataMain[0].setting.show_progressbar;
        slide_logo = duonutDataMain[0].setting.slide_logo;
        slide_logo_alignment = duonutDataMain[0].setting.slide_logo_alignment;
        gmail_integration = duonutDataMain[0].gmail_integration;
        show_leaderboard = duonutDataMain[0].setting.show_leaderboard;
        lock = duonutDataMain[0].lock;
        show_ai_welcomepage = duonutDataMain[0].setting.show_ai_welcomepage;
        ai_welcomepage_title = duonutDataMain[0].setting.ai_welcomepage_title;
        ai_welcomepage_description = duonutDataMain[0].setting.ai_welcomepage_description;
        ai_welcomepage_fontcolor = duonutDataMain[0].setting.ai_welcomepage_fontcolor;
        ai_welcomepage_bgcolor = duonutDataMain[0].setting.ai_welcomepage_bgcolor;
        no_of_user_views = duonutDataMain[0].setting.no_of_user_views;
    }
    const languageOptions = [
        { code: "en", name: "English", displayName: "English" },
        { code: "hi", name: "Hindi", displayName: "Hindi(हिन्दी)" },
        { code: "bn", name: "Bengali", displayName: "Bengali(বাংলা)" },
        { code: "mr", name: "Marathi", displayName: "Marathi(मराठी)" },
        { code: "ta", name: "Tamil", displayName: "Tamil(தமிழ்)" },
        { code: "te", name: "Telugu", displayName: "Telugu(తెలుగు)" },
        { code: "gu", name: "Gujarati", displayName: "Gujarati(ગુજરાતી)" },
        { code: "kn", name: "Kannada", displayName: "Kannada(ಕನ್ನಡ)" },
        { code: "ml", name: "Malayalam", displayName: "Malayalam(മലയാളം)" },
        { code: "pa", name: "Punjabi", displayName: "Punjabi(ਪੰਜਾਬੀ)" },
        { code: "es", name: "Spanish", displayName: "Spanish(Español)" },
        { code: "ja", name: "Japanese", displayName: "Japanese(日本語)" },
        { code: "ar", name: "Arabic", displayName: "Arabic(العربية)" },
        { code: "fr", name: "French", displayName: "French(Français)" },
        { code: "nl", name: "Dutch", displayName: "Dutch(Nederlands)" },
        { code: "pt", name: "Portuguese", displayName: "Portuguese(Português)" },
        { code: "ru", name: "Russian", displayName: "Russian(Русский)" },
        { code: "it", name: "Italian", displayName: "Italian(Italiano)" },
        { code: "ko", name: "Korean", displayName: "Korean(한국어)" },
        { code: "zh-TW", name: "Chinese", displayName: "Chinese(中文)" },
        { code: "fi", name: "Finnish", displayName: "Finnish(Suomi)" },
        { code: "sk", name: "Slovak", displayName: "Slovak(Slovenčina)" },
        { code: "sr", name: "Serbian", displayName: "Serbian(Српски)" },
        { code: "ms", name: "Malay", displayName: "Malay(Bahasa Melayu)" },
        { code: "id", name: "Indonesian", displayName: "Indonesian(Bahasa Indonesia)" },
        { code: "tr", name: "Turkish", displayName: "Turkish(Türkçe)" },
        { code: "pl", name: "Polish", displayName: "Polish(Polski)" },
        { code: "uk", name: "Ukrainian", displayName: "Ukrainian(Українська)" },
        { code: "tl", name: "Filipino", displayName: "Filipino(Tagalog)" },
        { code: "el", name: "Greek", displayName: "Greek(Ελληνικά)" },
        { code: "sv", name: "Swedish", displayName: "Swedish(Svenska)" },
        { code: "cs", name: "Czech", displayName: "Czech(Čeština)" },
        { code: "bg", name: "Bulgarian", displayName: "Bulgarian(Български)" },
        { code: "da", name: "Danish", displayName: "Danish(Dansk)" },
    ];
    const sortedLanguageOptions = languageOptions
        .filter((lang) => lang.displayName !== "English")
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

    // Add "English" as the first option
    sortedLanguageOptions.unshift(
        languageOptions.find((lang) => lang.displayName === "English")
    );

    const getTranslatedText = (key, language) => {
        const translations = {
            time: {
                en: "Time",
                hi: "समय", // Hindi
                bn: "সময়", // Bengali
                mr: "वेळ", // Marathi
                ta: "நேரம்", // Tamil
                te: "సమయం", // Telugu
                gu: "સમય", // Gujarati
                kn: "ಸಮಯ", // Kannada
                ml: "സമയം", // Malayalam
                pa: "ਵੇਲਾ", // Punjabi
                es: "Tiempo", // Spanish
                ja: "時間", // Japanese
                ar: "وقت", // Arabic
                fr: "Temps", // French
                nl: "Tijd", // Dutch
                pt: "Tempo", // Portuguese
                ru: "Время", // Russian
                it: "Tempo", // Italian
                ko: "시간", // Korean
                "zh-TW": "时间", //Chinese
                fi: "Aika", // Finnish
                sk: "Čas", // Slovak
                sr: "Време", // Serbian
                ms: "Masa", // Malay
                id: "Waktu", // Indonesian
                tr: "Zaman", // Turkish
                pl: "Czas", // Polish
                uk: "Час", // Ukrainian
                tl: "Oras", // Filipino
                el: "Ώρα", // Greek
                sv: "Tid", // Swedish
                cs: "Čas", // Czech
                bg: "Време", // Bulgarian
                da: "Tid", // Danish
            },
            start: {
                en: "Start",
                hi: "शुरू", // Hindi
                bn: "শুরু", // Bengali
                mr: "सुरु", // Marathi
                ta: "தொடங்கு", // Tamil
                te: "ప్రారంభించు", // Telugu
                gu: "શરૂ કરો", // Gujarati
                kn: "ಆರಂಭಿಸಿ", // Kannada
                ml: "തുടങ്ങുക", // Malayalam
                pa: "ਸ਼ੁਰੂ ਕਰੋ", // Punjabi
                es: "Comenzar", // Spanish
                ja: "始める", // Japanese
                ar: "يبدأ", // Arabic
                fr: "Commencer", // French
                nl: "Begin", // Dutch
                pt: "Começar", // Portuguese
                ru: "Начать", // Russian
                it: "Inizia", // Italian
                ko: "시작", // Korean
                "zh-TW": "开始", //Chinese
                fi: "Aloita", // Finnish
                sk: "Začať", // Slovak
                sr: "Почни", // Serbian
                ms: "Mula", // Malay
                id: "Mulai", // Indonesian
                tr: "Başla", // Turkish
                pl: "Rozpocznij", // Polish
                uk: "Почати", // Ukrainian
                tl: "Simulan", // Filipino
                el: "Έναρξη", // Greek
                sv: "Starta", // Swedish
                cs: "Začít", // Czech
                bg: "Започни", // Bulgarian
                da: "Start", // Danish
            },
        };

        return translations[key][language] || translations[key].en; // Default to English if translation is missing
    };
    useEffect(() => {
        if (duonutDataMain[0]) {
            // console.log("duonut_active_status", duonut_active_status)

            if (lock) {
                if (isLoggedIn) {
                    const formData = new FormData();
                    formData.append('userId', localStorage.getItem("userId"));
                    formData.append('duonutId', duonutId);
                    dispatch(getDuonutUserAccessAsync(formData)).then((response) => {
                        // console.log(response);
                        if (response.status !== 201) {
                            setduonutPage(8);
                            return null;
                        }
                    }).catch(error => {
                        console.log(error);
                        setduonutPage(8);
                        return null;
                    });

                } else {
                    setduonutPage(8);
                    return null;
                }
            } else if (!duonut_active_status) {
                setduonutPage(6);
                return null;
            } else if (!published) {
                // console.log(isLoggedIn, created_by.id, localStorage.getItem("userId"))
                if (isLoggedIn && created_by.id === parseInt(localStorage.getItem("userId"))) {

                } else {
                    setduonutPage(6);
                    return null;
                }
            }

            var userIsPro = false;
            if (created_by.pro_user) {
                userIsPro = true;
            } else if (created_by.expire_date) {
                const currentDate = new Date();
                const dateToCompare = new Date(created_by.expire_date);
                const comparisonResult = currentDate < dateToCompare;
                if (comparisonResult) {
                    userIsPro = true;
                } else {
                    userIsPro = false;
                }
            }
            setisUserPro(userIsPro);
            // console.log("userIsPro", userIsPro);

            const currentDate = new Date();
            const dateToCompare = new Date(duonut_close_date);
            currentDate.setHours(0, 0, 0, 0);
            dateToCompare.setHours(0, 0, 0, 0);

            if (!userIsPro && total_views > parseInt(no_of_user_views)) {
                setduonutPage(7);
            } else if (is_duonut_close_date && currentDate > dateToCompare) {
                setduonutPage(6);
            } else if (is_duonut_close_limit && total_views >= duonut_close_limit) {
                setduonutPage(6);
            } else if (show_ai_welcomepage) {
                var modifyName = "guest" + (total_views + 1);
                setnickname(modifyName);
                setduonutPage(11);
                dispatch(viewerDisplayId(displayId));
                dispatch(viewerUrlParams(queryParamsObject));
                dispatch(viewerNickName(modifyName));
            } else if (!show_joinNowPage || duonutDataMain[0].created_by.email === "hello@duonut.com") {
                var modifyName = "guest" + (total_views + 1);
                setnickname(modifyName);
                setduonutPage(2);
                dispatch(viewerDisplayId(displayId));
                dispatch(viewerUrlParams(queryParamsObject));
                dispatch(viewerNickName(modifyName));
                if (duonutDataMain[0]?.user_activity[0]?.timer) {
                    setSeconds(duonutDataMain[0]?.user_activity[0]?.timer);
                    settimesup(true);
                } else {
                    setSeconds(null);
                    settimesup(false);
                }
            } else {
                setduonutPage(1);
            }

            if (startpage_bgcolor !== "#0b1350") {
                setcustomStartPage(true);
            } else {
                setcustomStartPage(false);
            }
        }

    }, [duonutData]);

    const source = {
        position: "absolute",
        right: "50%",
        left: "50%",
        top: "-150px",
        zIndex: "5000"
    };
    const bigExplodeProps = {
        force: 0.6,
        duration: 3000,
        particleCount: 200,
        floorHeight: 1600,
        floorWidth: 1600
    };

    useEffect(() => {
        if (gmail_integration?.access_key) {

            // console.log("selectSlide", selectedSlide, gmail_integration?.step);
            if (gmail_integration?.trigger !== "Step Loaded") return;
            if (parseInt(gmail_integration?.step) !== parseInt(selectedSlide)) return;
            // console.log("selectSlide1", selectedSlide);

            var email_id = gmail_integration?.to?.replace(/{{(.*?)}}/g, (match, captured) => {
                const lowerCaptured = captured.toLowerCase();
                // Convert keys in save_form_data to lowercase for case-insensitive check
                const lowerSaveFormData = Object.fromEntries(
                    Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                );
                if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                    return lowerSaveFormData[lowerCaptured];
                } else {
                    return match; // if no replacement found, keep the original placeholder
                }
            });
            var subject = gmail_integration?.subject?.replace(/{{(.*?)}}/g, (match, captured) => {
                const lowerCaptured = captured.toLowerCase();
                // Convert keys in save_form_data to lowercase for case-insensitive check
                const lowerSaveFormData = Object.fromEntries(
                    Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                );
                if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                    return lowerSaveFormData[lowerCaptured];
                } else {
                    return match; // if no replacement found, keep the original placeholder
                }
            });
            var message = gmail_integration?.message?.replace(/{{(.*?)}}/g, (match, captured) => {
                const lowerCaptured = captured.toLowerCase();
                // Convert keys in save_form_data to lowercase for case-insensitive check
                const lowerSaveFormData = Object.fromEntries(
                    Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
                );
                if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
                    return lowerSaveFormData[lowerCaptured];
                } else {
                    return match; // if no replacement found, keep the original placeholder
                }
            });
            // console.log("data", email_id, subject, message);

            sendEmail(gmail_integration?.access_key, gmail_integration?.refresh_key, email_id, subject, message);

        }

    }, [gmail_integration, selectedSlide]);

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post(
                'https://oauth2.googleapis.com/token',
                new URLSearchParams({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET, // Replace with your client secret
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                })
            );
            // console.log(response.data)
            return response.data.access_token;
        } catch (error) {
            console.error('Error refreshing access token', error);
            throw error;
        }
    };

    const sendEmail = async (accessToken, refreshToken, email_id, subject, message) => {
        // console.log("data", accessToken, email_id, subject, message);

        const email = [
            `To: ${email_id}`,
            `Subject: ${subject}`,
            '',
            `${message}`,
        ].join('\n');

        const base64EncodedEmail = btoa(unescape(encodeURIComponent(email)));

        try {
            await axios.post(
                'https://www.googleapis.com/gmail/v1/users/me/messages/send',
                {
                    raw: base64EncodedEmail,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            // console.log('Email sent successfully');
        } catch (error) {
            console.error('Error sending email', error);
            if (error.response && error.response.status === 401) { // Access token expired
                try {
                    const newAccessToken = await refreshAccessToken(refreshToken);
                    await sendEmail(newAccessToken, refreshToken, email_id, subject, message);
                } catch (refreshError) {
                    console.error('Error refreshing access token', refreshError);
                }
            } else {
                console.error('Error sending email', error);
            }
        }
    };

    useEffect(() => {
        if (step && step <= duonutData?.user_activity?.length && duonutPage === 2) {
            // console.log("swiperRef.current", swiperRef.current, goToSlide)

            if (swiperRef.current) {
                swiperInstance = swiperRef.current.swiper; // Access the Swiper instance
                swiperInstance.slideTo(step - 1, 100, false);
                // settimesup(false);
            } else {
                console.error('Swiper instance not found!');
            }
        }
    }, [swiperRef, step, duonutPage, duonutData]);

    useEffect(() => {
        if (goToSlide !== -1) {
            // console.log("swiperRef.current", swiperRef.current, goToSlide)
            if (duonutData.user_activity.length === goToSlide) {
                var save_analytics = {
                    display_id: viewer_display_id,
                    watch_id: save_analytics_response[0] ? save_analytics_response[0].watch_id : "",
                    step: goToSlide,
                    end: "1",
                    nickname: viewer_nick_name,
                    // country: location[0] ? location[0].address?.country : "",
                    // city: location[0] ? location[0].address?.state_district : "",
                    // location: location[0] ? location[0].display_name : "",
                    url_params: viewer_url_params
                }
                // console.log(save_analytics);
                dispatch(saveAnalyticsAsync(save_analytics));
                if (show_leaderboard) {
                    setduonutPage(3);
                } else {
                    setduonutPage(4);
                }
                return;
            }
            if (swiperRef.current) {
                swiperInstance = swiperRef.current.swiper; // Access the Swiper instance
                swiperInstance.slideTo(goToSlide, 200, false);
                // setSeconds(null);
                // settimesup(false);
                // if (duonutData?.user_activity[goToSlide]?.timer) {
                //     setSeconds(duonutData?.user_activity[goToSlide]?.timer);
                //     settimesup(true);
                // } else {
                //     setSeconds(null);
                //     settimesup(false);
                // }
            } else {
                console.error('Swiper instance not found!');
            }
        }
    }, [goToSlide]);

    const slideChange = (swiperCore) => {
        const {
            activeIndex,
            snapIndex,
            previousIndex,
            realIndex,
        } = swiperCore;
        // console.log("slideChange", activeIndex, snapIndex, previousIndex, realIndex);
        dispatch(selectSlide(activeIndex));
        dispatch(updateGoToSlide(-1));
        setActiveSlideIndex(activeIndex);
        setanimationLoaded(false);
        if (duonutData?.user_activity[activeIndex]?.timer) {
            setSeconds(duonutData?.user_activity[activeIndex]?.timer);
            settimesup(true);
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        } else {
            setSeconds(null);
            settimesup(false);
        }

        var save_analytics = {
            display_id: viewer_display_id,
            watch_id: save_analytics_response[0] ? save_analytics_response[0].watch_id : "",
            step: activeIndex,
            // end: lastQue ? "1" : "0",
            nickname: viewer_nick_name,
            // country: location[0] ? location[0].address?.country : "",
            // city: location[0] ? location[0].address?.state_district : "",
            // location: location[0] ? location[0].display_name : "",
            url_params: viewer_url_params
        }
        // console.log(save_analytics);
        dispatch(saveAnalyticsAsync(save_analytics));
    }

    useEffect(() => {
        setIsInIframe(window.self !== window.top);
    }, []);

    const playQuizs = () => {
        if (password && passwordChar && nickname) {
            var data = {
                password: passwordChar
            }
            dispatch(postquizAsync(data, paramsid)).then((response) => {
                // console.log(response);
                setTimeout(() => {
                    setduonutPage(2);
                    dispatch(viewerDisplayId(displayId));
                    dispatch(viewerUrlParams(queryParamsObject));
                    dispatch(viewerNickName(nickname2 ? nickname + " - " + nickname2 : nickname));
                    if (duonutDataMain[0]?.user_activity[0]?.timer) {
                        setSeconds(duonutDataMain[0]?.user_activity[0]?.timer);
                        settimesup(true);
                    } else {
                        setSeconds(null);
                        settimesup(false);
                    }
                }, 100); //miliseconds
            }).catch(error => {
                console.log(error);
                setnameMsg("Enter your correct password");
                setTimeout(() => {
                    setnameMsg("");
                }, 5000); //miliseconds
            });

        } else if (!password && nickname && (!startpage_text2 || (startpage_text2 && nickname2))) {
            setduonutPage(2);
            dispatch(viewerDisplayId(displayId));
            dispatch(viewerUrlParams(queryParamsObject));
            dispatch(viewerNickName(nickname2 ? nickname + " - " + nickname2 : nickname));
            if (duonutDataMain[0]?.user_activity[0]?.timer) {
                setSeconds(duonutDataMain[0]?.user_activity[0]?.timer);
                settimesup(true);
            } else {
                setSeconds(null);
                settimesup(false);
            }
        } else {
            setnameMsg("Enter your play name");
            setTimeout(() => {
                setnameMsg("");
            }, 5000); //miliseconds
        }
    }

    const playQuizs1 = () => {
        setduonutPage(2);

        if (duonutDataMain[0]?.user_activity[0]?.timer) {
            setSeconds(duonutDataMain[0]?.user_activity[0]?.timer);
            settimesup(true);
        } else {
            setSeconds(null);
            settimesup(false);
        }

    }

    const handleNameKeyPress = (e) => {

        setnickname(e.target.value);
        if (e.keyCode === 13 && !startpage_text2 && !password) {
            document.getElementById("submitBtn").click();
        }
    }
    const handleNameKeyPress2 = (e) => {

        setnickname2(e.target.value);
        if (e.keyCode === 13) {
            document.getElementById("submitBtn").click();
        }
    }
    const handlePassKeyPress = (e) => {

        setpasswordChar(e.target.value);
        if (e.keyCode === 13) {
            document.getElementById("submitBtn").click();
        }
    }

    useEffect(() => {
        // console.log("seconds", seconds, timesup);
        if (seconds > 0 && timesup) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            // Set a new timer
            timerRef.current = setTimeout(() => setSeconds(seconds - 1), 1000);
        }
        if (seconds === 0 && timesup) {
            if (duonutData.user_activity[selectedSlide]?.elements?.some(element => element?.elementType === "Reorder")) {
                var answer = duonutData.user_activity[selectedSlide]?.elements?.find(element => element?.elementType === "Reorder")?.elementAttributes.options;
                setCorrectAnswer(answer.join(", "));
                setShowModel(3);

                setTimeout(() => {
                    setShowModel(0);
                    dispatch(updateGoToSlide(selectedSlide + 1));
                }, 3100);

            } else if (duonutData.user_activity[selectedSlide]?.elements?.some(element => element?.elementType === "ReorderImage")) {
                var answer = duonutData.user_activity[selectedSlide]?.elements?.find(element => element?.elementType === "ReorderImage")?.elementAttributes.options;
                setCorrectAnswer(answer.join(", "));
                setShowModel(3);

                setTimeout(() => {
                    setShowModel(0);
                    dispatch(updateGoToSlide(selectedSlide + 1));
                }, 3100);

            } else {
                dispatch(updateGoToSlide(selectedSlide + 1));
            }
        }

        return () => {
            // Clean up the timer when the component unmounts or the effect is cleaned up
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
        // eslint-disable-next-line
    }, [seconds, timesup]);

    const bgAudio = () => {
        if (!playing) {
            audioRef.current.play();
            audioRef.current.volume = 0.5;
        } else {
            audioRef.current.pause();
        }
        setplaying(!playing);
    }

    // Function to translate text using Lingva Translate API
    const translateText = async (texts, targetLanguage) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${domain}/duonut/language_translator`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    source: "en",
                    target: targetLanguage,
                    query: texts,
                },
            });

            if (response.status === 200) {
                // console.log("Translation:", response);
                return response?.data.translation; // return the translated text
            } else {
                // Handle unsuccessful status codes
                return "Error: Translation failed. Please try again.";
            }
        } catch (error) {
            console.error('Error in translation API:', error);
            return "Error translating text. Please try again."; // Handle API errors gracefully
        }
    };

    useEffect(() => {
        const fetchText = async () => {
            const textMap = {};
            const text = await translateText(ai_welcomepage_description, language);
            textMap[ai_welcomepage_description] = text;
            settransaltedText(textMap);
        };

        if (language !== "en") {
            fetchText();
        }
    }, [language]);

    return (<>

        {duonutPage === 0 &&
            <div className="Viewer_loader">
                <img alt="loader" src={loader} height={100} width={100} />
            </div>
        }
        {duonutPage === 1 &&
            <>
                <div className='join-us' style={customStartPage
                    ? { backgroundColor: startpage_bgcolor, color: startpage_fontcolor }
                    : { backgroundImage: `url(${background})` }}>
                    <div className='inner-wrap'>
                        {/* {isAudio && <div className='activity-icons'>
                            {audioStatus ? <VolumeUpIcon className='icon' onClick={() => bgAudio()} />
                                : <VolumeMuteIcon className='icon' onClick={() => bgAudio()} />}
                        </div>} */}
                        {startpage_logo ? <div className='JN1Img'>
                            <img className='JN1showImg' src={startpage_logo} alt="" />
                        </div> : <div></div>}
                        {startpage_title ? <div className='JN1title' style={{ color: startpage_fontcolor }}>{startpage_title}</div>
                            : <div style={{ display: "none" }}></div>}
                        {startpage_description ?
                            <div className='JN1description' style={{ color: startpage_fontcolor }}>{startpage_description}</div>
                            : <div style={{ display: "none" }}></div>}


                        <h1 className='activity-subtitle'>{package_name ? package_name : ""}</h1>
                        <div className='mid-wrapper'>
                            <div>
                                <input className='activity-input' type="text" name='name'
                                    style={customStartPage ? {
                                        backgroundColor: startpage_bgcolor, color: startpage_fontcolor,
                                        border: `1px solid ${startpage_fontcolor}`, width: password || startpage_text2 ? "20%" : "35%"
                                    } : { width: password || startpage_text2 ? "20%" : "35%" }}
                                    onKeyUp={event => handleNameKeyPress(event)}
                                    placeholder={startpage_text ? startpage_text : 'Enter your play name'} autoFocus={true} />
                                {startpage_text2 && <input className='activity-input' type="text" name='name2'
                                    style={customStartPage ? {
                                        backgroundColor: startpage_bgcolor, color: startpage_fontcolor,
                                        border: `1px solid ${startpage_fontcolor}`, width: password || startpage_text2 ? "20%" : "35%"
                                    } : { width: password || startpage_text2 ? "20%" : "35%" }}
                                    onKeyUp={event => handleNameKeyPress2(event)}
                                    placeholder={startpage_text2 ? startpage_text2 : 'Enter your play name'} />}
                                {password && <input className='activity-input' type="password" name='pass'
                                    style={customStartPage ? {
                                        backgroundColor: startpage_bgcolor, color: startpage_fontcolor,
                                        border: `1px solid ${startpage_fontcolor}`, width: password || startpage_text2 ? "20%" : "35%"
                                    } : { width: password || startpage_text2 ? "20%" : "35%" }}
                                    onKeyUp={event => handlePassKeyPress(event)} placeholder='Enter your password' />}
                                <button className='activity-button' type='submit' id='submitBtn'
                                    style={customStartPage ? {
                                        backgroundColor: startpage_fontcolor, color: startpage_bgcolor,
                                        fontWeight: "800"
                                    } : {}}
                                    onClick={() => playQuizs()}><span>Start</span></button>
                                {nameMsg && <div style={{ color: "red", fontWeight: "600", fontSize: "3vh" }}>{nameMsg}</div>}
                            </div>
                        </div>
                        <div className='profile-wrapper'>
                            <span>Created By: </span>
                            {created_by.profile_picture ?
                                <img className='profile-pic' src={`${backendUrl}${created_by.profile_picture}`} alt="" />
                                :
                                <i className="fa fa-user-circle-o" style={{ margin: "0px 10px" }} aria-hidden="true"></i>
                            }
                            <span>{created_by.name}</span>
                        </div>

                        {/* {referral && !isLoggedIn && <button className='code-purple-button' onClick={() => showReferral()}>Get Referral Link</button>}
                        {referral && isLoggedIn && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "2vh 2vh 2vh 3.5rem" }}>
                            <div className='SL1couponcode'>
                                <div>Referral Link - </div>
                                {referralLink}
                            </div>
                            <span style={{ marginLeft: "1rem", cursor: "pointer", fontSize: "0.8rem" }} onClick={() => referralCodeCopybtn()}>{iscopyReferralCode ? "Copied" : "Copy"}</span>
                        </div>}

                        {!isInIframe && width > 800 && !created_by?.pro_user && <button className='btn-primary'
                            style={{ position: "fixed", bottom: "3px", right: "1rem", zIndex: "1000", fontSize: "0.7rem" }}
                            onClick={() => window.open("https://duonut.com/")} >
                            Powered by <label style={{ fontSize: "0.8rem", fontWeight: "600" }}>Duonut</label>
                        </button>} */}
                        {/* {show_recommendation && recommend && recommendSection()} */}
                    </div>
                </div></>
        }

        {duonutPage === 11 &&
            <>
                <div className='join-us' style={{ backgroundColor: ai_welcomepage_bgcolor, color: ai_welcomepage_fontcolor }}>
                    <div className='inner-wrap' style={{ width: "30rem", margin: "auto", textAlign: "left" }}>
                        {ai_welcomepage_title ? <div className='JN1title' style={{
                            color: ai_welcomepage_fontcolor, fontWeight: "800",
                            margin: "1rem 0rem", fontSize: "1.5rem"
                        }}>{ai_welcomepage_title}</div>
                            : <div style={{ display: "none" }}></div>}
                        <div>
                            {getTranslatedText("time", language)}:{" "}
                            <label style={{ fontWeight: "600" }}>{duonutData.user_activity.length} min</label>
                        </div>
                        <div style={{
                            maxHeight: '200px',  // Restricts the visible height to 6 options (approx. 200px)
                            overflowY: 'auto',   // Adds a scroll when the options exceed the max height
                            width: '10rem',      // Adjust the width if needed
                            margin: '1rem 0',
                            borderRadius: '5px',
                            border: '1px solid #ccc'
                        }}>
                            <select
                                id="language"
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                }}
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            >
                                {sortedLanguageOptions.map((lang) => (
                                    <option key={lang.code} value={lang.code}>
                                        {lang.displayName}
                                    </option>
                                ))}
                            </select>

                        </div>
                        {ai_welcomepage_description ?
                            <div className='JN1description' style={{
                                color: ai_welcomepage_fontcolor,
                                margin: "2rem 0rem",
                                width: "100%", lineHeight: "25px"
                            }}>{language === "en" ? ai_welcomepage_description : transaltedText[ai_welcomepage_description] || "Loading..."}
                            </div>
                            : <div style={{ display: "none" }}></div>}

                        <div className='mid-wrapper' style={{ padding: "1vh 0" }}>
                            <button
                                className="activity-button"
                                type="submit"
                                id="submitBtn"
                                style={{
                                    backgroundColor: ai_welcomepage_fontcolor, color: ai_welcomepage_bgcolor,
                                    fontWeight: "800"
                                }}
                                onClick={() => playQuizs1()}
                            >
                                <span>{getTranslatedText("start", language)}</span>
                            </button>
                        </div>
                        {!isUserPro && <div style={{ margin: "2rem 0rem" }}>
                            <img style={{ width: "auto", height: "2rem" }}
                                src="https://app.duonut.com/assets/duonut_logo.png" alt="duonut" loading="lazy" /><br />

                            <p style={{ fontSize: "0.85rem", margin: "10px 0px", color: "rgb(156,163,175)" }}>
                                <a href="https://duonut.com/termsandconditions" style={{ color: "inherit", textDecoration: "none", userSelect: "none", textDecorationLine: "underline" }} target="_blank">Terms of Service</a>
                                &nbsp; | &nbsp;<a href="https://duonut.com/privacypolicy" style={{ color: "inherit", textDecoration: "none", userSelect: "none", textDecorationLine: "underline" }} target="_blank">Privacy Policy</a>
                            </p>
                        </div>}
                    </div>
                </div></>
        }

        {duonutPage === 2 && <div style={base_bgimage ? { backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("${base_bgimage}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }
            : { backgroundColor: base_color }}>
            <audio
                id="bgmusic"
                src={isAudio}
                ref={audioRef}
            ></audio>
            {showModel === 1 && <div style={source}>
                <ConfettiExplosion {...bigExplodeProps} />
            </div>}
            {showModel === 1 && <CorrectModel bgcolor={"#0F2AA9"} />}
            {showModel === 2 && <WrongModel bgcolor={"#0F2AA9"} answer={correctAnswer} />}
            {showModel === 3 && <TimesUpModel bgcolor={"#0F2AA9"} answer={correctAnswer} />}
            <Swiper
                id="swiper"
                className="swiper_container"
                ref={swiperRef}
                // virtual
                onSlideChange={(swiperCore) => slideChange(swiperCore)}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                modules={[Navigation, Mousewheel, Keyboard]}
                mousewheel={(viewer_sliding && show_viewer_vertical) ? true : false}
                keyboard={viewer_sliding ? true : false}
                simulateTouch={viewer_sliding ? true : false}
                direction={show_viewer_vertical ? "vertical" : "horizontal"}
            // centeredSlides={true}
            // height={window.innerHeight}
            // scrollbar={{ draggable: true }}
            // navigation={dir ? false : true}
            // pagination
            >
                {duonutData.user_activity.map((mData, i) => {
                    // console.log("slide", mData)
                    var slideAnimationTime = (mData?.slideAnimation === "none" || mData?.slideAnimation === undefined) ? 10 : 1000;
                    setTimeout(() => { setanimationLoaded(true); }, slideAnimationTime);

                    return (<>
                        <SwiperSlide key={`slide-${i}`} style={{ listStyle: "none" }}>
                            <div className={`designMainContainer `}
                                style={{
                                    flexDirection: "column",
                                    height: "100%",
                                    backgroundColor: base_bgimage ? "none" : mData?.backgroundImage ? base_color : mData.backgroundColor
                                }}>
                                {show_progressbar && <div style={{ position: "absolute", top: "0", width: " 100%" }}>
                                    <div className="progress-bar" role="progressbar" style={{
                                        width: Math.round((i + 1) * (100 / duonutData.user_activity.length)) + "%", height: "4px",
                                        backgroundColor: duonutData.user_activity[i]?.elements?.find(element => element?.elementType === "Text")?.elementAttributes.color ?
                                            duonutData.user_activity[i]?.elements?.find(element => element?.elementType === "Text")?.elementAttributes.color : "#0d6efd"
                                    }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>}
                                <div className='viewertopnav'>
                                    <div className='viewerinsidetopnav'>
                                        {(slide_logo && !isInIframe && slide_logo_alignment === "top left") && <img className="viewerlogoImg" key={slide_logo} src={slide_logo} alt="" />}
                                    </div>
                                    <div className='viewerinsidetopnav'>
                                        {mData?.timer > 0 && <div className='viewertimerbox'>
                                            <div className='viewertimer'>{seconds}</div>
                                            <div className='viewersecond'>second</div>
                                        </div>}
                                    </div>
                                    <div className='viewerinsidetopnav' style={{ justifyContent: "flex-end", marginRight: "2rem", fontSize: "1.5rem" }}>
                                        {(slide_logo && !isInIframe && slide_logo_alignment === "top right") && <img className="viewerlogoImg" key={slide_logo} src={slide_logo} alt="" />}

                                        {isAudio && <>{playing ? <i onClick={() => bgAudio()} style={{ cursor: "pointer", padding: "2px", width: '1.5rem' }} className="fa fa-volume-up" aria-hidden="true"></i>
                                            : <i onClick={() => bgAudio()} style={{ cursor: "pointer", padding: "2px", width: '1.5rem' }} className="fa fa-volume-off" aria-hidden="true"></i>}</>}
                                    </div>
                                </div>
                                {animationLoaded && <div className={`elementsContainer ${activeSlideIndex === i ? mData?.slideAnimation : ""}`}
                                    style={width < 800 ? {
                                        backgroundColor: mData?.backgroundColor ? mData.backgroundColor : "white",
                                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${mData?.backgroundImage})`,
                                        transform: 'scale(1)', position: "relative", width: "100%", height: "100vh", overflow: "auto"
                                    } : {
                                        backgroundColor: mData?.backgroundColor ? mData.backgroundColor : "white",
                                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${mData?.backgroundImage})`,
                                        transform: !isInIframe ? 'scale(1)' : 'scale(0.9)', position: "relative"
                                    }} id="noElement">
                                    {mData?.elements?.slice().sort((a, b) => a.posY - b.posY).map((element, index) => {
                                        return (
                                            <div
                                                key={element.id}
                                                id={index}
                                                className={`${activeSlideIndex === i ? element?.animation : ""}`}
                                                style={width < 800 ?
                                                    element.width < 350 ?
                                                        {
                                                            top: "unset", left: "unset",
                                                            width: element.width, height: element.height,
                                                            position: "unset", transform: "unset",
                                                            margin: "1rem auto",
                                                            order: element?.order ? element?.order : index + 1
                                                        }
                                                        : {
                                                            top: "unset", left: "unset",
                                                            width: "auto", height: element.elementType === "Iframe" ? "100%" : "auto",
                                                            position: "unset", transform: "unset",
                                                            margin: "15px 5px",
                                                            order: element?.order ? element?.order : index + 1
                                                        }
                                                    : {
                                                        position: 'absolute',
                                                        zIndex: element.zIndex,
                                                        left: element.posX,
                                                        top: element.posY,
                                                        height: element.height,
                                                        width: element.width,
                                                        transform: element.transform
                                                    }}
                                            >
                                                <Element element={element} slideId={`slide-${i}`} setShowModel={setShowModel} setCorrectAnswer={setCorrectAnswer} language={language} />
                                            </div>
                                        );
                                    })}
                                </div>}
                            </div>
                        </SwiperSlide>
                    </>)
                })}

                {!show_viewer_vertical && viewer_sliding && duonutData?.user_activity.length > 1 && <><div ref={navigationPrevRef} className="swiper__navigation_icon left">
                    <img src="/assets/icons/left-icon.svg" style={{ cursor: "pointer" }} />
                </div>
                    <div ref={navigationNextRef} className="swiper__navigation_icon right">
                        <img src="/assets/icons/right-icon.svg" style={{ cursor: "pointer" }} />
                    </div></>}
                {duonutPage === 2 && !isInIframe && width > 800 && (!isUserPro || duonutDataMain[0].created_by.email === "onlypromo@duonut.com") && <button className='btn-primary'
                    style={{ position: "fixed", bottom: "3px", right: "1rem", zIndex: "1000", fontSize: "0.7rem" }}
                    onClick={() => window.open("https://duonut.com/")} >
                    Powered by <label style={{ fontSize: "0.8rem", fontWeight: "600" }}>Duonut</label>
                </button>}
                {(slide_logo && !isInIframe && slide_logo_alignment === "bottom left") && <img className="viewerlogoImg" style={{ position: "fixed", bottom: "0.5rem", left: "0rem", zIndex: "1000" }} key={slide_logo} src={slide_logo} alt="" />}
                {(slide_logo && !isInIframe && slide_logo_alignment === "bottom right") && <img className="viewerlogoImg" style={{ position: "fixed", bottom: "3rem", right: "0rem", zIndex: "1000" }} key={slide_logo} src={slide_logo} alt="" />}

            </Swiper>
        </div>
        }
        {
            duonutPage === 3 && <LeaderBoard nickname={nickname} setduonutPage={setduonutPage} />
        }
        {
            duonutPage === 4 && <ThankyouPage nickname={nickname} />
        }
        {duonutPage === 5 &&
            <div className="join-us">
                <div className='inner-wrap' style={{ fontSize: "1.5rem", background: "white", color: "black" }}>
                    Oops! Looks like you can not view this activity. Please contact the creator of this activity for more information.
                </div>
            </div>
        }
        {duonutPage === 6 &&
            <div className="join-us">
                <div className='inner-wrap' style={{ fontSize: "1.5rem", background: "white", color: "black" }}>
                    This activity is not currently available for viewing. Please contact the creator for further details.
                </div>
            </div>
        }
        {duonutPage === 7 &&
            <div className="join-us">
                <div className='inner-wrap' style={{ fontSize: "1.5rem", background: "white", color: "black" }}>
                    Your Duonut Pro access has expired. Please renew to access this activity.
                </div>
            </div>
        }
        {duonutPage === 8 &&
            <div className="join-us">
                <div className='inner-wrap' style={{ fontSize: "1.5rem", background: "white", color: "black" }}>
                    You have not access this Duonut.
                </div>
            </div>
        }

    </>
    );
}

export default Viewer;