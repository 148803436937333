import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {  updateLogoutModalActive } from "../../../redux/slices/formDetails";
// import { updateIsLoggedIn } from "../../../redux/slices/user";

import styles from "./Logout.module.css";

export const Logout = () => {
  const dispatch = useDispatch();

  const { logoutModalActive } = useSelector((state) => state.formDetails);

  const handleClose = () => {
    dispatch(updateLogoutModalActive(false));
  };

  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userId");
    localStorage.removeItem("withGoogle");
    localStorage.removeItem("withMicrosoft");
    localStorage.removeItem("appId");
    localStorage.removeItem("subDomain");
    localStorage.removeItem("proUser");
    localStorage.removeItem("userProfilePicture");
    localStorage.removeItem("userType");
    localStorage.removeItem("expireDate");

    window.location = "/";
    // dispatch(updateIsLoggedIn(false));
  };

  return (
    <>
      <AnimatePresence>
        {logoutModalActive && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "tween", ease: "anticipate" }}
            className={styles.modal_wrapper}
            onClick={handleClose}
          >
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.5 }}
              transition={{ duration: 0.5, type: "tween", ease: "anticipate" }}
              className={styles.content_wrapper}
              onClick={(e) => e.stopPropagation()}
            >
              <h4 className={styles.content__heading}>Log out ?</h4>
              <p className={styles.content__para}>Are you sure want to logout? </p>
              <div className={styles.content__button_wrapper}>
                <button className={` ${styles.button_cancel}`} onClick={handleClose}>
                  Cancel
                </button>
                <button className={` ${styles.button_confirm}`} onClick={handleLogout}>
                  Confirm
                </button>
              </div>
              <div className={styles.close_icon} onClick={handleClose}>
                <img src="/assets/icons/close.svg" alt="" />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
