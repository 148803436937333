import React, { useState, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import "./Nugget.css"
import { frontendUrl, backendUrl } from "../../services/constants";
import { saveChannelAsync, showChannelresponse } from "../../redux/slices/ChannelReducer";
import {  editNuggetsAsync, deleteNuggetsAsync, deleteNuggetsMappingAsync, editNuggetsMappingAsync, editNuggetThumbnailAsync, updateNuggetPricingAsync } from "../../redux/slices/NuggetsReducer";
import VerticalBars from '../../assets/icons/myduonut/vertical-bars.svg'
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toggleUserMappingModal, updateNuggetId } from "../../redux/slices/UserMappingReducer";

var shareLink = "";
const Nugget = () => {
    const dispatch = useDispatch();
    const paramsid = useParams();
    const nugget_id = parseInt(paramsid.id);
    const navigate = useNavigate();
    const {  userProAccess } = useSelector((state) => state.user);

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    const [nuggetData, setnuggetData] = useState("");
    const [nuggetDuonutData, setnuggetDuonutData] = useState([]);
    const [channelId, setchannelId] = useState(0);
    const [showOptions, setShowOptions] = useState(0);
    const [editNuggetName, seteditNuggetName] = useState(false)
    const [nuggetName, setnuggetName] = useState("")
    const [visibility, setvisibility] = useState(true)
    const ref = useRef(null);
    const ref1 = useRef(null);
    const delref = useRef(null);
    const delref1 = useRef(null);
    const inputRef = useRef(null);
    const [iscopy, setiscopy] = useState(false);
    const pricingref = useRef(null);
    const pricingref1 = useRef(null);
    const [pricingTitle, setpricingTitle] = useState("")
    const [pricingDescription, setpricingDescription] = useState("")
    const [actualPrice, setactualPrice] = useState("")
    const [discountedPrice, setdiscountedPrice] = useState("")
    const [buttonText, setbuttonText] = useState("")
    const [buttonUrl, setbuttonUrl] = useState("")
    const [message, setmessage] = useState("");

    useEffect(() => {
        if (!userProAccess) {
            window.location = '/';
        }
    }, []);

    useEffect(() => {
        dispatch(saveChannelAsync());
        // console.log("inside");
    }, []);
    const channelData = useSelector(showChannelresponse);
    const channelData1 = channelData[0];

    // console.log(channelData1);

    useEffect(() => {
        if (channelData1) {
            // console.log(channelData1);
            if (channelData1.empty_message) {
                // setcreateChannel(true);
            } else {

                let foundNugget = null;
                let channel_id = null;

                // Loop through the channelData to find the nugget
                channelData1?.channels?.forEach(channelItem => {
                    channelItem.nuggets.forEach(nugget => {
                        if (nugget.id === nugget_id) {
                            foundNugget = nugget;
                            channel_id = channelItem.channel.id;
                        }
                    });
                });

                // Set the found nugget details
                if (foundNugget) {
                    setchannelId(channel_id);
                    setnuggetData(foundNugget);
                    setnuggetName(foundNugget.name);
                    setvisibility(foundNugget.visibility);
                    setpricingTitle(foundNugget.pricing_title);
                    setpricingDescription(foundNugget.pricing_description);
                    setactualPrice(foundNugget.actual_price);
                    setdiscountedPrice(foundNugget.discounted_price);
                    setbuttonText(foundNugget.button_text);
                    setbuttonUrl(foundNugget.button_url);

                    var dData = [];
                    foundNugget.nugget_duonut.map((duo, i) => {
                        dData.push(duo);
                    })
                    dData.sort(function (a, b) {
                        return parseFloat(a.priority) - parseFloat(b.priority);
                    });
                    // console.log(dData);
                    setnuggetDuonutData(dData);
                } else {
                    console.log("Nugget with the specified ID was not found.");
                }
            }
        }
    }, [channelData1])

    const handleNugget = (e) => {
        // window.open(`${frontend_url}/duonut/${display_id}`)
        e.stopPropagation();
    }
    const popUpHandler = (e, id) => {
        setShowOptions(id);

        e.stopPropagation();
    }

    // to close duonut options menu
    const optionsRef = useRef(null)
    const handleClose = () => setShowOptions(0)
    useOutsideAlerter(optionsRef, handleClose);

    const handleView = (e, display_id) => {
        window.open(`/duonut/${display_id}`)
        e.stopPropagation();
    }

    const handleViewDashboard = (e, id) => {
        window.open(`/duonutreports/${id}`)
        e.stopPropagation();
    }

    const handleRemoveDuonut = (e, nugget_id, duonutId) => {
        var nuggetMappingData1 = {
            nugget: nugget_id,
            duonut: duonutId,
        }
        dispatch(deleteNuggetsMappingAsync(nuggetMappingData1));
        toast.success(`Duonut Removed from Nugget`);
        setShowOptions(0);
        setTimeout(() => {
            dispatch(saveChannelAsync());
        }, 1000); //miliseconds
        e.stopPropagation();
    }

    const getItemStyle = (isDragging, draggableStyle) => ({

        background: isDragging ? '#3498db' : '',
        color: isDragging ? "white" : "",

        ...draggableStyle,
    });

    function handleOnDragEnd(result) {
        // console.log(result);
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        const items = Array.from(nuggetDuonutData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setnuggetDuonutData(items);
        // console.log(items);

        var nuggetMappingData = {
            nugget: nuggetData.id,
            source: result.source.index + 1,
            destination: result.destination.index + 1
        }
        dispatch(editNuggetsMappingAsync(nuggetMappingData));

    }

    const onClickSaveButton = () => {
        var nuggetsData = {
            id: nuggetData.id,
            name: nuggetName,
            visibility: nuggetData.visibility
        }
        dispatch(editNuggetsAsync(nuggetsData));
        seteditNuggetName(false);
    }
    const onClickCancelButton = () => {
        setnuggetName(nuggetData.name);
        seteditNuggetName(false);
    }

    const onClickDeleteButton = () => {
        delref.current.click();
    }
    const confirmDelete = () => {
        delref1.current.click();

        var nuggetsData = {
            id: nuggetData.id
        }
        dispatch(deleteNuggetsAsync(nuggetsData));
        navigate('/workflow');
    }
    const cancelDelete = () => {
        delref1.current.click();
    }

    const onClickShareButton = () => {
        shareLink = `${frontend_url}/viewworkflows/${nuggetData.id}`;
        setiscopy(false);
        ref.current.click();
    }

    const continuebtn = () => {
        ref1.current.click();
        setiscopy(false);
    }
    const viewbtn = () => {
        ref1.current.click();
        window.open(`/viewworkflows/${nuggetData.id}`);
        // window.location.reload();
    }

    const copycode = () => {
        var copyText = `${frontend_url}/viewworkflows/${nuggetData.id}`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopy(true);
    }

    const changeVisible = (type) => {
        if (type === 1) {
            setvisibility(true);
            var nuggetsData = {
                id: nuggetData.id,
                name: nuggetName,
                visibility: true
            }
            dispatch(editNuggetsAsync(nuggetsData));
        } else {
            setvisibility(false);
            var nuggetsData = {
                id: nuggetData.id,
                name: nuggetName,
                visibility: false
            }
            dispatch(editNuggetsAsync(nuggetsData));
        }
    }

    const onClickThumbnailChange = () => {
        inputRef.current.value = null; // reset the value of the file input
        inputRef.current.click();
    }

    const updateNuggetImage = (e) => {
        // console.log(e)
        const formData = new FormData();
        formData.append('id', nuggetData.id);
        formData.append('thumbnail', e.target.files[0], e.target.files[0].name);

        dispatch(editNuggetThumbnailAsync(formData));
        setTimeout(() => {
            dispatch(saveChannelAsync());
        }, 500); //miliseconds
    }

    const onClickUserMappingButton = () => {
        dispatch(updateNuggetId(nugget_id));
        dispatch(toggleUserMappingModal(true));
    };

    const submit = (event) => {
        event.preventDefault();

        var pricingData = {
            nugget_id: nugget_id,
            pricing_title: pricingTitle,
            pricing_description: pricingDescription,
            actual_price: actualPrice,
            discounted_price: discountedPrice,
            button_text: buttonText,
            button_url: buttonUrl,
        }
        dispatch(updateNuggetPricingAsync(pricingData));
        setmessage("Saved successfully.");

        setTimeout(() => {
            setmessage("");
            pricingref1.current.click();
        }, 3000); //miliseconds

    }

    return (
        <>
            <button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#shareModal`}>
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`shareModal`} tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content SM1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div className='SM1shareTitle'> Share</div>
                            {/* <div className='PM1sharemsg'>YOU CAN SHARE THIS QUIZ TO YOUR FAMILY OR FRIENDS.</div> */}
                            <br></br>
                            <div className="PM1circle_buttons">
                                <label className='SM1iconCircle' onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${shareLink}`)}>
                                    <i className="fa fa-facebook" aria-hidden="false"></i>
                                </label>
                                <label className='SM1iconCircle' onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink} ?page=ld`)}>
                                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                                </label>
                                <label className='SM1iconCircle' onClick={() => window.open(`https://twitter.com/share?text=${shareLink}`)} >
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                </label>
                                <label className='SM1iconCircle' onClick={() => window.open(`https://api.whatsapp.com/send/?text=${shareLink}`)}>
                                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                </label>
                            </div>
                            <br></br>
                            <button className='SM1Continuebtn' onClick={() => copycode()}>{iscopy ? "Copied" : "Copy Link"}</button>
                            <button className='SM1Continuebtn' onClick={() => viewbtn()}>View</button>
                            <button className='SM1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            <button ref={delref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#delModal`}>
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`delModal`} tabIndex="-1" aria-labelledby="delModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content SM1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={delref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div className='SM1shareTitle'> Confirm delete the Nugget? </div>
                            <br></br>
                            <div>Do you really want to delete {nuggetName}? </div>
                            <br></br>
                            <button className='SM1Continuebtn' onClick={() => confirmDelete()}>Confirm Delete</button>
                            <button className='SM1Continuebtn' onClick={() => cancelDelete()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <button ref={pricingref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#pricingModal`}>
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`pricingModal`} tabIndex="-1" aria-labelledby="pricingModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content SM1modalMain" style={{ marginTop: "8vh", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={pricingref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div className='SM1shareTitle' style={{ margin: "0vh 0vh 1vh 0vh" }}>Workflows Pricing</div>
                            <div></div>
                            <div>
                                <form onSubmit={submit}>
                                    <div className='usersettingInputbox'>
                                        <div style={{ textAlign: "left", padding: "4px 0px" }}>Enter Pricing Title</div>
                                        <input type="text" value={pricingTitle} onChange={(e) => setpricingTitle(e.target.value)} />
                                    </div>
                                    <div className='usersettingInputbox'>
                                        <div style={{ textAlign: "left", padding: "4px 0px" }}>Enter Pricing Description</div>
                                        <input type="text" value={pricingDescription} onChange={(e) => setpricingDescription(e.target.value)} />
                                    </div>
                                    <div className='usersettingInputbox'>
                                        <div style={{ textAlign: "left", padding: "4px 0px" }}>Enter Actual Price</div>
                                        <input type="text" value={actualPrice} onChange={(e) => setactualPrice(e.target.value)} />
                                    </div>
                                    <div className='usersettingInputbox'>
                                        <div style={{ textAlign: "left", padding: "4px 0px" }}>Enter Discounted Price</div>
                                        <input type="text" value={discountedPrice} onChange={(e) => setdiscountedPrice(e.target.value)} />
                                    </div>
                                    <div className='usersettingInputbox'>
                                        <div style={{ textAlign: "left", padding: "4px 0px" }}>Enter Button Text</div>
                                        <input type="text" value={buttonText} onChange={(e) => setbuttonText(e.target.value)} />
                                    </div>
                                    <div className='usersettingInputbox'>
                                        <div style={{ textAlign: "left", padding: "4px 0px" }}>Enter Button Url</div>
                                        <input type="text" value={buttonUrl} onChange={(e) => setbuttonUrl(e.target.value)} />
                                    </div>

                                    {message && <div style={{ margin: "1vh auto" }}>{message}</div>}
                                    <button type="submit" className="SM1Continuebtn" disabled={message ? true : false}
                                        style={{ margin: "1vh 1vh 0vh 0vh", backgroundColor: message ? "rgba(7, 168, 7, 0.897)" : "" }} >{message ? "Submitted" : "Submit"}</button>
                                    <button type="button" style={{ margin: "1vh 0vh 0vh 1vh" }} className='SM1Continuebtn' onClick={() => pricingref1.current.click()}>Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='nuggetApp'>
                <div className='ADP1backBtn' onClick={() => { navigate(`/workflow/${channelId}`); }}>
                    <i style={{ fontSize: "1.5rem", padding: "0px 8px 2px 0px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back
                </div>
                {nuggetData && <div className='nuggetTemplate row' key={nuggetData}>
                    <div className='nuggetContainer1 col-md-4 col-sm-12'>
                        <div style={{ position: "relative" }}>
                            {nuggetData.thumbnail ?
                                <img style={{ width: "300px", height: "200px", borderRadius: "10px", marginTop: "1rem" }} src={`${backendUrl}${nuggetData.thumbnail}`} loading="lazy"></img>
                                : <img style={{ width: "300px", height: "200px", borderRadius: "10px", marginTop: "1rem" }} src='/assets/thumbnail.jpg' loading="lazy"></img>}
                            <i style={{ cursor: "pointer", position: "absolute", right: "0rem", top: "1rem", padding: "1rem", marginTop: "-1px", marginRight: "-10px", backgroundColor: "white", borderRadius: "50%", color: "black" }} className="fa fa-pencil" aria-hidden="true" onClick={() => onClickThumbnailChange()}>
                                <input type="file" ref={inputRef}
                                    onChange={(e) => updateNuggetImage(e)}
                                    accept="image/*"
                                    onClick={(e) => { e.target.value = null }} name="postImages"
                                    style={{ display: "none" }} />
                            </i>
                        </div>
                        <div style={{ fontSize: "1.4rem", padding: "10px 5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <input style={{
                                backgroundColor: "transparent", color: "black", fontSize: "inherit", borderBottom: editNuggetName ? "2px solid black" : "none"
                            }} disabled={editNuggetName ? false : true} value={nuggetName === null ? "" : nuggetName} onChange={(e) => (setnuggetName(e.target.value))} />
                            <i key={editNuggetName} style={{ cursor: "pointer", display: editNuggetName ? "none" : "block", fontSize: "16px" }} className="fa fa-pencil" aria-hidden="true" onClick={() => seteditNuggetName(true)}></i>
                        </div>
                        {editNuggetName && <div>
                            <button className='nuggetButton' onClick={() => onClickSaveButton()} >Save</button>
                            <button className='nuggetButton' onClick={() => onClickCancelButton()} >Cancel</button>
                        </div>}
                        <div style={{ margin: "1rem" }}>{nuggetData.nugget_duonut.length} Duonut Activities</div>
                        <div style={{ display: "flex", margin: "0.5rem" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <input type="radio" id="Public" name="visibility" checked={visibility} style={{ width: "25px", height: "25px", margin: "2px 8px 2px 2px", accentColor: "black" }} onChange={() => changeVisible(1)} />
                                <label for="Public">Public</label>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "2rem" }}>
                                <input type="radio" id="Private" name="visibility" checked={!visibility} style={{ width: "25px", height: "25px", margin: "2px 8px 2px 2px", accentColor: "black" }} onChange={() => changeVisible(2)} />
                                <label for="Private">Private</label>
                            </div>
                        </div>
                        <div>
                            {!visibility && <button className='nuggetButton2' style={{ margin: "0.5rem", fontSize: "1rem", width: "11rem" }} onClick={() => onClickUserMappingButton()} >Map User</button>}
                            {!visibility && <button className='nuggetButton2' style={{ margin: "0.5rem", fontSize: "1rem", width: "11rem" }} onClick={() => pricingref.current.click()} >Set Pricing</button>}
                        </div>
                        <div>
                            <button className='nuggetButton2' style={{ margin: "0.5rem", fontSize: "1rem", width: "11rem" }} onClick={() => onClickShareButton()} ><i style={{ paddingRight: "5px" }} className="fa fa-share" aria-hidden="true"></i>Share Workflows</button>
                            <button className='nuggetButton2' style={{ margin: "0.5rem", fontSize: "1rem", width: "11rem" }} onClick={() => onClickDeleteButton()} ><i style={{ paddingRight: "5px" }} className="fa fa-trash" aria-hidden="true"></i>Delete Workflows</button>
                        </div>

                    </div>

                    <div className='nuggetContainer2 col-md-8 col-sm-12' key={channelData1}>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="dragOpt" type='dragOpt' direction='vertical'>
                                {(provided, snapshot) => (
                                    <div style={{ padding: "1rem 2rem" }} {...provided.droppableProps} ref={provided.innerRef}>
                                        {nuggetDuonutData.map((duonut, i) => {
                                            console.log("nuggetDuonutData", duonut)
                                            if (duonut.length <= 0) return;
                                            return (
                                                <Draggable key={duonut.id} draggableId={(duonut.id).toString()} index={i}>
                                                    {(provided, snapshot) => (
                                                        <div className='nuggetDuonutContainer'
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style)
                                                            }}
                                                            onClick={handleNugget}>
                                                            {/* <img style={{ width: "120px", height: "80px", borderRadius: "10px" }} src={`${backendUrl}${duonut.duonut.duonutthumbnail_set[0].thumbnail}`} loading="lazy"></img> */}
                                                            {duonut.duonut.setting?.coverPhoto ? <img src={duonut.duonut.setting?.coverPhoto} style={{ width: "120px", height: "80px", borderRadius: "10px" }} alt="" loading="lazy" />
                                                                : duonut.duonut.user_activity.length > 0 ? <img src={duonut.duonut.user_activity[0]?.thumbnail} style={{ width: "120px", height: "80px", borderRadius: "10px" }} alt="" loading="lazy" />
                                                                    : <img src='/assets/thumbnail.jpg' style={{ width: "120px", height: "80px", borderRadius: "10px" }} alt="" loading="lazy" />}
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                <div style={{ padding: "1rem" }}>{duonut.duonut.name}</div>
                                                                <div style={{ position: "relative", display: 'flex', alignItems: 'center' }} onClick={e => popUpHandler(e, duonut.duonut.id)}>
                                                                    <img src={VerticalBars} style={{ cursor: 'pointer', padding: '2px 6px', paddingLeft: '8px' }} loading="lazy" />
                                                                    {showOptions === duonut.duonut.id && <div ref={optionsRef}
                                                                        style={{ position: "absolute", width: "13rem", top: "40px", right: "15px", borderRadius: "10px", zIndex: "99", backgroundColor: "white", boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.1)" }}>
                                                                        <ul className='nuggetOptions'>
                                                                            <li onClick={e => handleRemoveDuonut(e, nuggetData.id, duonut.duonut.id)}>
                                                                                Remove from Nugget
                                                                            </li>
                                                                            <li onClick={() => window.open(`${frontend_url}/design/${duonut.duonut.id}`)}>
                                                                                Edit Duonut
                                                                            </li>
                                                                            <li onClick={e => handleView(e, duonut.duonut.display_id)}>
                                                                                View as a user
                                                                            </li>
                                                                            <li onClick={e => handleViewDashboard(e, duonut.duonut.id)}>
                                                                                View Dashboard
                                                                            </li>
                                                                        </ul>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>}
            </div>
        </>
    );

}

export default Nugget;