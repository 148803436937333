import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements } from '../../../redux/slices/userActivity';
import { EditTextarea } from "react-edit-text";
import { AnimatePresence, motion } from 'framer-motion';
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function OpenEnded() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [openEnded, setopenEnded] = useState(false);
    const [openEndedObjective, setopenEndedObjective] = useState("");
    const [openEndedFeedbackDesired, setopenEndedFeedbackDesired] = useState("");
    const dispatch = useDispatch()
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [error_message, setErrorMessage] = useState("");

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const openEnded = tempElement?.elementAttributes?.openEnded;
            const openEndedObjective = tempElement?.elementAttributes?.openEndedObjective;
            const openEndedFeedbackDesired = tempElement?.elementAttributes?.openEndedFeedbackDesired;
            if (openEnded) {
                setopenEnded(openEnded);
                setopenEndedObjective(openEndedObjective);
                setopenEndedFeedbackDesired(openEndedFeedbackDesired);
            } else {
                setopenEnded(false);
                setopenEndedObjective("");
                setopenEndedFeedbackDesired("");
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleOpenEndedChange = () => {
        // const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    openEnded: !openEnded
                },
            };
            console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleObjectiveChange = (e) => {
        console.log(e)
        const value = e.target.value;
        setopenEndedObjective(value);

        // if (!openEndedObjective) {
        //     setErrorMessage('Please give a proper objective for your research/survey.');
        //     setShowErrorMessage(true);
        //     setTimeout(() => setShowErrorMessage(false), 5000);
        //     return null;
        // }
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    openEndedObjective: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    // const handleObjectiveChange = (e) => {
    //     const value = e.target.value;
    //     setopenEndedObjective(value);

    //     if (userActivities[selectedUserActivity].elements.length >= 1) {
    //         // Deep copy userActivities
    //         const updatedActivities = JSON.parse(JSON.stringify(userActivities));
    //         const targetElement = updatedActivities[selectedUserActivity].elements[selectedComponent.target_id];

    //         // Update the objective in the copied element
    //         targetElement.elementAttributes.openEndedObjective = value;

    //         // Dispatch the updated state
    //         dispatch(updateUserActivity({ userActivities: updatedActivities }));
    //     }
    // };


    const handleFeedbackDesiredChange = (e) => {
        console.log(e)
        const value = e.target.value;
        setopenEndedFeedbackDesired(value);
        // if (!openEndedFeedbackDesired) {
        //     setErrorMessage('Please give a proper message for your research/survey.');
        //     setShowErrorMessage(true);
        //     setTimeout(() => setShowErrorMessage(false), 5000);
        //     return null;
        // }
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    openEndedFeedbackDesired: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleFeedbackDesiredClick = (value) => {
        console.log(value)
        setopenEndedFeedbackDesired(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    openEndedFeedbackDesired: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    return (<>
        <div className="EditorRowContainer" style={{ margin: "1rem 0px" }}>
            <input type='checkbox' id='openEndedField' name='openEndedField'
                style={{ width: "20px", height: "20px", margin: "2px 8px 2px 2px" }}
                checked={openEnded ? true : false}
                onChange={() => handleOpenEndedChange()} />

            <label style={{ marginRight: "1rem" }} for="openEndedField">Open-Ended?</label>

        </div>
        {openEnded && <> <div className="EditorRowContainer">
            <div style={{ marginRight: "1rem" }}>Add Objective
                <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                    data-tooltip-content="On the basis of the objective you provide your user will get the follow up questions based on that."
                    data-tooltip-id="settingGuide">
                    <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                </span></div>
            <EditTextarea rows={3}
                id="Objective-input-field"
                value={openEndedObjective}
                style={{ fontSize: "14px", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "15rem", paddingLeft: "5px" }}
                onEditMode={() => { setTimeout(() => document.querySelector(`#Objective-input-field`).select(), 100); }}
                onChange={(e) => (handleObjectiveChange(e))}
                placeholder="Write your Objective."
            />
        </div>
            <div className="EditorRowContainer">
                <div style={{ marginRight: "0rem" }}>Add Feedback desired
                    <span style={{ cursor: "pointer", paddingLeft: "1rem" }}
                        data-tooltip-content="Which type of feedback you want from your users, eg- Message clarity, overall experience, etc."
                        data-tooltip-id="settingGuide">
                        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                    </span></div>
                <EditTextarea rows={3}
                    id="Feedback-input-field"
                    value={openEndedFeedbackDesired}
                    style={{ fontSize: "14px", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", width: "21rem", paddingLeft: "5px" }}
                    onEditMode={() => { setTimeout(() => document.querySelector(`#Feedback-input-field`).select(), 100); }}
                    onChange={(e) => (handleFeedbackDesiredChange(e))}
                    placeholder="Write your Feedback desired."
                />
            </div>
            {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <label style={{ padding: "5px", margin: "10px 10px 10px 0px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                    onClick={() => handleFeedbackDesiredClick("I'd like feedback on the overall visual attractiveness of the creative asset.")}>
                    Visual appeal</label>
                <label style={{ padding: "5px", margin: "5px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                    onClick={() => handleFeedbackDesiredClick("I'd like feedback on the clarity and impact of the message conveyed.")}>
                    Message clarity</label>
                <label style={{ padding: "5px", margin: "10px 0px 10px 10px", fontSize: "14px", backgroundColor: "#EDEDED", borderRadius: "4px", cursor: "pointer" }}
                    onClick={() => handleFeedbackDesiredClick("I'd like feedback on how well the asset aligns with our brand identity.")}>
                    Brand consistency</label>
            </div> */}
            <AnimatePresence>
                {showErrorMessage && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        style={{
                            position: "fixed",
                            top: "5%",
                            left: "40%",
                            transform: "translateX(-50%)",
                            background: "#DC143C",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            fontSize: "16px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                        }}
                    >
                        {error_message}
                    </motion.div>
                )}
            </AnimatePresence>
            <ReactTooltip id="settingGuide" place="top" effect="solid"> </ReactTooltip>
        </>}
    </>
    )
}

